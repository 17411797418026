import React, { useState } from 'react';
import {
  Box,
  Button as MuiButton,
  ButtonGroup,
  Card as MuiCard,
  CardContent,
  CardProps,
  Grid,
  Link as MuiLink,
  Modal,
  Tooltip as MuiTooltip,
  Typography,
  IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import RoundedButton from '../../../settings/components/Buttons/RoundedButton';
import {
  DefaultUpgradePlanColor,
  PlanPeriod,
  PlanState,
  upgradePlanColor
} from '../../../../models/plan';
import {
  MAX_BUSINESS_SEATS,
  MAX_STARTER_SEATS,
  MAX_TEAM_SEATS,
  MIN_BUSINESS_SEATS,
  MIN_STARTER_SEATS,
  MIN_TEAM_SEATS,
  MONTHLY_BUSINESS_PRICE,
  MONTHLY_DEFAULT_STARTER_PRICE,
  MONTHLY_STARTER_PRICE,
  MONTHLY_TEAM_PRICE,
  YEARLY_BUSINESS_PRICE,
  YEARLY_DEFAULT_BUSINESS_PRICE,
  YEARLY_DEFAULT_STARTER_PRICE,
  YEARLY_DEFAULT_TEAM_PRICE,
  YEARLY_STARTER_PRICE,
  YEARLY_TEAM_PRICE
} from '../../../../constants/plan';
import PlanIcon from '../../../../components/Icons/PlanIcon';
import { PlanName } from '../../../../models/user';
import ToolTipMessage from './ToolTipMessage';
import {
  TRENDPOP_GREY,
  TRENDPOP_PURPLE_3,
  TRENDPOP_WHITE
} from 'constants/colors';
import CloseIcon from '@material-ui/icons/Close';
import {
  BusinessFeatureCardDescription,
  EnterpriseFeatureCardDescription,
  StarterFeatureCardDescription,
  TeamFeatureCardDescription
} from 'components/Plans/FeatureCardDescription';
import { UpgradePlanLogo } from 'components/Logos';

export interface Organization {
  response?: any;
  status_code?: number;
}
export interface RenderPlansProps {
  tab: PlanPeriod;
  planState: PlanState;
  setPlanState: React.Dispatch<React.SetStateAction<PlanState>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SelectPlanModalButtonProps {
  planState: PlanState;
  setPlanState: React.Dispatch<React.SetStateAction<PlanState>>;
}

export interface RenderCardPlanProps {
  isSelected: boolean;
  setPlanState: React.Dispatch<React.SetStateAction<PlanState>>;
  tab?: PlanPeriod;
  planCount?: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CounterButton = withStyles(() => ({
  root: {
    borderRadius: 30,
    color: '#000',
    minWidth: 25,
    maxHeight: 20,
    padding: 0
  }
}))(MuiButton);

const DisplayButton = withStyles(() => ({
  root: {
    color: '#000',
    backgroundColor: '#f0f0f0',
    minWidth: 35,
    maxHeight: 20,
    pointerEvents: 'none'
  }
}))(MuiButton);

const Card = withStyles(() => ({
  root: {
    height: 650,
    backgroundColor: '#fff',
    border: (props: { current: string }) =>
      props.current === 'true'
        ? `5px solid ${TRENDPOP_PURPLE_3}`
        : `1px solid ${TRENDPOP_GREY}`,
    borderRadius: 20
  }
}))((props: { current: string } & CardProps) => <MuiCard {...props} />);

const Tooltip = withStyles(() => ({
  arrow: {
    color: '#333333'
  },
  tooltip: {
    fontSize: 14,
    backgroundColor: '#333333'
  }
}))(MuiTooltip);

const Link = withStyles(() => ({
  root: {
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: TRENDPOP_PURPLE_3
    }
  }
}))(MuiLink);

const RenderStarterComponent = (props: RenderCardPlanProps) => {
  const {
    tab,
    isSelected = false,
    setPlanState,
    planCount = MIN_STARTER_SEATS,
    setOpen
  } = props;
  const [count, setCount] = useState<number>(planCount);

  // Constants used
  const minSeats = MIN_STARTER_SEATS;
  const maxSeats = MAX_STARTER_SEATS;
  const rate =
    tab === PlanPeriod.Monthly
      ? count > minSeats
        ? MONTHLY_DEFAULT_STARTER_PRICE +
          (count - minSeats) * MONTHLY_STARTER_PRICE
        : MONTHLY_DEFAULT_STARTER_PRICE
      : count > minSeats
      ? YEARLY_DEFAULT_STARTER_PRICE + (count - minSeats) * YEARLY_STARTER_PRICE
      : YEARLY_DEFAULT_STARTER_PRICE;

  const handleSubtract = () => {
    if (count > minSeats) {
      const newCount = count - 1;
      setCount(newCount);

      if (isSelected) {
        setPlanState((prevPlanState) => ({
          ...prevPlanState,
          rate:
            tab === PlanPeriod.Monthly
              ? newCount > minSeats
                ? MONTHLY_DEFAULT_STARTER_PRICE +
                  (newCount - minSeats) * MONTHLY_STARTER_PRICE
                : newCount * MONTHLY_STARTER_PRICE
              : newCount * YEARLY_STARTER_PRICE,
          count: newCount,
          isPlanSelected: true
        }));
      }
    }
  };

  const handleAdd = () => {
    if (count < maxSeats) {
      const newCount = count + 1;
      setCount(newCount);

      if (isSelected) {
        setPlanState((prevPlanState) => ({
          ...prevPlanState,
          rate:
            tab === PlanPeriod.Monthly
              ? newCount * MONTHLY_STARTER_PRICE
              : newCount * YEARLY_STARTER_PRICE,
          count: newCount,
          isPlanSelected: true
        }));
      }
    }
  };

  const handleSelect = () => {
    setPlanState((prevPlanState) => ({
      ...prevPlanState,
      plan: PlanName.Starter,
      count: count,
      rate: rate,
      isPlanSelected: true
    }));
    setOpen(false);
  };

  // Dynamically changes button label for plan
  let buttonLabel = 'Selected';
  if (!isSelected) {
    buttonLabel = 'Select';
  }

  const tooltipText = ToolTipMessage(count, minSeats, maxSeats);

  return (
    <Card current={`${isSelected}`}>
      <CardContent>
        <Box height={25} marginTop={1} />
        <Box marginBottom={2} display="flex" alignItems="center">
          <PlanIcon color={upgradePlanColor[PlanName.Starter]} />
          <Typography variant="h5" style={{ paddingLeft: 5 }}>
            Starter
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">
            {tab === PlanPeriod.Monthly
              ? `$${rate}`
              : `$${Math.round((rate / 12) * 100) / 100}`}
          </Typography>
          <Typography variant="h4" display="inline" color="textSecondary">
            / month
          </Typography>
        </Box>
        <Typography variant="body1" color="textSecondary">
          {tab === PlanPeriod.Monthly
            ? ' Billed Monthly.'
            : `Billed at $${rate} annually.`}
        </Typography>
        <Box
          marginTop={10}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <ButtonGroup size="small" style={{ paddingRight: 10 }}>
            <Tooltip
              arrow
              interactive
              title={count === minSeats ? tooltipText : ''}>
              <CounterButton onClick={handleSubtract}>
                <Typography align="center">-</Typography>
              </CounterButton>
            </Tooltip>
            <DisplayButton>{count}</DisplayButton>
            <Tooltip
              arrow
              interactive
              title={count === maxSeats ? tooltipText : ''}>
              <CounterButton onClick={handleAdd}>
                <Typography align="center">+</Typography>
              </CounterButton>
            </Tooltip>
          </ButtonGroup>
          <Typography variant="body2" color="textSecondary">
            Monthly active users
          </Typography>
        </Box>
        <Box marginY={2} display="flex" justifyContent="center">
          <RoundedButton
            onClick={handleSelect}
            color={isSelected ? TRENDPOP_WHITE : DefaultUpgradePlanColor}
            backgroundColor={
              !isSelected ? TRENDPOP_WHITE : DefaultUpgradePlanColor
            }
            borderColor={DefaultUpgradePlanColor}
            style={{ width: '100%' }}
            value={buttonLabel}
          />
        </Box>
        <Box my={3} />
        <Box ml={2}>
          <StarterFeatureCardDescription />
        </Box>
      </CardContent>
    </Card>
  );
};

const RenderTeamComponent = (props: RenderCardPlanProps) => {
  const {
    tab,
    isSelected = false,
    setPlanState,
    planCount = MIN_TEAM_SEATS,
    setOpen
  } = props;
  const [count, setCount] = useState<number>(planCount);

  // Constants used
  const minSeats = MIN_TEAM_SEATS;
  const maxSeats = MAX_TEAM_SEATS;
  const rate =
    tab === PlanPeriod.Monthly
      ? count * MONTHLY_TEAM_PRICE
      : count > minSeats
      ? YEARLY_DEFAULT_TEAM_PRICE + (count - minSeats) * YEARLY_TEAM_PRICE
      : YEARLY_DEFAULT_TEAM_PRICE;

  const handleSubtract = () => {
    if (count > minSeats) {
      const newCount = count - 1;
      setCount(newCount);

      if (isSelected) {
        setPlanState((prevPlanState) => ({
          ...prevPlanState,
          rate:
            tab === PlanPeriod.Monthly
              ? newCount * MONTHLY_TEAM_PRICE
              : count > minSeats
              ? YEARLY_DEFAULT_TEAM_PRICE +
                (count - minSeats) * YEARLY_TEAM_PRICE
              : YEARLY_DEFAULT_TEAM_PRICE,
          count: newCount,
          isPlanSelected: true
        }));
      }
    }
  };

  const handleAdd = () => {
    if (count < maxSeats) {
      const newCount = count + 1;
      setCount(newCount);

      if (isSelected) {
        setPlanState((prevPlanState) => ({
          ...prevPlanState,
          rate:
            tab === PlanPeriod.Monthly
              ? newCount * MONTHLY_TEAM_PRICE
              : newCount * YEARLY_TEAM_PRICE,
          count: newCount,
          isPlanSelected: true
        }));
      }
    }
  };

  const handleSelect = () => {
    setPlanState((prevPlanState) => ({
      ...prevPlanState,
      plan: PlanName.Team,
      count: count,
      rate: rate,
      isPlanSelected: true
    }));
    setOpen(false);
  };

  // Dynamically changes button label for plan
  let buttonLabel = 'Selected';
  if (!isSelected) {
    buttonLabel = 'Select';
  }

  const tooltipText = ToolTipMessage(count, minSeats, maxSeats);

  return (
    <Card current={`${isSelected}`}>
      <CardContent>
        <Box height={25} marginTop={1} />
        <Box marginBottom={2} display="flex" alignItems="center">
          <PlanIcon color={upgradePlanColor[PlanName.Team]} />
          <Typography variant="h5" style={{ paddingLeft: 5 }}>
            Team
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">
            {tab === PlanPeriod.Monthly
              ? `$${rate}`
              : `$${Math.round((rate / 12) * 100) / 100}`}
          </Typography>
          <Typography variant="h4" display="inline" color="textSecondary">
            / month
          </Typography>
        </Box>
        <Typography variant="body1" color="textSecondary">
          {tab === PlanPeriod.Monthly
            ? ' Billed Monthly.'
            : `Billed at $${rate} annually.`}
        </Typography>
        <Box
          marginTop={10}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <ButtonGroup size="small" style={{ paddingRight: 10 }}>
            <Tooltip
              arrow
              interactive
              title={count === minSeats ? tooltipText : ''}>
              <CounterButton onClick={handleSubtract}>
                <Typography align="center">-</Typography>
              </CounterButton>
            </Tooltip>
            <DisplayButton>{count}</DisplayButton>
            <Tooltip
              arrow
              interactive
              title={count === maxSeats ? tooltipText : ''}>
              <CounterButton onClick={handleAdd}>
                <Typography align="center">+</Typography>
              </CounterButton>
            </Tooltip>
          </ButtonGroup>
          <Typography variant="body2" color="textSecondary">
            Monthly active users
          </Typography>
        </Box>
        <Box marginY={2} display="flex" justifyContent="center">
          <RoundedButton
            onClick={handleSelect}
            color={isSelected ? TRENDPOP_WHITE : DefaultUpgradePlanColor}
            backgroundColor={
              !isSelected ? TRENDPOP_WHITE : DefaultUpgradePlanColor
            }
            borderColor={DefaultUpgradePlanColor}
            style={{ width: '100%' }}
            value={buttonLabel}
          />
        </Box>
        <Box my={3} />
        <Box ml={2}>
          <TeamFeatureCardDescription />
        </Box>
      </CardContent>
    </Card>
  );
};

const RenderBusinessComponent = (props: RenderCardPlanProps) => {
  const {
    tab,
    isSelected = false,
    setPlanState,
    planCount = MIN_BUSINESS_SEATS,
    setOpen
  } = props;
  const [count, setCount] = useState<number>(planCount);

  // Constants used
  const minSeats = MIN_BUSINESS_SEATS;
  const maxSeats = MAX_BUSINESS_SEATS;
  const rate =
    tab === PlanPeriod.Monthly
      ? count * MONTHLY_BUSINESS_PRICE
      : count > minSeats
      ? YEARLY_DEFAULT_BUSINESS_PRICE +
        (count - minSeats) * YEARLY_BUSINESS_PRICE
      : YEARLY_DEFAULT_BUSINESS_PRICE;

  const handleSubtract = () => {
    if (count > minSeats) {
      const newCount = count - 1;
      setCount(newCount);

      if (isSelected) {
        setPlanState((prevPlanState) => ({
          ...prevPlanState,
          rate:
            tab === PlanPeriod.Monthly
              ? newCount * MONTHLY_BUSINESS_PRICE
              : count > minSeats
              ? YEARLY_DEFAULT_BUSINESS_PRICE +
                (count - minSeats) * YEARLY_BUSINESS_PRICE
              : YEARLY_DEFAULT_BUSINESS_PRICE,
          count: newCount,
          isPlanSelected: true
        }));
      }
    }
  };

  const handleAdd = () => {
    if (count < maxSeats) {
      const newCount = count + 1;
      setCount(newCount);

      if (isSelected) {
        setPlanState((prevPlanState) => ({
          ...prevPlanState,
          rate:
            tab === PlanPeriod.Monthly
              ? newCount * MONTHLY_BUSINESS_PRICE
              : newCount * YEARLY_BUSINESS_PRICE,
          count: newCount,
          isPlanSelected: true
        }));
      }
    }
  };

  const handleSelect = () => {
    setPlanState((prevPlanState) => ({
      ...prevPlanState,
      plan: PlanName.Business,
      count: count,
      rate: rate,
      isPlanSelected: true
    }));
    setOpen(false);
  };

  // Dynamically changes button label for plan
  let buttonLabel = 'Selected';
  if (!isSelected) {
    buttonLabel = 'Select';
  }

  const tooltipText = ToolTipMessage(count, minSeats, maxSeats);

  return (
    <Card current={`${isSelected}`}>
      <CardContent>
        <Box height={25} marginTop={1} />
        <Box marginBottom={2} display="flex" alignItems="center">
          <PlanIcon color={upgradePlanColor[PlanName.Business]} />
          <Typography variant="h5" style={{ paddingLeft: 5 }}>
            Business
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">
            {tab === PlanPeriod.Monthly
              ? `$${rate}`
              : `$${Math.round((rate / 12) * 100) / 100}`}
          </Typography>
          <Typography variant="h4" display="inline" color="textSecondary">
            / month
          </Typography>
        </Box>
        <Typography variant="body1" color="textSecondary">
          {tab === PlanPeriod.Monthly
            ? ' Billed Monthly.'
            : `Billed at $${rate} annually.`}
        </Typography>
        <Box
          marginTop={10}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <ButtonGroup size="small" style={{ paddingRight: 10 }}>
            <Tooltip
              arrow
              interactive
              title={count === minSeats ? tooltipText : ''}>
              <CounterButton onClick={handleSubtract}>
                <Typography align="center">-</Typography>
              </CounterButton>
            </Tooltip>
            <DisplayButton>{count}</DisplayButton>
            <Tooltip
              arrow
              interactive
              title={count === maxSeats ? tooltipText : ''}>
              <CounterButton onClick={handleAdd}>
                <Typography align="center">+</Typography>
              </CounterButton>
            </Tooltip>
          </ButtonGroup>
          <Typography variant="body2" color="textSecondary">
            Monthly active users
          </Typography>
        </Box>
        <Box marginY={2} display="flex" justifyContent="center">
          <RoundedButton
            onClick={handleSelect}
            color={isSelected ? TRENDPOP_WHITE : DefaultUpgradePlanColor}
            backgroundColor={
              !isSelected ? TRENDPOP_WHITE : DefaultUpgradePlanColor
            }
            borderColor={DefaultUpgradePlanColor}
            style={{ width: '100%' }}
            value={buttonLabel}
          />
        </Box>
        <Box my={3} />
        <Box ml={2}>
          <BusinessFeatureCardDescription />
        </Box>
      </CardContent>
    </Card>
  );
};

const RenderEnterpriseComponent = (props: RenderCardPlanProps) => {
  const { isSelected = false, setPlanState, setOpen } = props;

  const handleSelect = () => {
    setPlanState((prevPlanState) => ({
      ...prevPlanState,
      plan: PlanName.Enterprise,
      count: 0,
      rate: 0,
      isPlanSelected: true
    }));
    setOpen(false);
  };

  // Dynamically changes button label for plan
  let buttonLabel = 'Selected';
  if (!isSelected) {
    buttonLabel = 'Select';
  }

  return (
    <Card current={`${isSelected}`}>
      <CardContent>
        <Box height={25} marginTop={1} />
        <Box marginBottom={2} display="flex" alignItems="center">
          <PlanIcon color={upgradePlanColor[PlanName.Enterprise]} />
          <Typography variant="h5" style={{ paddingLeft: 5 }}>
            Enterprise
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">Custom</Typography>
          <Typography variant="h4" display="inline" color="textSecondary">
            / month
          </Typography>
        </Box>
        <Box my={21} />
        <Box display="flex" justifyContent="center">
          <RoundedButton
            onClick={handleSelect}
            color={isSelected ? TRENDPOP_WHITE : DefaultUpgradePlanColor}
            backgroundColor={
              !isSelected ? TRENDPOP_WHITE : DefaultUpgradePlanColor
            }
            borderColor={DefaultUpgradePlanColor}
            style={{ width: '100%' }}
            value={buttonLabel}
          />
        </Box>
        <Box my={3} />
        <Box ml={2}>
          <EnterpriseFeatureCardDescription />
        </Box>
      </CardContent>
    </Card>
  );
};

const RenderPlans = (props: RenderPlansProps) => {
  const { tab, planState, setPlanState, setOpen } = props;

  return (
    <Box padding={5}>
      <Grid container direction="row" justify="center">
        <Box width={280} padding={2}>
          <RenderStarterComponent
            tab={tab}
            isSelected={planState.plan === PlanName.Starter ? true : false}
            setPlanState={setPlanState}
            planCount={
              planState.plan === PlanName.Starter ? planState.count : undefined
            }
            setOpen={setOpen}
          />
        </Box>
        <Box width={280} padding={2}>
          <RenderTeamComponent
            tab={tab}
            isSelected={planState.plan === PlanName.Team ? true : false}
            setPlanState={setPlanState}
            planCount={
              planState.plan === PlanName.Team ? planState.count : undefined
            }
            setOpen={setOpen}
          />
        </Box>
        <Box width={280} padding={2}>
          <RenderBusinessComponent
            tab={tab}
            isSelected={planState.plan === PlanName.Business ? true : false}
            setPlanState={setPlanState}
            planCount={
              planState.plan === PlanName.Business ? planState.count : undefined
            }
            setOpen={setOpen}
          />
        </Box>
        <Box width={280} padding={2}>
          <RenderEnterpriseComponent
            isSelected={
              planState.plan !== PlanName.Starter &&
              planState.plan !== PlanName.Team &&
              planState.plan !== PlanName.Business
                ? true
                : false
            }
            setPlanState={setPlanState}
            setOpen={setOpen}
          />
        </Box>
      </Grid>
    </Box>
  );
};

const SelectPlanModalButton: React.FC<SelectPlanModalButtonProps> = ({
  planState,
  setPlanState
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {planState.isPlanSelected ? (
        <Box my={2}>
          <Typography variant="body1">
            <Link onClick={() => setOpen(true)}>Change Plan</Link>
          </Typography>
        </Box>
      ) : (
        <Box my={5}>
          <Typography variant="h6">
            <Link onClick={() => setOpen(true)}>Select one here</Link> or get
            <br />
            started with the one below
          </Typography>
        </Box>
      )}

      <Modal open={open} onClose={() => setOpen(false)} disableAutoFocus>
        <Box
          style={{
            width: '90vw',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            borderRadius: '30px',
            maxHeight: '95%',
            overflow: 'auto',
            padding: 4
          }}>
          <Box position="absolute" top={30} left={{ xs: 10, sm: 50 }}>
            <UpgradePlanLogo />
          </Box>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            <Box mt={4} width="100%" display="flex" justifyContent="right">
              <IconButton
                onClick={() => setOpen(false)}
                style={{ marginRight: 20 }}>
                <CloseIcon style={{ width: 24, height: 24 }} />
              </IconButton>
            </Box>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center">
              {/* ENG-1205: remove yearly option from product */}
              <RenderPlans
                tab={PlanPeriod.Monthly}
                planState={planState}
                setPlanState={setPlanState}
                setOpen={setOpen}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default SelectPlanModalButton;

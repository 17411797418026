import React from 'react';
import styled from 'styled-components/macro';
import { NavLink, withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../../vendor/perfect-scrollbar.css';
import {
  Box,
  Chip,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import _ from 'lodash';

import { useUser } from '../../context/hooks/user';
import { dashboardSettingsRoutes } from '../../routes';
import { DashboardSettingsLogo } from '../../components/Logos';
import { CloverSvg } from '../../components/Icons/Clover';

const SIDEBAR_WIDTH = '258px';

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  min-width: ${SIDEBAR_WIDTH};
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-left: ${(props) => props.theme.spacing(3.5)}px;
  padding-right: ${(props) => props.theme.spacing(3.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: 0px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const Category = styled(ListItem)`
  cursor: pointer;
  border-radius: 30px;
  margin-top: ${(props) => props.theme.spacing(4)}px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
  padding-top: ${(props) => props.theme.spacing(1)}px;
  padding-bottom: ${(props) => props.theme.spacing(1)}px;
  padding-left: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => props.theme.sidebar.activeBackground};

    span {
      color: ${(props) => props.theme.sidebar.activeColor};
    }

    svg {
      color: ${(props) => props.theme.sidebar.activeColor};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const Link = styled(ListItem)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  padding-left: ${(props) => props.theme.spacing(16)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  width: fit-content;

  span {
    display: block;
    width: fit-content;
    color: ${(props) => props.theme.sidebar.color};
  }

  .clover-active {
    display: none;
  }

  &.${(props) => props.activeClassName} {
    span {
      color: ${(props) => props.theme.sidebar.activeLinkColor};
    }

    .clover-active {
      display: contents;
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarCategory = ({ name, icon, badge, ...rest }) => {
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {badge ? <CategoryBadge label={badge} /> : ''}
    </Category>
  );
};

const SidebarLink = ({
  name,
  to,
  badge,
  isAdminOnly = false,
  isAdmin = false
}) => {
  // Greys out the route if user is not admin and accessing an admin route
  const color = isAdminOnly === true && isAdmin === false ? 'grey' : '#c487ff';

  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
      color={color}>
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ''}
      <Box className="clover-active" display="flex" alignItems="center">
        <CloverSvg
          style={{ marginLeft: 10 }}
          color={color}
          size={20}
          viewBoxWidth={50}
          viewBoxHeight={25}
        />
      </Box>
    </Link>
  );
};

const DashboardSidebar = ({ location, ...sidebarProps }) => {
  const { user } = useUser();

  const routes = [...dashboardSettingsRoutes()];
  const isAdmin = user.isWorkspaceAdmin();
  const isWorkspaceActive =
    user && user.getWorkspace() ? user.getIsWorkspaceActive() : false;

  return (
    <Drawer {..._.omit(sidebarProps, 'staticContext')}>
      <Brand
        component={NavLink}
        to={isWorkspaceActive ? '/auth/callback' : '/workspace-select'}
        button>
        <DashboardSettingsLogo style={{ maxHeight: 30, maxWidth: 200 }} />
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            <Category
              to={isWorkspaceActive ? '/auth/callback' : '/workspace-select'}
              component={NavLink}>
              <ChevronLeftIcon />
              <CategoryText>Back</CategoryText>
            </Category>
            {routes
              .filter((x) => !x.hideFromSidebar)
              .filter((r) => !r.enabledForUser || r.enabledForUser(user))
              .map((category, index) => (
                <React.Fragment key={index}>
                  {category.children && category.icon ? (
                    <React.Fragment key={index}>
                      <SidebarCategory
                        className={(() => {
                          if (
                            location.pathname &&
                            location.pathname.startsWith(
                              category.sidebarPath || category.path
                            )
                          ) {
                            return 'active';
                          } else {
                            return null;
                          }
                        })()}
                        name={category.id}
                        to={category.sidebarPath || category.path}
                        activeClassName="active"
                        component={NavLink}
                        icon={category.icon}
                        exact
                        button
                      />

                      {category.children
                        .filter(
                          (r) => !r.enabledForUser || r.enabledForUser(user)
                        )
                        .map((route, index) => (
                          <SidebarLink
                            key={index}
                            name={route.name}
                            to={route.sidebarPath || route.path}
                            icon={route.icon}
                            badge={route.badge}
                            isAdminOnly={route.isAdminOnly}
                            isAdmin={isAdmin}
                          />
                        ))}
                    </React.Fragment>
                  ) : category.icon ? (
                    <SidebarCategory
                      className={(() => {
                        if (
                          location.pathname &&
                          location.pathname.startsWith(
                            category.sidebarPath || category.path
                          )
                        ) {
                          return 'active';
                        } else {
                          return null;
                        }
                      })()}
                      name={category.id}
                      to={category.sidebarPath || category.path}
                      activeClassName="active"
                      component={NavLink}
                      icon={category.icon}
                      exact
                      button
                      badge={category.badge}
                    />
                  ) : null}
                </React.Fragment>
              ))}
          </Items>
        </List>
      </Scrollbar>
    </Drawer>
  );
};

export default withRouter(DashboardSidebar);

import axios from 'axios';
import { requester } from '../../api';
import { abbaResponseHandler, axiosErrorHandler } from 'api/util';
import { ReportType } from './models';
import { LIVE_FETCH } from 'constants/links';
import { useQuery } from 'react-query';
import { APIRequestError } from 'models/http';
import {
  backendResourceNameOf,
  RefreshEntityType,
  RefreshResponse
} from 'models/refresh';

const CancelToken = axios.CancelToken;

export enum ReportTypeField {
  profile = 'profile_id',
  profile_name = 'profile_unique_id',
  tag = 'tag_title',
  sound = 'sound_id',
  song = 'song_id',
  album = 'album_id',
  artist = 'artist_id',
  item = 'video_id'
}

interface SaveDetailReportHistoryParams {
  type: ReportType;
  field: ReportTypeField;
  id: string;
  url: string;
}

export const saveDetailReportHistory = (
  body: SaveDetailReportHistoryParams
): Promise<any> => {
  const url = `${process.env.REACT_APP_MIA_URL}/v1/dashboard/search_history`;
  const cancelToken = CancelToken.source();

  const params = {
    type: body.type,
    url: body.url,
    [body.field]: body.id
  };

  return requester
    .post(
      url,
      {
        ...params
      },
      {
        cancelToken: cancelToken.token
      }
    )
    .then(abbaResponseHandler, axiosErrorHandler);
};

export const generatePdf = (
  id: string,
  urlType: string,
  key: string,
  extraParams: any
): Promise<any> => {
  const url = `${process.env.REACT_APP_MIA_URL}/v1/report/${urlType}-pdf`;
  const cancelToken = CancelToken.source();

  const params = {
    [key]: id,
    ...extraParams
  };

  return requester
    .post(url, params, {
      cancelToken: cancelToken.token,
      responseType: 'blob'
    })
    .then(abbaResponseHandler, axiosErrorHandler);
};

const getUrlForLiveFetch = (
  refreshType: RefreshEntityType,
  id: string,
  videoId?: string
): string => {
  const resource = backendResourceNameOf(refreshType);
  let endpoint = `${LIVE_FETCH}/${resource}/${id}`;
  endpoint +=
    refreshType === RefreshEntityType.Video ? `/videos/${videoId}` : '';
  return endpoint;
};

export const useLiveFetchReport = (
  id: string,
  type: RefreshEntityType,
  enabled: boolean,
  videoId?: string
) => {
  const url = getUrlForLiveFetch(type, id, videoId);

  return useQuery<RefreshResponse, APIRequestError>(
    [`tp-live-fetch-id-${type}`, id],
    async () => {
      return await requester
        .get<RefreshResponse>(url)
        .then(abbaResponseHandler, axiosErrorHandler);
    },
    {
      refetchInterval: 2000,
      enabled: !!id && enabled
    }
  );
};

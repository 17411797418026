import React, { useContext, useState } from 'react';
import LoggedOutModal from '../components/Auth/LoggedOutModal';

interface LoggedOutModalContextType {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export const LoggedOutModalContext = React.createContext<
  LoggedOutModalContextType | undefined
>(undefined);

export const useLoggedOutModalContext = () => {
  const context = useContext(LoggedOutModalContext);
  if (!context) {
    throw new Error(
      'useLoggedOutModalContext must be used within an LoggedOutModalContextProvider'
    );
  }
  return context;
};

export const LoggedOutModalProvider = ({
  children
}: {
  children: JSX.Element[];
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <LoggedOutModalContext.Provider value={{ showModal, setShowModal }}>
      {showModal && (
        <LoggedOutModal open={showModal} onClose={() => setShowModal(false)} />
      )}
      {children}
    </LoggedOutModalContext.Provider>
  );
};

import { Icon } from '@iconify/react';
import googleFill from '@iconify-icons/mdi/google';
import { TRENDPOP_GREY } from 'constants/colors';

interface GoogleIconProps {
  color?: string;
  size?: string;
}

const GoogleIcon = (props: GoogleIconProps) => {
  const color = props.color || TRENDPOP_GREY;
  const size = props.size || '1em';

  return <Icon icon={googleFill} color={color} width={size} height={size} />;
};

export default GoogleIcon;

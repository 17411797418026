import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = (props) =>
  makeStyles({
    button: {
      color: props.color || '#fff',
      backgroundColor: props.backgroundColor || '#000',
      borderColor: props.borderColor || '#000',
      borderRadius: 28,
      fontSize: 14,
      marginBottom: 2,
      padding: '12px 30px',
      border: `1px solid ${props.borderColor || '#000'}`,
      height: props.buttonSize === 'small' ? 35 : 'auto',

      '&:hover': {
        backgroundColor: props.backgroundColor || '#000',
        boxShadow: '0px 2px 5px 1px #bebfbf'
      },

      '&.Mui-disabled': {
        border: '0px',
        backgroundColor: '#c4c8cc',
        color: '#fff'
      }
    }
  });

const RoundedButton = (props) => {
  const classes = useStyles(props)();
  const { disabled, href, onClick, value, endIcon, target, style } = props;

  return (
    <Button
      className={classes.button}
      disabled={disabled || false}
      href={href}
      onClick={onClick}
      endIcon={endIcon}
      target={target}
      style={style}>
      {value}
    </Button>
  );
};

export default RoundedButton;

import { Box } from '@material-ui/core';
import MurmurHash3 from 'imurmurhash';

export const SVG_FILENAMES = [
  'albotros-head.svg',
  'ant-eater.svg',
  'baboon-head.svg',
  'bear-head.svg',
  'beaver-head.svg',
  'big-angler.svg',
  'big-bee.svg',
  'big-beetle.svg',
  'big-bug.svg',
  'big-butterfly.svg',
  'big-centipede.svg',
  'big-crab.svg',
  'big-dragonflay.svg',
  'big-flounder.svg',
  'big-fly.svg',
  'big-grampus.svg',
  'big-ladybug.svg',
  'big-mite.svg',
  'big-moray.svg',
  'big-mosquito.svg',
  'big-perch.svg',
  'big-pike.svg',
  'big-piranha.svg',
  'big-salmon.svg',
  'big-scorpio.svg',
  'big-shark.svg',
  'big-shrimp.svg',
  'big-silverfish.svg',
  'big-spider.svg',
  'big-squid.svg',
  'big-starfish.svg',
  'big-swordfish.svg',
  'big-tuna.svg',
  'big-wasp.svg',
  'big-zander.svg',
  'bison-head.svg',
  'boar-head.svg',
  'bulldog-head.svg',
  'butterfly-with-big-wings.svg',
  'capybara-head.svg',
  'cat-head.svg',
  'chameleon-head.svg',
  'chimpanzee-head.svg',
  'cock-head.svg',
  'colibri-head.svg',
  'cow-head.svg',
  'crane-head.svg',
  'crocodile-head.svg',
  'deadlock-head.svg',
  'deer-head.svg',
  'dog-head.svg',
  'dolphin-jumping.svg',
  'dove-head.svg',
  'duck-head.svg',
  'eagle-head.svg',
  'elephant-head.svg',
  'female-sheep-head.svg',
  'fennec-fox-head.svg',
  'fox-head.svg',
  'frog-head.svg',
  'giraffe-head.svg',
  'globe-fish.svg',
  'goat-head.svg',
  'gold-fish.svg',
  'guinea-pig-heag.svg',
  'hedgehog-head.svg',
  'hippopotamus-head.svg',
  'horse-head.svg',
  'hyena-head.svg',
  'kangaroo-head.svg',
  'koala-head.svg',
  'lama-head.svg',
  'lemur-head.svg',
  'lion-head.svg',
  'male-sheep-head.svg',
  'mink-head.svg',
  'mole-head.svg',
  'moose-head.svg',
  'mouse-head.svg',
  'ostrich-head.svg',
  'owl-head.svg',
  'panda-bear-head.svg',
  'parrot-head.svg',
  'penguin-head.svg',
  'pig-head.svg',
  'platypus-head.svg',
  'rabbit-head.svg',
  'raccoon-head.svg',
  'sea-urchin.svg',
  'seal-head.svg',
  'skunk-head.svg',
  'sloth-head.svg',
  'snake-head.svg',
  'snigir-head.svg',
  'sparrow-head.svg',
  'squirrel-head.svg',
  'swan-head.svg',
  'tiger-head.svg',
  'turtle-head.svg',
  'wolf-head.svg'
];

const CollectionIcon = (props) => {
  const { collection, size = 45, padding = 4, borderWidth = 2 } = props;
  const idx = MurmurHash3(collection.id).result() % SVG_FILENAMES.length;
  const path = collection.icon
    ? collection.icon
    : '/static/img/animal-pack/' + SVG_FILENAMES[idx];

  return (
    <Box
      style={{
        borderStyle: 'solid',
        borderWidth: borderWidth,
        borderColor: 'rgba(0, 0, 0, .75)',
        borderRadius: size / 10,
        padding: padding,
        width: size + padding * 2 + borderWidth * 2,
        height: size + padding * 2 + borderWidth * 2
      }}>
      <img alt={collection.name} src={path} height={size} width={size} />
    </Box>
  );
};

export default CollectionIcon;

import React from 'react';
import { withTheme } from 'styled-components/macro';
import { Box, Hidden, IconButton } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import DashboardUserDropdown from './DashboardUserDropdown';
import { Menu as MenuIcon } from '@material-ui/icons';

const DashboardHeader = (props) => {
  const { drawerToggle } = props;

  return (
    <Box
      padding={4}
      position="relative"
      width="100%"
      display="flex"
      flexDirection="row-reverse"
      alignItems="center"
      justifyContent="space-between">
      <DashboardUserDropdown />
      <Hidden mdUp>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={drawerToggle}>
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Box>
  );
};

export default withWidth()(withTheme(DashboardHeader));

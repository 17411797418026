import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import {
  axiosErrorHandler,
  abbaResponseHandler,
  miaResponseHandler,
  structuredErrorHandler
} from './util';
import { requester } from './index';
import { UserResponse, WorkspaceUserRole } from '../models/user';
import { MIA_FORGOT_PASSWORD } from 'constants/links';

const CancelToken = axios.CancelToken;

export interface UpdateUserArgs {
  digest_frequency?: string;
  alert_frequency?: string;
}

export const legacyGetUser = (): [Promise<UserResponse>, CancelTokenSource] => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v1/workspace/user`;
  const cancelToken = CancelToken.source();

  return [
    requester
      .get<AxiosResponse<UserResponse>>(url, {
        cancelToken: cancelToken.token,
        // /v1/user is an ABBA API request that will be used to set, clear, and update cookies.
        // Because this endpoint may update cookies from a cross-site origin, we need to explicitly
        // opt-in clientside.
        withCredentials: true
      })
      .then(abbaResponseHandler)
      .catch(axiosErrorHandler),
    cancelToken
  ];
};

export const updateUser = (
  updateOptions: UpdateUserArgs
): [Promise<undefined>, CancelTokenSource] => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v1/user/update`;
  const cancelToken = CancelToken.source();
  return [
    requester
      .post(url, updateOptions, {
        cancelToken: cancelToken.token
      })
      .then(abbaResponseHandler, structuredErrorHandler),
    cancelToken
  ];
};

export const addUser = (
  workspaceId: string,
  email?: string,
  admin?: boolean
) => {
  const url = `${process.env.REACT_APP_MIA_URL}/v1/workspace/users/invite`;
  return requester
    .post(
      url,
      {
        email: email,
        admin: admin
      },
      {
        withCredentials: true
      }
    )
    .then(miaResponseHandler, axiosErrorHandler);
};

export const removeUser = (workspaceId: string, email: string) => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v1/workspace/${workspaceId}/users`;
  return requester
    .delete(url, { data: { email: email } })
    .then(abbaResponseHandler, structuredErrorHandler);
};

export const updateWorkspaceUser = (
  workspaceId: string,
  userId: string,
  updateCols: { role?: WorkspaceUserRole }
) => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v1/workspace/${workspaceId}/user/${userId}`;
  return requester
    .post(url, updateCols)
    .then(abbaResponseHandler, structuredErrorHandler);
};

export const listCollections = () => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v2/user/collections`;
  const cancelToken = CancelToken.source();
  return [
    requester
      .get(url, {
        cancelToken: cancelToken.token
      })
      .then(abbaResponseHandler, structuredErrorHandler),
    cancelToken
  ];
};

export const changePassword = (email: string) => {
  const url = MIA_FORGOT_PASSWORD;
  const cancelToken = CancelToken.source();
  return [
    requester
      .post(
        url,
        { email, redirect: `${window.location.origin}/auth/reset-password` },
        {
          cancelToken: cancelToken.token
        }
      )
      .then(abbaResponseHandler, structuredErrorHandler),
    cancelToken
  ];
};

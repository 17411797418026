import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import {
  appRoutes,
  publicFacingRoutes,
  dashboardSettingsRoutes
} from './index';
import { QueryParamProvider } from 'use-query-params';

import { SearchMetadataProvider } from '../context/searchMetadata';
import DashboardLayout from '../layouts/Dashboard';
import PublicLayout from '../layouts/Public';
import DashboardSettingsLayout from '../layouts/DashboardSettings';
import { RouteListener } from './RouteListener';
import { useParams } from 'react-router';
import { TrendpopUserProvider } from '../context/user';
import { postAuthRoutes } from './auth';
import { LoggedOutModalProvider } from 'context/loggedOutModal';

const Page = (props) => {
  useEffect(() => {
    document.title = props.title || 'Trendpop';
  }, [props.title]);

  return props.children;
};

const childRoutes = (Layout, routes) => {
  return routes.map(
    (
      {
        component: Component,
        guard,
        children,
        path,
        sidebarPath,
        omitHeader,
        id,
        name = ''
      },
      index
    ) => {
      const Guard = guard || React.Fragment;

      return children ? (
        children.map((element, index) => {
          const Guard = element.guard || React.Fragment;

          return (
            <Route
              key={index}
              path={
                element.sidebarPath
                  ? [element.path, element.sidebarPath]
                  : element.path
              }
              exact
              render={(props) => (
                <Page title={element.name}>
                  <Guard>
                    <Layout omitHeader={element.omitHeader}>
                      <element.component {...props} />
                    </Layout>
                  </Guard>
                </Page>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={sidebarPath ? [path, sidebarPath] : path}
          exact
          render={(props) => (
            <Page title={name || id}>
              <Guard>
                <Layout omitHeader={omitHeader}>
                  <Component {...props} />
                </Layout>
              </Guard>
            </Page>
          )}
        />
      ) : null;
    }
  );
};

// This is a temporary migration measure for moving users from /settings/organization (legacy) paths to /settings/workspace (production) paths.
// TODO(yoavz): Remove this component 2 months after launching workspace name change.
const OrganizationRedirectElementToDeprecate = () => {
  const params = useParams();
  const extraPath = params['*'];
  const path = extraPath
    ? `/settings/workspace/${extraPath}`
    : '/settings/workspace';
  return <Redirect to={path} />;
};

const InnerRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/auth/callback" />} />

      {childRoutes(PublicLayout, [postAuthRoutes])}
      {childRoutes(DashboardLayout, appRoutes())}
      {childRoutes(DashboardSettingsLayout, [...dashboardSettingsRoutes()])}

      {/* TODO(yoavz): Remove this route 2 months after launching workspace name */}
      <Route
        exact
        path={'/settings/organization/*'}
        element={OrganizationRedirectElementToDeprecate}
      />
      <Route
        render={() => {
          if (process.env.NODE_ENV === 'development') {
            console.log(`unknown path ${window.location}, redirecting to 404`);
          }
          window.location.replace('/404');
          return null;
        }}
      />
    </Switch>
  );
};

const Routes = () => {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <RouteListener>
          <Switch>
            {childRoutes(PublicLayout, [...publicFacingRoutes()])}
            <LoggedOutModalProvider>
              <TrendpopUserProvider>
                <SearchMetadataProvider>
                  <InnerRoutes />
                </SearchMetadataProvider>
              </TrendpopUserProvider>
            </LoggedOutModalProvider>
          </Switch>
        </RouteListener>
      </QueryParamProvider>
    </Router>
  );
};

export default Routes;

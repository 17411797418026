import React, { useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Button,
  Grid,
  Hidden,
  TextField,
  Link,
  CircularProgress
} from '@material-ui/core';
import {
  TRENDPOP_PURPLE_3,
  TRENDPOP_PURPLE_4
} from '../../../constants/colors';
import Banner from '../components/Banner';
import { validateEmail } from 'pages/settings/WorkspaceSettingsUsers';
import { forgotPassword } from './queries';

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: 600,
    color: TRENDPOP_PURPLE_3
  },
  submitButton: {
    height: 50,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  },
  imageContainer: {
    backgroundColor: TRENDPOP_PURPLE_4,
    background: `url('/static/img/line-vector.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 20%',
    backgroundSize: '100%',
    objectFit: 'cover'
  },
  formContainer: {
    padding: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    maxWidth: '400px',
    width: '100%'
  }
}));

const ForgotPassword = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const validEmail = email.length > 0 && validateEmail(email);

  const handleForgotPassword = () => {
    setLoading(true);

    const promise = forgotPassword({
      email: email,
      redirect: `${window.location.origin}/auth/reset-password`
    });

    promise.then(
      () => {
        setLoading(false);
        setIsSubmitted(true);
      },
      (error: any) => {
        console.log(error);
        setLoading(false);
        setError(
          'Error in sending reset password code. Please try again later.'
        );
      }
    );
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError('');
  };

  return (
    <Grid container justify="center">
      <Hidden smDown>
        <Grid item sm={3} className={classes.imageContainer}>
          <Banner />
        </Grid>
      </Hidden>
      <Grid item xs={10} sm={9} className={classes.formContainer}>
        <Box display="flex" flexDirection="column" className={classes.form}>
          {!isSubmitted ? (
            <>
              <Typography variant="h3" style={{ fontWeight: 600 }}>
                Reset your password
              </Typography>
              <Box my={2} />
              <Typography variant="h6" style={{ fontWeight: 600 }}>
                Enter your email and we'll send you instructions on how to reset
                your password.
              </Typography>
              <Box my={2} />
              <TextField
                fullWidth
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email address"
                error={Boolean(error)}
                helperText={error || ''}
              />
              <Box my={5} />
              <Button
                disabled={loading || !validEmail}
                variant="contained"
                color="primary"
                className={classes.submitButton}
                onClick={handleForgotPassword}>
                {loading ? (
                  <CircularProgress size="20px" />
                ) : (
                  'Send Instructions'
                )}
              </Button>
              <Box my={5} />
              <Box display="flex">
                <Typography variant="body1">
                  Go back to{' '}
                  <Link href="/auth/login" className={classes.link}>
                    Login
                  </Link>
                </Typography>
              </Box>
            </>
          ) : (
            <Box>
              <Typography variant="h3" style={{ fontWeight: 600 }}>
                Instructions sent!
              </Typography>
              <Box my={2} />
              <Typography variant="h6" style={{ fontWeight: 600 }}>
                If an account matches the email you entered, you'll receive a
                link in your inbox to reset your password.
              </Typography>
              <Box my={5} />
              <Box display="flex">
                <Typography variant="body1">
                  Go back to{' '}
                  <Link href="/auth/login" className={classes.link}>
                    Login
                  </Link>
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;

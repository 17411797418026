import React, { useEffect, useReducer, ReactNode } from 'react';

import { updateUser as updateUserRequest, UpdateUserArgs } from '../api/user';
import {
  addCollectionItem as addCollectionItemRequest,
  removeCollectionItem as removeCollectionItemRequest
} from '../api/collections';
import { AmplitudeClient } from '../logging/amplitude';
import _ from 'lodash';

import {
  EmailFrequency,
  HomeScreen,
  PlanName,
  WorkspaceUserRole
} from '../models/user';
import { APIRequestError, TrendpopLoadError } from '../models/http';
import {
  Favorite,
  CollectionItemType,
  DBCollectionItem,
  dbCollectionItemToFavorite,
  CollectionItem,
  collectionItemToFavorite
} from '../models/collection';
import { parseISO } from 'date-fns';
import { Set } from 'immutable';
import { AUTH_LOGIN_PATH } from 'constants/links';
import { useLoginLocalState } from 'pages/accounts/useLoginLocalState';
import { BillingStatus } from 'models/workspaceSelect';

interface Limits {
  collections: number;
  campaigns: number;
  saved_queries: number;
  collection_items: number;
  collection_csv_upload: number;
}

interface Workspace {
  stripe_id: string;
  internal_id: string;
  name: string;
  account_owner_id: string;
  account_owner_email: string;
  subdomain: string | null;
  home_screen: HomeScreen;
  subscription?: Subscription;
  limits?: Limits;
  features?: Features;
}

interface WorkspaceUser {
  id: string;
  admin: boolean;
  role: WorkspaceUserRole;
  favorites: CollectionItem[];
  favorites_collection_id: string;
}

interface Subscription {
  id: string;
  created_at: string;
  customer_id: string;
  status: BillingStatus;
  self_serve_trial: boolean;
  current_period_start: string;
  current_period_end: string;
  cancel_at_period_end: boolean;
  collection_method: string;
  days_until_due?: number;
  billing_interval_count: bigint;
  billing_interval: 'month' | 'quarter' | 'year';
  product_id: string;
  plan_name: PlanName;
  seat_count?: bigint;
  upcoming_invoice_total?: bigint;

  current_seats: number;
  max_seats: number;
  price: {
    adjustable_quantity: boolean;
    id: string;
    max_quantity: number | null;
    min_quantity: number | null;
  };
  product: {
    id: string;
    monthly_price: {
      adjustable_quantity: boolean;
      id: string;
      max_quantity: number | null;
      min_quantity: number | null;
    };
    product_name: string;
    quarterly_price: {
      adjustable_quantity: boolean;
      id: string;
      max_quantity: number | null;
      min_quantity: number | null;
    };
    self_serve: boolean;
    slug: string;
    yearly_price: {
      adjustable_quantity: boolean;
      id: string;
      max_quantity: number | null;
      min_quantity: number | null;
    };
  };
}

export interface Features {
  id: string;
  billing_override: boolean;
  alerting: boolean;
  catalog: boolean;
  hourly_tracking: boolean;
  workspace: boolean;
  api: boolean;
  data_dumps: boolean;
  music_discovery: boolean;
  music_links: boolean;
  sound_discovery: boolean;
  creator_discovery: boolean;
  tag_discovery: boolean;
  benchmarking: boolean;
  video_discovery: boolean;
  dashboard: boolean;
  granular_demographics: boolean;
  youtube_data_dumps: boolean;
  full_access_data_dumps: boolean;
  campaigns: boolean;
  video_discovery_contact_links: boolean;
  saved_queries: boolean;
  notifications: boolean;
}

interface UserModel {
  id: string;
  activated_workspaces: number;
  total_workspaces: number;
  first_name: string;
  last_name: string;
  email: string;
  picture: string;
  digest_frequency?: EmailFrequency;
  alert_frequency?: EmailFrequency;
  authed_at: string;
  email_verified: boolean;
  name_verified: boolean;
  trendpop_admin: boolean;
  newsletter: boolean;
  has_active_trial_workspace: boolean;
}

interface UserResponse {
  user: UserModel;
  workspace_user: WorkspaceUser;
  workspace: Workspace;
}

export const TrendpopUserContext = React.createContext<
  [User | undefined, boolean, TrendpopLoadError | undefined]
>([undefined, true, undefined]);

const favoritesToCacheKey = (f: Favorite) => {
  return `${f.type}:${f.id}`;
};

interface UserState {
  loaded: boolean;
  favoritesCache: Set<string>;
  response?: UserResponse;
}

const INITIAL_STATE: UserState = {
  loaded: false,
  favoritesCache: Set<string>(),
  response: undefined
};

enum UserActionTypes {
  LOAD_TRENDPOP_USER = 'LOAD_TRENDPOP_USER',
  UPDATE_USER = 'UPDATE_USER',
  ADD_TO_FAVORITES = 'ADD_TO_FAVORITES',
  REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES'
}
interface LoadTrendpopAction {
  type: UserActionTypes.LOAD_TRENDPOP_USER;
  payload: UserResponse;
}

interface AddToFavoritesAction {
  type: UserActionTypes.ADD_TO_FAVORITES;
  payload: {
    type: CollectionItemType;
    id: string;
  };
}

interface RemoveFromFavoritesAction {
  type: UserActionTypes.REMOVE_FROM_FAVORITES;
  payload: {
    type: CollectionItemType;
    id: string;
  };
}

interface UpdateUserAction {
  type: UserActionTypes.UPDATE_USER;
  payload: UpdateUserArgs;
}

type DispatchActions =
  | LoadTrendpopAction
  | AddToFavoritesAction
  | RemoveFromFavoritesAction
  | UpdateUserAction;
type DispatchType = React.Dispatch<DispatchActions>;

const userStateReducer = (state: UserState, action: DispatchActions) => {
  switch (action.type) {
    case UserActionTypes.LOAD_TRENDPOP_USER: {
      const response = (action as LoadTrendpopAction).payload;
      try {
        AmplitudeClient.setUserId(response.user.id);
      } catch (e) {
        console.error('Could not set userId for amplitude', e);
      }

      // Instead of manipulating the "favorites" directly, we'll use a more efficient FavoritesCache structure to add and remove from favorites.
      const favorites = response.workspace_user?.favorites;

      const favoritesCache = _.reduce(
        favorites || [],
        (acc: Set<string>, ci: CollectionItem) => {
          const f = collectionItemToFavorite(ci);
          return acc.add(favoritesToCacheKey(f));
        },
        Set<string>()
      );

      return {
        loaded: true,
        response: response,
        favoritesCache: favoritesCache
      };
    }

    case UserActionTypes.ADD_TO_FAVORITES: {
      if (!state.loaded) {
        throw Error('Cannot add to favorites before user is loaded');
      }

      const newItem = (action as AddToFavoritesAction).payload;
      return {
        ...state,
        favoritesCache: state.favoritesCache.add(favoritesToCacheKey(newItem))
      };
    }

    case UserActionTypes.REMOVE_FROM_FAVORITES: {
      if (!state.loaded) {
        throw Error('Cannot remove from favorites before user is loaded');
      }

      const itemToRemove = (action as RemoveFromFavoritesAction).payload;
      return {
        ...state,
        favoritesCache: state.favoritesCache.remove(
          favoritesToCacheKey(itemToRemove)
        )
      };
    }

    case UserActionTypes.UPDATE_USER: {
      if (!state.loaded) {
        throw Error('Cannot remove from update before user is loaded');
      }

      return _.set(state, ['response', 'user'], {
        ...state.response!.user,
        ...action.payload
      });
    }

    default:
      throw new Error('Invalid action type: ' + action);
  }
};

export class User {
  state: UserState;
  dispatch: DispatchType;

  constructor(userState: UserState, dispatch: DispatchType) {
    this.state = userState;
    this.dispatch = dispatch;
  }

  getUserId(): string | undefined {
    return this.state.response?.user?.id;
  }

  getTotalWorkspaces() {
    return this.state.response?.user?.total_workspaces || 0;
  }

  getUserHasTrialWorkspace() {
    return this.state.response?.user?.has_active_trial_workspace;
  }

  getWorkspaceHomeRoute(): HomeScreen {
    return this.state.response?.workspace?.home_screen || 'home';
  }

  getFavoritesCollectionId() {
    return this.state.response?.workspace_user.favorites_collection_id;
  }

  isWorkspaceAdmin() {
    return Boolean(this.state.response?.workspace_user?.admin);
  }

  featureMusicLinkingEnabled() {
    return Boolean(this.state.response?.workspace?.features?.music_links);
  }

  featureMusicDiscoveryEnabled() {
    return Boolean(this.state.response?.workspace?.features?.music_discovery);
  }

  featureSoundDiscoveryEnabled() {
    return Boolean(this.state.response?.workspace?.features?.sound_discovery);
  }

  featureCreatorDiscoveryEnabled() {
    return Boolean(this.state.response?.workspace?.features?.creator_discovery);
  }

  featureTagDiscoveryEnabled() {
    return Boolean(this.state.response?.workspace?.features?.tag_discovery);
  }

  featureVideoDiscoveryEnabled() {
    return Boolean(this.state.response?.workspace?.features?.video_discovery);
  }

  featureBenchmarkingEnabled() {
    return Boolean(this.state.response?.workspace?.features?.benchmarking);
  }

  featureAlertingEnabled() {
    return Boolean(this.state.response?.workspace?.features?.alerting);
  }

  featureCatalogEnabled() {
    return Boolean(this.state.response?.workspace?.features?.catalog);
  }

  featureHourlyTrackingEnabled() {
    return Boolean(this.state.response?.workspace?.features?.hourly_tracking);
  }

  featureDataDumpsEnabled() {
    return Boolean(this.state.response?.workspace?.features?.data_dumps);
  }

  featureAPIEnabled() {
    return Boolean(this.state.response?.workspace?.features?.api);
  }

  featureDashboardEnabled() {
    return Boolean(this.state.response?.workspace?.features?.dashboard);
  }

  featureDeveloperEnabled() {
    return this.featureDataDumpsEnabled() || this.featureAPIEnabled();
  }

  featureWorkspaceEnabled() {
    return Boolean(this.state.response?.workspace?.features?.workspace);
  }

  featureGranularDemographics() {
    return Boolean(
      this.state.response?.workspace?.features?.granular_demographics
    );
  }

  featureVideoDiscoveryContactLinks() {
    return Boolean(
      this.state.response?.workspace?.features?.video_discovery_contact_links
    );
  }

  featureCampaignsEnabled() {
    return Boolean(this.state.response?.workspace?.features?.campaigns);
  }

  featureSavedQueriesEnabled() {
    return Boolean(this.state.response?.workspace?.features?.saved_queries);
  }

  featureNotificationsEnabled() {
    return Boolean(this.state.response?.workspace?.features?.notifications);
  }

  limitCampaigns() {
    return this.state.response?.workspace?.limits?.campaigns || 0;
  }

  limitCollections() {
    return this.state.response?.workspace?.limits?.collections || 0;
  }

  limitSavedQueries() {
    return this.state.response?.workspace?.limits?.saved_queries;
  }

  limitCollectionItems() {
    return this.state.response?.workspace?.limits?.collection_items;
  }

  limitCollectionItemCsvUpload() {
    return this.state.response?.workspace?.limits?.collection_csv_upload;
  }

  billingState() {
    return this.state.response?.workspace?.subscription?.status;
  }

  billingSelfServeTrial() {
    return Boolean(
      this.billingState() === 'trialing' &&
        this.state.response?.workspace?.subscription?.self_serve_trial
    );
  }

  billingValidUntil() {
    const currentPeriodEnd = this.state.response!.workspace?.subscription
      ?.current_period_end;
    return currentPeriodEnd ? parseISO(currentPeriodEnd) : undefined;
  }

  getWorkspace() {
    return this.state.response?.workspace;
  }

  getWorkspaceId() {
    return this.state.response!.workspace.internal_id;
  }

  getIsWorkspaceActive() {
    const isActive =
      this.state.response!.workspace.subscription?.status === 'trialing' ||
      this.state.response!.workspace.subscription?.status === 'active';
    return isActive;
  }

  getWorkspaceAdminEmail() {
    return this.state.response!.workspace.account_owner_email;
  }

  getDigestFrequency() {
    return this.state.response?.user?.digest_frequency || 'off';
  }

  getNewsletter() {
    return this.state.response?.user?.newsletter || false;
  }

  getCatalogAlertFrequency() {
    return this.state.response?.user?.alert_frequency || 'off';
  }

  getFirstName() {
    return this.state.response?.user?.first_name;
  }

  getLastName() {
    return this.state.response?.user?.last_name;
  }

  getDisplayName() {
    return `${this.state.response?.user?.first_name} ${this.state.response?.user?.last_name}`;
  }

  getEmail() {
    return this.state.response!.user.email;
  }

  isUserTrendpopAdmin(): boolean {
    return Boolean(this.state.response?.user?.trendpop_admin);
  }

  getPhotoURL() {
    return this.state.response!.user.picture;
  }

  getIsEmailVerified() {
    return this.state.response!.user.email_verified;
  }

  getIsNameVerified() {
    return this.state.response!.user.name_verified;
  }

  getCurrentSeats() {
    return this.state.response!.workspace.subscription?.current_seats;
  }

  getMaxSeats() {
    return this.state.response!.workspace.subscription?.max_seats;
  }

  getPlan() {
    return this.state.response!.workspace.subscription?.product.slug;
  }

  itemInFavorites(item: Favorite) {
    return this.state.favoritesCache.has(favoritesToCacheKey(item));
  }

  addToFavorites(
    item: DBCollectionItem,
    errorCallback: (error: APIRequestError) => void
  ) {
    const favoriteId = this.getFavoritesCollectionId();
    if (!favoriteId) {
      throw Error('Cannot add to favorites before user is loaded');
    }

    const favorite = dbCollectionItemToFavorite(item);
    const [promise] = addCollectionItemRequest({
      ...item,
      collectionId: favoriteId
    });
    promise.catch((error: APIRequestError) => {
      if (errorCallback) {
        errorCallback(error);
      }
      this.dispatch({
        type: UserActionTypes.REMOVE_FROM_FAVORITES,
        payload: favorite
      });
    });
    return this.dispatch({
      type: UserActionTypes.ADD_TO_FAVORITES,
      payload: dbCollectionItemToFavorite(item)
    });
  }

  removeFromFavorites(
    item: DBCollectionItem,
    errorCallback: (error: APIRequestError) => void
  ) {
    const favoriteId = this.getFavoritesCollectionId();
    if (!favoriteId) {
      throw Error('Cannot add to favorites before user is loaded');
    }

    const favorite = dbCollectionItemToFavorite(item);
    const [promise] = removeCollectionItemRequest({
      ...item,
      collectionId: favoriteId
    });

    promise.catch((error: APIRequestError) => {
      if (errorCallback) {
        errorCallback(error);
      }
      return this.dispatch({
        type: UserActionTypes.ADD_TO_FAVORITES,
        payload: favorite
      });
    });
    return this.dispatch({
      type: UserActionTypes.REMOVE_FROM_FAVORITES,
      payload: favorite
    });
  }

  updateUser(updateOptions: UpdateUserArgs) {
    const [promise] = updateUserRequest(updateOptions);
    // Store the state of the user record before sending the request. If the
    // request fails, then we will rollback to this state.
    const rollbackState = this.state.response?.user;

    promise.catch(() => {
      if (rollbackState) {
        return this.dispatch({
          type: UserActionTypes.UPDATE_USER,
          payload: rollbackState
        });
      }
    });

    return this.dispatch({
      type: UserActionTypes.UPDATE_USER,
      payload: updateOptions
    });
  }

  // ENG-1140 Bug fix of discovery being turned off/disabled
  // Affects Trendpop search and import CSV functionality, depending on what features is enabled for workspace
  getDefaultSearchTypes() {
    const defaultSearchTypes = [
      'tiktok_profile',
      'tiktok_sound',
      'tiktok_tag',
      'tiktok_item',
      'tiktok_duet'
    ];
    if (this.featureMusicLinkingEnabled()) {
      defaultSearchTypes.push(
        'spotify_track',
        'spotify_album',
        'spotify_artist'
      );
    }
    return defaultSearchTypes;
  }

  // ENG-1140 Bug fix of discovery being turned off/disabled
  // Affects Trendpop search and import CSV functionality, depending on what features is enabled for workspace
  getCollectionItemTypes() {
    const defaultSearchTypes = [
      'tiktok_profile',
      'tiktok_sound',
      'tiktok_tag',
      'tiktok_item'
    ];
    if (this.featureMusicLinkingEnabled()) {
      defaultSearchTypes.push(
        'spotify_track',
        'spotify_album',
        'spotify_artist'
      );
    }
    return defaultSearchTypes;
  }

  getHomeScreenOptions(): { [key: string]: string } {
    const options = {
      home: 'Home'
    } as { [key: string]: string };
    if (this.featureCatalogEnabled()) {
      options.catalogs = 'Catalogs';
    }
    return options;
  }
}

export const TrendpopUserProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const { workspaceId } = useLoginLocalState();
  const [userState, dispatch] = useReducer(userStateReducer, INITIAL_STATE);
  const [loadError, setLoadError] = React.useState<
    TrendpopLoadError | undefined
  >();
  useEffect(() => {
    const url = `${process.env.REACT_APP_MIA_URL}/v1/user`;
    // To prevent infinite loading screen, set a clientside timeout on
    // of 5s to fetch the user. This handles the case where a user's
    // internet is slow, or a legacyGetUser request just doesn't return.
    const userRequestTimeout = setTimeout(() => {
      setLoadError({
        description: 'Application timed out attempting to reach the server.',
        status: 503
      });
    }, 25000);

    let config = {
      headers: {}
    };
    if (workspaceId) {
      config = {
        headers: {
          'x-tp-workspace': workspaceId
        }
      };
    }

    fetch(url, {
      headers: config.headers,
      credentials: 'include'
    })
      .then((r) => r.json())
      .then((response: UserResponse | any) => {
        if (response.code === 401 || response.code === 404) {
          localStorage.clear();
          window.location.href = `${window.location.origin}${AUTH_LOGIN_PATH}`;
          return;
        }

        if (response.code && response.code !== 200) {
          localStorage.clear();
          setLoadError({
            description: response.msg,
            status: response.code
          });
          return;
        }

        clearTimeout(userRequestTimeout);
        dispatch({
          type: UserActionTypes.LOAD_TRENDPOP_USER,
          payload: response
        });
      })
      .catch((error) => {
        clearTimeout(userRequestTimeout);
        setLoadError({
          description: error.message,
          status: error.status
        });
      });
  }, [dispatch, setLoadError, workspaceId]);

  const user = new User(userState, dispatch);

  const userLoading = !loadError && !user.state.loaded;

  return (
    <TrendpopUserContext.Provider value={[user, userLoading, loadError]}>
      {children}
    </TrendpopUserContext.Provider>
  );
};

import React from 'react';

const CSVIcon = (props) => {
  const size = props.size || 20;
  return (
    <img
      alt="Download CSV"
      src="/static/img/csv-file-format-extension.png"
      height={size}
      width={size}
    />
  );
};

export default CSVIcon;

import React from 'react';
import {
  Typography,
  makeStyles,
  Button,
  Grid,
  Hidden,
  Box
} from '@material-ui/core';
import {
  TRENDPOP_PURPLE_3,
  TRENDPOP_PURPLE_4
} from '../../../constants/colors';
import Banner from '../components/Banner';
import DashboardUserDropdown from 'layouts/components/DashboardUserDropdown';

const useStyles = makeStyles(() => ({
  link: {
    fontWeight: 600,
    color: TRENDPOP_PURPLE_3
  },
  continueButton: {
    width: 300,
    height: 50,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  },
  imageContainer: {
    backgroundColor: TRENDPOP_PURPLE_4,
    background: `url('/static/img/line-vector.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 20%',
    backgroundSize: '100%',
    objectFit: 'cover'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '500px',
    width: '100%'
  }
}));

const DemoBooked = () => {
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <Hidden smDown>
        <Grid item sm={3} className={classes.imageContainer}>
          <Banner />
        </Grid>
      </Hidden>
      <Grid item xs={10} sm={9} className={classes.formContainer}>
        <Box position="absolute" top={30} right={{ xs: 0, sm: 50 }}>
          <DashboardUserDropdown />
        </Box>
        <Box className={classes.form}>
          <Typography variant="h3" style={{ fontWeight: 600 }}>
            Your demo has been booked!
          </Typography>
          <Box my={2} />
          <Button
            href="/workspace-select"
            variant="contained"
            color="primary"
            className={classes.continueButton}>
            All set
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DemoBooked;

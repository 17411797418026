import React from 'react';

const NotEnoughDataIcon = (props) => {
  const size = props.size || '75px';
  return (
    <img
      style={{ maxHeight: size, filter: 'opacity(75%)' }}
      src="/static/img/icons/not-enough-data.png"
      alt="Not Enough Data Found"
    />
  );
};

export default NotEnoughDataIcon;

import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = (props) =>
  makeStyles({
    button: {
      color: props.color || '#fff',
      backgroundColor: props.backgroundColor || '#000',
      borderRadius: 5,
      fontSize: 12,
      marginBottom: 2,
      padding: '2px 16px',

      '&:hover': {
        backgroundColor: props.backgroundColor || '#000',
        boxShadow: '0px 2px 5px 1px #bebfbf'
      },

      '&.Mui-disabled': {
        border: '0px',
        backgroundColor: '#c4c8cc',
        color: '#fff'
      }
    }
  });

const SquareButton = (props) => {
  const classes = useStyles(props)();
  const {
    disabled,
    href,
    onClick,
    value,
    endIcon,
    component = undefined,
    to = undefined,
    target = undefined
  } = props;

  return (
    <Button
      className={classes.button}
      disabled={disabled || false}
      href={href}
      onClick={onClick}
      endIcon={endIcon}
      component={component}
      to={to}
      target={target}>
      {value}
    </Button>
  );
};

export default SquareButton;

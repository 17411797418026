import styled from 'styled-components/macro';
import { Popover } from '@material-ui/core';

const DropdownPopover = styled(Popover)`
  & > div {
    border: 1px solid rgba(0 0 0 / 10%);
    box-shadow: 0px 0px 12px 5px rgb(0 0 0 / 10%);
  }
`;

export default DropdownPopover;

import { green, grey } from '@material-ui/core/colors';
import { THEMES } from '../constants';
import { PaletteType } from '@material-ui/core';

const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 '
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    type: 'light' as PaletteType,
    primary: {
      main: '#000',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#ed2541',
      contrastText: '#FFF'
    },
    tertiary: {
      main: '#bc78ff',
      contrastText: '#FFF'
    },
    background: {
      default: '#F7F9FC',
      paper: '#FFF'
    }
  },
  header: {
    color: grey[500],
    background: '#fff',
    search: {
      color: grey[800]
    },
    indicator: {
      background: customBlue[600]
    }
  },
  footer: {
    color: grey[500],
    background: '#FFF'
  },
  sidebar: {
    color: '#fff',
    background: '#000',
    activeColor: '#000',
    activeBackground: '#bc78ff',
    activeLinkColor: '#c487ff',
    comingSoonBackground: '#49d88a',
    hoverBackground: '#322240',
    header: {
      color: grey[200],
      background: '#000'
    },
    footer: {
      color: grey[200],
      background: '#1E2A38',
      online: {
        background: green[500]
      }
    },
    badge: {
      color: '#FFF',
      background: customBlue[500]
    }
  }
};

const variants = [defaultVariant];

export default variants;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  makeStyles,
  Button,
  Grid,
  Hidden,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Link as ExternalLink
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { TRENDPOP_PURPLE_3, TRENDPOP_GREY_2 } from '../../../constants/colors';
import SocialLogins from './components/SocialLogins';
import { fetchPostRedirect } from '../util';
import {
  AUTH_CALLBACK_PATH,
  MARKETING_TERMS_OF_USE,
  MIA_SIGNUP
} from '../../../constants/links';
import ReCAPTCHALib from 'react-google-recaptcha';
import { validateEmail } from 'pages/settings/WorkspaceSettingsUsers';
import Banner from '../components/Banner';
import { useLoginLocalState } from '../useLoginLocalState';

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: 600,
    color: TRENDPOP_PURPLE_3
  },
  continueButton: {
    height: 50,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  },
  imageContainer: {
    backgroundColor: TRENDPOP_GREY_2,
    background: `url('/static/img/line-vector.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 20%',
    backgroundSize: '100%',
    objectFit: 'cover'
  },
  formContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    maxWidth: '500px',
    width: '100%'
  },
  formCheckbox: {
    margin: theme.spacing(3)
  }
}));

const SignUp = () => {
  const classes = useStyles();
  const { email: paramEmail, setWorkspaceId } = useLoginLocalState();

  const recaptchaRef = useRef<ReCAPTCHALib>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [errorState, setErrorState] = useState({
    email: false,
    password: false
  });

  const [showPassword, setShowPassword] = useState(false);
  const [formState, setFormState] = useState({
    tos: false,
    accepted_promotion: false
  });

  const validEmail = email.length > 0 && validateEmail(email);

  useEffect(() => {
    setEmail(paramEmail);
  }, [paramEmail]);

  const handleSignUp = useCallback(() => {
    if (!paramEmail) {
      setWorkspaceId('');
    }

    setLoading(true);
    const promise = fetchPostRedirect(
      MIA_SIGNUP,
      {
        email: email,
        password: password,
        'g-recaptcha-response': captcha,
        accepted_promotion: formState.accepted_promotion
      },
      (error) => {
        if (error.toLowerCase().includes('invalid password')) {
          if (error.toLowerCase().includes('8 characters')) {
            setError('Password must be 8 characters long.');
          } else {
            setError(
              'Password must contain an uppercase letter, lowercase letter, and a number.'
            );
          }

          setErrorState((prevState) => ({
            ...prevState,
            password: true
          }));
        } else if (error.toLowerCase().includes('invalid email')) {
          setError('User already exists.');
          setErrorState((prevState) => ({
            ...prevState,
            email: true
          }));
        } else {
          setError('System error occured. Please try again.');
        }
        // Reset recaptcha
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      },
      `${window.location.origin}${AUTH_CALLBACK_PATH}`
    );

    promise.then(
      () => {
        setLoading(false);
      },
      (error: any) => {
        console.log(error);
        setLoading(false);
        setError(error.message);
      }
    );
  }, [
    paramEmail,
    email,
    password,
    captcha,
    formState.accepted_promotion,
    setWorkspaceId
  ]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError('');
    setErrorState((prevState) => ({
      ...prevState,
      email: false
    }));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setError('');
    setErrorState((prevState) => ({
      ...prevState,
      password: false
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [event.target.name]: event.target.checked });
  };

  const { tos, accepted_promotion } = formState;

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRecaptchaChange = (value: string | null) => {
    setCaptcha(value);
  };

  return (
    <Grid container justify="center">
      <Hidden smDown>
        <Grid item sm={3} className={classes.imageContainer}>
          <Banner />
        </Grid>
      </Hidden>
      <Grid item xs={10} sm={9} className={classes.formContainer}>
        <Box className={classes.form}>
          <Box>
            <Typography variant="h3">Sign up for free</Typography>
            <Box my={2} />
            <Typography variant="h5">
              Already have an account?{' '}
              <Link to="/auth/login" className={classes.link}>
                Login
              </Link>
            </Typography>
          </Box>
          <Box my={5} />
          <Box>
            <form>
              <Typography variant="h5">Email Address</Typography>
              <Box my={2} />
              <Typography variant="body1" color="textSecondary">
                Please use your work email so we can connect you with your team
              </Typography>
              <Box my={2} />
              <TextField
                fullWidth
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email Address"
                error={Boolean(errorState.email)}
              />
              <Box my={5} />
              <Typography variant="h5">Password</Typography>
              <Box my={2} />
              <TextField
                fullWidth
                variant="outlined"
                value={password}
                type={showPassword ? 'text' : 'password'}
                onChange={handlePasswordChange}
                placeholder="Choose password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePassword}
                        color="primary">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(errorState.password)}
              />
              {error && (
                <>
                  <Box my={2} />
                  <FormHelperText error>{error}</FormHelperText>
                </>
              )}
              <Box my={2} />
              <FormControl
                component="fieldset"
                className={classes.formCheckbox}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={tos}
                        onChange={handleChange}
                        name="tos"
                      />
                    }
                    label={
                      <Typography variant="body1" color="textSecondary">
                        Agree to Trendpops's{' '}
                        <ExternalLink
                          href={MARKETING_TERMS_OF_USE}
                          target="_blank"
                          className={classes.link}>
                          Terms of Service
                        </ExternalLink>
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={accepted_promotion}
                        onChange={handleChange}
                        name="accepted_promotion"
                      />
                    }
                    label={
                      <Typography variant="body1" color="textSecondary">
                        Get emails from Trendpop about product updates, industry
                        news, and events.
                      </Typography>
                    }
                  />
                </FormGroup>
              </FormControl>
              <Box my={3} />
              <ReCAPTCHALib
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY!}
                onChange={handleRecaptchaChange}
              />
              <Box my={3} />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSignUp}
                className={classes.continueButton}
                disabled={
                  loading ||
                  !formState.tos ||
                  !validEmail ||
                  password.length < 1 ||
                  captcha === null
                }>
                {loading ? (
                  <CircularProgress size="20px" />
                ) : (
                  <Typography variant="subtitle1">Continue</Typography>
                )}
              </Button>
            </form>
            <Box my={3} />
            <SocialLogins />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignUp;

import Login from '../pages/accounts/login';
import SignUp from '../pages/accounts/sign-up';
import SignUpContinue from '../pages/accounts/sign-up/SignUpContinue';
import SignUpInvitation from '../pages/accounts/sign-up/SignUpInvitation';
import VerificationCode from '../pages/accounts/sign-up/VertificationCode';
import Callback from '../pages/accounts/callback';
import ForgotPassword from 'pages/accounts/password/ForgotPassword';
import ResetPassword from 'pages/accounts/password/ResetPassword';
import Lockout from 'pages/accounts/lockout';
import WorkspaceCreate from 'pages/accounts/workspace-create';
import { WorkspaceSelect } from '../pages/workspace-select';
import DemoBooked from 'pages/accounts/sign-up/DemoBooked';
import ErrorPage500 from 'pages/accounts/error/ErrorPage500';
import ErrorPage404 from 'pages/accounts/error/ErrorPage404';
import UpgradePlan from 'pages/upgrade-plan';

export const preAuthRoutes = {
  id: 'PreAuthRoutes',
  children: [
    {
      id: 'Login',
      path: '/auth/login',
      component: Login
    },
    {
      id: 'SignUp',
      path: '/auth/signup',
      component: SignUp
    },
    {
      id: 'ForgotPassword',
      path: '/auth/forgot-password',
      component: ForgotPassword
    },
    {
      id: 'ResetPassword',
      path: '/auth/reset-password',
      component: ResetPassword
    },
    {
      id: 'ErrorPage500',
      path: '/error',
      component: ErrorPage500
    },
    {
      id: 'ErrorPage404',
      path: '/404',
      component: ErrorPage404
    }
  ]
};

export const postAuthRoutes = {
  id: 'PostAuthRoutes',
  children: [
    {
      id: 'SignUpContinue',
      path: '/auth/signup-continue',
      component: SignUpContinue
    },
    {
      id: 'SignUpInvite',
      path: '/auth/signup-invite',
      component: SignUpInvitation
    },
    {
      id: 'VerificationCode',
      path: '/auth/verify',
      component: VerificationCode
    },
    {
      id: 'Callback',
      path: '/auth/callback',
      component: Callback
    },
    {
      id: 'WorkspaceCreate',
      path: '/workspace/create',
      component: WorkspaceCreate
    },
    {
      id: 'WorkspaceSelect',
      path: '/workspace-select',
      component: WorkspaceSelect
    },
    {
      id: 'Lockout',
      path: '/lockout',
      component: Lockout
    },
    {
      id: 'DemoBooked',
      path: '/demo-booked',
      component: DemoBooked
    },
    {
      id: 'UpgradePlan',
      path: '/upgrade-plan',
      component: UpgradePlan
    },
    {
      id: 'ActivatePlan',
      path: '/activate-plan',
      component: UpgradePlan
    }
  ]
};

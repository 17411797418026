import _ from 'lodash';
import { ObjectModel } from 'objectmodel';
import {
  Mail as MailIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Youtube as YoutubeIcon,
  Twitter as TwitterIcon,
  Twitch as TwitchIcon
} from 'react-feather';
import { SpotifyIcon, SnapchatIcon } from '../components/Icons';

const OptionalNumber = [Number, undefined, null];

export const EntityType = {
  tiktok_sound: 'Sound',
  tiktok_profile: 'Creator',
  tiktok_tag: 'Tag',
  tiktok_item: 'Video',
  spotify_track: 'Song',
  spotify_artist: 'Artist',
  spotify_album: 'Album',
  isrc: 'ISRC'
};

export const GrowthTimeRange = {
  '1d': 'Last Day',
  '1w': 'Last Week',
  '1m': 'Last Month'
};

export const ProfileVerifiedSetting = {
  all: 'All',
  verified: 'Verified',
  unverified: 'Unverified'
};

export const SortType = {
  cumulative: 'Cumulative',
  growth: 'Growth',
  percent_growth: 'Growth (%)'
};

export const HistoryChartType = {
  cumulative: 'Cumulative',
  growth: 'Growth',
  growthPercent: 'Percent Growth (%)'
};

export const HistoryTimeGranularity = {
  '1h': 'Hourly',
  '1d': 'Daily',
  '7d': 'Weekly'
};

export const GrowthValue = new ObjectModel({
  absolute: OptionalNumber,
  percent: OptionalNumber
});

export const MetricValue = new ObjectModel({
  latest: Number,
  daily: [undefined, GrowthValue],
  weekly: [undefined, GrowthValue],
  monthly: [undefined, GrowthValue]
});

export const ContactLinks = [
  { code: 'external.emails', name: 'Email', iconComponent: MailIcon },
  { code: 'external.facebook', name: 'Facebook', iconComponent: FacebookIcon },
  {
    code: 'external.instagram',
    name: 'Instagram',
    iconComponent: InstagramIcon
  },
  { code: 'external.youtube', name: 'Youtube', iconComponent: YoutubeIcon },
  { code: 'external.twitter', name: 'Twitter', iconComponent: TwitterIcon },
  { code: 'external.spotify', name: 'Spotify', iconComponent: SpotifyIcon },
  { code: 'external.twitch', name: 'Twitch', iconComponent: TwitchIcon },
  { code: 'external.snapchat', name: 'Snapchat', iconComponent: SnapchatIcon }
];

export const ContactLinksMap = _.chain(ContactLinks)
  .keyBy('code')
  .mapValues('name')
  .value();

export const Genders = {
  male: 'Male',
  female: 'Female',
  all: 'All'
};

export const VideoMetrics = {
  views: 'Views',
  shares: 'Shares',
  comments: 'Comments',
  likes: 'Likes'
};

export const MentionsMetrics = {
  count: 'Videos',
  ...VideoMetrics
};

export const MetricsGranularity = {
  month: 'Monthly',
  week: 'Weekly',
  day: 'Daily'
};

export const MetricsTimeRange = {
  '7d': 'Last Week',
  '30d': 'Last Month',
  '365d': 'Last Year',
  '912d': 'Earliest Possible'
};

export const timeRangeToFieldLabel = (range: '1d' | '1w' | '1m') => {
  switch (range) {
    case '1d':
      return 'day';
    case '1w':
      return 'week';
    case '1m':
      return 'month';
    default:
      throw Error();
  }
};

export const timeRangeToChipLabel = (range: '1d' | '1w' | '1m') => {
  switch (range) {
    case '1d':
      return 'Daily';
    case '1w':
      return 'Weekly';
    case '1m':
      return 'Monthly';
    default:
      throw Error('Unable to convert time to chip label: ' + range);
  }
};

export const ColorScheme1 = [
  '#000',
  '#49d88b',
  '#f65925',
  '#974ced',
  '#c487ff',
  '#e3dcff'
];

export const ColorScheme2 = [
  '#ffa600',
  '#ff7c43',
  '#f95d6a',
  '#d45087',
  '#a05195',
  '#665191',
  '#2f4b7c',
  '#003f5c'
];

export const ColorSchemeGender = ['#c487ff', '#49d88b'];

export const USStates = [
  {
    name: 'Alabama',
    code: 'US-AL'
  },
  {
    name: 'Alaska',
    code: 'US-AK'
  },
  {
    name: 'American Samoa',
    code: 'US-AS'
  },
  {
    name: 'Arizona',
    code: 'US-AZ'
  },
  {
    name: 'Arkansas',
    code: 'US-AR'
  },
  {
    name: 'California',
    code: 'US-CA'
  },
  {
    name: 'Colorado',
    code: 'US-CO'
  },
  {
    name: 'Connecticut',
    code: 'US-CT'
  },
  {
    name: 'Delaware',
    code: 'US-DE'
  },
  {
    name: 'District Of Columbia',
    code: 'US-DC'
  },
  {
    name: 'Federated States Of Micronesia',
    code: 'US-FM'
  },
  {
    name: 'Florida',
    code: 'US-FL'
  },
  {
    name: 'Georgia',
    code: 'US-GA'
  },
  {
    name: 'Guam',
    code: 'US-GU'
  },
  {
    name: 'Hawaii',
    code: 'US-HI'
  },
  {
    name: 'Idaho',
    code: 'US-ID'
  },
  {
    name: 'Illinois',
    code: 'US-IL'
  },
  {
    name: 'Indiana',
    code: 'US-IN'
  },
  {
    name: 'Iowa',
    code: 'US-IA'
  },
  {
    name: 'Kansas',
    code: 'US-KS'
  },
  {
    name: 'Kentucky',
    code: 'US-KY'
  },
  {
    name: 'Louisiana',
    code: 'US-LA'
  },
  {
    name: 'Maine',
    code: 'US-ME'
  },
  {
    name: 'Marshall Islands',
    code: 'US-MH'
  },
  {
    name: 'Maryland',
    code: 'US-MD'
  },
  {
    name: 'Massachusetts',
    code: 'US-MA'
  },
  {
    name: 'Michigan',
    code: 'US-MI'
  },
  {
    name: 'Minnesota',
    code: 'US-MN'
  },
  {
    name: 'Mississippi',
    code: 'US-MS'
  },
  {
    name: 'Missouri',
    code: 'US-MO'
  },
  {
    name: 'Montana',
    code: 'US-MT'
  },
  {
    name: 'Nebraska',
    code: 'US-NE'
  },
  {
    name: 'Nevada',
    code: 'US-NV'
  },
  {
    name: 'New Hampshire',
    code: 'US-NH'
  },
  {
    name: 'New Jersey',
    code: 'US-NJ'
  },
  {
    name: 'New Mexico',
    code: 'US-NM'
  },
  {
    name: 'New York',
    code: 'US-NY'
  },
  {
    name: 'North Carolina',
    code: 'US-NC'
  },
  {
    name: 'North Dakota',
    code: 'US-ND'
  },
  {
    name: 'Northern Mariana Islands',
    code: 'US-MP'
  },
  {
    name: 'Ohio',
    code: 'US-OH'
  },
  {
    name: 'Oklahoma',
    code: 'US-OK'
  },
  {
    name: 'Oregon',
    code: 'US-OR'
  },
  {
    name: 'Palau',
    code: 'US-PW'
  },
  {
    name: 'Pennsylvania',
    code: 'US-PA'
  },
  {
    name: 'Puerto Rico',
    code: 'US-PR'
  },
  {
    name: 'Rhode Island',
    code: 'US-RI'
  },
  {
    name: 'South Carolina',
    code: 'US-SC'
  },
  {
    name: 'South Dakota',
    code: 'US-SD'
  },
  {
    name: 'Tennessee',
    code: 'US-TN'
  },
  {
    name: 'Texas',
    code: 'US-TX'
  },
  {
    name: 'Utah',
    code: 'US-UT'
  },
  {
    name: 'Vermont',
    code: 'US-VT'
  },
  {
    name: 'Virgin Islands',
    code: 'US-VI'
  },
  {
    name: 'Virginia',
    code: 'US-VA'
  },
  {
    name: 'Washington',
    code: 'US-WA'
  },
  {
    name: 'West Virginia',
    code: 'US-WV'
  },
  {
    name: 'Wisconsin',
    code: 'US-WI'
  },
  {
    name: 'Wyoming',
    code: 'US-WY'
  }
];

export const USStatesMap = _.chain(USStates)
  .keyBy('code')
  .mapValues('name')
  .value();

export const AgeGenerations = [
  {
    name: 'Generation Z (<= Age 24)',
    code: 'z'
  },
  {
    name: 'Generation Y (Age 25 - 40)',
    code: 'y'
  },
  {
    name: 'Generation X (Age 41 - 56)',
    code: 'x'
  },
  {
    name: 'Baby Boomers (Age 57+)',
    code: 'b'
  }
];

export const AgeGenerationMap = _.chain(AgeGenerations)
  .keyBy('code')
  .mapValues('name')
  .value();

export enum Virality {
  Viral = 'viral',
  BlowingUp = 'blowing_up',
  Hot = 'hot',
  Growing = 'growing'
}

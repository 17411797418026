export const diversificationLabels = [
  {
    index: 0,
    name: 'Unknown',
    disabled: true
  },
  {
    index: 1,
    name: 'Entertainment'
  },
  {
    index: 2,
    name: 'Others',
    disabled: true
  },
  {
    index: 3,
    name: 'Random Shoot'
  },
  {
    index: 4,
    name: 'Performance'
  },
  {
    index: 5,
    name: 'Lifestyle'
  },
  {
    index: 6,
    name: 'Lip-sync'
  },
  {
    index: 7,
    name: 'Entertainment Culture'
  },
  {
    index: 8,
    name: 'Comedy'
  },
  {
    index: 9,
    name: 'Talents'
  },
  {
    index: 10,
    name: 'Daily Life'
  },
  {
    index: 11,
    name: 'Singing & Dancing'
  },
  {
    index: 12,
    name: 'Games'
  },
  {
    index: 13,
    name: 'Finger Dance & Basic Dance'
  },
  {
    index: 14,
    name: 'Diary & VLOG'
  },
  {
    index: 15,
    name: 'Video Games'
  },
  {
    index: 16,
    name: 'Movies & TV works'
  },
  {
    index: 17,
    name: 'Sport & Outdoor'
  },
  {
    index: 18,
    name: 'Food & Drink'
  },
  {
    index: 19,
    name: 'Anime & Comics'
  },
  {
    index: 20,
    name: 'Comics & Cartoon, Anime'
  },
  {
    index: 21,
    name: 'Beauty & Style'
  },
  {
    index: 22,
    name: 'Sports'
  },
  {
    index: 23,
    name: 'Music'
  },
  {
    index: 24,
    name: 'Nature'
  },
  {
    index: 25,
    name: 'Animals'
  },
  {
    index: 26,
    name: 'Celebrity Clips & Variety Show'
  },
  {
    index: 27,
    name: 'Family & Relationship'
  },
  {
    index: 28,
    name: 'Auto & Vehicle'
  },
  {
    index: 29,
    name: 'Relationship'
  },
  {
    index: 30,
    name: 'Beauty & Care'
  },
  {
    index: 31,
    name: 'Traditional Sports'
  },
  {
    index: 32,
    name: 'Outfit'
  },
  {
    index: 33,
    name: 'Cars, Trucks & Motorcycles'
  },
  {
    index: 34,
    name: 'Culture & Education & Technology'
  },
  {
    index: 35,
    name: 'Scripted Comedy'
  },
  {
    index: 36,
    name: 'Family'
  },
  {
    index: 37,
    name: 'Pets'
  },
  {
    index: 38,
    name: 'Romance'
  },
  {
    index: 39,
    name: 'Singing & Instruments'
  },
  {
    index: 40,
    name: 'Cooking'
  },
  {
    index: 41,
    name: 'Scripted Drama'
  },
  {
    index: 42,
    name: 'Society'
  },
  {
    index: 43,
    name: 'Education'
  },
  {
    index: 44,
    name: 'Babies'
  },
  {
    index: 45,
    name: 'Beauty'
  },
  {
    index: 46,
    name: 'Fitness & Health'
  },
  {
    index: 47,
    name: 'Selfies'
  },
  {
    index: 48,
    name: 'Food Display'
  },
  {
    index: 49,
    name: 'Hair'
  },
  {
    index: 50,
    name: 'Dance'
  },
  {
    index: 51,
    name: 'Art'
  },
  {
    index: 52,
    name: 'Mukbangs & Tasting'
  },
  {
    index: 53,
    name: 'Home & Garden'
  },
  {
    index: 54,
    name: 'Work & Jobs'
  },
  {
    index: 55,
    name: 'Drinks'
  },
  {
    index: 56,
    name: 'Fitness'
  },
  {
    index: 57,
    name: 'Non-Video Games'
  },
  {
    index: 58,
    name: 'Social Issues'
  },
  {
    index: 59,
    name: 'Cosplay'
  },
  {
    index: 60,
    name: 'Technology'
  },
  {
    index: 61,
    name: 'Entertainment News'
  },
  {
    index: 62,
    name: 'Motivation'
  },
  {
    index: 63,
    name: 'Extreme Sports'
  },
  {
    index: 64,
    name: 'Toys & Collectables'
  },
  {
    index: 65,
    name: 'Pranks'
  },
  {
    index: 66,
    name: 'Graphic Art'
  },
  {
    index: 67,
    name: 'School Education'
  },
  {
    index: 68,
    name: 'Sports News'
  },
  {
    index: 69,
    name: 'Travel'
  },
  {
    index: 70,
    name: 'Tech Products & Infos'
  },
  {
    index: 71,
    name: 'Farm Animals'
  },
  {
    index: 72,
    name: 'Humanities'
  },
  {
    index: 73,
    name: 'Social News'
  },
  {
    index: 74,
    name: 'Campus Life'
  },
  {
    index: 75,
    name: 'DIY & Handcrafts'
  },
  {
    index: 76,
    name: 'Other Talent'
  },
  {
    index: 77,
    name: 'Health & Wellness'
  },
  {
    index: 78,
    name: 'Hilarious Fails'
  },
  {
    index: 79,
    name: 'Photography'
  },
  {
    index: 80,
    name: 'Software & APPs'
  },
  {
    index: 81,
    name: 'Nail Art'
  },
  {
    index: 82,
    name: 'Oddly Satisfying'
  },
  {
    index: 83,
    name: 'Natural Environment'
  },
  {
    index: 84,
    name: 'Scenery & Plants'
  },
  {
    index: 85,
    name: 'Professional Personal Development'
  },
  {
    index: 86,
    name: 'Street Interviews & Social Experiments'
  },
  {
    index: 87,
    name: 'Food Tour & Recommendations'
  },
  {
    index: 88,
    name: 'Advertisement'
  },
  {
    index: 89,
    name: 'Supernatural & Horror'
  },
  {
    index: 90,
    name: 'Fishing, Hunting & Camping'
  },
  {
    index: 91,
    name: 'Outdoor Activities'
  },
  {
    index: 92,
    name: 'Life Hacks'
  },
  {
    index: 93,
    name: 'Recreation Facilities'
  },
  {
    index: 94,
    name: 'Business & Finance'
  },
  {
    index: 95,
    name: 'Science'
  },
  {
    index: 96,
    name: 'Professional Special Effects'
  },
  {
    index: 97,
    name: 'Magic'
  },
  {
    index: 98,
    name: 'Theatre & Stage'
  },
  {
    index: 99,
    name: 'Environmental Protections'
  },
  {
    index: 100,
    name: 'Culture'
  },
  {
    index: 101,
    name: 'Traditional Culture'
  }
];

export const AllDiversificationLabelNames = new Map<string, number>(
  diversificationLabels
    .filter((x) => !x.disabled)
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })
    .map((x) => [x.name, 0])
);

export const DiversificationLabelIndexMap = new Map<string, string>(
  diversificationLabels.map((c) => [c.index.toString(), c.name])
);

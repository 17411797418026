import * as Sentry from '@sentry/react';

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN || '',
    environment: process.env.REACT_APP_SENTRY_ENV || 'development',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0
  });
};

export const sentryLog = (level: Sentry.SeverityLevel, message: string) => {
  Sentry.captureMessage(message, level);
};

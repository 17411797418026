// Used when you need to make a POST request to MIA, but you also are expecting a 302 redirect as a response.
// Follow on 302 redirect, otherwise call onError with the error message.
export const fetchPostRedirect = async (
  url: string,
  params: any,
  onError: (a: string) => void,
  redirectUrl?: string,
  isCreateWorkspace = false,
  isCreateTrial = false
) => {
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    redirect: 'follow',
    body: JSON.stringify(params)
  })
    .then((response) => {
      if (response.status === 200) {
        if (isCreateTrial) {
          response
            .json()
            .then((data) => (window.location.href = data.redirect_url));
        } else if (isCreateWorkspace) {
          response
            .json()
            .then((data) => (window.location.href = data.checkout_url));
        } else if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      } else if (response.status === 400) {
        response.json().then((error) => {
          onError(error.msg);
        });
      } else {
        onError('Expected a redirect, but got status: ' + response.status);
      }
    })
    .catch((error) => {
      onError(error.message);
    });
};

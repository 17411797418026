export const MIA_BILLING_LINK = `${process.env.REACT_APP_MIA_URL}/v1/workspace/billing`;
export const MIA_BILLING_OVERVIEW_LINK = `${process.env.REACT_APP_MIA_URL}/v1/workspace/billing/overview`;
export const MIA_BILLING_PORTAL_LINK = `${process.env.REACT_APP_MIA_URL}/v1/workspace/billing/portal`;
export const MIA_BILLING_CHECKOUT_LINK = `${process.env.REACT_APP_MIA_URL}/v1/workspace/billing/checkout`;
export const MIA_BILLING_CANCEL_LINK = `${process.env.REACT_APP_MIA_URL}/v1/workspace/billing/cancel`;
export const MIA_BILLING_REACTIVATE_LINK = `${process.env.REACT_APP_MIA_URL}/v1/workspace/billing/reactivate`;
export const MIA_BILLING_UPDATE_PLAN_LINK = `${process.env.REACT_APP_MIA_URL}/v1/workspace/billing/plan`;
export const MIA_BILLING_ACTIVATE_PLAN_LINK = `${process.env.REACT_APP_MIA_URL}/v1/workspace/billing/post-cancellation/checkout`;
export const MIA_INVOICE_PDF = `${process.env.REACT_APP_MIA_URL}/v1/invoice/pdf`;
export const MIA_INVOICE_RECEIPT_PDF = `${process.env.REACT_APP_MIA_URL}/v1/invoice/receipt-pdf`;
export const MIA_INVOICE_PAYMENT_LINK = `${process.env.REACT_APP_MIA_URL}/v1/invoice/payment-link`;
export const MIA_NOTIFICATION_NEWSLETTER = `${process.env.REACT_APP_MIA_URL}/v1/notification/newsletter`;

export const MIA_WORKSPACES_LIST = `${process.env.REACT_APP_MIA_URL}/v1/workspaces`;
export const MIA_WORKSPACE_SELLER_INVITE = `${process.env.REACT_APP_MIA_URL}/v1/workspaces/trial`;
export const MIA_WORKSPACE_SELF_SERVE_TRIAL = `${process.env.REACT_APP_MIA_URL}/v2/workspaces`; // Free Trial
export const MIA_WORKSPACE_SELECT = `${process.env.REACT_APP_MIA_URL}/v1/workspaces/select`;
export const MIA_NOTIFICATIONS = `${process.env.REACT_APP_MIA_URL}/v1/notifications`;

export const MIA_LOGIN = `${process.env.REACT_APP_MIA_URL}/v1/user/auth/login`;
export const MIA_LOGIN_SOCIAL = `${process.env.REACT_APP_MIA_URL}/v1/user/auth/login/social`;
export const MIA_SIGNUP = `${process.env.REACT_APP_MIA_URL}/v1/user/auth/signup`;
export const MIA_LOGOUT = `${process.env.REACT_APP_MIA_URL}/v1/user/auth/logout`;
export const MIA_VERIFY_EMAIL = `${process.env.REACT_APP_MIA_URL}/v1/user/email/verify`;
export const MIA_RESEND_EMAIL = `${process.env.REACT_APP_MIA_URL}/v1/user/email/resend`;
export const MIA_FORGOT_PASSWORD = `${process.env.REACT_APP_MIA_URL}/v1/user/password/forgot`;
export const MIA_RESET_PASSWORD = `${process.env.REACT_APP_MIA_URL}/v1/user/password/reset`;
export const MIA_USER = `${process.env.REACT_APP_MIA_URL}/v1/user`;

export const AUTH_CALLBACK_PATH = `/auth/callback`;
export const AUTH_LOGIN_PATH = `/auth/login`;
export const AUTH_WORKSPACE_SELECT = `/workspace-select`;
export const AUTH_WORKSPACE_SETTINGS = `/settings/workspace`;

export const WORKSPACE_SETTINGS_API_KEYS = `/settings/workspace/api-keys`;

export const MARKETING_CREATE_WORKSPACE = `${process.env.REACT_APP_MARKETING_SITE_URL}/create`;
export const MARKETING_TERMS_OF_USE = `${process.env.REACT_APP_MARKETING_SITE_URL}/terms-of-use`;
export const MARKETING_PRIVACY_POLICY = `${process.env.REACT_APP_MARKETING_SITE_URL}/privacy-policy`;

export const API_DOCUMENTATION = 'https://api.trendpop.com/v2/docs';

export const SPOTIFY_ICON = 'https://images.trendpop.com/icons/spotify.png';

export const SUPPORT_EMAIL = 'support@trendpop.com';

export const CALENDLY_UPSELL_LINK = process.env.REACT_APP_CALENDLY_UPSELL_LINK;

export const TIKTOK_SOUND_AUDIO_ASSET_BASE_PATH =
  'https://trendpop-entity-assets.s3.us-west-2.amazonaws.com/audio/tiktok_sounds';

export const LIVE_FETCH = `${process.env.REACT_APP_MIA_URL}/v1/liveFetch`;

import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';
import { PlanName } from 'models/user';
import { upgradePlanColor } from 'models/plan';
import { CloverSvg } from 'components/Icons/Clover';

interface CloverIconProps {
  iconColor: string;
}

const FeatureBox = withStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 10,
    padding: 2
  }
}))(Box);

const CloverIcon: React.FC<CloverIconProps> = ({ iconColor }) => {
  return (
    <CloverSvg
      style={{ marginLeft: 10 }}
      color={iconColor}
      size={20}
      viewBoxWidth={50}
      viewBoxHeight={25}
    />
  );
};

export const StarterFeatureCardDescription = () => {
  return (
    <Box>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Starter]} />
        <Typography>1 User</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Starter]} />
        <Typography>1 Collection</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Starter]} />
        <Typography>Track TikTok Creators</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Starter]} />
        <Typography>Track TikTok Sounds</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Starter]} />
        <Typography>Track TikTok Hashtags</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Starter]} />
        <Typography>Track Tiktok Videos</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Starter]} />
        <Typography>Basic Support</Typography>
      </FeatureBox>
    </Box>
  );
};

export const TeamFeatureCardDescription = () => {
  return (
    <Box>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Team]} />
        <Typography>5 users</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Team]} />
        <Typography>All Starter Capabilities</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Team]} />
        <Typography>5 Collections</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Team]} />
        <Typography>1 Campaign</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Team]} />
        <Typography>Admin Controls</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Team]} />
        <Typography>Basic Support</Typography>
      </FeatureBox>
    </Box>
  );
};

export const BusinessFeatureCardDescription = () => {
  return (
    <Box>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Business]} />
        <Typography>10 Users</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Business]} />
        <Typography>All Team Capabilities</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Business]} />
        <Typography>Enhanced Collections</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Business]} />
        <Typography>Priority Support</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Business]} />
        <Typography>Custom Payment Options</Typography>
      </FeatureBox>
    </Box>
  );
};

export const EnterpriseFeatureCardDescription = () => {
  return (
    <Box>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Enterprise]} />
        <Typography>Custom Seat Options</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Enterprise]} />
        <Typography>Custom # of Campaigns</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Enterprise]} />
        <Typography>Custom # of Collections</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Enterprise]} />
        <Typography>Real-time Email Alerts</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Enterprise]} />
        <Typography>Custom Dashboards</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Enterprise]} />
        <Typography>Hourly Data Tracking</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Enterprise]} />
        <Typography>Custom Payment Options</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Enterprise]} />
        <Typography>Expert Support</Typography>
      </FeatureBox>
      <FeatureBox>
        <CloverIcon iconColor={upgradePlanColor[PlanName.Enterprise]} />
        <Typography>And Much More...</Typography>
      </FeatureBox>
    </Box>
  );
};

import React from 'react';
import styled from 'styled-components/macro';
import { Alert as MuiAlert } from '@material-ui/lab';
import { spacing } from '@material-ui/system';
import { Link } from '@material-ui/core';

import { FREE_TRIAL_HEADER_MSG } from '../../constants/copy';
import { useUser } from 'context/hooks/user';

const Alert = styled(MuiAlert)(spacing);

const Alerts = () => {
  const { user, isLoading: userLoading, error: userError } = useUser();

  // This component may be used outside of the AuthGuard context, so it's important we check whether a user has successfully loaded.
  if (!user || userLoading || userError) {
    return <></>;
  }

  const freeTrialBaseMsg = FREE_TRIAL_HEADER_MSG(user.billingValidUntil());
  const billingDashboardLink = (
    <Link href="/settings/workspace/billing">billing settings</Link>
  );

  if (user.billingState() === 'past_due') {
    if (user.isWorkspaceAdmin()) {
      return (
        <Alert severity="error">
          Your workspace's payment is past due, please update your payment
          information in {billingDashboardLink}.
        </Alert>
      );
    } else {
      return (
        <Alert severity="error">
          Your workspace's payment is past due, please contact your workspace or
          billing administrator to ensure you maintain access to Trendpop.
        </Alert>
      );
    }
  } else if (user.billingState() === 'trialing') {
    if (user.isWorkspaceAdmin()) {
      return (
        <Alert severity="warning">
          {freeTrialBaseMsg}
          {' Upgrade to a paid subscription in '}
          {billingDashboardLink}
          {'.'}
        </Alert>
      );
    } else {
      return (
        <Alert severity="warning">
          {freeTrialBaseMsg}
          {
            ' Please contact your workspace or billing administrator to upgrade to a paid subscription.'
          }
        </Alert>
      );
    }
  }

  return null;
};

export default Alerts;

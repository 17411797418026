import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button as MuiButton,
  ButtonGroup,
  Card as MuiCard,
  CardContent,
  CardProps,
  CircularProgress,
  Container,
  Grid,
  Link as MuiLink,
  Modal,
  Paper,
  Tooltip as MuiTooltip,
  Typography
} from '@material-ui/core';
import { withStyles, styled } from '@material-ui/core/styles';

import { getBillingOverview } from '../../api/billing';
import CompareFeatures from './components/CompareFeatures';
import CompareSupport from './components/CompareSupport';
import RoundedButton from '../settings/components/Buttons/RoundedButton';
import SquareButton from '../settings/components/Buttons/SquareButton';

import PlanIcon from './components/PlanIcon';
import { UpgradePlanLogo } from '../../components/Logos';
import {
  DefaultUpgradePlanColor,
  getPeriodString,
  PlanPeriod,
  upgradePlanColor
} from '../../models/plan';
import StructuredErrorDisplay from '../../components/StructuredErrorDisplay';
import { AUTH_CALLBACK_PATH, SUPPORT_EMAIL } from '../../constants/links';
import { Billing } from '../../models/invoices';
import {
  LIMIT_MAX_SEATS_MESSAGE,
  LIMIT_MIN_SEATS_MESSAGE,
  MAX_BUSINESS_SEATS,
  MAX_STARTER_SEATS,
  MAX_TEAM_SEATS,
  MIN_BUSINESS_SEATS,
  MIN_STARTER_SEATS,
  MIN_TEAM_SEATS,
  MONTHLY_BUSINESS_PRICE,
  MONTHLY_DEFAULT_STARTER_PRICE,
  MONTHLY_STARTER_PRICE,
  MONTHLY_TEAM_PRICE,
  YEARLY_BUSINESS_PRICE,
  YEARLY_DEFAULT_BUSINESS_PRICE,
  YEARLY_DEFAULT_STARTER_PRICE,
  YEARLY_DEFAULT_TEAM_PRICE,
  YEARLY_STARTER_PRICE,
  YEARLY_TEAM_PRICE
} from '../../constants/plan';
import { useWorkspace } from '../../api/workspace';
import { PlanName } from 'models/user';
import DashboardUserDropdown from 'layouts/components/DashboardUserDropdown';
import { useUser } from 'context/hooks/user';
import { activatePlan, upgradePlan } from './queries';
import { useSnackbar } from 'notistack';
import { formatHttpErr } from 'utils/legacyFormat';
import LoggedOutModal from 'components/Auth/LoggedOutModal';
import { User } from 'context/user';
import {
  TRENDPOP_BLACK_2,
  TRENDPOP_BLUE,
  TRENDPOP_GREY_2,
  TRENDPOP_GREY_3,
  TRENDPOP_PURPLE_3,
  TRENDPOP_WHITE
} from 'constants/colors';
import {
  BusinessFeatureCardDescription,
  EnterpriseFeatureCardDescription,
  StarterFeatureCardDescription,
  TeamFeatureCardDescription
} from 'components/Plans/FeatureCardDescription';
import { StatusCodes } from 'http-status-codes';
import { BillingStatus } from 'models/workspaceSelect';

export interface RenderPlansProps {
  period: string;
  billingState: any;
  workspaceState: any;
  currentPlanName: string;
}

export interface RenderCardPlanProps {
  isCurrent: boolean;
  currentPlanName?: string;
  period: string;
  seats?: number | undefined;
  workspaceUsers?: number | null;
  user?: User;
  currentPlanPeriod: string;
  inactive?: boolean;
  workspaceId?: string;
}

export interface RenderModalContentProps {
  count: number;
  workspaceUsers: null | number;
  buttonLabel: string;
  open: boolean;
  handleClose: () => void;
  rate: number;
  plan: PlanName;
  period: string;
  total: string | null;
  setTotal: React.Dispatch<React.SetStateAction<string | null>>;
  currency: string | null;
  setCurrency: React.Dispatch<React.SetStateAction<string | null>>;
  openPreviewModal: boolean;
  setOpenPreviewModal: React.Dispatch<React.SetStateAction<boolean>>;
  inactive?: boolean;
  workspaceId?: string;
}

const getPlanPeriod = (periodInterval: string): PlanPeriod => {
  switch (periodInterval) {
    case 'month':
      return PlanPeriod.Monthly;
    case 'year':
      return PlanPeriod.Yearly;
    default:
      throw new Error('Invalid period type');
  }
};

const CounterButton = withStyles(() => ({
  root: {
    borderRadius: 30,
    color: TRENDPOP_BLACK_2,
    minWidth: 25,
    maxHeight: 20,
    padding: 0
  }
}))(MuiButton);

const DisplayButton = withStyles(() => ({
  root: {
    color: TRENDPOP_BLACK_2,
    backgroundColor: TRENDPOP_GREY_2,
    minWidth: 35,
    maxHeight: 20,
    pointerEvents: 'none'
  }
}))(MuiButton);

const CurrentPlanNameBox = withStyles(() => ({
  root: {
    padding: '2px 10px',
    backgroundColor: '#e5fcff',
    border: '1px solid #3bc2ff',
    borderRadius: 5,
    color: '#3bc2ff',
    fontWeight: 'bold'
  }
}))(Box);

const Card = withStyles(() => ({
  root: {
    height: 650,
    backgroundColor: TRENDPOP_WHITE,
    border: (props: { current: string }) =>
      props.current === 'true' ? `5px solid ${TRENDPOP_PURPLE_3}` : '0',
    borderRadius: 20
  }
}))((props: { current: string } & CardProps) => <MuiCard {...props} />);

const Tooltip = withStyles(() => ({
  arrow: {
    color: '#333333'
  },
  tooltip: {
    fontSize: 14,
    backgroundColor: '#333333'
  }
}))(MuiTooltip);

const Link = styled(RouterLink)({
  display: 'inline-block',
  color: TRENDPOP_BLUE,
  textDecoration: 'none'
});

const tooltipMessage = (
  count: number,
  workspaceUsers: number | null,
  minSeats: number,
  maxSeats: number
) => {
  return count !== minSeats && workspaceUsers && workspaceUsers === count ? (
    <Typography>
      {LIMIT_MIN_SEATS_MESSAGE}{' '}
      <Link target="_blank" to="/settings/workspace/users">
        Manage users
      </Link>
    </Typography>
  ) : count === maxSeats ? (
    <Typography>{LIMIT_MAX_SEATS_MESSAGE}</Typography>
  ) : (
    ''
  );
};

const RenderModalContent: React.FC<RenderModalContentProps> = ({
  count,
  workspaceUsers,
  buttonLabel,
  open,
  handleClose,
  rate,
  plan,
  period,
  total,
  setTotal,
  currency,
  setCurrency,
  openPreviewModal,
  setOpenPreviewModal,
  inactive,
  workspaceId
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const isDowngrade = buttonLabel === 'Downgrade';
  const [loading, setLoading] = useState(false);
  const [subscriptionUpdated, setSubscriptionUpdated] = useState(false);

  const handleUpgradePlan = (isPreview = true) => {
    setLoading(true);
    const promise = upgradePlan({
      plan: plan,
      type: getPeriodString(period as PlanPeriod),
      quantity: count,
      preview: isPreview
    });

    promise
      .then(
        (response: any) => {
          if (isPreview) {
            // Stripe API includes decimals must divide by 100
            setTotal((response.total / 100).toLocaleString());
            setCurrency(response.currency.toUpperCase());
            setOpenPreviewModal(true);
          } else {
            setOpenPreviewModal(false);
            setSubscriptionUpdated(true);
          }
        },
        (error: any) => {
          console.log(error);
          enqueueSnackbar(
            `Failed to communicate with server: ${formatHttpErr(error)}`,
            {
              variant: 'error'
            }
          );
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const handleActivateNewPlan = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const promise = activatePlan({
      plan: plan,
      period: getPeriodString(period as PlanPeriod),
      seat: count,
      redirect: `${window.location.origin}${AUTH_CALLBACK_PATH}?workspace_id=${workspaceId}`,
      cancel_redirect: `${window.location.origin}/activate-plan`
    });

    promise
      .then((response) => {
        setLoading(false);
        window.location.href = response.checkout_url;
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
        enqueueSnackbar(
          `Failed to communicate with server: ${formatHttpErr(error)}`,
          {
            variant: 'error'
          }
        );
      });
  };

  const modalPrompt = () => {
    if (workspaceUsers && workspaceUsers > count) {
      return (
        <Box>
          <Typography variant="h5">
            Sorry, we can't process your request
          </Typography>
          <Box my={6} />
          <Typography variant="body1">
            Your downgrade request cannot be processed as the new plan has fewer
            seats/users than your current plan. Please remove some seats/users
            on account to {count} or fewer.
          </Typography>
          <Box my={6} />
          <Box display="flex" flexDirection="row-reverse">
            <SquareButton
              component={Link}
              to="/settings/workspace/users"
              target="_blank"
              color={TRENDPOP_BLACK_2}
              backgroundColor={TRENDPOP_GREY_3}
              onClick={handleClose}
              value="Manage Users"
            />
            <Box mx={2} />
            <SquareButton onClick={handleClose} value="Keep Plan" />
          </Box>
        </Box>
      );
    } else if (workspaceUsers && workspaceUsers <= count && isDowngrade) {
      return (
        <Box>
          <Typography variant="h5">Confirm Downgrade</Typography>
          <Box my={6} />
          <Typography variant="body1">
            Are you sure you want to make this change? Your subscription will be
            downgraded immediately, and the new subscription fee will take
            effect upon your next billing cycle.
          </Typography>
          <Box my={6} />
          <Box display="flex" flexDirection="row-reverse">
            <SquareButton onClick={handleClose} value={'Keep Plan'} />
            <Box mx={2} />
            <SquareButton
              color={TRENDPOP_BLACK_2}
              backgroundColor={TRENDPOP_GREY_3}
              onClick={() => handleUpgradePlan(true)}
              value={
                loading ? (
                  <CircularProgress
                    size={16}
                    style={{ color: TRENDPOP_BLACK_2 }}
                  />
                ) : (
                  'Update Subscription'
                )
              }
            />
          </Box>
        </Box>
      );
    } else if (
      workspaceUsers &&
      workspaceUsers <= count &&
      buttonLabel === 'Upgrade'
    ) {
      return (
        <Box>
          <Typography variant="h5">Confirm Upgrade</Typography>
          <Box my={6} />
          <Typography variant="body1">
            Once you switch to your new plan, it will take effect immediately.
            Your monthly rate has been updated to $
            {period === PlanPeriod.Monthly
              ? rate
              : `${Math.round((rate / 12) * 100) / 100}`}
            /month USD.
          </Typography>
          <Box my={6} />
          <Box display="flex" flexDirection="row-reverse">
            <SquareButton
              color={TRENDPOP_BLACK_2}
              backgroundColor={TRENDPOP_GREY_3}
              onClick={handleClose}
              value={'Keep Plan'}
            />
            <Box mx={2} />
            <SquareButton
              onClick={() => handleUpgradePlan(true)}
              value={
                loading ? (
                  <CircularProgress
                    size={16}
                    style={{ color: TRENDPOP_WHITE }}
                  />
                ) : (
                  'Update Subscription'
                )
              }
            />
          </Box>
        </Box>
      );
    } else if (inactive) {
      return (
        <Box>
          <Typography variant="h5">Confirm New Plan</Typography>
          <Box my={6} />
          <Typography variant="body1">
            Once you switch to your new plan, it will take effect immediately.
            Your monthly rate has been updated to $
            {period === PlanPeriod.Monthly
              ? rate
              : `${Math.round((rate / 12) * 100) / 100}`}
            /month USD.
          </Typography>
          <Box my={6} />
          <Box display="flex" flexDirection="row-reverse">
            <SquareButton
              color={TRENDPOP_BLACK_2}
              backgroundColor={TRENDPOP_GREY_3}
              onClick={handleClose}
              value={'Cancel'}
            />
            <Box mx={2} />
            <SquareButton
              onClick={() => handleActivateNewPlan()}
              value={
                loading ? (
                  <CircularProgress
                    size={16}
                    style={{ color: TRENDPOP_WHITE }}
                  />
                ) : (
                  'Activate New Plan'
                )
              }
            />
          </Box>
        </Box>
      );
    } else {
      return (
        <Box>
          <Typography variant="h5">
            Sorry, we can't process your request
          </Typography>
          <Box my={6} />
          <Typography variant="body1">
            An unknown error has occured. Please try again later or contact a
            system administrator.
          </Typography>
          <Box my={6} />
          <Box display="flex" flexDirection="row-reverse">
            <SquareButton onClick={handleClose} value={'Close'} />
          </Box>
        </Box>
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableBackdropClick={subscriptionUpdated}>
      <Paper
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          padding: '30px',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 500
        }}>
        {subscriptionUpdated ? (
          <Box>
            <Box my={10} display="flex" justifyContent="center">
              <Typography variant="subtitle1">
                Your Subscription has been updated!
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row-reverse">
              <SquareButton href="/" value={'Go to Dashboard'} />
              <Box mx={2} />
              <SquareButton
                color={TRENDPOP_BLACK_2}
                backgroundColor={TRENDPOP_GREY_3}
                href="/settings/workspace/billing"
                value={'Back to Billing'}
              />
            </Box>
          </Box>
        ) : openPreviewModal ? (
          <Box>
            <Typography variant="h5">Confirm {buttonLabel}?</Typography>
            <Box my={6} />
            <Typography variant="body1">
              {isDowngrade
                ? `Your new rate of $${total} ${currency} will take effect in the next billing cycle.`
                : `An extra charge of $${total} ${currency} will be applied for the remainder of the month.`}
            </Typography>
            <Box my={6} />
            <Box display="flex" flexDirection="row-reverse">
              <SquareButton
                color={isDowngrade ? '' : TRENDPOP_BLACK_2}
                backgroundColor={isDowngrade ? '' : TRENDPOP_GREY_3}
                onClick={handleClose}
                value={'Keep Plan'}
              />
              <Box mx={2} />
              <SquareButton
                color={isDowngrade ? TRENDPOP_BLACK_2 : ''}
                backgroundColor={isDowngrade ? TRENDPOP_GREY_3 : ''}
                onClick={() => handleUpgradePlan(false)}
                value={
                  loading ? (
                    <CircularProgress
                      size={16}
                      style={{
                        color: isDowngrade ? TRENDPOP_BLACK_2 : TRENDPOP_WHITE
                      }}
                    />
                  ) : (
                    'Confirm'
                  )
                }
              />
            </Box>
          </Box>
        ) : (
          modalPrompt()
        )}
      </Paper>
    </Modal>
  );
};

const RenderStarterComponent: React.FC<RenderCardPlanProps> = ({
  period,
  isCurrent = false,
  seats = MIN_STARTER_SEATS,
  workspaceUsers = null,
  currentPlanPeriod,
  inactive,
  workspaceId
}) => {
  const [count, setCount] = useState<number>(seats);
  const [open, setOpen] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [total, setTotal] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string | null>(null);

  // Constants used
  const minSeats = MIN_STARTER_SEATS;
  const maxSeats = MAX_STARTER_SEATS;
  const rate =
    period === PlanPeriod.Monthly
      ? count > minSeats
        ? MONTHLY_DEFAULT_STARTER_PRICE +
          (count - minSeats) * MONTHLY_STARTER_PRICE
        : MONTHLY_DEFAULT_STARTER_PRICE
      : count > minSeats
      ? YEARLY_DEFAULT_STARTER_PRICE + (count - minSeats) * YEARLY_STARTER_PRICE
      : YEARLY_DEFAULT_STARTER_PRICE;

  const handleSubtract = () => {
    if (count > minSeats) {
      if ((workspaceUsers !== null && workspaceUsers < count) || !isCurrent) {
        setCount(count - 1);
      }
    }
  };

  const handleAdd = () => {
    if (count < maxSeats) {
      setCount(count + 1);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenPreviewModal(false);
  };

  // Dynamically changes button label for plan
  let buttonLabel = 'Downgrade';
  if (inactive) {
    buttonLabel = 'Activate Plan';
  } else if (isCurrent && count < seats) {
    buttonLabel = 'Downgrade';
  } else if (isCurrent && count >= seats) {
    buttonLabel = 'Upgrade';
  }

  const tooltipText = tooltipMessage(count, workspaceUsers, minSeats, maxSeats);
  const modalContent = RenderModalContent({
    count,
    workspaceUsers,
    buttonLabel,
    open,
    handleClose,
    rate,
    plan: PlanName.Starter,
    period,
    total,
    setTotal,
    currency,
    setCurrency,
    openPreviewModal,
    setOpenPreviewModal,
    inactive,
    workspaceId
  });

  return (
    <Card current={`${isCurrent}`}>
      <CardContent>
        {isCurrent ? (
          <Box display="flex" flexDirection="row-reverse" height={25}>
            <CurrentPlanNameBox>Current Plan</CurrentPlanNameBox>
          </Box>
        ) : (
          <Box height={25} mt={1} />
        )}
        <Box marginBottom={2} display="flex" alignItems="center">
          <PlanIcon color={upgradePlanColor[PlanName.Starter]} />
          <Typography variant="h5" style={{ paddingLeft: 5 }}>
            Starter
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">
            {period === PlanPeriod.Monthly
              ? `$${rate}`
              : `$${Math.round((rate / 12) * 100) / 100}`}
          </Typography>
          <Typography variant="h4" display="inline" color="textSecondary">
            / month
          </Typography>
        </Box>
        <Typography variant="body1" color="textSecondary">
          {period === PlanPeriod.Monthly
            ? ' Billed Monthly.'
            : `Billed at $${rate} annually.`}
        </Typography>
        <Box mt={10} display="flex" justifyContent="center" alignItems="center">
          <ButtonGroup size="small" style={{ paddingRight: 10 }}>
            <Tooltip
              arrow
              interactive
              title={
                isCurrent && (count === minSeats || workspaceUsers === count)
                  ? tooltipText
                  : ''
              }>
              <CounterButton onClick={handleSubtract}>
                <Typography align="center">-</Typography>
              </CounterButton>
            </Tooltip>
            <DisplayButton>{count}</DisplayButton>
            <Tooltip
              arrow
              interactive
              title={count === maxSeats ? tooltipText : ''}>
              <CounterButton onClick={handleAdd}>
                <Typography align="center">+</Typography>
              </CounterButton>
            </Tooltip>
          </ButtonGroup>
          <Typography variant="body2" color="textSecondary">
            Monthly active users
          </Typography>
        </Box>
        <Box my={2} display="flex" justifyContent="center">
          {modalContent}
          <RoundedButton
            disabled={
              isCurrent && period === currentPlanPeriod && count === seats
            }
            onClick={handleOpen}
            color={DefaultUpgradePlanColor}
            backgroundColor={TRENDPOP_WHITE}
            borderColor={DefaultUpgradePlanColor}
            style={{ width: '100%' }}
            value={buttonLabel}
          />
        </Box>
        <StarterFeatureCardDescription />
      </CardContent>
    </Card>
  );
};

const RenderTeamComponent: React.FC<RenderCardPlanProps> = ({
  period,
  isCurrent = false,
  currentPlanName,
  seats = MIN_TEAM_SEATS,
  workspaceUsers = null,
  currentPlanPeriod,
  inactive,
  workspaceId
}) => {
  const [count, setCount] = useState<number>(seats);
  const [open, setOpen] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [total, setTotal] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string | null>(null);

  // Constants used
  const minSeats = MIN_TEAM_SEATS;
  const maxSeats = MAX_TEAM_SEATS;
  const rate =
    period === PlanPeriod.Monthly
      ? count * MONTHLY_TEAM_PRICE
      : count > minSeats
      ? YEARLY_DEFAULT_TEAM_PRICE + (count - minSeats) * YEARLY_TEAM_PRICE
      : YEARLY_DEFAULT_TEAM_PRICE;

  const handleSubtract = () => {
    if (count > minSeats) {
      if ((workspaceUsers !== null && workspaceUsers < count) || !isCurrent) {
        setCount(count - 1);
      }
    }
  };

  const handleAdd = () => {
    if (count < maxSeats) {
      setCount(count + 1);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenPreviewModal(false);
  };

  // Dynamically changes button label for plan
  let buttonLabel = 'Upgrade';
  if (inactive) {
    buttonLabel = 'Activate Plan';
  } else if (isCurrent && count < seats) {
    buttonLabel = 'Downgrade';
  } else if (isCurrent && count >= seats) {
    buttonLabel = 'Upgrade';
  } else if (currentPlanName !== PlanName.Starter) {
    buttonLabel = 'Downgrade';
  }

  const tooltipText = tooltipMessage(count, workspaceUsers, minSeats, maxSeats);
  const modalContent = RenderModalContent({
    count,
    workspaceUsers,
    buttonLabel,
    open,
    handleClose,
    rate,
    plan: PlanName.Team,
    period,
    total,
    setTotal,
    currency,
    setCurrency,
    openPreviewModal,
    setOpenPreviewModal,
    inactive,
    workspaceId
  });

  return (
    <Card current={`${isCurrent}`}>
      <CardContent>
        {isCurrent ? (
          <Box display="flex" flexDirection="row-reverse" height={25}>
            <CurrentPlanNameBox>Current Plan</CurrentPlanNameBox>
          </Box>
        ) : (
          <Box height={25} mt={1} />
        )}
        <Box marginBottom={2} display="flex" alignItems="center">
          <PlanIcon color={upgradePlanColor[PlanName.Team]} />
          <Typography variant="h5" style={{ paddingLeft: 5 }}>
            Team
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">
            {period === PlanPeriod.Monthly
              ? `$${rate}`
              : `$${Math.round((rate / 12) * 100) / 100}`}
          </Typography>
          <Typography variant="h4" display="inline" color="textSecondary">
            / month
          </Typography>
        </Box>
        <Typography variant="body1" color="textSecondary">
          {period === PlanPeriod.Monthly
            ? 'Billed Monthly.'
            : `Billed at $${rate} annually.`}
        </Typography>
        <Box mt={10} display="flex" justifyContent="center" alignItems="center">
          <ButtonGroup size="small" style={{ paddingRight: 10 }}>
            <Tooltip
              arrow
              interactive
              title={
                isCurrent && (count === minSeats || workspaceUsers === count)
                  ? tooltipText
                  : ''
              }>
              <CounterButton onClick={handleSubtract}>
                <Typography align="center">-</Typography>
              </CounterButton>
            </Tooltip>
            <DisplayButton>{count}</DisplayButton>
            <Tooltip
              arrow
              interactive
              title={count === maxSeats ? tooltipText : ''}>
              <CounterButton onClick={handleAdd}>
                <Typography align="center">+</Typography>
              </CounterButton>
            </Tooltip>
          </ButtonGroup>
          <Typography variant="body2" color="textSecondary">
            Monthly active users
          </Typography>
        </Box>
        <Box my={2} display="flex" justifyContent="center">
          {modalContent}
          <RoundedButton
            disabled={
              isCurrent && period === currentPlanPeriod && count === seats
            }
            onClick={handleOpen}
            color={DefaultUpgradePlanColor}
            backgroundColor={TRENDPOP_WHITE}
            borderColor={DefaultUpgradePlanColor}
            style={{ width: '100%' }}
            value={buttonLabel}
          />
        </Box>
        <TeamFeatureCardDescription />
      </CardContent>
    </Card>
  );
};

const RenderBusinessComponent: React.FC<RenderCardPlanProps> = ({
  period,
  isCurrent = false,
  currentPlanName,
  seats = MIN_BUSINESS_SEATS,
  workspaceUsers = null,
  currentPlanPeriod,
  inactive,
  workspaceId
}) => {
  const [count, setCount] = useState<number>(seats);
  const [open, setOpen] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [total, setTotal] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string | null>(null);

  // Constants used
  const minSeats = MIN_BUSINESS_SEATS;
  const maxSeats = MAX_BUSINESS_SEATS;
  const rate =
    period === PlanPeriod.Monthly
      ? count * MONTHLY_BUSINESS_PRICE
      : count > minSeats
      ? YEARLY_DEFAULT_BUSINESS_PRICE +
        (count - minSeats) * YEARLY_BUSINESS_PRICE
      : YEARLY_DEFAULT_BUSINESS_PRICE;

  const handleSubtract = () => {
    if (count > minSeats) {
      if ((workspaceUsers !== null && workspaceUsers < count) || !isCurrent) {
        setCount(count - 1);
      }
    }
  };

  const handleAdd = () => {
    if (count < maxSeats) {
      setCount(count + 1);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenPreviewModal(false);
  };

  // Dynamically changes button label for plan
  let buttonLabel = 'Upgrade';
  if (inactive) {
    buttonLabel = 'Activate Plan';
  } else if (
    !isCurrent &&
    currentPlanName !== PlanName.Starter &&
    currentPlanName !== PlanName.Team
  ) {
    buttonLabel = 'Downgrade';
  } else if (isCurrent && count < seats) {
    buttonLabel = 'Downgrade';
  } else if (isCurrent && count > seats) {
    buttonLabel = 'Upgrade';
  }

  const tooltipText = tooltipMessage(count, workspaceUsers, minSeats, maxSeats);
  const modalContent = RenderModalContent({
    count,
    workspaceUsers,
    buttonLabel,
    open,
    handleClose,
    rate,
    plan: PlanName.Business,
    period,
    total,
    setTotal,
    currency,
    setCurrency,
    openPreviewModal,
    setOpenPreviewModal,
    inactive,
    workspaceId
  });

  return (
    <Card current={`${isCurrent}`}>
      <CardContent>
        {isCurrent ? (
          <Box display="flex" flexDirection="row-reverse" height={25}>
            <CurrentPlanNameBox>Current Plan</CurrentPlanNameBox>
          </Box>
        ) : (
          <Box height={25} mt={1} />
        )}
        <Box marginBottom={2} display="flex" alignItems="center">
          <PlanIcon color={upgradePlanColor[PlanName.Business]} />
          <Typography variant="h5" style={{ paddingLeft: 5 }}>
            Business
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">
            {period === PlanPeriod.Monthly
              ? `$${rate}`
              : `$${Math.round((rate / 12) * 100) / 100}`}
          </Typography>
          <Typography variant="h4" display="inline" color="textSecondary">
            / month
          </Typography>
        </Box>
        <Typography variant="body1" color="textSecondary">
          {period === PlanPeriod.Monthly
            ? ' Billed Monthly.'
            : `Billed at $${rate} annually.`}
        </Typography>
        <Box mt={10} display="flex" justifyContent="center" alignItems="center">
          <ButtonGroup size="small" style={{ paddingRight: 10 }}>
            <Tooltip
              arrow
              interactive
              title={
                isCurrent && (count === minSeats || workspaceUsers === count)
                  ? tooltipText
                  : ''
              }>
              <CounterButton onClick={handleSubtract}>
                <Typography align="center">-</Typography>
              </CounterButton>
            </Tooltip>
            <DisplayButton>{count}</DisplayButton>
            <Tooltip
              arrow
              interactive
              title={count === maxSeats ? tooltipText : ''}>
              <CounterButton onClick={handleAdd}>
                <Typography align="center">+</Typography>
              </CounterButton>
            </Tooltip>
          </ButtonGroup>
          <Typography variant="body2" color="textSecondary">
            Monthly active users
          </Typography>
        </Box>
        <Box my={2} display="flex" justifyContent="center">
          {modalContent}
          <RoundedButton
            disabled={
              isCurrent && period === currentPlanPeriod && count === seats
            }
            onClick={handleOpen}
            color={DefaultUpgradePlanColor}
            backgroundColor={TRENDPOP_WHITE}
            borderColor={DefaultUpgradePlanColor}
            style={{ width: '100%' }}
            value={buttonLabel}
          />
        </Box>
        <BusinessFeatureCardDescription />
      </CardContent>
    </Card>
  );
};

const RenderEnterpriseComponent: React.FC<RenderCardPlanProps> = ({
  isCurrent = false
}) => {
  return (
    <Card current={`${isCurrent}`}>
      <CardContent>
        {isCurrent ? (
          <Box display="flex" flexDirection="row-reverse" height={25}>
            <CurrentPlanNameBox>Current Plan</CurrentPlanNameBox>
          </Box>
        ) : (
          <Box height={25} mt={1} />
        )}
        <Box marginBottom={2} display="flex" alignItems="center">
          <PlanIcon color={upgradePlanColor[PlanName.Enterprise]} />
          <Typography variant="h5" style={{ paddingLeft: 5 }}>
            Enterprise
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">Custom</Typography>
          <Typography variant="h4" display="inline" color="textSecondary">
            / month
          </Typography>
        </Box>
        <Box my={22} />
        <Box display="flex" justifyContent="center">
          <MuiLink
            href={`mailto:${SUPPORT_EMAIL}?subject=[ENTERPRISE PLAN] Request more info`}
            style={{
              textDecoration: 'none',
              textAlign: 'center',
              color: TRENDPOP_WHITE,
              borderColor: DefaultUpgradePlanColor,
              borderRadius: 28,
              backgroundImage:
                'linear-gradient(90deg, rgba(60,0,160,1) 0%, rgba(119,32,150,1) 50%, rgba(181,63,137,1) 100%)',
              fontSize: 14,
              padding: '15px 30px',
              width: '100%'
            }}>
            Contact Support
          </MuiLink>
        </Box>
        <EnterpriseFeatureCardDescription />
      </CardContent>
    </Card>
  );
};

const RenderPlans: React.FC<RenderPlansProps> = ({
  period,
  currentPlanName,
  billingState,
  workspaceState
}) => {
  const seats = billingState ? billingState?.response.Seats : undefined;
  const users = workspaceState ? workspaceState?.users?.length : null;
  const currentPlanPeriod =
    getPlanPeriod(billingState?.response.Interval) || '';
  const inactive =
    billingState &&
    (billingState?.response.Status === BillingStatus.Canceled ||
      billingState?.response.Status === BillingStatus.Trialing)
      ? true
      : false;

  const standardPlanProps = {
    period,
    currentPlanName,
    workspaceUsers: users,
    currentPlanPeriod,
    inactive,
    workspaceId: workspaceState.workspace.internal_id
  };

  return (
    <Box padding={5}>
      <Grid container direction="row" justify="center">
        <Box width={280} padding={2}>
          <RenderStarterComponent
            {...standardPlanProps}
            isCurrent={
              !inactive && currentPlanName === PlanName.Starter ? true : false
            }
            seats={currentPlanName === PlanName.Starter ? seats : undefined}
          />
        </Box>
        <Box width={280} padding={2}>
          <RenderTeamComponent
            {...standardPlanProps}
            isCurrent={
              !inactive && currentPlanName === PlanName.Team ? true : false
            }
            seats={currentPlanName === PlanName.Team ? seats : undefined}
          />
        </Box>
        <Box width={280} padding={2}>
          <RenderBusinessComponent
            {...standardPlanProps}
            isCurrent={
              !inactive && currentPlanName === PlanName.Business ? true : false
            }
            seats={currentPlanName === PlanName.Business ? seats : undefined}
          />
        </Box>
        <Box width={280} padding={2}>
          <RenderEnterpriseComponent
            period={period}
            isCurrent={
              (!inactive && currentPlanName === PlanName.Enterprise) ||
              (currentPlanName !== PlanName.Starter &&
                currentPlanName !== PlanName.Team &&
                currentPlanName !== PlanName.Business)
                ? true
                : false
            }
            currentPlanPeriod={currentPlanPeriod}
          />
        </Box>
      </Grid>
    </Box>
  );
};

const UpgradePlan = () => {
  const { user, isLoading: userLoading } = useUser();
  const [openFeature, setOpenFeature] = useState(true);
  const [openSupport, setOpenSupport] = useState(true);

  const [billingState, setBillingState] = useState<Billing>({});
  const [billingLoading, setBillingLoading] = useState(false);
  const [billingStateError, setBillingStateError] = useState<Billing>({});

  const workspaceId = user.getWorkspace() ? user.getWorkspaceId() : null;

  const {
    data: workspaceResponse,
    isLoading: workspaceLoading,
    error: workspaceError
  } = useWorkspace(workspaceId);
  const currentPlanName = workspaceResponse?.workspace.plan_name
    ? workspaceResponse?.workspace.plan_name
    : null;

  useEffect(() => {
    if (billingState.response) {
      return;
    }

    setBillingLoading(true);
    const [promise, cancelToken] = getBillingOverview();
    promise
      .then((response) => {
        setBillingState({
          response: response,
          status_code: StatusCodes.OK
        });
        setBillingLoading(false);
      })
      .catch((error) => {
        setBillingStateError({
          response: error.error,
          status_code: error.status_code
        });
        setBillingLoading(false);
      });

    return () => {
      cancelToken.cancel();
    };
  }, [billingState, setBillingState, setBillingStateError]);

  if (userLoading || billingLoading || workspaceLoading) {
    return (
      <Box
        display="flex"
        width="100%"
        height="90vh"
        justifyContent="center"
        alignItems="center">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (
    !user ||
    !workspaceId ||
    !currentPlanName ||
    workspaceError ||
    (billingStateError && billingStateError.status_code)
  ) {
    return (
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center">
        <StructuredErrorDisplay
          response={workspaceError || billingStateError}
        />
        <LoggedOutModal open={true} />
      </Box>
    );
  }

  return (
    <Box padding={5}>
      <Box>
        <Box position="absolute" top={30} right={{ xs: 0, sm: 50 }}>
          <DashboardUserDropdown />
        </Box>
        <Box position="absolute" top={30} left={{ xs: 10, sm: 50 }}>
          <UpgradePlanLogo />
        </Box>
      </Box>
      <Box my={18} />
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center">
          {/* ENG-1205: remove yearly option from product */}
          <RenderPlans
            period={PlanPeriod.Monthly}
            currentPlanName={currentPlanName}
            billingState={billingState}
            workspaceState={workspaceResponse}
          />
        </Grid>
        <Container style={{ marginBottom: 50 }}>
          <CompareFeatures
            setOpenFeature={setOpenFeature}
            openFeature={openFeature}
          />
          <Box my={10} />
          <CompareSupport
            setOpenSupport={setOpenSupport}
            openSupport={openSupport}
          />
        </Container>
      </Grid>
    </Box>
  );
};

export default UpgradePlan;

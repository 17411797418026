export type CollectionItemType =
  | 'profile'
  | 'profile_name'
  | 'tag'
  | 'sound'
  | 'artist'
  | 'song'
  | 'album'
  | 'item';

export interface Favorite {
  type: CollectionItemType;
  id: string;
}

export interface CollectionItem {
  created_at: string;
  collection_id: string;
  item_type: CollectionItemType;

  profile_id?: string;
  sound_id?: string;
  tag_title?: string;
  profile_unique_id?: string;
  song_id?: string;
  artist_id?: string;
  album_id?: string;
  video_id?: string;
}

export const collectionItemToFavorite = (ci: CollectionItem): Favorite => {
  const { item_type } = ci;
  const id = (() => {
    switch (item_type) {
      case 'profile':
        return ci.profile_id!;
      case 'profile_name':
        return ci.profile_unique_id!;
      case 'tag':
        return ci.tag_title!;
      case 'sound':
        return ci.sound_id!;
      case 'artist':
        return ci.artist_id!;
      case 'song':
        return ci.song_id!;
      case 'album':
        return ci.album_id!;
      case 'item':
        return ci.video_id!;
    }
  })();
  return { id: id, type: item_type };
};

export interface DBCollectionItem {
  itemType: CollectionItemType;
  profileId?: string;
  profileUniqueId?: string;
  soundId?: string;
  tagTitle?: string;
  videoId?: string;
  artistId?: string;
  albumId?: string;
  songId?: string;
}

export const dbCollectionItemToFavorite = (db: DBCollectionItem): Favorite => {
  const { itemType } = db;
  const id = (() => {
    switch (itemType) {
      case 'profile':
        return db.profileId!;
      case 'profile_name':
        return db.profileUniqueId!;
      case 'tag':
        return db.tagTitle!;
      case 'sound':
        return db.soundId!;
      case 'artist':
        return db.artistId!;
      case 'song':
        return db.songId!;
      case 'album':
        return db.albumId!;
      case 'item':
        return db.videoId!;
    }
  })();
  return { id: id, type: itemType };
};

export interface CollectionList {
  id: string;
  email: string;
  icon: string;
  item_present: boolean;
  name: string;
  description: string;
  num_items: number;
  shared: boolean;
  user_id: string;
  user_name: string;
  workspace_read: boolean;
  workspace_write: boolean;
  created_at: Date;
  updated_at: Date;
}

// Code duplicate in models/history.ts
export enum CollectionChartMetricVideos {
  Views = 'views',
  Likes = 'likes',
  Comments = 'comments',
  Shares = 'shares'
}

// Code duplicate in models/history.ts
export enum CollectionChartMetricCreators {
  Followers = 'followers',
  Likes = 'likes',
  TotalMentions = 'total_mentions',
  TotalMentionsViews = 'total_mentions_views',
  TotalMentionsLikes = 'total_mentions_likes',
  TotalMentionsShares = 'total_mentions_shares',
  TotalMentionsComments = 'total_mentions_comments'
}

export enum CollectionChartMetricCreatorsAsLabel {
  followers = 'Followers',
  likes = 'Likes',
  total_mentions = 'Total Mentions',
  total_mentions_views = 'Total Mentions Views',
  total_mentions_likes = 'Total Mentions Likes',
  total_mentions_shares = 'Total Mentions Shares',
  total_mentions_comments = 'Total Mentions Comments'
}

export enum CollectionChartMetricSounds {
  Videos = 'videos'
  // TotalViews = 'total_views', // TODO(Jon): Disabled as Metrics for Cardi are broken
  // TotalLikes = 'total_likes',
  // TotalComments = 'total_comments',
  // TotalShares = 'total_shares'
}

export enum CollectionChartMetricHashtags {
  Videos = 'videos'
  // TotalViews = 'total_views', // TODO(Jon): Disabled as Metrics for Cardi are broken
  // TotalLikes = 'total_likes',
  // TotalComments = 'total_comments',
  // TotalShares = 'total_shares'
}

import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import { NavLink, withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Separator from './Separator';
import '../../vendor/perfect-scrollbar.css';
import {
  Box,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  IconButton,
  List as MuiList,
  ListItem,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Menu as MenuIcon } from '@material-ui/icons';
import _ from 'lodash';

import {
  appRoutes,
  profileSettingsRoute,
  dashboardSettingsRoutes
} from '../../routes';
import { HorizontalLogo } from '../../components/Logos';
import { CloverSvg } from '../../components/Icons/Clover';
import { LayoutContext } from '../../context/layout';
import { DRAWER_MIN_WIDTH } from '../../constants';
import { useUser } from '../../context/hooks/user';

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 64px);
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-left: ${(props) => props.theme.spacing(3.5)}px;
  padding-right: ${(props) => props.theme.spacing(3.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: 0px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    color: ${(props) => props.theme.sidebar.header.color};
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const Category = styled(ListItem)`
  cursor: pointer;
  border-radius: 30px;
  margin-top: ${(props) => props.theme.spacing(3)}px;
  margin-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-top: ${(props) => props.theme.spacing(1)}px;
  padding-bottom: ${(props) => props.theme.spacing(1)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  &.${(props) => props.active} {
    background-color: ${(props) => props.theme.sidebar.activeBackground};

    span {
      color: ${(props) => props.theme.sidebar.activeColor};
    }

    svg {
      color: ${(props) => props.theme.sidebar.activeColor};
    }
  }

  &.Mui-disabled {
    width: fit-content;
  }

  &.MuiListItem-button {
    &:hover {
      color: ${(props) => props.theme.sidebar.activeColor};
      background-color: ${(props) => props.theme.sidebar.hoverBackground};

      &.${(props) => props.active} {
        background-color: ${(props) => props.theme.sidebar.activeBackground};

        span {
          color: ${(props) => props.theme.sidebar.activeColor};
        }
      }
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding-left: ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  border-radius: 30px;
  margin-top: ${(props) => props.theme.spacing(2)}px;
  margin-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(13)}px;
  padding-top: ${(props) => props.theme.spacing(1)}px;
  padding-bottom: ${(props) => props.theme.spacing(1)}px;
  width: 100%;

  span {
    display: block;
    width: fit-content;
    color: ${(props) => props.theme.sidebar.color};
  }

  .clover-active {
    display: none;
  }

  &.${(props) => props.activeClassName} {
    span {
      color: ${(props) => props.theme.sidebar.activeLinkColor};
    }

    .clover-active {
      display: contents;
    }
  }

  &.MuiListItem-button {
    &:hover {
      color: ${(props) => props.theme.sidebar.activeColor};
      background-color: ${(props) => props.theme.sidebar.hoverBackground};

      &.${(props) => props.activeClassName} {
        background-color: ${(props) => props.theme.sidebar.background};

        span {
          color: ${(props) => props.theme.sidebar.activeLinkColor};
        }
      }
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    display: flex;
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const ComingSoon = styled(Box)`
  text-align: center;
  font-size: 10px;
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.sidebar.comingSoonBackground};
`;

const SidebarCategory = ({
  name,
  icon,
  isOpen,
  isCollapsable,
  disabled,
  badge,
  sidebarOpen,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center">
      <Tooltip arrow title={sidebarOpen ? '' : name} placement="right">
        <Category {...rest} disabled={disabled} active="active">
          {icon}
          {sidebarOpen && (
            <>
              <CategoryText>{name}</CategoryText>
              {isCollapsable ? (
                isOpen ? (
                  <CategoryIconMore />
                ) : (
                  <CategoryIconLess />
                )
              ) : null}
              {badge ? <CategoryBadge label={badge} /> : ''}
            </>
          )}
        </Category>
      </Tooltip>
      {disabled === true && sidebarOpen && (
        <ComingSoon flexGrow={1}>Coming Soon!</ComingSoon>
      )}
    </Box>
  );
};

const SidebarLink = ({ name, to, badge }) => {
  return (
    <Link button dense component={NavLink} to={to} activeClassName="active">
      <LinkText>
        {name}
        <Box className="clover-active" display="flex" alignItems="flex-end">
          <CloverSvg
            style={{ marginLeft: 10 }}
            color="#c487ff"
            size={20}
            viewBoxWidth={50}
            viewBoxHeight={25}
          />
        </Box>
      </LinkText>
      {badge ? <LinkBadge label={badge} /> : ''}
    </Link>
  );
};

const Sidebar = ({ location, ...sidebarProps }) => {
  const { user } = useUser();

  const routes = appRoutes();
  const [layoutState, setLayoutState] = useContext(LayoutContext);
  const sidebarOpen = layoutState.sidebarOpen;
  const handleDrawerToggle = () => {
    setLayoutState((state) => {
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen
      };
    });

    // Force close all nested dropdown categories
    setOpenRoutes({});
  };

  const initOpenRoutes = () => {
    const pathName = location.pathname;
    let _routes = {};

    routes
      .filter((x) => !x.hideFromSidebar)
      .filter((r) => !r.enabledForUser || r.enabledForUser(user))
      .forEach((route, idx) => {
        if (route.children && route.children.length > 0) {
          const isActive = pathName.indexOf(route.path) === 0;
          _routes = Object.assign({}, _routes, {
            [idx]: isActive
          });
        }
      });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );

    // When a parent category is clicked, force to show sidebar always
    setLayoutState((state) => {
      return {
        ...state,
        sidebarOpen: true
      };
    });
  };

  return (
    <Drawer {..._.omit(sidebarProps, 'staticContext')}>
      <Scrollbar>
        <Box display="flex">
          <IconButton
            onClick={handleDrawerToggle}
            style={{
              color: '#fff',
              height: 64,
              width: DRAWER_MIN_WIDTH
            }}>
            <MenuIcon />
          </IconButton>
          {sidebarOpen && (
            <Brand>
              <HorizontalLogo style={{ maxHeight: 30, maxWidth: 150 }} />
            </Brand>
          )}
        </Box>
        <List disablePadding>
          <Items>
            {routes
              .filter((x) => !x.hideFromSidebar)
              .filter((r) => !r.enabledForUser || r.enabledForUser(user))
              .map((category, index) => (
                <React.Fragment key={index}>
                  {category.isSeparator ? (
                    <Separator />
                  ) : category.children && category.icon ? (
                    <React.Fragment key={index}>
                      <SidebarCategory
                        className={(() => {
                          if (
                            (location.pathname &&
                              location.pathname.startsWith(
                                category.sidebarPath || category.path
                              )) ||
                            category.children.find(
                              (route) =>
                                location.pathname.startsWith(route.path) ||
                                location.pathname.startsWith(route.sidebarPath)
                            )
                          ) {
                            return 'active';
                          } else {
                            return null;
                          }
                        })()}
                        isOpen={!openRoutes[index]}
                        isCollapsable={true}
                        name={category.id}
                        icon={category.icon}
                        button={true}
                        onClick={() => toggle(index)}
                        disabled={category.disabled || false}
                        sidebarOpen={sidebarOpen}
                      />

                      <Collapse
                        in={openRoutes[index]}
                        timeout="auto"
                        unmountOnExit>
                        {category.children
                          .filter(
                            (r) => !r.enabledForUser || r.enabledForUser(user)
                          )
                          .map((route, index) => (
                            <SidebarLink
                              key={index}
                              name={route.name}
                              to={route.sidebarPath || route.path}
                              badge={route.badge}
                            />
                          ))}
                      </Collapse>
                    </React.Fragment>
                  ) : category.icon ? (
                    <SidebarCategory
                      className={(() => {
                        if (
                          location.pathname &&
                          location.pathname.startsWith(
                            category.sidebarPath || category.path
                          )
                        ) {
                          return 'active';
                        } else {
                          return null;
                        }
                      })()}
                      isCollapsable={false}
                      name={category.id}
                      to={category.sidebarPath || category.path}
                      component={NavLink}
                      icon={category.icon}
                      exact
                      button
                      badge={category.badge}
                      disabled={category.disabled || false}
                      sidebarOpen={sidebarOpen}
                    />
                  ) : null}
                </React.Fragment>
              ))}
          </Items>
          <Box paddingBottom={5}>
            <Items>
              <Separator />
              <React.Fragment>
                <SidebarCategory
                  isCollapsable={true}
                  name="Settings"
                  icon={profileSettingsRoute.icon}
                  button={true}
                  sidebarOpen={sidebarOpen}
                  isOpen={!openRoutes[routes.length]}
                  onClick={() => toggle(routes.length)}
                />
                <Collapse
                  in={openRoutes[routes.length]}
                  timeout="auto"
                  unmountOnExit>
                  {dashboardSettingsRoutes()
                    .filter((r) => !r.enabledForUser || r.enabledForUser(user))
                    .map((route, index) => (
                      <SidebarLink
                        key={index}
                        name={route.name}
                        to={route.sidebarPath || route.path}
                        badge={route.badge}
                      />
                    ))}
                </Collapse>
              </React.Fragment>
            </Items>
          </Box>
        </List>
      </Scrollbar>
    </Drawer>
  );
};

export default withRouter(Sidebar);

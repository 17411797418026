import React from 'react';
import styled from 'styled-components/macro';
import {
  Box,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem
} from '@material-ui/core';

import {
  MARKETING_TERMS_OF_USE,
  MARKETING_PRIVACY_POLICY,
  SUPPORT_EMAIL
} from '../constants/links';

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
  &,
  &:hover,
  &:active {
    color: #ff0000;
  }

  margin: 5px;
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <Box display="flex" flexDirection="row">
        <ListItem button={true} component="a" href={`mailto:${SUPPORT_EMAIL}`}>
          <ListItemText primary="Contact Us" />
        </ListItem>
        <ListItem button={true} component="a" href={MARKETING_TERMS_OF_USE}>
          <ListItemText primary="Terms of Use" />
        </ListItem>
        <ListItem button={true} component="a" href={MARKETING_PRIVACY_POLICY}>
          <ListItemText primary="Privacy Policy" />
        </ListItem>
        <Box style={{ flexGrow: 1 }} />
        <ListItem button={true}>
          <ListItemText primary={`© ${new Date().getFullYear()} - Trendpop`} />
        </ListItem>
      </Box>
    </Wrapper>
  );
};

export default Footer;

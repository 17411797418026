import React from 'react';
import { Avatar, Box, Chip, Typography, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { VerifiedIcon } from './Icons';
import { EntityType } from '../models';
import * as Format from '../utils/legacyFormat';
import {
  searchResultToIconUrl,
  searchResultToFallbackIcon,
  searchResultToPrimaryMetricLabel
} from '../models/discovery';

const SearchResultRow = (props) => {
  const {
    result,
    resultToLabelFn = (result) => EntityType[result.entity_type],
    searchBarIconSize = 32
  } = props;
  const theme = useTheme();

  const icon = (
    <Avatar
      alt={result.primary}
      src={searchResultToIconUrl(result)}
      style={{
        width: searchBarIconSize,
        height: searchBarIconSize,
        backgroundColor: theme.palette.primary.main
      }}>
      {searchResultToFallbackIcon(result)}
    </Avatar>
  );

  const primary = (() => {
    if (result.entity_type === 'tiktok_profile') {
      return '@' + result.primary;
    } else if (result.entity_type === 'tiktok_tag') {
      return '#' + result.primary;
    } else {
      return result.primary;
    }
  })();

  const verified = (() => {
    if (result.entity_type === 'tiktok_sound') {
      if (result.verified === undefined) {
        return null;
      } else {
        return (
          <Chip
            size="small"
            color={result.verified ? 'primary' : 'secondary'}
            label={result.verified ? 'Official' : 'Original'}
          />
        );
      }
    } else {
      return result.verified ? (
        <IconButton size="small" disabled>
          <VerifiedIcon size={14} />
        </IconButton>
      ) : null;
    }
  })();

  return (
    <Box
      key={result.id}
      display="flex"
      flexDirection="row"
      alignItems="center"
      style={{
        width: '100%',
        borderRadius: '20px' // Add radius to make it oval-shaped
      }}>
      <Box mr={2}>{icon}</Box>
      <Box>
        <Typography variant="h5">
          {primary} {verified}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {resultToLabelFn(result)}
        </Typography>
      </Box>
      <Box style={{ flexGrow: 1 }} />
      <Box display="flex" flexDirection="column">
        {result.primary_metric && (
          <Typography variant="body1">
            {Format.fmtNumber(result.primary_metric)}{' '}
            {searchResultToPrimaryMetricLabel(result)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SearchResultRow;

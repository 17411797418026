import React, { useMemo } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Chip as MuiChip,
  IconButton,
  Paper as MuiPaper,
  Popover,
  TextField,
  Tooltip,
  Typography,
  withStyles,
  Modal,
  Grid
} from '@material-ui/core';

import _, { uniqueId } from 'lodash';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import {
  createSavedQuery,
  editSavedQuery,
  useSavedQueryListResponse
} from '../pages/saved-searches/queries';
import { useSnackbar } from 'notistack';
import { format as dateFormat, isValid, parseISO } from 'date-fns';
import {
  fmtNumber,
  fmtPercent,
  formatHttpErr,
  regionCodeToRegionName,
  langCodeToLanguageName
} from '../utils/legacyFormat';
import { SavedQueryKeyLabel } from '../models/savedQuery';
import { diversificationLabels } from '../models/diversificationLabels';
import { timeRangeToChipLabel, AgeGenerations } from '../models/index';
import { useSavedQueriesLimit, useUser } from '../context/hooks/user';
import { useQueryClient } from 'react-query';
import { CalendlyModal } from './CalendlyModal';
import { CALENDLY_UPSELL_LINK } from '../constants/links';

const Paper = withStyles(() => ({
  root: {
    position: 'relative',
    minWidth: 400,
    minHeight: 150,
    padding: 10
  }
}))(MuiPaper);

const Chip = withStyles(() => ({
  root: {
    size: 'small',
    margin: 2
  }
}))(MuiChip);

const capitalizeAndConvert = (input: string) => {
  if (typeof input !== 'string') {
    return input;
  }

  const words: string[] = input.split('_');
  const capitalizedWords: string[] = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const output: string = capitalizedWords.join(' ');

  return output;
};

const getSettingKeyAndValue = (key: string, settings: any) => {
  const labelKey = key as keyof typeof SavedQueryKeyLabel;

  // for diversication label/content categories specific
  if (key === ('diversificationLabels' as keyof typeof SavedQueryKeyLabel)) {
    return `${SavedQueryKeyLabel[labelKey] || ''} ${settings[key]
      .map(
        (item: any) =>
          `${
            diversificationLabels.find((obj) => obj.index === parseInt(item))
              ?.name
          }`
      )
      .join(', ')}`;
  } else if (
    key === ('creatorAgeGeneration' as keyof typeof SavedQueryKeyLabel)
  ) {
    return `${SavedQueryKeyLabel[labelKey] || ''} ${settings[key]
      .map(
        (item: any) =>
          `${AgeGenerations.find((obj) => obj.code === item)?.name}`
      )
      .join(', ')}`;
  } else if (
    key === ('creatorLanguage' as keyof typeof SavedQueryKeyLabel) ||
    key === ('audienceLanguage' as keyof typeof SavedQueryKeyLabel)
  ) {
    return `${SavedQueryKeyLabel[labelKey] || ''} ${settings[key]
      .map((item: any) => `${langCodeToLanguageName(item)}`)
      .join(', ')}`;
  } else if (
    key === ('creatorLocation' as keyof typeof SavedQueryKeyLabel) ||
    key === ('audienceLocation' as keyof typeof SavedQueryKeyLabel)
  ) {
    return `${SavedQueryKeyLabel[labelKey] || ''} ${settings[key]
      .map((item: any) => `${regionCodeToRegionName(item)}`)
      .join(', ')}`;
  } else if (key === ('minimumEngagement' as keyof typeof SavedQueryKeyLabel)) {
    return `> ${fmtPercent(settings[key])} ${SavedQueryKeyLabel[labelKey]}`;
    // Check if its start date and end date object
  } else if (
    settings[key] instanceof Date ||
    (isValid(parseISO(settings[key])) &&
      key === ('startDate' as keyof typeof SavedQueryKeyLabel))
  ) {
    return `${dateFormat(new Date(settings[key]), 'MM/dd/yyyy')} - ${dateFormat(
      new Date(settings['endDate']),
      'MM/dd/yyyy'
    )}`;
  } else if (settings[key] instanceof Array) {
    // for Videos Page using TrendpopSearch's Search bar
    if (Object.prototype.hasOwnProperty.call(settings[key][0], 'primary')) {
      return `${SavedQueryKeyLabel[labelKey] || ''}: ${settings[key]
        .map((item: any) => item.primary)
        .join(', ')}`;
    } else {
      return `${SavedQueryKeyLabel[labelKey] || ''}: ${settings[key]
        .map((item: any) => item)
        .join(', ')}`;
    }
    // for numbers of users or views to be readable
  } else if (typeof settings[key] === 'number') {
    return `> ${fmtNumber(settings[key])} ${
      SavedQueryKeyLabel[labelKey] || ''
    }`;
  } else if (
    key === ('orderBy' as keyof typeof SavedQueryKeyLabel) ||
    key === ('searchType' as keyof typeof SavedQueryKeyLabel) ||
    key === ('searchTerms' as keyof typeof SavedQueryKeyLabel) ||
    key === ('rawSearchQuery' as keyof typeof SavedQueryKeyLabel) ||
    key === ('searchResults' as keyof typeof SavedQueryKeyLabel)
  ) {
    return `${SavedQueryKeyLabel[labelKey]} ${capitalizeAndConvert(
      settings[key]
    )}`;
  } else if (key === ('timeRange' as keyof typeof SavedQueryKeyLabel)) {
    return `${timeRangeToChipLabel(settings[key])}`;
  } else {
    return `${capitalizeAndConvert(settings[key])}`;
  }
};

const generateSettingChipFilter = (key: string, savedQuery: any) => {
  const labelKey = key as keyof typeof SavedQueryKeyLabel;
  const settings = savedQuery.filters;

  // for diversication label/content categories specific
  if (key === ('diversificationLabels' as keyof typeof SavedQueryKeyLabel)) {
    return settings[key].map((item: any) => (
      <Chip
        key={uniqueId()}
        label={
          diversificationLabels.find((obj) => obj.index === parseInt(item))
            ?.name
        }
      />
    ));
  } else if (
    key === ('creatorAgeGeneration' as keyof typeof SavedQueryKeyLabel)
  ) {
    return settings[key].map((item: any) => (
      <Chip
        key={uniqueId()}
        label={`${AgeGenerations.find((obj) => obj.code === item)?.name}`}
      />
    ));
  } else if (
    key === ('creatorLanguage' as keyof typeof SavedQueryKeyLabel) ||
    key === ('audienceLanguage' as keyof typeof SavedQueryKeyLabel)
  ) {
    return settings[key].map((item: any) => (
      <Chip key={uniqueId()} label={langCodeToLanguageName(item)} />
    ));
  } else if (
    key === ('creatorLocation' as keyof typeof SavedQueryKeyLabel) ||
    key === ('audienceLocation' as keyof typeof SavedQueryKeyLabel)
  ) {
    return settings[key].map((item: any) => (
      <Chip key={uniqueId()} label={regionCodeToRegionName(item)} />
    ));
    // for percentage, one-off case in creators
  } else if (key === ('minimumEngagement' as keyof typeof SavedQueryKeyLabel)) {
    return (
      <Chip
        key={uniqueId()}
        label={`> ${fmtPercent(settings[key])} ${SavedQueryKeyLabel[labelKey]}`}
      />
    );
    // Check if its start date and end date object
  } else if (key === ('startDate' as keyof typeof SavedQueryKeyLabel)) {
    return (
      <Chip
        key={uniqueId()}
        label={`${dateFormat(new Date(settings[key]), 'MM/dd/yyyy')} -
              ${dateFormat(new Date(settings['endDate']), 'MM/dd/yyyy')}`}
      />
    );
  } else if (settings[key] instanceof Array) {
    // for Videos Page using TrendpopSearch's Search bar
    if (Object.prototype.hasOwnProperty.call(settings[key][0], 'primary')) {
      return settings[key].map((item: any) => (
        <Chip key={uniqueId()} label={item.primary} />
      ));
    } else {
      return settings[key].map((item: any) => (
        <Chip key={uniqueId()} label={item} />
      ));
    }
    // for numbers of users or views to be readable
  } else if (typeof settings[key] === 'number') {
    return (
      <Chip
        key={uniqueId()}
        label={`> ${fmtNumber(settings[key])} ${
          SavedQueryKeyLabel[labelKey] || ''
        }`}
      />
    );
  } else if (
    key === ('searchType' as keyof typeof SavedQueryKeyLabel) ||
    key === ('searchTerms' as keyof typeof SavedQueryKeyLabel) ||
    key === ('rawSearchQuery' as keyof typeof SavedQueryKeyLabel) ||
    key === ('searchResults' as keyof typeof SavedQueryKeyLabel)
  ) {
    return (
      <Chip
        key={uniqueId()}
        label={`${SavedQueryKeyLabel[labelKey]} ${capitalizeAndConvert(
          settings[key]
        )}`}
      />
    );
  } else if (key === ('orderBy' as keyof typeof SavedQueryKeyLabel)) {
    return (
      <Chip
        key={uniqueId()}
        label={`Top ${capitalizeAndConvert(
          savedQuery.discovery_type
        )}s by ${capitalizeAndConvert(settings[key])}`}
      />
    );
  } else if (key === ('timeRange' as keyof typeof SavedQueryKeyLabel)) {
    return (
      <Chip key={uniqueId()} label={timeRangeToChipLabel(settings[key])} />
    );
  } else {
    return (
      <Chip key={uniqueId()} label={capitalizeAndConvert(settings[key])} />
    );
  }
};

export const generateSearchName = (settings: any, isNewSavedQuery = false) => {
  const generatedName = Object.keys(settings)
    .filter(
      (key) =>
        settings[key] instanceof Array
          ? settings[key].length > 0
          : settings[key] !== null &&
            settings[key] !== undefined &&
            settings[key] !== '' &&
            key !== ('searchType' as keyof typeof SavedQueryKeyLabel) &&
            key !== ('queryType' as keyof typeof SavedQueryKeyLabel) &&
            key !== ('endDate' as keyof typeof SavedQueryKeyLabel) // ignores 'endDate' as start date will only be required
    )
    .map((key) => {
      return getSettingKeyAndValue(key, settings);
    })
    .join(', ');

  if (isNewSavedQuery && generatedName.length > 80) {
    return generatedName.slice(0, 80) + ' ...';
  } else {
    return generatedName;
  }
};

// Generates an array of chips for view in saved query
export const generateChipFilters = (savedQuery: any) => {
  const settings = savedQuery.filters;

  const chipFilters = Object.keys(settings)
    .filter(
      (key) =>
        settings[key] instanceof Array
          ? settings[key].length > 0
          : settings[key] !== null &&
            settings[key] !== undefined &&
            settings[key] !== '' &&
            key !== ('searchType' as keyof typeof SavedQueryKeyLabel) &&
            key !== ('queryType' as keyof typeof SavedQueryKeyLabel) &&
            key !== ('endDate' as keyof typeof SavedQueryKeyLabel) // ignores 'endDate' as start date will only be required
    )
    .map((key) => {
      return generateSettingChipFilter(key, savedQuery);
    });

  return chipFilters;
};

const SavedSearchButton = (props: { settings: any; type: 'string' }) => {
  const { settings, type } = props;

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = React.useState<string>(
    generateSearchName(settings, true)
  );
  const [savedQueryId, setSavedQueryId] = React.useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState(false);
  const [limitModalOpen, setLimitModalOpen] = React.useState(false);

  const { user } = useUser();

  const savedQueriesLimit = useSavedQueriesLimit();
  const { data: response, isLoading } = useSavedQueryListResponse();

  const savedQueriesLimitReached = useMemo(() => {
    if (savedQueriesLimit) {
      return (
        response?.length &&
        response?.filter((s) => s.email === user.getEmail()).length ===
          savedQueriesLimit
      );
    } else {
      return false;
    }
  }, [savedQueriesLimit, response, user]);

  // Update generated name when settings change
  React.useEffect(() => {
    setName(generateSearchName(settings, true));
  }, [settings]);

  if (isLoading) {
    return (
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <CircularProgress size="small" color="secondary" />
      </Box>
    );
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!savedQueriesLimitReached) {
      submitCreateSavedQuery();
      setAnchorEl(event.currentTarget);
    } else {
      setLimitModalOpen(true);
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setLoading(false);
    setSavedQueryId(null);
    setAnchorEl(null);
    setLimitModalOpen(false);
  };

  const handlePopoverClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setName(event.target.value);
  };

  const submitCreateSavedQuery = () => {
    // Convert ISO Date strings to yyyy-mm-dd format
    const settingsData = _.cloneDeep(settings);
    for (const key in settingsData) {
      if (Object.prototype.hasOwnProperty.call(settingsData, key)) {
        if (key === 'endDate' || key === 'startDate') {
          settingsData[key] = dateFormat(settingsData[key], 'yyyy-MM-dd');
        }
      }
    }

    const promise = createSavedQuery({
      name: name,
      type: type,
      filters: settingsData
    });

    promise
      .then(
        (response: any) => {
          setSavedQueryId(response.id);
          enqueueSnackbar(`Saved Query successfully created`, {
            variant: 'success'
          });
        },
        (error: any) => {
          enqueueSnackbar(
            `Failed to create Saved Query: ${formatHttpErr(error)}`,
            {
              variant: 'error'
            }
          );
        }
      )
      .finally(() => {
        queryClient.invalidateQueries({
          queryKey: [`tp-saved-query-list`]
        });
      });
  };

  const submitEditSavedQuery = () => {
    if (savedQueryId) {
      setLoading(true);
      const promise = editSavedQuery(savedQueryId, {
        name: name,
        workspace_write: false,
        workspace_read: false
      });

      promise
        .then(
          () => {
            enqueueSnackbar(`Search query name updated`, {
              variant: 'success'
            });
          },
          (error: any) => {
            enqueueSnackbar(
              `Failed to save search Query: ${formatHttpErr(error)}`,
              {
                variant: 'error'
              }
            );
          }
        )
        .finally(() => {
          queryClient.invalidateQueries({
            queryKey: [`tp-saved-query-list`]
          });
          handleClose();
        });
    }
  };

  const validName = (name: string) => {
    return name.length > 0 && name.length < 128;
  };

  return (
    <>
      <Tooltip title="Save Search">
        <IconButton size="small" onClick={handleClick}>
          {!anchorEl ? <BookmarkBorderIcon /> : <BookmarksIcon />}
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handlePopoverClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        style={{ zIndex: 99999 }}>
        <Paper variant="outlined">
          <Typography variant="h6">Searches saved!</Typography>
          <Box my={3} />
          <TextField
            autoFocus
            fullWidth
            onMouseDown={(e) => e.stopPropagation()} // fixes videos page won't click on textfield, onClick() not working
            label="Saved Search Name"
            value={name}
            onChange={handleNameChange}
            error={!validName(name)}
            helperText={
              !validName(name)
                ? 'Please enter a name for the saved query between 0 and 128 characters'
                : ''
            }
          />
          <Box my={10} />
          <Box display="flex" justifyContent="flex-end">
            <Button color="primary" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Box mx={2} />
            <Button
              color="primary"
              variant="contained"
              onClick={submitEditSavedQuery}
              disabled={loading || !validName(name)}>
              {loading ? <CircularProgress size={20} /> : 'Save'}
            </Button>
          </Box>
        </Paper>
      </Popover>
      <Modal
        onClick={(e) => e.stopPropagation()}
        open={limitModalOpen}
        onClose={handleClose}>
        <Paper
          onClick={(e) => e.stopPropagation()}
          style={{
            zIndex: 9999,
            top: '50%',
            left: '50%',
            padding: '30px',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 400
          }}>
          <Box width="100%">
            <Typography variant="h5">Saved Searches Limit Reached</Typography>
            <Box my={8} />
            <Typography variant="body1" color="textSecondary">
              You've reached your limit on Saved Searches. Please set up a call
              with sales to upgrade your plan.
            </Typography>
            <Box my={10} />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignContent="center">
              <Button
                variant="contained"
                href="/saved-searches"
                style={{
                  marginTop: 10
                }}>
                Go to Saved Searches
              </Button>
              <CalendlyModal
                buttonLabel={'Schedule a call'}
                url={CALENDLY_UPSELL_LINK}
                style={{
                  marginTop: 10
                }}
              />
            </Grid>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default SavedSearchButton;

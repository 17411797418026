import React, { PropsWithChildren } from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { Box, CssBaseline, Grid, Hidden, Theme } from '@material-ui/core';
import DashboardSidebar from './components/DashboardSidebar';
import { LayoutContext } from '../context/layout';
import { DRAWER_WIDTH } from '../constants';
import DashboardHeader from './components/DashboardHeader';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props: { theme: Theme }) =>
      props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`;

const DashboardSettings = ({ children }: PropsWithChildren<unknown>) => {
  const [layoutState, setLayoutState] = React.useContext(LayoutContext);
  const mobileOpen = layoutState.mobileOpen;

  const handleDrawerToggle = () => {
    setLayoutState((state: { mobileOpen: boolean }) => {
      return {
        ...state,
        mobileOpen: !state.mobileOpen
      };
    });
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Hidden mdUp implementation="js">
        <DashboardSidebar
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
        />
      </Hidden>
      <Hidden smDown implementation="css">
        <DashboardSidebar
          variant="permanent"
          style={{ height: '100%' }}
          PaperProps={{
            style: {
              width: DRAWER_WIDTH,
              position: 'relative',
              height: '100%'
            }
          }}
        />
      </Hidden>
      <Box display="flex" flexDirection="column" style={{ flex: 1 }}>
        <DashboardHeader drawerToggle={handleDrawerToggle} />
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start">
          {children}
        </Grid>
      </Box>
    </Root>
  );
};

export default DashboardSettings;

import React, { useState } from 'react';

import { IconButton, Snackbar, Tooltip } from '@material-ui/core';
import ShareIcon from '../components/Icons/ShareIcon';

const CopyURLButton = () => {
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(true);
    navigator.clipboard.writeText(window.location.toString());
  };

  return (
    <>
      <Tooltip title="Copy search URL">
        <IconButton size="small" onClick={handleClick}>
          <ShareIcon />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied search URL to clipboard"
      />
    </>
  );
};

export default CopyURLButton;

import amplitude from 'amplitude-js';

export const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY;

export const AMPLITUDE_URL = 'mia.internal.trendpop.com/v1/proxy/amp/';

enum TPopAmplitudeEvent {
  PageView = 'page view',
  ButtonClick = 'button click' // Event name subject to change :)
}

export class AmplitudeClient {
  // ========================================
  // Setup
  // ========================================

  static initAmplitudeSession() {
    // @ts-ignore
    amplitude.getInstance().init(AMPLITUDE_KEY, null, {
      apiEndpoint: AMPLITUDE_URL,
      useDynamicConfig: true
    });
  }

  // ========================================
  // Config
  // ========================================

  static setUserId(userId: string) {
    amplitude.getInstance().setUserId(userId);
  }

  // ========================================
  // Events
  // ========================================

  static logPageView(route: string, extras: Record<string, any> = {}) {
    amplitude.getInstance().logEvent(TPopAmplitudeEvent.PageView, {
      page: route,
      ...extras
    });
  }

  // NOT USED YET! TODO: Discuss how best to organize button names, as there will be many
  static logButtonClick(buttonName: string, extras: Record<string, any> = {}) {
    amplitude.getInstance().logEvent(TPopAmplitudeEvent.ButtonClick, {
      buttonName,
      ...extras
    });
  }
}

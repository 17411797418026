import axios from 'axios';

import { abbaResponseHandler, axiosErrorHandler } from '../../../api/util';
import { MIA_FORGOT_PASSWORD, MIA_RESET_PASSWORD } from 'constants/links';

const CancelToken = axios.CancelToken;

export const forgotPassword = (body: {
  email: string;
  redirect: string;
}): Promise<any> => {
  const url = MIA_FORGOT_PASSWORD;
  const cancelToken = CancelToken.source();

  return axios
    .post(
      url,
      {
        ...body
      },
      {
        cancelToken: cancelToken.token
      }
    )
    .then(abbaResponseHandler, axiosErrorHandler);
};

export const resetPassword = (body: {
  reset_id: string;
  password: string;
  repeat: string;
}): Promise<any> => {
  const url = MIA_RESET_PASSWORD;
  const cancelToken = CancelToken.source();

  return axios
    .post(
      url,
      {
        ...body
      },
      {
        cancelToken: cancelToken.token
      }
    )
    .then(abbaResponseHandler, axiosErrorHandler);
};

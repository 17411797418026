// Seats
// https://trendpop.atlassian.net/browse/ENG-723
// Plan Pricing
// https://trendpop.atlassian.net/browse/ENG-726

// Seats
// Min
export const MIN_STARTER_SEATS = 1;
export const MIN_TEAM_SEATS = 5;
export const MIN_BUSINESS_SEATS = 10;
// Max
export const MAX_STARTER_SEATS = 4;
export const MAX_TEAM_SEATS = 9;
export const MAX_BUSINESS_SEATS = 24;

// Plan Pricing
// Monthly Default Prices
export const MONTHLY_DEFAULT_STARTER_PRICE = 125;
// Monthly additional seat
export const MONTHLY_STARTER_PRICE = 100;
export const MONTHLY_TEAM_PRICE = 100;
export const MONTHLY_BUSINESS_PRICE = 100;

// Yearly Default Prices (20% off) * the initial seat count in stripe
export const YEARLY_DEFAULT_STARTER_PRICE = 1200;
export const YEARLY_DEFAULT_TEAM_PRICE = 4800;
export const YEARLY_DEFAULT_BUSINESS_PRICE = 9600;
// Yearly Additional Seat Prices
export const YEARLY_STARTER_PRICE = 100;
export const YEARLY_TEAM_PRICE = 100;
export const YEARLY_BUSINESS_PRICE = 100;

// Tooltip message
export const LIMIT_MIN_SEATS_MESSAGE =
  'You must delete some of your current users before reducing user count on your plan.';
export const LIMIT_MAX_SEATS_MESSAGE =
  'You have reached the maximum number of users allowed for this plan. Upgrading to the next plan offers more users at a better rate.';

import axios, { AxiosError, AxiosResponse } from 'axios';
import { APIRequestError } from 'models/http';
import { API_REQUEST_CANCELED } from '../constants';
export const abbaResponseHandler = (response: AxiosResponse) => {
  if (response.data === undefined) {
    return Promise.reject({
      description: 'Client failed to parse ABBA response'
    });
  } else {
    const data = response.data || {};
    return Promise.resolve(data);
  }
};

export const miaResponseHandler = (response: AxiosResponse) => {
  if (response.data === undefined) {
    return Promise.reject({
      description: 'Client failed to parse MIA response'
    });
  } else {
    const data = response.data || {};
    return Promise.resolve(data);
  }
};

export const axiosErrorHandler = (
  error: AxiosError
): Promise<APIRequestError> => {
  if (axios.isCancel(error)) {
    return Promise.reject(API_REQUEST_CANCELED);
  }

  if (axios.isAxiosError(error)) {
    if (error.response) {
      const status = error.response.status || 500;
      const data = error.response.data || {};
      const msg = data.detail || data.error || data.msg || JSON.stringify(data);
      return Promise.reject({ description: msg, status: status });
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error('Network error in making request: ', error.request);
      return Promise.reject({
        description:
          'Internal server error, request did not recieve a response',
        status: 500
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Client error setting up request: ', error.message);
      return Promise.reject({
        description: error.message,
        status: 500
      });
    }
  }

  throw error;
};

// const prettyPrint = (x: object) => {
//   return JSON.stringify(x, null, 2); // spacing level = 2
// };

// const prettyPrintAxiosError = (error: AxiosError) => {
//   if (error.response) {
//     return prettyPrint(error.response);
//   } else {
//     return prettyPrint(error);
//   }
// };

export const defaultErrorHandler = (message: string) => {
  return (error: AxiosError) => {
    if (axios.isCancel(error)) {
      return Promise.reject(API_REQUEST_CANCELED);
    }

    console.log(message);
    // this leaks sensitive information to the console
    // console.log(prettyPrintAxiosError(error));
    return Promise.reject(message);
  };
};

export const structuredErrorHandler = (error: AxiosError) => {
  if (axios.isCancel(error)) {
    return Promise.reject(API_REQUEST_CANCELED);
  }

  if (error.response) {
    return Promise.reject({
      status_code: error.response.status,
      error: error.response.data.detail || error.response.data.error
    });
  } else {
    return Promise.reject({
      status_code: null,
      error: 'Unable to reach server. Please try again later.'
    });
  }
};

import { Icon } from '@iconify/react';
import IcRoundRemoveCircleOutline from '@iconify/icons-ic/round-remove-circle-outline';
import { TRENDPOP_BLACK } from 'constants/colors';

interface AddTaskIconProps {
  color?: string;
  size?: string;
}

const MinusCircleIcon = (props: AddTaskIconProps) => {
  const color = props.color || TRENDPOP_BLACK;
  const size = props.size || '75px';

  return (
    <Icon
      icon={IcRoundRemoveCircleOutline}
      color={color}
      width={size}
      height={size}
    />
  );
};

export default MinusCircleIcon;

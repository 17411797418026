// See color palettes at https://trendpop.atlassian.net/wiki/spaces/MAR/pages/13238273/Color+Palletes

export const TRENDPOP_RED = '#FF2543';
export const TRENDPOP_BLUE = '#5CE3F2';
export const TRENDPOP_WHITE = '#FFFFFF';
export const TRENDPOP_GREEN = '#00D88A';
export const TRENDPOP_GREEN_2 = '#49D88A';
export const TRENDPOP_BLACK = '#000B1B';
export const TRENDPOP_BLACK_2 = '#000000';
export const TRENDPOP_ORANGE = '#F65925';
export const TRENDPOP_PURPLE_1 = '#974CEE';
export const TRENDPOP_PURPLE_2 = '#C488FF';
export const TRENDPOP_PURPLE_3 = '#7D2DDB';
export const TRENDPOP_PURPLE_4 = '#E4DBFF';
export const TRENDPOP_GREY = '#C4C8CC';
export const TRENDPOP_GREY_2 = '#F1F1F1';
export const TRENDPOP_GREY_3 = '#E0E0E0';
export const TRENDPOP_GREY_4 = '#808080';
export const TRENDPOP_BODY_TEXT = '#4F4F4F';

export const TRENDPOP_CAMPAIGN_LIVE = TRENDPOP_GREEN;
export const TRENDPOP_CAMPAIGN_PLANNING = TRENDPOP_ORANGE;
export const TRENDPOP_CAMPAIGN_COMPLETED = TRENDPOP_BODY_TEXT;

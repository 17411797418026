import axios from 'axios';

import { requester } from '../../api';
import { abbaResponseHandler, axiosErrorHandler } from '../../api/util';
import {
  MIA_BILLING_ACTIVATE_PLAN_LINK,
  MIA_BILLING_UPDATE_PLAN_LINK
} from 'constants/links';
import { PlanName } from 'models/user';

interface UpgradePlanProps {
  plan: PlanName;
  type: string;
  quantity: number;
  preview: boolean;
}

interface ActivatePlanProps {
  plan: PlanName;
  period: string;
  seat: number;
  redirect: string;
  cancel_redirect: string;
}

const CancelToken = axios.CancelToken;
export const upgradePlan = (body: UpgradePlanProps): Promise<any> => {
  const url = MIA_BILLING_UPDATE_PLAN_LINK;
  const cancelToken = CancelToken.source();

  return requester
    .post(
      url,
      {
        ...body
      },
      {
        cancelToken: cancelToken.token
      }
    )
    .then(abbaResponseHandler, axiosErrorHandler);
};

export const activatePlan = (body: ActivatePlanProps): Promise<any> => {
  const url = MIA_BILLING_ACTIVATE_PLAN_LINK;
  const cancelToken = CancelToken.source();

  return requester
    .post(
      url,
      {
        ...body
      },
      {
        cancelToken: cancelToken.token
      }
    )
    .then(abbaResponseHandler, axiosErrorHandler);
};

import React from 'react';
import { Link } from '@material-ui/core';
import Url from 'url-parse';
import { Link as ReactRouterLink } from 'react-router-dom';
import _ from 'lodash';

import {
  fmtTrendpopHashtagDetailPath,
  fmtTrendpopProfileDetailPath
} from './legacyFormat';
import { CollectionItem } from '../models/collectionItem';
import { SPOTIFY_ICON } from '../constants/links';

// Functions based on simple heuristics that attempt to parse a URL for a Tik
// Tok song URL. Returns the song ID if one is found, otherwise, return null;

export const tryParseSoundID = (url) => {
  const parsed = new Url(url);
  if (!parsed || !parsed.pathname || !parsed.hostname.includes('tiktok')) {
    return null;
  }

  const regex = /^\/music\/.*-(\d+)/g;
  var arr = regex.exec(parsed.pathname);
  if (arr && arr.length >= 2) {
    return arr[1];
  }

  const regex2 = /^\/h5\/share\/music\/(\d+)/g;
  arr = regex2.exec(parsed.pathname);
  if (arr && arr.length >= 2) {
    return arr[1];
  }

  return null;
};

export const tryParseProfileUniqueId = (url) => {
  const parsed = new Url(url);
  if (!parsed || !parsed.pathname || !parsed.hostname.includes('tiktok')) {
    return null;
  }

  const regex = /@([^/]+)/g;
  var arr = regex.exec(parsed.pathname);
  if (arr && arr.length >= 2) {
    return arr[1];
  }

  return null;
};

export const tryParseHashtagTitle = (url) => {
  const parsed = new Url(url);
  if (!parsed || !parsed.pathname || !parsed.hostname.includes('tiktok')) {
    return null;
  }

  const regex = /^\/tag\/(.+)/g;
  var arr = regex.exec(parsed.pathname);
  if (arr && arr.length >= 2) {
    return arr[1];
  }

  return null;
};

export const tryParseVideoId = (url) => {
  const parsed = new Url(url);
  if (!parsed || !parsed.pathname || !parsed.hostname.includes('tiktok')) {
    return [null, null];
  }

  const regex = /@([^/]+)\/video\/([^/]+)/g;
  var arr = regex.exec(parsed.pathname);
  if (arr && arr.length >= 3) {
    return [arr[1], arr[2]];
  }

  return [null, null];
};

export const tryParseSpotifyAlbum = (url) => {
  const parsed = new Url(url);
  if (!parsed || !parsed.pathname || !parsed.hostname.includes('spotify')) {
    return null;
  }

  const regex = /^\/album\/(.+)$/g;
  var arr = regex.exec(parsed.pathname);
  if (arr && arr.length >= 2) {
    return arr[1];
  }

  return null;
};

export const tryParseSpotifyArtist = (url) => {
  const parsed = new Url(url);
  if (!parsed || !parsed.pathname || !parsed.hostname.includes('spotify')) {
    return null;
  }

  const regex = /^\/artist\/(.+)$/g;
  var arr = regex.exec(parsed.pathname);
  if (arr && arr.length >= 2) {
    return arr[1];
  }

  return null;
};

export const tryParseSpotifyTrack = (url) => {
  const parsed = new Url(url);
  if (!parsed || !parsed.pathname || !parsed.hostname.includes('spotify')) {
    return null;
  }

  const regex = /^\/track\/(.+)$/g;
  var arr = regex.exec(parsed.pathname);
  if (arr && arr.length >= 2) {
    return arr[1];
  }

  return null;
};

export const tryParseISRC = (fragment) => {
  const regex = /^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/g;
  var arr = regex.exec(fragment);
  if (arr && arr.length >= 1) {
    return arr[0];
  }

  return null;
};

export const tryParseURL = (url, allowedTypes) => {
  let matches = [];

  const soundId = tryParseSoundID(url);
  if (soundId && allowedTypes.includes('tiktok_sound')) {
    matches.push({
      entity_type: 'tiktok_sound',
      id: soundId,
      primary: 'Sound ' + soundId,
      collection_item: CollectionItem({
        itemType: 'sound',
        soundId: soundId
      })
    });
  }

  const profileUniqueId = tryParseProfileUniqueId(url);
  if (profileUniqueId && allowedTypes.includes('tiktok_profile')) {
    matches.push({
      entity_type: 'tiktok_profile',
      primary: profileUniqueId,
      collection_item: CollectionItem({
        itemType: 'profile_name',
        profileUniqueId: profileUniqueId
      })
    });
  }

  const hashtagTitle = tryParseHashtagTitle(url);
  if (hashtagTitle && allowedTypes.includes('tiktok_tag')) {
    matches.push({
      entity_type: 'tiktok_tag',
      primary: hashtagTitle,
      collection_item: CollectionItem({
        itemType: 'tag',
        tagTitle: hashtagTitle
      })
    });
  }

  const [uniqueId, videoId] = tryParseVideoId(url);
  if (uniqueId && videoId && allowedTypes.includes('tiktok_item')) {
    matches.push({
      entity_type: 'tiktok_item',
      profileUniqueId: uniqueId,
      videoId: videoId,
      primary: 'Video ' + videoId,
      collection_item: CollectionItem({
        itemType: 'item',
        videoId: videoId
      })
    });
  }

  if (uniqueId && videoId && allowedTypes.includes('tiktok_duet')) {
    matches.push({
      entity_type: 'tiktok_duet',
      profileUniqueId: uniqueId,
      id: videoId,
      primary: 'Video ' + videoId
    });
  }

  const spotifyArtist = tryParseSpotifyArtist(url);
  if (spotifyArtist && allowedTypes.includes('spotify_artist')) {
    matches.push({
      entity_type: 'spotify_artist',
      id: spotifyArtist,
      primary: 'Spotify Artist ' + spotifyArtist,
      icon: SPOTIFY_ICON,
      collection_item: CollectionItem({
        itemType: 'artist',
        artistId: spotifyArtist
      })
    });
  }

  const spotifyTrackId = tryParseSpotifyTrack(url);
  if (spotifyTrackId && allowedTypes.includes('spotify_track')) {
    matches.push({
      entity_type: 'spotify_track',
      id: spotifyTrackId,
      primary: 'Spotify Track ' + spotifyTrackId,
      icon: SPOTIFY_ICON,
      collection_item: CollectionItem({
        itemType: 'song',
        songId: spotifyTrackId
      })
    });
  }

  const spotifyAlbum = tryParseSpotifyAlbum(url);
  if (spotifyAlbum && allowedTypes.includes('spotify_album')) {
    matches.push({
      entity_type: 'spotify_album',
      id: spotifyAlbum,
      primary: 'Spotify Album ' + spotifyAlbum,
      icon: SPOTIFY_ICON,
      collection_item: CollectionItem({
        itemType: 'album',
        albumId: spotifyAlbum
      })
    });
  }

  return matches;
};

const RouterLink = (props) => {
  // React router will pass a "navigate" prop to Link, which will trigger a DOM
  // warning: "Warning: Invalid value for prop `navigate` on <a> tag." To
  // avoid this warning, prevent 'navigate' from being passed to link if
  // present.
  //
  // https://github.com/ReactTraining/react-router/issues/6962
  const LinkWithoutNavigate = (props) => {
    return <Link {..._.omit(props, 'navigate')} />;
  };

  return (
    <ReactRouterLink
      target="_blank"
      rel="noopener noreferrer"
      component={LinkWithoutNavigate}
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...props}
    />
  );
};

export const tokenizeVideoDescription = (description) => {
  if (!description) {
    return [];
  }

  // Do basic tokenization to add links to any relevant users and/or hashtags.
  // First, split on whitespace. Next, separate any remaining tokens that are
  // concatenations of hashtags or accounts (e.g. #a#b#c or xyz@account)
  const tokens = description
    .split(' ')
    .map((token) => {
      var result = [''];
      for (var i = 0; i < token.length; i++) {
        if (i !== 0 && (token[i] === '#' || token[i] === '@')) {
          result.push('');
        }
        result[result.length - 1] += token[i];
      }

      return result;
    })
    .flat();

  return tokens
    .map((t) => t.toWellFormed())
    .map((token, idx) => {
      if (token.startsWith('@')) {
        return [
          <RouterLink
            to={fmtTrendpopProfileDetailPath(token.slice(1))}
            key={idx}>
            {token}
          </RouterLink>,
          <span key={`${idx}-space`}> </span>
        ];
      } else if (token.startsWith('#')) {
        return [
          <RouterLink
            to={fmtTrendpopHashtagDetailPath(token.slice(1))}
            key={idx}>
            {token}
          </RouterLink>,
          <span key={`${idx}-space`}> </span>
        ];
      } else {
        return [
          <span key={idx}>{token}</span>,
          <span key={`${idx}-space`}> </span>
        ];
      }
    })
    .flat()
    .slice(0, -1);
};

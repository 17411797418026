import { useHistory } from 'react-router-dom';
import { useLocalStorage } from '../../utils/hooks';
import { useEffect } from 'react';

// For the purposes of this library, setting a local state variable to empty string is equivalent to it not being set in the first place.
// Conversely, setting a local state variable to a string is the same effect as clearing it from local storage.
export interface LoginLocalState {
  workspaceId: string;
  setWorkspaceId: (x: string) => void;

  workspaceName: string;
  setWorkspaceName: (x: string) => void;

  redirectUrl: string;
  setRedirectUrl: (x: string) => void;

  productSlug: string;
  setProductSlug: (x: string) => void;

  sellerInviteId: string;
  setSellerInviteId: (x: string) => void;

  resetId: string;
  setResetId: (x: string) => void;

  email: string;
  setEmail: (x: string) => void;

  period: string;
  setPeriod: (x: string) => void;

  seat: string;
  setSeat: (x: string) => void;

  error: string;
  setError: (x: string) => void;

  trialPeriod: string;
  setTrialPeriod: (x: string) => void;
}

export const useLoginLocalState = (): LoginLocalState => {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);

  const [workspaceId, setWorkspaceId] = useLocalStorage('workspace_id', '');
  const queryParamWorkspaceId = queryParams.get('workspace_id') || '';

  const [workspaceName, setWorkspaceName] = useLocalStorage(
    'workspace_name',
    ''
  );
  const queryParamWorkspaceName = queryParams.get('workspace_name') || '';

  const [redirectUrl, setRedirectUrl] = useLocalStorage('redirect_url', '');
  const queryParamRedirectUrl = queryParams.get('redirect_url') || '';

  const [productSlug, setProductSlug] = useLocalStorage('product_slug', '');
  const queryParamProductSlug = queryParams.get('product_slug') || '';

  const [sellerInviteId, setSellerInviteId] = useLocalStorage(
    'seller_invite_id',
    ''
  );
  const queryParamSellerInviteId = queryParams.get('seller_invite_id') || '';

  const [resetId, setResetId] = useLocalStorage('reset_id', '');
  const queryParamResetId = queryParams.get('reset_id') || '';

  const [period, setPeriod] = useLocalStorage('period', '');
  const queryParamPeriod = queryParams.get('period') || '';

  const [seat, setSeat] = useLocalStorage('seat', '');
  const queryParamSeat = queryParams.get('seat') || '';

  const [email, setEmail] = useLocalStorage('email', '');
  const queryParamEmail = queryParams.get('email') || '';

  const [error, setError] = useLocalStorage('error', '');
  const queryParamError = queryParams.get('error') || '';

  const [trialPeriod, setTrialPeriod] = useLocalStorage('trial_period', '');
  const queryParamTrialPeriod = queryParams.get('trial_period') || '';

  useEffect(() => {
    if (queryParamWorkspaceId) {
      setWorkspaceId(queryParamWorkspaceId);
    }
  }, [queryParamWorkspaceId, setWorkspaceId]);

  useEffect(() => {
    if (queryParamWorkspaceName) {
      setWorkspaceName(queryParamWorkspaceName);
    }
  }, [queryParamWorkspaceName, setWorkspaceName]);

  useEffect(() => {
    if (queryParamEmail) {
      setEmail(queryParamEmail);
    }
  }, [queryParamEmail, setEmail]);

  useEffect(() => {
    if (queryParamRedirectUrl) {
      setRedirectUrl(queryParamRedirectUrl);
    }
  }, [queryParamRedirectUrl, setRedirectUrl]);

  useEffect(() => {
    if (queryParamProductSlug) {
      setProductSlug(queryParamProductSlug);
    }
  }, [queryParamProductSlug, setProductSlug]);

  useEffect(() => {
    if (queryParamSellerInviteId) {
      setSellerInviteId(queryParamSellerInviteId);
    }
  }, [queryParamSellerInviteId, setSellerInviteId]);

  useEffect(() => {
    if (queryParamResetId) {
      setResetId(queryParamResetId);
    }
  }, [queryParamResetId, setResetId]);

  useEffect(() => {
    if (queryParamPeriod) {
      setPeriod(queryParamPeriod);
    }
  }, [queryParamPeriod, setPeriod]);

  useEffect(() => {
    if (queryParamSeat) {
      setSeat(queryParamSeat);
    }
  }, [queryParamSeat, setSeat]);

  useEffect(() => {
    if (queryParamError) {
      setError(queryParamError);
    }
  }, [queryParamError, setError]);

  useEffect(() => {
    if (queryParamTrialPeriod) {
      setTrialPeriod(queryParamTrialPeriod);
    }
  }, [queryParamTrialPeriod, setTrialPeriod]);

  // Removes redirect url after reaching to destination
  useEffect(() => {
    if (history.location.pathname === redirectUrl) {
      setRedirectUrl('');
    }
  }, [history.location.pathname, setRedirectUrl, redirectUrl]);

  return {
    workspaceId,
    setWorkspaceId,

    workspaceName,
    setWorkspaceName,

    redirectUrl,
    setRedirectUrl,

    productSlug,
    setProductSlug,

    sellerInviteId,
    setSellerInviteId,

    resetId,
    setResetId,

    email,
    setEmail,

    period,
    setPeriod,

    seat,
    setSeat,

    error,
    setError,

    trialPeriod,
    setTrialPeriod
  };
};

import React, {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect
} from 'react';
import { useHistory } from 'react-router-dom';
import { AmplitudeClient } from '../logging/amplitude';
import { RecentProjectType } from 'models/dashboard';
import {
  RecentProjectTypeField,
  saveRecentProjectHistory
} from 'pages/home/queries';
import { ReportTypeField, saveDetailReportHistory } from 'pages/detail/queries';
import { ReportType } from 'pages/detail/models';
import { useSnackbar } from 'notistack';

export const RouteListener: React.FC<PropsWithChildren<never>> = ({
  children
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const h = useHistory();

  const saveHistory = useCallback(
    (pathname: string, params: URLSearchParams) => {
      const saveReport = (type: ReportType, id: string) => {
        const field = ReportTypeField[type];
        const url = window.location.href;
        saveDetailReportHistory({ type, field, id, url }).catch((err) => {
          enqueueSnackbar(`Unable to save history: ${err.description}`, {
            variant: 'error'
          });
        });
      };

      const saveProject = (type: RecentProjectType, id: string) => {
        const field = RecentProjectTypeField[type];
        saveRecentProjectHistory({ type, field, id }).catch((err) => {
          enqueueSnackbar(`Unable to save history: ${err.description}`, {
            variant: 'error'
          });
        });
      };

      // Report routes
      if (pathname.startsWith('/detail')) {
        if (pathname.startsWith('/detail/')) {
          const detailId = pathname.split('/').pop(); // gets the id which is at the end of the pathname
          if (!detailId) return; // if no id is found don't save

          if (
            pathname.includes('/creators/') &&
            !pathname.includes('/videos/')
          ) {
            saveReport(ReportType.ProfileName, detailId);
          } else if (pathname.includes('/videos/')) {
            saveReport(ReportType.Video, detailId);
          } else if (pathname.includes('/sounds/')) {
            saveReport(ReportType.Sound, detailId);
          } else if (pathname.includes('/tags/')) {
            saveReport(ReportType.Tag, detailId);
          } else if (pathname.includes('/artists/')) {
            saveReport(ReportType.Artist, detailId);
          } else if (pathname.includes('/songs/')) {
            saveReport(ReportType.Song, detailId);
          } else if (pathname.includes('/isrc/')) {
            saveReport(ReportType.Song, detailId);
          } else if (pathname.includes('/albums/')) {
            saveReport(ReportType.Album, detailId);
          }
        } else {
          // if Params in detail e.g. /detail?tag_title=sample
          if (params.has('creator_unique_id')) {
            if (params.has('video_id')) {
              saveReport(ReportType.Video, params.get('video_id')!);
            } else {
              saveReport(
                ReportType.ProfileName,
                params.get('creator_unique_id')!
              );
            }
          } else if (params.has('sound_id')) {
            saveReport(ReportType.Sound, params.get('sound_id')!);
          } else if (params.has('tag_title')) {
            saveReport(ReportType.Tag, params.get('tag_title')!);
          } else if (params.has('artist_id')) {
            saveReport(ReportType.Artist, params.get('tag_title')!);
          } else if (params.has('isrc') || params.has('song_id')) {
            saveReport(
              ReportType.Song,
              (params.get('isrc') || params.get('song_id'))!
            );
          } else if (params.has('album_id')) {
            saveReport(ReportType.Album, params.get('album_id')!);
          }
        }
      } else {
        // Project routes
        const projectId = pathname.split('/').pop(); // gets the id which is at the end of the pathname
        if (!projectId) return; // if no id is found don't save

        if (pathname.startsWith('/catalogs/')) {
          saveProject(RecentProjectType.Catalog, projectId);
        } else if (pathname.startsWith('/campaigns/')) {
          saveProject(RecentProjectType.Campaign, projectId);
        } else if (pathname.startsWith('/collections/')) {
          saveProject(RecentProjectType.Collection, projectId);
        }
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    // Detects if the page was opened in a new tab it will then only save history if so
    if (
      h.location.pathname.startsWith('/detail') ||
      h.location.pathname.startsWith('/catalogs/') ||
      h.location.pathname.startsWith('/campaigns/') ||
      h.location.pathname.startsWith('/collections/')
    ) {
      const navigationEntry = window.performance
        .getEntriesByType('navigation')[0]
        ?.toJSON()?.type;
      if (navigationEntry !== 'reload') {
        saveHistory(
          h.location.pathname,
          new URLSearchParams(h.location.search)
        );
      }
    }

    return h.listen((location, action) => {
      const pathname = location.pathname;
      const params = new URLSearchParams(h.location.search);

      if (
        pathname.startsWith('/detail') ||
        pathname.startsWith('/catalogs/') ||
        pathname.startsWith('/campaigns/') ||
        pathname.startsWith('/collections/')
      ) {
        saveHistory(pathname, params);
      }

      AmplitudeClient.logPageView(location.pathname, {
        action
      });
    });
  }, [h, saveHistory]);

  return <Fragment>{children}</Fragment>;
};

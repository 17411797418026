import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Box,
  TextField,
  Typography,
  makeStyles,
  Button,
  Grid,
  Link,
  CircularProgress,
  FormHelperText
} from '@material-ui/core';
import {
  TRENDPOP_BLACK,
  TRENDPOP_GREY_2,
  TRENDPOP_PURPLE_3
} from '../../../constants/colors';
import {
  AUTH_CALLBACK_PATH,
  MIA_RESEND_EMAIL,
  MIA_VERIFY_EMAIL
} from '../../../constants/links';
import { fetchPostRedirect } from '../util';
import { useUser } from 'context/hooks/user';
import { Redirect } from 'react-router-dom';
import Banner from '../components/Banner';

const defaultTimer = 30000;

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: 600,
    color: TRENDPOP_PURPLE_3
  },
  linkButton: {
    fontWeight: 600,
    textDecoration: 'underline'
  },
  submitButton: {
    height: 50,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  },
  imageContainer: {
    backgroundColor: TRENDPOP_GREY_2,
    background: `url('/static/img/line-vector.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 20%',
    backgroundSize: '100%',
    objectFit: 'cover'
  },
  formContainer: {
    padding: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    maxWidth: '450px',
    width: '100%'
  }
}));

const VerificationCode = () => {
  const classes = useStyles();
  const { user, isLoading, error: userDataError } = useUser();

  const [loading, setLoading] = useState(false);
  const [remainingTime, setRemainingTime] = useState(defaultTimer);
  const [isResendDisabled, setisResendDisabled] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [digits, setDigits] = useState<string[]>(Array(7).fill(''));

  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  useEffect(() => {
    if (isResendDisabled) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1000);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  });

  const handleVerifyEmail = useCallback(() => {
    setLoading(true);
    const promise = fetchPostRedirect(
      MIA_VERIFY_EMAIL,
      {
        c1: digits[0],
        c2: digits[1],
        c3: digits[2],
        c4: digits[3],
        c5: digits[4],
        c6: digits[5],
        c7: digits[6]
      },
      () => {
        setError('Error in verifying email, please try again.');
      },
      `${window.location.origin}${AUTH_CALLBACK_PATH}`
    );

    promise.then(
      () => {
        setLoading(false);
      },
      (error: any) => {
        console.log(error);
        setLoading(false);
        setError(error.message);
      }
    );
  }, [digits]);

  const handleResendVerificationCode = useCallback(() => {
    fetchPostRedirect(MIA_RESEND_EMAIL, {}, () => {});

    if (!isResendDisabled) {
      setisResendDisabled(true);

      setTimeout(() => {
        setisResendDisabled(false);
        setRemainingTime(defaultTimer);
      }, defaultTimer);
    }
  }, [isResendDisabled]);

  const handleDigitChange = (index: number) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setError('');

    const { value } = event.target;
    // Remove any non-digit characters from the input
    const digitOnly = value.replace(/\D/g, '');
    const updatedDigits = [...digits];
    updatedDigits[index] = digitOnly;
    setDigits(updatedDigits);

    if (digitOnly.length === 1 && index < digits.length - 1) {
      inputRefs.current[index + 1]?.focus();
    } else if (digitOnly.length === 0 && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Backspace' && digits[index] === '' && index > 0) {
      setDigits((prevDigits) => {
        const updatedDigits = [...prevDigits];
        updatedDigits[index - 1] = '';
        return updatedDigits;
      });
      inputRefs.current[index - 1]?.focus();
    }

    // Added to remove the 2nd to the last digit as to input another number
    if (event.key === 'Backspace' && digits[index - 1] !== '' && index === 6) {
      setDigits((prevDigits) => {
        const updatedDigits = [...prevDigits];
        updatedDigits[index - 1] = '';
        return updatedDigits;
      });
    }
  };

  // Pre submits the form if all digits are filled
  useEffect(() => {
    const submit = digits.every((element) => element !== '');

    if (submit) {
      handleVerifyEmail();
    }
  }, [digits, handleVerifyEmail]);

  if (isLoading) {
    return (
      <Grid container alignItems="center" justify="center">
        <CircularProgress size="40px" color="secondary" />
      </Grid>
    );
  }

  if (user.getUserId() === undefined || userDataError) {
    return <Redirect to="/auth/login" />;
  }

  if (user.getIsEmailVerified()) {
    return <Redirect to="/auth/callback" />;
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={3} className={classes.imageContainer}>
        <Banner user={user} />
      </Grid>
      <Grid item xs={10} md={9} className={classes.formContainer}>
        <Box display="flex" flexDirection="column" className={classes.form}>
          <Box>
            <Typography variant="h3" style={{ fontWeight: 600 }}>
              Enter your verification code
            </Typography>
            <Box my={5} />
            <Typography variant="h6">
              Please enter the vertification code we sent to your email:
              <Box my={2} />
              <Link
                href={`mailto:${user.getEmail()}`}
                style={{ color: TRENDPOP_BLACK }}>
                {user.getEmail()}
              </Link>
            </Typography>
          </Box>
          <Box my={5} />
          <Box display="flex" alignItems="center">
            <Box component="span" mr={2}>
              <Typography variant="h4" noWrap>
                V -
              </Typography>
            </Box>
            {digits.slice(0, 3).map((digit, index) => (
              <TextField
                variant="outlined"
                key={index}
                value={digit}
                error={Boolean(error)}
                onChange={handleDigitChange(index)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(index, event)
                }
                inputProps={{
                  maxLength: 1,
                  pattern: '[0-9]',
                  style: { textAlign: 'center' }
                }}
                style={{ width: '45px', marginRight: '5px', minWidth: '35px' }}
                inputRef={(input) => (inputRefs.current[index] = input)}
              />
            ))}
            <Box component="span" mx={3}>
              <Typography variant="h4"> - </Typography>
            </Box>
            {digits.slice(3).map((digit, index) => (
              <TextField
                variant="outlined"
                key={index + 3}
                value={digit}
                error={Boolean(error)}
                onChange={handleDigitChange(index + 3)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(index + 3, event)
                }
                inputProps={{
                  maxLength: 1,
                  pattern: '[0-9]',
                  style: { textAlign: 'center' }
                }}
                style={{ width: '45px', marginRight: '5px', minWidth: '35px' }}
                inputRef={(input) => (inputRefs.current[index + 3] = input)}
              />
            ))}
          </Box>
          {error && <FormHelperText error>{error}</FormHelperText>}
          <Box my={3} />
          {isResendDisabled ? (
            <Typography>{`(${
              remainingTime / 1000
            } seconds before resending)`}</Typography>
          ) : (
            <Link
              align="left"
              component="button"
              className={classes.linkButton}
              onClick={handleResendVerificationCode}
              disabled={isResendDisabled}>
              <Typography>Resend verification code</Typography>
            </Link>
          )}
          <Box my={5} />
          <Button
            onClick={handleVerifyEmail}
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={digits.some((digit) => digit === '') || loading}>
            {loading ? (
              <CircularProgress size="20px" />
            ) : (
              <Typography variant="subtitle1">Submit</Typography>
            )}
          </Button>
          <Box my={5} />
          <Box display="flex" justifyContent="center">
            <Typography variant="body1">
              Sent to the wrong email?{' '}
              <Link href="/auth/callback" className={classes.link}>
                Go back
              </Link>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VerificationCode;

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from 'styled-components/macro';
import { create } from 'jss';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset
} from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';

import Routes from './routes/Routes';
import { AudioPlayerProvider } from './layouts/components/AudioPlayer';
import { LayoutProvider } from './context/layout';
import createTheme from './theme';
import { THEMES } from './constants';
import { AmplitudeClient } from './logging/amplitude';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point')
});

const App = () => {
  const queryClient = new QueryClient();

  useEffect(() => {
    AmplitudeClient.initAmplitudeSession();
  }, []);

  return (
    <React.Fragment>
      <Helmet titleTemplate="%s | Trendpop" defaultTitle="Trendpop" />
      <QueryClientProvider client={queryClient}>
        <LayoutProvider>
          <AudioPlayerProvider>
            <MuiThemeProvider theme={createTheme(THEMES.DEFAULT)}>
              <ThemeProvider theme={createTheme(THEMES.DEFAULT)}>
                <SnackbarProvider>
                  <StylesProvider jss={jss}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Routes />
                    </MuiPickersUtilsProvider>
                  </StylesProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </AudioPlayerProvider>
        </LayoutProvider>
      </QueryClientProvider>
    </React.Fragment>
  );
};

export default App;

import { SavedQueryListResponse } from './savedQuery';

export interface CreatorsToWatch {
  item_type: 'profile' | 'profile_name';
  profile_id?: string | undefined;
  profile_unique_id?: string | undefined;
}

export enum RecentProjectType {
  Campaign = 'campaign',
  Catalog = 'catalog',
  Collection = 'collection'
}

export interface RecentProjects {
  id: string;
  project: any;
  project_type: RecentProjectType;
  created_at: Date;
}

export interface TrendToWach {
  id: string;
  description: string;
  name: string;
  num_items: number;
  shared: boolean;
  user_email: string;
  user_id: string;
  user_name: string;
  workspace_read: boolean;
  workspace_write: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface Insights {
  trend_to_watch: TrendToWach[];
  saved_queries: SavedQueryListResponse[];
}

export interface SearchHistory {
  details: VideoDetails[];
  id: string;
  created_at: Date;
  report_type: string;
  report_url: string;
  workspace_user_id: string;

  // optional keys
  profile_unique_id: undefined | string | number;
  sound_id: undefined | string | number;
  tag_title: undefined | string | number;
  video_id: undefined | string | number;
  song_id: undefined | string | number;
  artist_id: undefined | string;
  album_id: undefined | string | number;
}

export interface VideoDetails {
  verified: boolean;
  tiktok_id: string;
  description: string;
  name: string;
  image_small: string;
  sound_id: string;
  profile_unique_id: string;
  title: string;
  original: boolean;
  deleted: boolean;
}

export interface DashboardResponse {
  recent_projects: RecentProjects[];
  search_history: SearchHistory[];
  creators_to_watch: CreatorsToWatch[];
  insights: Insights[];
}

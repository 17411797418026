import React from 'react';
import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  Tooltip as MuiTooltip,
  withStyles
} from '@material-ui/core';

import {
  DefaultUpgradePlanColor,
  upgradePlanColor
} from '../../../models/plan';
import MuiCheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PlanIcon from './PlanIcon';
import { PlanName } from 'models/user';
import {
  MIN_BUSINESS_SEATS,
  MIN_STARTER_SEATS,
  MIN_TEAM_SEATS
} from 'constants/plan';

export interface CompareFeaturesProps {
  setOpenFeature: (openFeature: boolean) => void;
  openFeature: boolean;
}

const CompareCollapsible = withStyles(() => ({
  root: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent'
    }
  }
}))(Box);

const CellHeaderBox = withStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))(Box);

const CellFeatureBox = withStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}))(Box);

const TableRow = withStyles(() => ({
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    borderTop: `1px solid #e4dbff`
  }
}))(MuiTableRow);

const TableCell = withStyles(() => ({
  root: {
    paddingTop: 15,
    paddingBottom: 15,
    borderLeft: `1px solid #e4dbff`,
    borderBottom: 'none'
  }
}))(MuiTableCell);

const Tooltip = withStyles(() => ({
  arrow: {
    color: '#333333'
  },
  tooltip: {
    fontSize: 14,
    backgroundColor: '#333333'
  }
}))(MuiTooltip);

const CheckIcon = withStyles({
  root: {
    color: DefaultUpgradePlanColor
  }
})(MuiCheckIcon);

const CompareFeatures = (props: CompareFeaturesProps) => {
  const { setOpenFeature, openFeature } = props;

  return (
    <Paper>
      <Box padding={5} justifyContent="center">
        <Box margin={5} border="1px solid #e4dbff" borderRadius={10}>
          <CompareCollapsible
            style={{
              color: DefaultUpgradePlanColor
            }}
            onClick={() => setOpenFeature(!openFeature)}>
            <Typography variant="h3">
              Compare Features {openFeature ? '-' : '+'}
            </Typography>
          </CompareCollapsible>
          <Collapse in={openFeature}>
            <TableContainer>
              <Table>
                <colgroup>
                  <col style={{ width: '40%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '15%' }} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}></TableCell>
                    <TableCell>
                      <CellHeaderBox>
                        <PlanIcon color={upgradePlanColor[PlanName.Starter]} />
                        <Typography style={{ paddingLeft: 5 }}>
                          Starter
                        </Typography>
                      </CellHeaderBox>
                    </TableCell>
                    <TableCell>
                      <CellHeaderBox>
                        <PlanIcon color={upgradePlanColor[PlanName.Team]} />
                        <Typography style={{ paddingLeft: 5 }}>Team</Typography>
                      </CellHeaderBox>
                    </TableCell>
                    <TableCell>
                      <CellHeaderBox>
                        <PlanIcon color={upgradePlanColor[PlanName.Business]} />
                        <Typography style={{ paddingLeft: 5 }}>
                          Business
                        </Typography>
                      </CellHeaderBox>
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#fff',
                        backgroundImage:
                          'linear-gradient(90deg, rgba(60,0,160,1) 0%, rgba(119,32,150,1) 50%, rgba(181,63,137,1) 100%)'
                      }}>
                      <CellHeaderBox>
                        <PlanIcon color="#fff" />
                        <Typography style={{ paddingLeft: 5 }}>
                          Enterprise
                        </Typography>
                      </CellHeaderBox>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>Users</TableCell>
                    <TableCell align="center">{MIN_STARTER_SEATS}</TableCell>
                    <TableCell align="center">{MIN_TEAM_SEATS}</TableCell>
                    <TableCell align="center">{MIN_BUSINESS_SEATS}</TableCell>
                    <TableCell align="center">Custom Seat Options</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Track TikTok Creator
                        <Tooltip
                          arrow
                          title="Trendpop's TikTok Creator Analytics allows users to gain insight into the social footprint of a creator on TikTok."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Track TikTok Sounds
                        <Tooltip
                          arrow
                          title="Trendpop's TikTok Sound Analytics allows users to gain insight into the social footprint of a sound on TikTok."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Track TikTok Hashtags
                        <Tooltip
                          arrow
                          title="Trendpop's TikTok Hashtag Analytics allows users to gain insight into the social footprint of a hashtag on TikTok."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon style={{ color: DefaultUpgradePlanColor }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon style={{ color: DefaultUpgradePlanColor }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon style={{ color: DefaultUpgradePlanColor }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon style={{ color: DefaultUpgradePlanColor }} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Track TikTok Videos
                        <Tooltip
                          arrow
                          title="Trendpop's TikTok Audience Intelligence combines Trendpop's proprietary machine learning algorithms with TikTok's data to provide smarter and more accurate audience demographics data."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Trendpop Audience Intelligence
                        <Tooltip
                          arrow
                          title="Trendpop's TikTok Audience Intelligence combines Trendpop's proprietary machine learning algorithms with TikTok's data to provide smarter and more accurate audience demographics data."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Collections
                        <Tooltip
                          arrow
                          title="Maintain a collection of creators, sounds, hashtags, songs, artists, and albums."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">
                      {MIN_STARTER_SEATS} / user
                    </TableCell>
                    <TableCell align="center">
                      {MIN_TEAM_SEATS} / user
                    </TableCell>
                    <TableCell align="center">
                      {MIN_BUSINESS_SEATS} / user
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Real-time Email Alerts
                        <Tooltip
                          arrow
                          title="Trendpop's Real-time Email Alerts will send you daily or weekly email notifications when creators, sounds, hashtags, or videos of interest are going viral."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Custom Dashboard Integration
                        <Tooltip
                          arrow
                          title="Trendpop's Custom Dashboard Integration is a premium feature that is specifically designed for music customers. It currently serves the purpose of providing a customized hub for record labels, publishers, and artist managers to manage their catalog or artist roster all in one place."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Hourly Data Tracking
                        <Tooltip
                          arrow
                          title="Trendpop's Hourly Data Tracking will refresh TikTok data points that are of interest on an hourly basis instead of daily basis to provide even more real-time insights."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </Box>
      </Box>
    </Paper>
  );
};

export default CompareFeatures;

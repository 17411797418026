import { Box, Tooltip, Typography } from '@material-ui/core';
import {
  TRENDPOP_CAMPAIGN_COMPLETED,
  TRENDPOP_CAMPAIGN_LIVE,
  TRENDPOP_CAMPAIGN_PLANNING
} from '../../constants/colors';

interface CloverProps {
  color: string;
  size: number;
  style?: React.CSSProperties;
  viewBoxWidth?: number;
  viewBoxHeight?: number;
}

const CloverTooltip: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" my={1}>
        <CloverSvg color={TRENDPOP_CAMPAIGN_PLANNING} size={24}></CloverSvg>
        <Box mx={1} />
        <Typography variant="body1">Planning</Typography>
      </Box>
      <Box display="flex" flexDirection="row" my={1}>
        <CloverSvg color={TRENDPOP_CAMPAIGN_LIVE} size={24} />
        <Box mx={1} />
        <Typography variant="body1">Live</Typography>
      </Box>
      <Box display="flex" flexDirection="row" my={1}>
        <CloverSvg color={TRENDPOP_CAMPAIGN_COMPLETED} size={24} />
        <Box mx={1} />
        <Typography variant="body1">Completed</Typography>
      </Box>
    </Box>
  );
};

export const CloverSvg: React.FC<CloverProps> = ({
  color,
  size,
  style,
  viewBoxWidth = size,
  viewBoxHeight = size
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 5.83485C0 9.05733 2.61234 11.6697 5.83483 11.6697H11.6697V5.83485C11.6697 2.61236 9.05732 1.81198e-05 5.83483 1.81198e-05V1.81198e-05C2.61234 1.81198e-05 0 2.61236 0 5.83485V5.83485Z"
        fill={color}
      />
      <path
        d="M24 5.83485C24 9.05733 21.3877 11.6697 18.1652 11.6697H12.3303V5.83485C12.3303 2.61236 14.9427 1.81198e-05 18.1652 1.81198e-05V1.81198e-05C21.3877 1.81198e-05 24 2.61236 24 5.83485V5.83485Z"
        fill={color}
      />
      <path
        d="M0 18.1651C0 14.9426 2.61234 12.3303 5.83483 12.3303H11.6697V18.1651C11.6697 21.3876 9.05732 23.9999 5.83483 23.9999V23.9999C2.61234 23.9999 0 21.3876 0 18.1651V18.1651Z"
        fill={color}
      />
      <path
        d="M24 18.1651C24 14.9426 21.3877 12.3303 18.1652 12.3303H12.3303V18.1651C12.3303 21.3876 14.9427 23.9999 18.1652 23.9999V23.9999C21.3877 23.9999 24 21.3876 24 18.1651V18.1651Z"
        fill={color}
      />
    </svg>
  );
};

const CloverIcon: React.FC<CloverProps> = ({
  color,
  size,
  viewBoxWidth = size,
  viewBoxHeight = size
}) => {
  return (
    <Tooltip title={<CloverTooltip />} placement="right">
      <span>
        <CloverSvg
          color={color}
          size={size}
          style={{ cursor: 'pointer', verticalAlign: 'bottom' }}
          viewBoxWidth={viewBoxWidth}
          viewBoxHeight={viewBoxHeight}
        />
      </span>
    </Tooltip>
  );
};

export default CloverIcon;

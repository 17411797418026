import React from 'react';
import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { TRENDPOP_PURPLE_3 } from 'constants/colors';

const useStyles = makeStyles(() => ({
  backButton: {
    height: 50,
    width: 175,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  }
}));

const ErrorPage404 = () => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justify="center" direction="column">
      <Typography variant="h3">404</Typography>
      <Box my={2} />
      <Typography variant="h5">This page doesn't seem to exist.</Typography>
      <Box my={5} />
      <Button
        href="/auth/callback"
        variant="contained"
        color="primary"
        className={classes.backButton}>
        Back to Home
      </Button>
    </Grid>
  );
};

export default ErrorPage404;

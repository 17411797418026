import React from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';

import GoogleIcon from '../../../../components/Icons/GoogleIcon';
import LinkedInIcon from '../../../../components/Icons/LinkedInIcon';
import {
  TRENDPOP_GREY,
  TRENDPOP_PURPLE_3,
  TRENDPOP_WHITE
} from '../../../../constants/colors';
import {
  MIA_LOGIN_SOCIAL,
  AUTH_CALLBACK_PATH
} from '../../../../constants/links';
import { useLoginLocalState } from 'pages/accounts/useLoginLocalState';

const useStyles = makeStyles(() => ({
  link: {
    fontWeight: 600,
    color: TRENDPOP_PURPLE_3
  },
  continueButton: {
    height: 50,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  },
  socialButton: {
    height: 50,
    borderRadius: 30,
    border: `1px solid ${TRENDPOP_PURPLE_3}`,
    backgroundColor: TRENDPOP_WHITE,
    '&:hover': {
      backgroundColor: TRENDPOP_WHITE
    }
  },
  orContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0'
  },
  or: {
    flexGrow: 1,
    height: '1px',
    backgroundColor: TRENDPOP_GREY,
    margin: '0 10px'
  }
}));

const SocialLogins = () => {
  const classes = useStyles();
  const { setWorkspaceId } = useLoginLocalState();

  const redirect = `${window.location.origin}${AUTH_CALLBACK_PATH}`;

  return (
    <Box>
      <Box className={classes.orContainer}>
        <Box className={classes.or}></Box>
        <Typography variant="body1" align="center">
          or
        </Typography>
        <Box className={classes.or}></Box>
      </Box>
      <Box my={3} />
      <Button
        variant="contained"
        fullWidth
        onClick={() => setWorkspaceId('')}
        href={`${MIA_LOGIN_SOCIAL}?provider=google-oauth2&redirect=${encodeURIComponent(
          redirect
        )}`}
        className={classes.socialButton}
        startIcon={<GoogleIcon />}>
        <Typography variant="subtitle1">Continue with Google</Typography>
      </Button>
      <Box my={3} />
      <Button
        variant="contained"
        fullWidth
        onClick={() => setWorkspaceId('')}
        href={`${MIA_LOGIN_SOCIAL}?provider=linkedin&redirect=${encodeURIComponent(
          redirect
        )}`}
        className={classes.socialButton}
        startIcon={<LinkedInIcon />}>
        <Typography variant="subtitle1">Continue with LinkedIn</Typography>
      </Button>
      {/* TODO(Jon) Account UI: Spotify hidden for now */}
      {/* <Box my={3} />
      <Button
        variant="contained"
        fullWidth
        href={`${MIA_LOGIN_SOCIAL}?provider=spotify&redirect=${encodeURIComponent(
          redirect
        )}`}
        className={classes.socialButton}
        startIcon={<SpotifyIcon color="#c4c8cc" />}>
        <Typography variant="subtitle1">Continue with Spotify</Typography>
      </Button> */}
    </Box>
  );
};

export default SocialLogins;

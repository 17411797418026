import numeral from 'numeral';
import { USStatesMap } from '../models';
import { AggregateCategoryCodes } from '../models/categories';
import { DiversificationLabelIndexMap } from '../models/diversificationLabels';

const isNumber = (value) => {
  return typeof value === 'number' && isFinite(value);
};

export const fmtNumber = (num) => {
  if (num == null) {
    return '';
  }

  if (isNumber(num) && Math.abs(num) < 1000) {
    if (Number.isInteger(num)) {
      return num.toString();
    } else {
      return num.toFixed(0);
    }
  } else {
    return numeral(num).format('0.0a');
  }
};

export const fmtPercent = (num) => {
  if (num == null) {
    return '';
    // Less than 1% -> display two decimals (e.g. 0.42%)
  } else if (Math.abs(num) < 0.01) {
    return numeral(num).format('0.00%');
    // Less than 10% -> display one decimal (e.g. 2.2%)
  } else if (Math.abs(num) < 0.1) {
    return numeral(num).format('0.0%');
  } else {
    return numeral(num).format('0%');
  }
};

export const capitalize = (str) => {
  if (str) {
    return str[0].toUpperCase() + str.substring(1);
  } else {
    return str;
  }
};

export const fmtTikTokHashtagURL = (hashtagName) => {
  return 'https://tiktok.com/tag/' + hashtagName;
};

export const fmtTikTokCreatorURL = (uniqueId) => {
  return 'https://tiktok.com/@' + uniqueId;
};

export const fmtTikTokSoundURL = (title, id) => {
  // examples:
  //
  //   positions -> positions
  //   Put Your Records On -> Put-Your-Records-On
  //   Where is the Love? -> Where-Is-The-Love
  const musicTitleCleaned = (title || '')
    .split(' ')
    .join('-')
    .replaceAll('?', '');
  const fragment = musicTitleCleaned + '-' + id;
  return 'https://tiktok.com/music/' + fragment;
};

export const fmtTikTokVideoURL = (profileUniqueId, videoId) => {
  return 'https://www.tiktok.com/@' + profileUniqueId + '/video/' + videoId;
};

export const fmtSpotifySongURLFromId = (songId) => {
  return 'https://open.spotify.com/track/' + songId;
};

export const fmtSpotifyArtistURLFromId = (artistId) => {
  return 'https://open.spotify.com/artist/' + artistId;
};

export const fmtSpotifyAlbumURLFromId = (albumId) => {
  return 'https://open.spotify.com/album/' + albumId;
};

export const fmtTrendpopHashtagDetailPath = (title) => {
  return `/detail?tag_title=${encodeURIComponent(title)}`;
};

export const fmtTrendpopProfileDetailPath = (uniqueId) => {
  return `/detail/creators/${encodeURIComponent(uniqueId)}`;
};

/**
 * Create an on-platform link to the video dashboard for a given video.
 * @param {string} uniqueId
 * @param {string} videoId
 */
export const fmtTrendpopVideoDetailPath = (uniqueId, videoId) => {
  return `/detail/creators/${uniqueId}/videos/${videoId}`;
};

export const fmtTrendpopSoundDetailPath = (id) => {
  return `/detail/sounds/${id}`;
};

export const fmtTrendpopISRCDetailPath = (isrc) => {
  return `/detail/isrc/${isrc}`;
};

export const fmtTrendpopSongDetailPath = (id) => {
  return `/detail/songs/${id}`;
};

export const fmtTrendpopArtistDetailPath = (id) => {
  return `/detail/artists/${id}`;
};

export const fmtTrendpopAlbumDetailPath = (id) => {
  return `/detail/albums/${id}`;
};

export const fmtFacebookURL = (username) => {
  return `https://facebook.com/${username}`;
};

export const fmtInstagramURL = (username) => {
  return `https://instagram.com/${username}`;
};

export const fmtYoutubeURL = (username) => {
  return `https://youtube.com/${username}`;
};

export const fmtTwitterURL = (username) => {
  return `https://twitter.com/${username}`;
};

export const fmtTwitchURL = (username) => {
  return `https://twitch.com/${username}`;
};

export const fmtSnapchatURL = (username) => {
  return `https://snapchat.com/add/${username}`;
};

export const fmtTrendpopProfileIconUrl = (tiktokId, size) => {
  const finalSize = size || 'small';
  return `https://trendpop-entity-assets.s3-us-west-2.amazonaws.com/icons/profiles/${finalSize}/${tiktokId}.jpeg`;
};

export const fmtTrendpopHashtagIconUrl = (tiktokId, size) => {
  const finalSize = size || 'small';
  return `https://trendpop-entity-assets.s3-us-west-2.amazonaws.com/icons/tags/${finalSize}/${tiktokId}.jpeg`;
};

export const fmtTrendpopSoundIconUrl = (tiktokId, size) => {
  const finalSize = size || 'small';
  return `https://trendpop-entity-assets.s3-us-west-2.amazonaws.com/icons/sounds/${finalSize}/${tiktokId}.jpeg`;
};

export const fmtTrendpopImagePostUrl = (tiktokId, count) => {
  return `https://trendpop-entity-assets.s3-us-west-2.amazonaws.com/imagePost/${tiktokId}-${count}.png`;
};

export const fmtTrendpopVideoUrl = (tiktokId) => {
  return `https://trendpop-entity-assets.s3-us-west-2.amazonaws.com/video/${tiktokId}.mp4`;
};

export const fmtTrendpopCollectionUrl = (collectionId, editOnOpen = false) => {
  if (editOnOpen) {
    return `/collections/${collectionId}?edit`;
  } else {
    return `/collections/${collectionId}`;
  }
};

export const fmtTrendpopCollectionsUrl = () => {
  return `/collections`;
};

export const fmtTrendpopCampaignUrl = (campaignId, openIntentOnNew = false) => {
  if (openIntentOnNew) {
    return `/campaigns/${campaignId}?new`;
  }
  return `/campaigns/${campaignId}`;
};

export const fmtTrendpopCampaignsUrl = () => {
  return `/campaigns`;
};

export const truncateStr = (str, truncateLen) => {
  if (!str) {
    return str;
  }

  if (str.length <= truncateLen) {
    return str;
  }

  return str.slice(0, truncateLen) + '...';
};

export const formatHttpErr = (error) => {
  if (!error) {
    return 'Unknown internal error';
  }

  const statusCode = error.status_code;
  const err =
    error.response ||
    error.error ||
    error.description ||
    'Unknown internal error';
  const errMsg = typeof err === 'string' ? err : JSON.stringify(err);

  return statusCode ? `${statusCode}: ${errMsg}` : errMsg;
};

// Best-effort attempt to transform the language codes / countries / states
// into the full names with Intl.DisplayNames.
//
// NOTE: Intl.DisplayNames is not supported in some older versions of
// Safari. If it isn't supported by the browser, display raw language
// codes as labels.

const langNames = Intl.DisplayNames
  ? new Intl.DisplayNames(['en'], {
      type: 'language'
    })
  : null;
export const langCodeToLanguageName = (code) => {
  try {
    return langNames.of(code);
  } catch {
    return code;
  }
};

const regionNames = Intl.DisplayNames
  ? new Intl.DisplayNames(['en'], {
      type: 'region'
    })
  : null;
export const regionCodeToRegionName = (code) => {
  try {
    return regionNames.of(code);
  } catch {
    return code;
  }
};

export const stateCodeToStateName = (code) => {
  return USStatesMap[code.toUpperCase()];
};

export const isStateCode = (code) => {
  return Object.prototype.hasOwnProperty.call(USStatesMap, code.toUpperCase());
};

export const isCountryCode = (code) => {
  return regionCodeToRegionName(code) !== code;
};

export const aggregateCategoryCodeToName = (code) => {
  return AggregateCategoryCodes.get(code);
};

export const diversificationLabelIndexToName = (idx) => {
  return DiversificationLabelIndexMap.get(idx);
};

export const A = 'A';

export const fmtPercentageWithTwoDecimals = (num) => {
  if (typeof num === 'number' && !isNaN(num)) {
    if (Number.isInteger(num)) {
      return num + '%'; // For integers, do not include decimal places
    } else {
      return num.toFixed(2) + '%'; // For non-integers, include two decimal places
    }
  }
  return ''; // Return empty string for non-numeric or null values
};

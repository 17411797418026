import { requester } from './index';
import { axiosErrorHandler, abbaResponseHandler } from './util';
import { Workspace, WorkspaceUser, PlanName } from '../models/user';
import { useQuery } from 'react-query';
import { APIRequestError } from '../models/http';

export interface GetWorkspaceResponse {
  workspace: Workspace & { seat_count: bigint; plan_name: PlanName };
  users: Array<WorkspaceUser>;
}

export const useWorkspace = (workspaceId: string | null) => {
  return useQuery<GetWorkspaceResponse, APIRequestError>(
    [`tp-workspaces`, workspaceId],
    async () => {
      const url = `${process.env.REACT_APP_MIA_URL}/v1/workspace`;
      return await requester
        .get<GetWorkspaceResponse>(url)
        .then(abbaResponseHandler, axiosErrorHandler);
    },
    {
      retry: 1,
      enabled: !!workspaceId
    }
  );
};

import axios from 'axios';
import { abbaResponseHandler, structuredErrorHandler } from './util';
import { requester } from './index';

const CancelToken = axios.CancelToken;

export const multiDetailV2 = (profileIds, soundIds, tagTitles) => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v2/detail`;

  const body = {
    profile_ids: profileIds || [],
    sound_ids: soundIds || [],
    tag_titles: tagTitles || []
  };

  const cancelToken = CancelToken.source();
  return [
    requester
      .post(url, body, {
        cancelToken: cancelToken.token
      })
      .then(abbaResponseHandler)
      .catch(structuredErrorHandler),
    cancelToken
  ];
};

export const searchAll = (query, entityTypes, numResults = 5) => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v1/search`;

  const cancelToken = CancelToken.source();
  return [
    requester
      .post(
        url,
        {
          query: query,
          entity_types: entityTypes,
          pagination: {
            size: numResults
          }
        },
        {
          cancelToken: cancelToken.token
        }
      )
      .then(abbaResponseHandler)
      .catch(structuredErrorHandler),
    cancelToken
  ];
};

export const demographicsForProfileUniqueIds = (profileUniqueIds) => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v1/metrics/demographics`;

  const cancelToken = CancelToken.source();
  return [
    requester
      .post(
        url,
        {
          unique_ids: profileUniqueIds
        },
        {
          cancelToken: cancelToken.token
        }
      )
      .then(abbaResponseHandler)
      .catch(structuredErrorHandler),
    cancelToken
  ];
};

export const getSearchMetadata = () => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v1/search/metadata`;

  const cancelToken = CancelToken.source();
  return [
    requester
      .get(url, {
        cancelToken: cancelToken.token
      })
      .then(abbaResponseHandler)
      .catch(structuredErrorHandler),
    cancelToken
  ];
};

import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  IconButton,
  makeStyles,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Link,
  CircularProgress,
  Avatar,
  FormHelperText
} from '@material-ui/core';
import {
  TRENDPOP_PURPLE_3,
  TRENDPOP_WHITE,
  TRENDPOP_PURPLE_4,
  TRENDPOP_GREY_2,
  TRENDPOP_BLACK_2
} from '../../../constants/colors';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import {
  AUTH_CALLBACK_PATH,
  AUTH_WORKSPACE_SELECT,
  CALENDLY_UPSELL_LINK,
  MIA_LOGOUT,
  MIA_USER,
  MIA_WORKSPACES_LIST,
  MIA_WORKSPACE_SELF_SERVE_TRIAL,
  MIA_WORKSPACE_SELLER_INVITE,
  SUPPORT_EMAIL
} from '../../../constants/links';
import SelectPlanModalButton from './components/SelectPlanModalButton';
import { PlanName, planNameToLabel } from '../../../models/user';
import {
  MIN_BUSINESS_SEATS,
  MIN_STARTER_SEATS,
  MIN_TEAM_SEATS,
  MONTHLY_BUSINESS_PRICE,
  MONTHLY_DEFAULT_STARTER_PRICE,
  MONTHLY_STARTER_PRICE,
  MONTHLY_TEAM_PRICE,
  YEARLY_DEFAULT_BUSINESS_PRICE,
  YEARLY_DEFAULT_STARTER_PRICE,
  YEARLY_DEFAULT_TEAM_PRICE
} from '../../../constants/plan';
import PlanIcon from '../../../components/Icons/PlanIcon';
import { PlanPeriod, PlanState, upgradePlanColor } from '../../../models/plan';
import { PopupModal } from 'react-calendly';
import { useUser } from 'context/hooks/user';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { fetchPostRedirect } from '../util';
import { useLoginLocalState } from '../useLoginLocalState';
import {
  BusinessFeatureCardDescription,
  EnterpriseFeatureCardDescription,
  StarterFeatureCardDescription,
  TeamFeatureCardDescription
} from 'components/Plans/FeatureCardDescription';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh'
  },
  linkButton: {
    fontWeight: 600,
    textDecoration: 'underline'
  },
  submitButton: {
    height: 50,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  },
  bookDemoButton: {
    height: 50,
    borderRadius: 30,
    color: TRENDPOP_BLACK_2,
    backgroundColor: TRENDPOP_WHITE,
    border: `1px solid ${TRENDPOP_PURPLE_3}`,
    '&:hover': {
      backgroundColor: TRENDPOP_WHITE
    }
  },
  sideBox: {
    maxWidth: '100%',
    padding: theme.spacing(10)
  },
  logo: {
    position: 'relative',
    bottom: 15
  },
  imageContainer: {
    backgroundColor: TRENDPOP_GREY_2,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 20%',
    backgroundSize: '100%',
    objectFit: 'cover'
  },
  formContainer: {
    padding: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    maxWidth: '500px',
    width: '100%'
  },
  formCheckbox: {
    margin: theme.spacing(3)
  },
  avatar: {
    width: 120,
    height: 120
  }
}));

interface ImageDisplayProps {
  imageUrl: string;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({ imageUrl }) => {
  const classes = useStyles();
  return (
    <Avatar className={classes.avatar} src={imageUrl} alt="profile-picture" />
  );
};

const SignUpContinue = () => {
  const classes = useStyles();

  const { user, isLoading, error: userDataError } = useUser();

  const {
    productSlug: paramProductSlug,
    period: paramPeriod,
    sellerInviteId: paramSellerInviteId,
    trialPeriod: paramTrialPeriod,
    setSellerInviteId: setParamSellerInviteId
  } = useLoginLocalState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (e: any) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      if (
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg'
      ) {
        setSelectedFile(file);
        const url = URL.createObjectURL(file);
        setImageUrl(url);
      } else {
        setError('Only image files are allowed');
      }
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [workspaceName, setWorkspaceName] = useState('');
  const [planState, setPlanState] = useState<PlanState>({
    period: PlanPeriod.Monthly, // monthly, yearly
    plan: PlanName.Team, // default Team plan
    count: MIN_TEAM_SEATS,
    rate: MONTHLY_TEAM_PRICE * MIN_TEAM_SEATS,
    isPlanSelected: false
  });

  useEffect(() => {
    if (paramProductSlug && !paramSellerInviteId) {
      const selectedPlan = paramProductSlug as PlanName;
      const seatCount =
        selectedPlan === PlanName.Starter
          ? MIN_STARTER_SEATS
          : selectedPlan === PlanName.Team
          ? MIN_TEAM_SEATS
          : selectedPlan === PlanName.Business
          ? MIN_BUSINESS_SEATS
          : 0;

      const selectedPrice =
        selectedPlan === PlanName.Starter
          ? paramPeriod && paramPeriod === PlanPeriod.Monthly
            ? MONTHLY_DEFAULT_STARTER_PRICE
            : YEARLY_DEFAULT_STARTER_PRICE
          : selectedPlan === PlanName.Team
          ? paramPeriod && paramPeriod === PlanPeriod.Monthly
            ? MONTHLY_TEAM_PRICE
            : YEARLY_DEFAULT_TEAM_PRICE
          : selectedPlan === PlanName.Business
          ? paramPeriod && paramPeriod === PlanPeriod.Monthly
            ? MONTHLY_BUSINESS_PRICE
            : YEARLY_DEFAULT_BUSINESS_PRICE
          : 0;

      const rate =
        paramPeriod === PlanPeriod.Monthly
          ? selectedPlan === PlanName.Starter && seatCount > MIN_STARTER_SEATS
            ? selectedPrice +
              (seatCount - MIN_STARTER_SEATS) * MONTHLY_STARTER_PRICE
            : selectedPrice * seatCount
          : selectedPrice;

      setPlanState({
        period: (paramPeriod as PlanPeriod) || PlanPeriod.Monthly, // monthly, yearly
        plan: selectedPlan, // starter, team, business, enterprise
        count: seatCount, // number
        rate: rate, // rate
        isPlanSelected: true
      });
    }

    if (user.getFirstName() !== undefined) {
      setFirstName(user.getFirstName()!);
    }

    if (user.getLastName() !== undefined) {
      setLastName(user.getLastName()!);
    }
  }, [setPlanState, user, paramSellerInviteId, paramProductSlug, paramPeriod]);

  // If seller invite id is detected
  useEffect(() => {
    if (paramSellerInviteId) {
      setPlanState((prevPlanState: PlanState) => ({
        ...prevPlanState,
        period: (paramPeriod as PlanPeriod) || PlanPeriod.Monthly,
        plan: paramProductSlug as PlanName, // starter, team, business, enterprise
        count: 0, // manual override to be 0 on free trial
        rate: 0, // manual override to be 0 on free trial
        isPlanSelected: true
      }));
    }

    // TODO(Jon) Seller invite flow: Find a better solution for clearing id after accepting invite
    // Clears seller invite id when unmounting to prevent loop when accepting invite
    return () => {
      setParamSellerInviteId('');
    };
  }, [
    setPlanState,
    setParamSellerInviteId,
    paramProductSlug,
    paramSellerInviteId,
    paramPeriod
  ]);

  const [trendpopUseState, setTrendpopUseState] = useState({
    talent_discovery: false,
    trend_discovery: false,
    campaign_management: false,
    competitor_analysis: false,
    data_integration: false,
    other: false
  });

  const [hearAboutUsState, setHearAboutUsState] = useState({
    google_search: false,
    linked_in: false,
    tiktok: false,
    article: false,
    email: false,
    word_of_mouth: false
  });

  const handlePatchUser = async () => {
    try {
      const formData = new FormData();

      if (selectedFile) {
        formData.append('picture', selectedFile);
      }

      formData.append('first_name', firstName);
      formData.append('last_name', lastName);

      await axios
        .patch(MIA_USER, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true
        })
        .then(() => {});
    } catch (error: any) {
      console.log('error: ', error);
      console.log('error message: ', error.message);
      setError(error.message);
    }
  };

  const handleCreateWorkspaceProfile = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const promiseOne = handlePatchUser();
    const checkedTrendpopUse = Object.keys(trendpopUseState).filter(
      (key: any) => trendpopUseState[key as keyof typeof trendpopUseState]
    );
    const checkedHearAboutus = Object.keys(hearAboutUsState).filter(
      (key: any) => hearAboutUsState[key as keyof typeof hearAboutUsState]
    );

    const promiseTwo = fetchPostRedirect(
      MIA_WORKSPACES_LIST,
      {
        name: workspaceName,
        plan: paramSellerInviteId
          ? (paramProductSlug as PlanName)
          : planState.plan,
        period: planState.period,
        seat: planState.count,
        used_for: checkedTrendpopUse,
        heard_from: checkedHearAboutus,
        redirect: `${window.location.origin}${AUTH_CALLBACK_PATH}`,
        cancel_redirect: `${window.location.origin}${AUTH_WORKSPACE_SELECT}`
      },
      () => {
        setError('Error in completing signing up, please try again.');
      },
      `${window.location.origin}${AUTH_CALLBACK_PATH}`,
      true
    );

    Promise.all([promiseOne, promiseTwo]).then(
      () => {
        setLoading(false);
      },
      (error: any) => {
        console.log(error);
        setError(error.message);
        setLoading(false);
      }
    );
  };

  const handleCreateWorkspaceProfileSellerInvite = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const promiseOne = handlePatchUser();
    const checkedTrendpopUse = Object.keys(trendpopUseState).filter(
      (key: any) => trendpopUseState[key as keyof typeof trendpopUseState]
    );
    const checkedHearAboutus = Object.keys(hearAboutUsState).filter(
      (key: any) => hearAboutUsState[key as keyof typeof hearAboutUsState]
    );

    const promiseTwo = fetchPostRedirect(
      MIA_WORKSPACE_SELLER_INVITE,
      {
        name: workspaceName,
        used_for: checkedTrendpopUse,
        heard_from: checkedHearAboutus,
        invite_id: paramSellerInviteId
      },
      () => {
        setError('Error in completing signing up, please try again.');
      },
      `${window.location.origin}${AUTH_CALLBACK_PATH}`,
      false,
      true
    );

    Promise.all([promiseOne, promiseTwo]).then(
      () => {
        setParamSellerInviteId('');
        setLoading(false);
      },
      (error: any) => {
        console.log(error);
        setLoading(false);
        setError(error.message);
      }
    );
  };

  const handleCreateWorkspaceProfileSelfServeFreeTrial = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const promiseOne = handlePatchUser();
    const checkedTrendpopUse = Object.keys(trendpopUseState).filter(
      (key: any) => trendpopUseState[key as keyof typeof trendpopUseState]
    );
    const checkedHearAboutus = Object.keys(hearAboutUsState).filter(
      (key: any) => hearAboutUsState[key as keyof typeof hearAboutUsState]
    );

    const promiseTwo = fetchPostRedirect(
      MIA_WORKSPACE_SELF_SERVE_TRIAL,
      {
        name: workspaceName,
        plan: paramSellerInviteId
          ? (paramProductSlug as PlanName)
          : planState.plan,
        period: planState.period,
        seat: planState.count,
        used_for: checkedTrendpopUse,
        heard_from: checkedHearAboutus,
        redirect: `${window.location.origin}${AUTH_CALLBACK_PATH}`,
        cancel_redirect: `${window.location.origin}${AUTH_WORKSPACE_SELECT}`
      },
      () => {
        setError('Error in completing signing up, please try again.');
      },
      `${window.location.origin}${AUTH_CALLBACK_PATH}`,
      false,
      true
    );

    Promise.all([promiseOne, promiseTwo]).then(
      () => {
        setLoading(false);
      },
      (error: any) => {
        console.log(error);
        setError(error.message);
        setLoading(false);
      }
    );
  };

  const handleChangeTrendpopUse = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTrendpopUseState({
      ...trendpopUseState,
      [event.target.name]: event.target.checked
    });
  };

  const handleChangeHearAboutUs = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHearAboutUsState({
      ...hearAboutUsState,
      [event.target.name]: event.target.checked
    });
  };

  const handleChangeFirstName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
    if (event.target.value.length > 0) {
      setWorkspaceName(`${event.target.value}'s Workspace`);
    }
  };

  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  if (isLoading) {
    return (
      <Grid container alignItems="center" justify="center">
        <CircularProgress size="40px" color="secondary" />
      </Grid>
    );
  }

  if (user.getUserId() === undefined || userDataError) {
    return <Redirect to="/auth/login" />;
  }

  if (user.getIsNameVerified()) {
    return <Redirect to="/auth/callback" />;
  }

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        xs={12}
        md={3}
        direction="column"
        justify="space-between"
        className={classes.imageContainer}>
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          alignItems="center"
          className={classes.sideBox}>
          <Box my={10} />
          <Box width="100%">
            {planState.isPlanSelected ? (
              <Box>
                {!paramSellerInviteId ? (
                  <Typography variant="h3" style={{ fontWeight: 600 }}>
                    Plan Summary
                  </Typography>
                ) : (
                  <Typography
                    variant="h3"
                    style={{ fontWeight: 600, color: TRENDPOP_PURPLE_3 }}>
                    You are
                    <br />
                    invited to
                  </Typography>
                )}
              </Box>
            ) : (
              <Box>
                <Typography
                  variant="h3"
                  style={{ fontWeight: 600, color: TRENDPOP_PURPLE_3 }}>
                  You haven't
                  <br />
                  selected a
                  <br />
                  plan yet.
                </Typography>
              </Box>
            )}
            {!paramSellerInviteId && (
              <SelectPlanModalButton
                planState={planState}
                setPlanState={setPlanState}
              />
            )}
            <Box my={10} />
            <Box marginBottom={2} display="flex" alignItems="center">
              <PlanIcon
                color={
                  upgradePlanColor[
                    planState.plan as keyof typeof upgradePlanColor
                  ]
                }
              />
              <Typography variant="h5" style={{ paddingLeft: 5 }}>
                {planNameToLabel(planState.plan)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="h2">
                {planState.rate === 0
                  ? 'Custom'
                  : planState.period === PlanPeriod.Monthly
                  ? `$${planState.rate}`
                  : `$${Math.round((planState.rate / 12) * 100) / 100}`}
              </Typography>
              <Typography variant="h4" display="inline" color="textSecondary">
                / month
              </Typography>
            </Box>
            <Typography variant="body1" color="textSecondary">
              {planState.rate === 0
                ? `${paramTrialPeriod ? paramTrialPeriod : '7'} days free trial`
                : planState.period === PlanPeriod.Monthly
                ? ' Billed Monthly.'
                : `Billed at $${planState.rate} annually.`}
            </Typography>
            <Box my={10} />
            <Box>
              {planState.plan === PlanName.Starter ? (
                <StarterFeatureCardDescription />
              ) : planState.plan === PlanName.Team ? (
                <TeamFeatureCardDescription />
              ) : planState.plan === PlanName.Business ? (
                <BusinessFeatureCardDescription />
              ) : (
                <EnterpriseFeatureCardDescription />
              )}
            </Box>
            <Box my={10} />
            <Box display="flex" flexDirection="column">
              <Typography variant="body1">
                {user.getEmail()}{' '}
                <Link className={classes.linkButton} href={MIA_LOGOUT}>
                  Logout
                </Link>
              </Typography>
              <Box my={1} />
              <Typography variant="body1">
                Got questions? Email us at{' '}
                <Link
                  className={classes.linkButton}
                  href={`mailto:${SUPPORT_EMAIL}`}>
                  {SUPPORT_EMAIL}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img
            height="40px"
            width="200px"
            className={classes.logo}
            alt="logo-dark"
            src="/static/img/logo-dark.png"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={9} className={classes.formContainer}>
        <Box className={classes.form}>
          <Box display="flex" flexDirection="column" className={classes.form}>
            <Box display="flex" justifyContent="center" flexDirection="column">
              <Box display="flex" alignItems="center" flexDirection="column">
                <Box my={1} />
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <input
                        type="file"
                        accept=".png,.jpeg,.jpg"
                        style={{ display: 'none' }}
                        id="image-upload"
                        onChange={handleFileChange}
                      />
                      <label htmlFor="image-upload">
                        <IconButton
                          color="primary"
                          component="span"
                          style={{
                            width: 120,
                            height: 120,
                            backgroundColor: imageUrl
                              ? TRENDPOP_WHITE
                              : TRENDPOP_PURPLE_4
                          }}>
                          {imageUrl ? (
                            <ImageDisplay imageUrl={imageUrl} />
                          ) : (
                            <AddPhotoAlternateOutlinedIcon
                              color="primary"
                              style={{
                                fontSize: 50
                              }}
                            />
                          )}
                        </IconButton>
                      </label>
                    </Grid>
                  </Grid>
                </Box>
                <Box my={1} />
                <input
                  type="file"
                  accept=".png,.jpeg,.jpg"
                  style={{ display: 'none' }}
                  id="image-upload"
                  onChange={handleFileChange}
                />
                <label htmlFor="image-upload">
                  <Typography
                    variant="body1"
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    Add Photo
                  </Typography>
                </label>
              </Box>
              <Box my={5} />
              <Typography variant="h3" style={{ fontWeight: 600 }}>
                Tell us your name
              </Typography>
              <Box my={2} />
              <Box display="flex">
                <TextField
                  fullWidth
                  variant="outlined"
                  value={firstName}
                  onChange={handleChangeFirstName}
                  placeholder="First name*"
                />
                <Box mx={1} />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={lastName}
                  onChange={handleChangeLastName}
                  placeholder="Last name*"
                />
              </Box>
              <Box my={3} />
              <Typography variant="h3" style={{ fontWeight: 600 }}>
                Tell us about your Workspace
              </Typography>
              <Box my={2} />
              <TextField
                fullWidth
                variant="outlined"
                value={workspaceName}
                onChange={(e) => setWorkspaceName(e.target.value)}
                placeholder="Workspace Name*"
              />
              <Box my={3} />
              <Box display="flex" component="span">
                <Typography variant="h5" style={{ fontWeight: 600 }}>
                  What are you using Trendpop for?
                </Typography>
                <Box mx={1} />
                <Typography variant="subtitle1">
                  (select all that apply)
                </Typography>
              </Box>
              <FormControl
                component="fieldset"
                className={classes.formCheckbox}>
                <FormGroup>
                  <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={trendpopUseState.talent_discovery}
                            onChange={handleChangeTrendpopUse}
                            name="talent_discovery"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary">
                            Talent Discovery
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={trendpopUseState.trend_discovery}
                            onChange={handleChangeTrendpopUse}
                            name="trend_discovery"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary">
                            Trend Discovery
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={trendpopUseState.campaign_management}
                            onChange={handleChangeTrendpopUse}
                            name="campaign_management"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary">
                            Campaign Management
                          </Typography>
                        }
                      />
                    </Box>
                    <Box mx={5} />
                    <Box display="flex" flexDirection="column">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={trendpopUseState.competitor_analysis}
                            onChange={handleChangeTrendpopUse}
                            name="competitor_analysis"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary">
                            Competitor Analysis
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={trendpopUseState.data_integration}
                            onChange={handleChangeTrendpopUse}
                            name="data_integration"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary">
                            Data Integration
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={trendpopUseState.other}
                            onChange={handleChangeTrendpopUse}
                            name="other"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary">
                            Other
                          </Typography>
                        }
                      />
                    </Box>
                  </Box>
                </FormGroup>
              </FormControl>
              <Typography variant="h3" style={{ fontWeight: 600 }}>
                How did you hear about us?
              </Typography>
              <FormControl
                component="fieldset"
                className={classes.formCheckbox}>
                <FormGroup>
                  <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={hearAboutUsState.google_search}
                            onChange={handleChangeHearAboutUs}
                            name="google_search"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary">
                            Google Search
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={hearAboutUsState.linked_in}
                            onChange={handleChangeHearAboutUs}
                            name="linked_in"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary">
                            LinkedIn
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={hearAboutUsState.tiktok}
                            onChange={handleChangeHearAboutUs}
                            name="tiktok"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary">
                            TikTok
                          </Typography>
                        }
                      />
                    </Box>
                    <Box mx={12} />
                    <Box display="flex" flexDirection="column">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={hearAboutUsState.article}
                            onChange={handleChangeHearAboutUs}
                            name="article"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary">
                            Article
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={hearAboutUsState.email}
                            onChange={handleChangeHearAboutUs}
                            name="email"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary">
                            Email
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={hearAboutUsState.word_of_mouth}
                            onChange={handleChangeHearAboutUs}
                            name="word_of_mouth"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary">
                            Word of mouth
                          </Typography>
                        }
                      />
                    </Box>
                  </Box>
                </FormGroup>
              </FormControl>
            </Box>
            {error && <FormHelperText error>{error}</FormHelperText>}
            <Box my={2} />
            <PopupModal
              onModalClose={() => setShowModal(false)}
              open={showModal}
              rootElement={document.getElementById('root')!}
              url={`${CALENDLY_UPSELL_LINK!}?first_name=${firstName}&last_name=${lastName}&full_name=${firstName} ${lastName}&email=${encodeURIComponent(
                user.getEmail()
              )}`}
            />
            {paramSellerInviteId ? (
              <Button
                fullWidth
                disabled={loading || !firstName || !lastName || !workspaceName}
                onClick={handleCreateWorkspaceProfileSellerInvite}
                variant="contained"
                color="primary"
                className={classes.submitButton}>
                Get Started
              </Button>
            ) : (
              <Box display="flex" justifyContent="space-between">
                {/* ENG-1138: Self checkout is hidden for enterprise tier and above. */}
                {planState.plan === PlanName.Enterprise ? (
                  <Button
                    fullWidth
                    disabled={
                      loading || !firstName || !lastName || !workspaceName
                    }
                    onClick={() => setShowModal(true)}
                    variant="contained"
                    color="primary"
                    className={classes.bookDemoButton}>
                    Request A Free Trial
                  </Button>
                ) : user.getUserHasTrialWorkspace() ? (
                  <Button
                    fullWidth
                    disabled={
                      loading || !firstName || !lastName || !workspaceName
                    }
                    onClick={handleCreateWorkspaceProfile}
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}>
                    {loading ? <CircularProgress size="20px" /> : 'Checkout'}
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    disabled={loading || !workspaceName}
                    onClick={handleCreateWorkspaceProfileSelfServeFreeTrial}
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}>
                    {loading ? (
                      <CircularProgress size="20px" />
                    ) : (
                      'Start My Trial'
                    )}
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignUpContinue;

import React from 'react';
import _ from 'lodash';
import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { searchAll } from '../api/misc';
import useDebounce from '../utils/debounce';
import { tryParseURL, tryParseISRC } from '../utils/parse';
import { API_REQUEST_CANCELED } from '../constants';
import { hintForSearchResultType } from '../models/discovery';
import * as CollectionItem from '../models/collectionItem';
import SearchResultRow from '../components/SearchResultRow';
import { useSnackbar } from 'notistack';
import CopyURLButton from '../components/CopyURLButton';
import SavedSearchButton from '../components/SavedSearchButton';
import { useUser } from 'context/hooks/user';

const TrendpopSearch = (props) => {
  const { user } = useUser();

  const {
    onChange,
    resultToLabelFn,
    searchType = '',
    numResults = 5,
    includeKeyword = false,
    ignoredSearchTypes = ['tiktok_duet'],
    disabled = false,
    showSavedSearchEndAdornments = false,
    disableSpotifySearch = false,
    overrideHint = null,
    settings = null
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const debouncedInputValue = useDebounce(inputValue, 500);

  // Search should be enabled based on the features available to the user.
  var defaultSearchTypes = user.getDefaultSearchTypes();

  // Remove any explicitly ignored types, regardless of whether enabled for the
  // user.
  defaultSearchTypes = defaultSearchTypes.filter(
    (t) => !ignoredSearchTypes.includes(t)
  );

  React.useEffect(() => {
    setOptions([]);
    if (!debouncedInputValue) {
      return;
    }

    // First, search for a full Tik Tok and/or Spotify URL.
    const maybeOptions = tryParseURL(debouncedInputValue, defaultSearchTypes);
    if (!_.isEmpty(maybeOptions)) {
      setOptions(maybeOptions);
      return;
    }

    // Next, we try parsing for an ISRC and/or a spotify link if
    // music metadata parsing is enabled.
    if (defaultSearchTypes.includes('spotify_track')) {
      const maybeISRC = tryParseISRC(debouncedInputValue);
      if (maybeISRC) {
        setOptions([
          {
            entity_type: 'isrc',
            id: maybeISRC,
            primary: 'ISRC ' + maybeISRC
          }
        ]);
        return;
      }
    }

    setLoading(true);
    var searchTypes = searchType ? [searchType] : defaultSearchTypes;

    // Next, try to look for a "@" or "#" at the start of the query which
    // indicates a profile or tag, respectively. If this is the case,
    // autofill that as the first option.
    // Next, if it's just a number,
    const trimmedInputValue = debouncedInputValue.trim();
    if (
      !ignoredSearchTypes.includes('tiktok_profile') &&
      trimmedInputValue.startsWith('@') &&
      trimmedInputValue.length > 1
    ) {
      searchTypes = ['tiktok_profile'];
      const uniqueId = trimmedInputValue.slice(1);
      setOptions([
        {
          entity_type: 'tiktok_profile',
          primary: uniqueId,
          collection_item: CollectionItem.CollectionItem({
            itemType: 'profile_name',
            profileUniqueId: uniqueId
          })
        }
      ]);
    } else if (
      !ignoredSearchTypes.includes('tiktok_tag') &&
      trimmedInputValue.startsWith('#') &&
      trimmedInputValue.length > 1
    ) {
      searchTypes = ['tiktok_tag'];
      const title = trimmedInputValue.slice(1);
      setOptions([
        {
          entity_type: 'tiktok_tag',
          primary: title,
          collection_item: CollectionItem.CollectionItem({
            itemType: 'tag',
            tagTitle: title
          })
        }
      ]);
    } else if (includeKeyword) {
      setOptions([
        {
          entity_type: 'keyword',
          primary: debouncedInputValue
        }
      ]);
    }

    // Spotify temporarily removed from import Campaign remove this once ready
    if (disableSpotifySearch && debouncedInputValue.includes('spotify.com')) {
      setTimeout(() => {
        setLoading(false);
      }, 300);
      return <></>;
    }

    const [promise, cancelToken] = searchAll(
      debouncedInputValue,
      searchTypes,
      numResults
    );
    promise
      .then((response) => {
        // Transform collection item into a structured model if the endpoint returned one.
        return (response || []).map((o) => {
          if (o.collection_item) {
            return {
              ...o,
              collection_item: CollectionItem.fromDuaLipa(o.collection_item)
            };
          } else {
            return o;
          }
        });
      })
      .then((response) => {
        // Since the logic above sometimes might add in options based on the
        // query, we need to dedupe options. We prefer options that are
        // returned from the backend to the ones we've added in logic above.
        const dedupeOptions = (options) => {
          return options.filter(
            (v, i, arr) =>
              arr.findIndex(
                (x) =>
                  x.primary === v.primary && x.entity_type === v.entity_type
              ) === i
          );
        };

        setLoading(false);
        setOptions((options) => dedupeOptions([...response, ...options]));
      })
      .catch((error) => {
        if (error === API_REQUEST_CANCELED) {
          return;
        }

        enqueueSnackbar(`Search failed: ${JSON.stringify(error)}`, {
          variant: 'error'
        });
        console.log(error);
        setLoading(false);
      });

    return () => {
      cancelToken.cancel();
    };

    // NOTE: Ignore "defaultSearchTypes" for this effect.
    // eslint-disable-next-line
  }, [
    debouncedInputValue,
    searchType,
    numResults,
    includeKeyword,
    enqueueSnackbar
  ]);

  return (
    <Autocomplete
      fullWidth
      disableClearable
      disabled={disabled}
      value={null}
      options={options}
      getOptionLabel={() => ''}
      filterOptions={(options) => options}
      noOptionsText={overrideHint || hintForSearchResultType(searchType)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={onChange}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      loading={loading}
      loadingText={<CircularProgress size={18} color="secondary" />}
      renderOption={(result) => (
        <SearchResultRow result={result} resultToLabelFn={resultToLabelFn} />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            endAdornment: showSavedSearchEndAdornments &&
              user.featureSavedQueriesEnabled() && (
                <>
                  {/* Video type saved search query */}
                  <SavedSearchButton settings={settings} type="video" />
                  <CopyURLButton />
                  {params.InputProps.endAdornment}
                </>
              )
          }}
        />
      )}
    />
  );
};

export default TrendpopSearch;

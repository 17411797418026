import React from 'react';

interface LogoProps {
  style?: React.CSSProperties;
}

export const HorizontalLogo = (props: LogoProps) => {
  const { style = {} } = props;
  return <img alt="Trendpop" src="/static/img/logo-light.png" style={style} />;
};

export const SquareLogo = (props: LogoProps) => {
  const { style = {} } = props;
  return <img alt="Trendpop" src="/static/img/logo-square.png" style={style} />;
};

export const DashboardSettingsLogo = (props: LogoProps) => {
  const { style = {} } = props;

  return (
    <img
      alt="Trendpop"
      src="/static/img/logo-dashboard-sidebar.png"
      style={style}
    />
  );
};

export const UpgradePlanLogo = (props: LogoProps) => {
  const { style = {} } = props;

  return (
    <img
      height="40px"
      width="200px"
      alt="Trendpop"
      src="/static/img/logo-dark.png"
      style={style}
    />
  );
};

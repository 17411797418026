import React from 'react';

export default function Separator() {
  return (
    <hr
      style={{
        height: 1,
        border: 'none',
        backgroundColor: '#666'
      }}
    />
  );
}

import { useState } from 'react';
import { PopupModal } from 'react-calendly';
import { Box, BoxProps, Button, Link, ButtonProps } from '@material-ui/core';

interface CalendlyModalProps {
  url?: string;
  buttonLabel: string;
  style?: BoxProps['style'];
  isLinkButton?: boolean;
  buttonStyle?: ButtonProps['style'];
}

export const CalendlyModal = (props: CalendlyModalProps) => {
  const { buttonLabel, url, style, isLinkButton = false, buttonStyle } = props;
  const [showModal, setShowModal] = useState(false);

  if (!url) {
    return <></>;
  }

  if (isLinkButton) {
    return (
      <Box style={style}>
        <Link
          component="button"
          style={{ textDecoration: 'underline', fontWeight: 600 }}
          onClick={() => setShowModal(true)}>
          {buttonLabel}
        </Link>
        <PopupModal
          onModalClose={() => setShowModal(false)}
          open={showModal}
          rootElement={document.getElementById('root')!}
          url={url}
        />
      </Box>
    );
  }

  return (
    <Box style={style}>
      <Button
        variant={'contained'}
        color={'primary'}
        onClick={() => setShowModal(true)}
        style={buttonStyle}>
        {buttonLabel}
      </Button>
      <PopupModal
        onModalClose={() => setShowModal(false)}
        open={showModal}
        rootElement={document.getElementById('root')!}
        url={url}
      />
    </Box>
  );
};

import { ObjectModel } from 'objectmodel';

export enum DiscoveryType {
  video = 'video',
  creator = 'creator',
  hashtag = 'hashtag',
  sound = 'sound',
  song = 'song',
  artist = 'artist',
  album = 'album'
}

export enum DiscoveryTypeLabel {
  video = 'Videos',
  creator = 'Creators',
  hashtag = 'Hashtags',
  sound = 'Sounds',
  song = 'Songs',
  artist = 'Artists',
  album = 'Albums'
}

export interface SavedQueryListResponse {
  id: string;
  email: string;
  created_at: string;
  discovery_type: DiscoveryType;
  filters: ObjectModel | any;
  name: string;
  workspace_read: boolean;
  workspace_write: boolean;
  updated_at: string;
  description?: string;
}

export enum SavedQueryKeyLabel {
  audienceLanguage = 'Audience Language',
  audienceLocation = 'Audience Location',
  brand = 'Brand',
  categories = 'Categories',
  contactLinks = 'Contact Links',
  countries = 'Countries',
  creatorAgeGeneration = 'Creator Age',
  creatorCategories = 'Creator Categories',
  creatorGender = 'Creator Gender',
  creatorLanguage = 'Creator Language',
  creatorLocation = 'Creator Location',
  dateRange = 'Date Range',
  diversificationLabels = 'Content Categories', // diversication label is Content Categories
  endDate = 'End Date',
  startDate = 'Start Date',
  featured = 'Hashtag',
  genres = 'Genres',
  languages = 'Languages',
  maximumDuration = 'Max Duration',
  minimumDuration = 'Min Duration',
  maximumVideoDuration = 'Max Video Duration',
  minimumVideoDuration = 'Min Video Duration',
  maximumNumFollowers = 'Max Followers',
  minimumNumFollowers = 'Min Followers',
  maximumNumVideos = 'Max Videos',
  minimumNumVideos = 'Min Videos',
  maximumNumViews = 'Max Views',
  minimumNumViews = 'Min Views',
  minimumAvgViews = 'Min Avg Views',
  minimumEngagement = 'Min Engagement',
  orderBy = 'Ordered By',
  query = 'Search',
  queryType = 'Match Type',
  rawSearchQuery = 'Search Query (Raw)',
  releaseDateRange = 'Release Date Range',
  searchResults = 'Search Results',
  searchTerms = 'Search Terms',
  searchType = 'Search Type',
  songType = 'Song Type',
  timeRange = 'Time Range'
}

export enum ImagePathByDiscoveryType {
  video = '/static/img/trending-search/videos.png',
  creator = '/static/img/trending-search/creators.png',
  hashtag = '/static/img/trending-search/tags.png',
  sound = '/static/img/trending-search/sounds.jpg',
  song = '/static/img/trending-search/songs.png',
  artist = '/static/img/trending-search/artists.jpg',
  album = '/static/img/trending-search/albums.jpg'
}

import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  makeStyles,
  Button,
  Grid,
  Hidden,
  CircularProgress
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
  TRENDPOP_PURPLE_3,
  TRENDPOP_PURPLE_4
} from '../../../constants/colors';
import SocialLogins from '../sign-up/components/SocialLogins';
import { validateEmail } from '../../settings/WorkspaceSettingsUsers';
import { AUTH_CALLBACK_PATH, MIA_LOGIN } from '../../../constants/links';
import { fetchPostRedirect } from '../util';
import Banner from '../components/Banner';
import { useLoginLocalState } from '../useLoginLocalState';

const useStyles = makeStyles(() => ({
  link: {
    fontWeight: 600,
    color: TRENDPOP_PURPLE_3
  },
  continueButton: {
    height: 50,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  },
  imageContainer: {
    backgroundColor: TRENDPOP_PURPLE_4,
    background: `url('/static/img/line-vector.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 20%',
    backgroundSize: '100%',
    objectFit: 'cover'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    maxWidth: '500px',
    width: '100%'
  }
}));

const Login = () => {
  const classes = useStyles();
  const { email: paramEmail, setWorkspaceId } = useLoginLocalState();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState<string>(paramEmail ? paramEmail : '');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  const validEmail = email.length > 0 && validateEmail(email);

  useEffect(() => {
    setEmail(paramEmail);
  }, [paramEmail]);

  const handleLogin = useCallback(() => {
    if (!paramEmail) {
      setWorkspaceId('');
    }

    setLoading(true);
    const promise = fetchPostRedirect(
      MIA_LOGIN,
      {
        email: email,
        password: password
      },
      () => {
        setError('Error logging in, please try again.');
      },
      `${window.location.origin}${AUTH_CALLBACK_PATH}`
    );

    promise.then(
      () => {
        setLoading(false);
      },
      (error: any) => {
        console.log(error);
        setLoading(false);
        setError(error.message);
      }
    );
  }, [paramEmail, email, password, setWorkspaceId]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid container justify="center">
      <Hidden smDown>
        <Grid item sm={3} className={classes.imageContainer}>
          <Banner />
        </Grid>
      </Hidden>
      <Grid item xs={10} sm={9} className={classes.formContainer}>
        <Box className={classes.form}>
          <Box>
            <Typography variant="h3">Login</Typography>
            <Box my={2} />
            <Typography variant="h5">
              Don't have an account?{' '}
              <Link to="/auth/signup" className={classes.link}>
                Sign up
              </Link>
            </Typography>
          </Box>
          <Box my={10} />
          <Box>
            <form>
              <Typography variant="h5">Email Address</Typography>
              <Box my={2} />
              <TextField
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => [setEmail(e.target.value), setError('')]}
                placeholder="Email Address"
                error={Boolean(error)}
              />
              <Box my={5} />
              <Typography variant="h5">Password</Typography>
              <Box my={2} />
              <TextField
                fullWidth
                variant="outlined"
                value={password}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => [setPassword(e.target.value), setError('')]}
                placeholder="Password"
                error={Boolean(error)}
                helperText={error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePassword}
                        color="primary">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Box my={5} />
              <Typography variant="h5">
                <Link to="/auth/forgot-password" className={classes.link}>
                  Forgot password?
                </Link>
              </Typography>
              <Box my={5} />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleLogin()}
                fullWidth
                className={classes.continueButton}
                disabled={loading || !validEmail || password.length < 1}>
                {loading ? (
                  <CircularProgress size="20px" />
                ) : (
                  <Typography variant="subtitle1">Continue</Typography>
                )}
              </Button>
            </form>
            <Box my={3} />
            <SocialLogins />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;

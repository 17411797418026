import React from 'react';

export const LayoutContext = React.createContext();

export const LayoutProvider = ({ children }) => {
  const [state, setState] = React.useState({
    sidebarOpen: true
  });

  return (
    <LayoutContext.Provider value={[state, setState]}>
      {children}
    </LayoutContext.Provider>
  );
};

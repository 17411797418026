import React from 'react';
import { Box, Grid, Link, Typography, makeStyles } from '@material-ui/core';
import { TRENDPOP_GREEN } from '../../../constants/colors';
import { CloverSvg } from '../../../components/Icons/Clover';
import { User } from 'context/user';
import { MIA_LOGOUT } from 'constants/links';

const useStyles = makeStyles((theme) => ({
  linkButton: {
    fontWeight: 600,
    textDecoration: 'underline'
  },
  bulletPoint: {
    marginLeft: theme.spacing(2),
    wordBreak: 'break-word'
  },
  sideBox: {
    maxWidth: '100%',
    height: 'calc(100vh - 40px)', // Size of logo 40px
    padding: theme.spacing(10)
  },
  logo: {
    position: 'relative',
    bottom: 15
  }
}));

interface BannerProps {
  user?: User;
}

const Banner = (props: BannerProps) => {
  const classes = useStyles();
  const { user } = props;

  return (
    <Grid container direction="column" justify="space-between">
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        className={classes.sideBox}>
        <Typography variant="h3">
          Use Trendpop to spot trends and opportunities
        </Typography>
        <Box my={5} />
        <Box>
          <Box display="flex">
            <CloverSvg
              color={TRENDPOP_GREEN}
              size={20}
              viewBoxWidth={25}
              viewBoxHeight={25}
              style={{ minWidth: 24 }}
            />
            <Typography variant="h5" className={classes.bulletPoint}>
              Identify real-time trends
            </Typography>
          </Box>
          <Box my={5} />
          <Box display="flex">
            <CloverSvg
              color={TRENDPOP_GREEN}
              size={20}
              viewBoxWidth={25}
              viewBoxHeight={25}
              style={{ minWidth: 24 }}
            />
            <Typography variant="h5" className={classes.bulletPoint}>
              Leverage trends for your business
            </Typography>
          </Box>
          <Box my={5} />
          <Box display="flex">
            <CloverSvg
              color={TRENDPOP_GREEN}
              size={20}
              viewBoxWidth={25}
              viewBoxHeight={25}
              style={{ minWidth: 24 }}
            />
            <Typography variant="h5" className={classes.bulletPoint}>
              Grow your sales/following/audience/streams
            </Typography>
          </Box>
          {user && (
            <>
              <Box my={20} />
              <Box>
                <Typography variant="body1">
                  {user.getEmail()}{' '}
                  <Link className={classes.linkButton} href={MIA_LOGOUT}>
                    Logout
                  </Link>
                </Typography>
                <Box my={2} />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img
          height="40px"
          width="200px"
          className={classes.logo}
          alt="logo-dark"
          src="/static/img/logo-dark.png"
        />
      </Box>
    </Grid>
  );
};

export default Banner;

import { Virality } from '../../models';

export interface ArtistReportResponse {
  id: string;
  name: string;

  genres: string[];

  video_sample_size: number;

  creator_unique_id: string | undefined;

  videos_current: number | undefined;
  videos_offset1: number | undefined;
  videos_offset7: number | undefined;
  videos_offset30: number | undefined;

  total_views: number | undefined;
  total_likes: number | undefined;
  total_shares: number | undefined;
  total_comments: number | undefined;

  image_url: string;
  refreshed?: string;
}

export interface ReportSoundResponse {
  // Base fields.
  id: string;
  video_count: number | undefined;
  deleted: number | undefined;
  play_url: string | undefined;
  original: boolean;
  title: string;
  author_name: string;

  // Augmented fields.
  virality_badge_daily: Virality | undefined;
  virality_badge_weekly: Virality | undefined;
  virality_badge_monthly: Virality | undefined;
  total_views: number | undefined;
  total_likes: number | undefined;
  total_shares: number | undefined;
  total_comments: number | undefined;
  videos_current: number | undefined;
  videos_offset1: number | undefined;
  videos_offset7: number | undefined;
  videos_offset30: number | undefined;
}

export enum ReportType {
  Profile = 'profile', // creator
  ProfileName = 'profile_name', // creator
  Tag = 'tag',
  Sound = 'sound',
  Song = 'song',
  Album = 'album',
  Artist = 'artist',
  Video = 'item'
}

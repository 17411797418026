import React from 'react';

export interface PlanIconProps {
  color: string;
}

const PlanIcon = (props: PlanIconProps) => {
  const { color } = props;

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="10pt"
      height="12.5pt"
      viewBox="0 0 200 207"
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,207.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none">
        <path
          d="M5 2003 c21 -214 86 -385 202 -536 353 -458 1003 -526 1443 -151 201
171 316 401 345 687 l7 67 -300 0 -300 0 -10 -59 c-38 -217 -245 -369 -451
-333 -171 30 -302 161 -332 330 l-11 62 -300 0 -300 0 7 -67z"
        />
        <path
          d="M882 999 c-221 -26 -422 -125 -589 -290 -160 -159 -254 -354 -283
-589 -5 -46 -10 -90 -10 -97 0 -10 63 -13 299 -13 l299 0 10 60 c15 81 53 155
114 216 161 165 410 158 570 -15 59 -63 90 -128 102 -213 l7 -48 300 0 c236 0
299 3 299 13 0 7 -5 51 -10 97 -29 237 -122 426 -292 598 -160 161 -358 255
-598 282 -95 11 -115 11 -218 -1z"
        />
      </g>
    </svg>
  );
};

export default PlanIcon;

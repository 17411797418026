export interface Workspace {
  internal_id: string;
  account_owner: string;
  name: string;
  admin: boolean;
  pending_invite: boolean;
  product_slug: string;
  billing_valid_until: string;
  billing_status: BillingStatus;
  billing_self_serve_trial: boolean;
  img?: string;
}

export enum BillingStatus {
  Trialing = 'trialing',
  Active = 'active',
  PastDue = 'past_due',
  Unpaid = 'unpaid',
  Paused = 'paused',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired'
}

export const billingStatusToLabel = (billingStatus: BillingStatus) => {
  switch (billingStatus) {
    case BillingStatus.Trialing:
      return 'Trialing';
    case BillingStatus.Active:
      return 'Active';
    case BillingStatus.PastDue:
      return 'Past Due';
    case BillingStatus.Unpaid:
      return 'Unpaid';
    case BillingStatus.Paused:
      return 'Paused';
    case BillingStatus.Canceled:
      return 'Canceled';
    case BillingStatus.Incomplete:
      return 'Incomplete';
    case BillingStatus.IncompleteExpired:
      return 'Incomplete Expired';
    default:
      return 'Finish Setup';
  }
};

export type MiaWorkspacesQuery = {
  workspaces: Array<Workspace>;
};

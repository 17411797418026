import React from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Button,
  Grid,
  Hidden,
  Link,
  CircularProgress
} from '@material-ui/core';
import {
  TRENDPOP_PURPLE_3,
  TRENDPOP_PURPLE_4
} from '../../../constants/colors';
import Banner from '../components/Banner';
import { useUser } from 'context/hooks/user';
import DashboardUserDropdown from 'layouts/components/DashboardUserDropdown';

const useStyles = makeStyles(() => ({
  link: {
    fontWeight: 600,
    color: TRENDPOP_PURPLE_3
  },
  submitButton: {
    height: 50,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  },
  imageContainer: {
    backgroundColor: TRENDPOP_PURPLE_4,
    background: `url('/static/img/line-vector.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 20%',
    backgroundSize: '100%',
    objectFit: 'cover'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    maxWidth: '500px',
    width: '100%'
  }
}));

const Lockout = () => {
  const classes = useStyles();

  const { user, isLoading } = useUser();

  if (isLoading) {
    return (
      <Grid container alignItems="center" justify="center">
        <CircularProgress size="40px" color="secondary" />
      </Grid>
    );
  }

  const workspaceAdminEmail = user.getWorkspaceAdminEmail();

  return (
    <Grid container alignItems="center" justify="center">
      <Hidden smDown>
        <Grid item sm={3} className={classes.imageContainer}>
          <Banner />
        </Grid>
      </Hidden>
      <Grid item xs={10} sm={9} className={classes.formContainer}>
        <Box position="absolute" top={30} right={{ xs: 0, sm: 50 }}>
          <DashboardUserDropdown />
        </Box>
        <Box display="flex" flexDirection="column" className={classes.form}>
          <Typography variant="h3" style={{ fontWeight: 600 }}>
            You have been locked out
          </Typography>
          <Box my={2} />
          <Typography variant="h6">
            Please contact your admin at{' '}
            <Link href={`mailto:${workspaceAdminEmail}`}>
              {workspaceAdminEmail}
            </Link>{' '}
            to reactivate your account.
          </Typography>
          <Box my={5} />
          <Button
            href="/workspace-select"
            variant="contained"
            color="primary"
            className={classes.submitButton}>
            Go Back to Workspaces
          </Button>
          <Box my={3} />
          <Typography variant="body1">
            Stuck? Please contact{' '}
            <Link href="mailto:billing@trendpop.com" className={classes.link}>
              billing@trendpop.com
            </Link>{' '}
            for help.
          </Typography>
          <Box my={5} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Lockout;

import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { Workspace } from '../../models/workspaceSelect';
import { useWorkspacesQuery } from './queries';
import {
  LoadingCard,
  WorkspaceCard
} from '../../components/Auth/WorkspaceCard';
import { Alert } from '@material-ui/lab';
import { SUPPORT_EMAIL } from '../../constants/links';
import { useUser } from 'context/hooks/user';
import DashboardUserDropdown from 'layouts/components/DashboardUserDropdown';

interface WorkspaceListProps {
  isLoading: boolean;
  workspaces: Workspace[]; // Add proper type
}

const useStyles = makeStyles({
  createWorkspaceButton: {
    color: '#fff',
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor: '#202020'
    }
  }
});

// Sets the amount of cards limit based on spefication
const CARDLIMIT = 8;

const Alerts = ({ errors }: { errors: string[] }): JSX.Element => {
  if (errors.length === 0) return <></>;

  return (
    <Box mb={4}>
      {errors.map((e) => (
        <Alert
          key={e}
          severity={'error'}
          style={{
            marginTop: 10,
            marginBottom: 10
          }}>
          {e}
        </Alert>
      ))}
    </Box>
  );
};

export const WorkspaceList = (props: WorkspaceListProps): JSX.Element => {
  const { workspaces, isLoading } = props;
  const [showAll, setShowAll] = useState(false);

  const workspaceCards = useMemo(() => {
    let _workspaces = workspaces;
    if (!showAll) {
      _workspaces = _workspaces.slice(0, CARDLIMIT);
    }
    return _workspaces.map((w, i) => {
      const num = i + 1;
      return (
        <Grid
          key={num}
          item
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          md={3}
          sm={6}
          xs={12}>
          <Grid
            item
            container
            xs={6}
            sm={10}
            justify="space-evenly"
            alignItems="center">
            <WorkspaceCard workspace={w} />
          </Grid>
        </Grid>
      );
    });
  }, [workspaces, showAll]);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ marginTop: 20 }}>
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        md={12}
        sm={10}
        xs={9}>
        {isLoading &&
          [...new Array(CARDLIMIT)].map((_, i) => (
            <Grid
              key={i + 1}
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
              md={3}
              sm={6}
              xs={12}>
              <LoadingCard />
            </Grid>
          ))}

        {!isLoading && workspaceCards}
      </Grid>
      {!showAll && workspaces.length > CARDLIMIT && (
        <Grid item container md={12} sm={11} xs={10}>
          <Button
            fullWidth
            variant={'contained'}
            onClick={() => setShowAll(true)}>
            <Typography
              style={{
                fontWeight: 'bold'
              }}>
              Load More
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export const WorkspaceSelect = (): JSX.Element => {
  const [workspaceFilter, setWorkspaceFilter] = useState('');
  const { data, isLoading, error: loadWorkspacesError } = useWorkspacesQuery();
  const { user, isLoading: userLoading } = useUser();

  const mobileScreen = useMediaQuery('(max-width:600px)');
  const classes = useStyles();

  const workspaces = useMemo(() => {
    return data?.workspaces || [];
  }, [data]);
  const filteredWorkspaces = useMemo(
    () =>
      workspaces.filter((w) =>
        w.name.toLowerCase().includes(workspaceFilter.toLowerCase())
      ),
    [workspaces, workspaceFilter]
  );

  // // TODO: Consider if this should be a react query hook or keep as callback
  // const onWorkspaceSelect = useWorkspaceCallback(
  //   selectedWorkspace,
  //   setSelectionLoading,
  //   setSelectWorkspaceError,
  //   redirectRoute
  // );

  // // Effect will call an API query when any workspace is clicked or value is changed
  // useEffect(() => {
  //   if (selectedWorkspace !== null) {
  //     onWorkspaceSelect();
  //   }
  // }, [selectedWorkspace, onWorkspaceSelect]);

  const errors = [];
  if (loadWorkspacesError) {
    errors.push('Something went wrong loading your workspaces.');
  }

  if (isLoading || userLoading) {
    return (
      <Grid container alignItems="center" justify="center">
        <CircularProgress size="40px" color="secondary" />
      </Grid>
    );
  }

  const loggedInEmail = user.getEmail();

  return (
    <Container
      style={{
        width: '100%',
        maxWidth: '1000px',
        height: '600px'
      }}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Box position="absolute" top={30} right={{ xs: 0, sm: 50 }}>
          <DashboardUserDropdown />
        </Box>
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
          xs={12}>
          <Alerts errors={errors} />
          <Grid
            item
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            md={12}
            xs={10}>
            <Typography
              variant={'h6'}
              style={{
                textAlign: 'center'
              }}
              gutterBottom>
              {loggedInEmail ? (
                <b>Welcome, {loggedInEmail}.</b>
              ) : (
                <b>Welcome.</b>
              )}
            </Typography>
            <Typography
              variant={'h6'}
              style={{
                textAlign: 'center',
                marginBottom: '16px'
              }}>
              Please select the workspace you would like to use below.
            </Typography>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
              md={12}
              xs={11}>
              <Grid
                item
                container
                direction={mobileScreen === true ? 'column' : 'row'}
                justify="space-between"
                alignItems="center"
                xs={12}
                style={{ marginTop: 15, marginBottom: 5 }}>
                <Typography variant={'h3'} style={{ fontWeight: 'bold' }}>
                  Select a Workspace
                </Typography>
                <Box>
                  <Button
                    style={{
                      marginTop: mobileScreen === true ? 10 : 2,
                      marginBottom: 2,
                      padding: '12px 30px',
                      fontSize: 14,
                      borderRadius: 28,
                      textTransform: 'none'
                    }}
                    href="/workspace/create"
                    className={classes.createWorkspaceButton}>
                    Create Workspace
                  </Button>
                </Box>
              </Grid>
              <TextField
                fullWidth
                style={{
                  marginLeft: 2,
                  marginRight: 2,
                  marginTop: 8,
                  width: '100%'
                }}
                variant={'outlined'}
                placeholder={'Start typing to filter workspaces...'}
                autoFocus
                disabled={isLoading || workspaces.length === 0}
                onChange={(e) => setWorkspaceFilter(e.currentTarget.value)}
              />
            </Grid>
          </Grid>

          <WorkspaceList
            isLoading={isLoading}
            workspaces={filteredWorkspaces}
          />

          {workspaceFilter && filteredWorkspaces.length === 0 && (
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 30,
                marginBottom: 30
              }}>
              <Typography>
                No workspaces matching &quot;{workspaceFilter}&quot;.
              </Typography>
            </Box>
          )}

          {workspaces.length === 0 && (
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 30,
                marginBottom: 30
              }}>
              <Typography>
                You are not currently a member of any workspaces. Please create
                an workspace to access Trendpop.
              </Typography>
            </Box>
          )}

          <Box style={{ marginTop: 20 }}>
            <Typography variant={'h6'} style={{ textAlign: 'center' }}>
              Don't see your workspace here? Create a new workspace for your
              account
            </Typography>
            <Typography
              variant={'h6'}
              style={{
                textAlign: 'center',
                marginTop: 10,
                marginBottom: 30
              }}>
              Stuck? Please contact{' '}
              <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link> for
              help.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

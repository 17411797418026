import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { Link as ReactRouterLink } from 'react-router-dom';

import { rgba } from 'polished';
import DropdownPopover from '../../components/Filters/DropdownPopover';
import { AUTH_CALLBACK_PATH, MIA_LOGOUT } from '../../constants/links';
import _ from 'lodash';
import ErrorIcon from '@material-ui/icons/Error';
import { useWorkspacesQuery } from '../../pages/workspace-select/queries';
import { BillingStatus, Workspace } from '../../models/workspaceSelect';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

import {
  Avatar,
  Badge,
  Button,
  Box,
  Divider,
  Grid,
  Link as MuiLink,
  MenuList,
  IconButton as MuiIconButton,
  Typography,
  CircularProgress,
  Link
} from '@material-ui/core';
import { SupervisorAccount } from '@material-ui/icons';
import { workspaceStatusColor } from '../../pages/workspace-select/util';
import { useUser } from 'context/hooks/user';
import { useLoginLocalState } from '../../pages/accounts/useLoginLocalState';
import {
  removeAddItemTutorial,
  removeAnnouncementsVersionKey,
  removeChartTutorial,
  removeFirstTimeLoggedIn
} from 'components/Announcements/utils';

// Default workspaces shown before a load more click
const WORKSPACE_LIMIT = 4;

const InternalLink = styled(ReactRouterLink)`
  color: inherit;
  text-decoration: inherit;
`;

const ExternalLink = styled(MuiLink)`
  color: #4f4f4f;
  text-decoration: inherit;

  &:hover {
    text-decoration: inherit;
  }
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 18px;
    height: 18px;
  }
`;

const DropdownBox = styled(Box)`
  padding: 0 ${(props) => props.theme.spacing(4)}px;
  margin-left: ${(props) => props.theme.spacing(4)}px;
  ${(props) => rgba(props.theme.palette.primary.main, 0.3)};
`;

const UserBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const DropdownButton = styled(Button)`
  margin-bottom: 5px;
  border: 1px solid #c3c8cc;
  border-radius: 28px;
  min-width: 100px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 25px;
  padding-right: 25px;
`;

const RenderWorkspaceItem = (props: {
  workspace: Workspace;
  active: boolean;
}) => {
  const { workspace, active } = props;

  return (
    <Button
      variant={active === true ? 'contained' : 'text'}
      style={{
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        borderRadius: 28,
        justifyContent: 'flex-start',
        textAlign: 'left'
      }}>
      {workspace.img ? (
        <Avatar style={{ width: '25px', height: '25px' }} src={workspace.img} />
      ) : (
        <Avatar
          style={{
            backgroundColor: '#000',
            width: '25px',
            height: '25px'
          }}>
          <SupervisorAccount
            style={{ color: '#fff', width: '15px', height: '15px' }}
          />
        </Avatar>
      )}
      <FiberManualRecord
        style={{
          paddingLeft: 10,
          width: '22px',
          height: '22px',
          color: workspaceStatusColor(workspace)
        }}
      />
      <Typography style={{ paddingLeft: 10 }}>{workspace.name}</Typography>
    </Button>
  );
};

const RenderDropdownWorkspaceList = (props: {
  workspaces: Array<Workspace>;
  handleClick: (workspaceId: string | null) => void;
}) => {
  const { workspaces, handleClick } = props;
  const [showAll, setShowAll] = useState(false);

  const workspaceItems = useMemo(() => {
    let _workspaces = workspaces;
    if (!showAll) {
      _workspaces = _workspaces.slice(0, WORKSPACE_LIMIT);
    }
    return _workspaces.map((w: Workspace, i: number) => {
      const num = i + 1;
      return (
        <Link href={AUTH_CALLBACK_PATH} underline="none">
          <Box
            maxWidth="250px"
            key={num}
            paddingX={5}
            marginY={1}
            textAlign="left"
            onClick={() => handleClick(w.internal_id)}>
            <RenderWorkspaceItem key={num} workspace={w} active={false} />
          </Box>
        </Link>
      );
    });
  }, [workspaces, showAll, handleClick]);

  return (
    <Grid container direction="column" justify="flex-start">
      <Box
        width="100%"
        maxWidth="250px"
        maxHeight="250px"
        style={{
          overflow: 'hidden',
          overflowY: 'visible',
          paddingRight: 0
        }}>
        {workspaceItems}
      </Box>
      {!showAll && workspaceItems.length >= WORKSPACE_LIMIT && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button>
            <Typography
              style={{ color: '#4f4f4f' }}
              onClick={() => setShowAll(true)}>
              Show More
            </Typography>
          </Button>
        </Box>
      )}
    </Grid>
  );
};

const DashboardUserDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // Get current the user's current workspace
  const { user, isLoading } = useUser();
  const { setWorkspaceId } = useLoginLocalState();

  const currentWorkspaceId =
    user && !isLoading && user.getWorkspace() ? user?.getWorkspaceId() : null;

  // Get all of the user's workspaces
  const { data, error: loadWorkspacesError } = useWorkspacesQuery();
  const workspaces = useMemo(() => {
    return data?.workspaces || [];
  }, [data]);

  // Filters the workspaces that are not cancelled and the same as current workspace ID
  const workspacesFiltered = useMemo(
    () =>
      workspaces.filter(
        (w) =>
          w.billing_status !== BillingStatus.Canceled &&
          w.internal_id !== currentWorkspaceId
      ),
    [workspaces, currentWorkspaceId]
  );

  // Gets the current workspace from the workspace list for status
  const currentWorkspace = useMemo(
    () =>
      workspaces.find((workspace) => {
        return workspace.internal_id === currentWorkspaceId;
      }),
    [workspaces, currentWorkspaceId]
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (workspaceId: string | null) => {
    if (workspaceId !== null) {
      setWorkspaceId(workspaceId);
    }
  };

  if (loadWorkspacesError) {
    return <ErrorIcon fontSize="large" style={{ color: '#ff2543' }} />;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!user) {
    return <></>;
  }

  return (
    <DropdownBox>
      <UserBadge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        variant="dot">
        <IconButton onClick={handleOpen} color="inherit">
          <Avatar
            alt={user.getDisplayName() || user.getEmail()}
            src={user.getPhotoURL()}
          />
        </IconButton>
      </UserBadge>
      <DropdownPopover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Box m={2} width={'100%'}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
              xs={3}>
              <Avatar
                style={{ width: '50px', height: '50px' }}
                variant="rounded"
                alt={user.getDisplayName() || user.getEmail()}
                src={user.getPhotoURL()}
              />
            </Grid>
            <Grid
              item
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
              xs={8}
              style={{ paddingRight: 25 }}>
              <Typography>{user.getDisplayName()}</Typography>
              <Typography>{user.getEmail()}</Typography>
            </Grid>
          </Grid>
          <Box my={2} mr={3}>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center">
              <InternalLink
                to="/"
                style={{
                  pointerEvents: user.featureDashboardEnabled()
                    ? 'auto'
                    : 'none'
                }}>
                <DropdownButton disabled={!user.featureDashboardEnabled()}>
                  <Typography>Dashboard</Typography>
                </DropdownButton>
              </InternalLink>
              <ExternalLink
                href={MIA_LOGOUT}
                onClick={() => [
                  setWorkspaceId(''),
                  removeAnnouncementsVersionKey(),
                  removeFirstTimeLoggedIn(),
                  removeChartTutorial(),
                  removeAddItemTutorial()
                ]}>
                <DropdownButton>
                  <Typography>Sign Out</Typography>
                </DropdownButton>
              </ExternalLink>
            </Grid>
          </Box>
          <Divider />
          <MenuList>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center">
              {currentWorkspace && (
                <Link href={AUTH_CALLBACK_PATH} underline="none">
                  <Box
                    pl={3}
                    pr={4}
                    my={1}
                    width="100%"
                    minWidth="250px"
                    maxWidth="250px"
                    textAlign="left"
                    onClick={() => handleClick(currentWorkspaceId)}>
                    <RenderWorkspaceItem
                      workspace={currentWorkspace}
                      active={true}
                    />
                  </Box>
                </Link>
              )}
              {!_.isEmpty(workspacesFiltered) && (
                <RenderDropdownWorkspaceList
                  workspaces={workspacesFiltered}
                  handleClick={handleClick}
                />
              )}
            </Grid>
          </MenuList>
          <Divider />
          <Box m={2} display="flex" justifyContent="center">
            <ExternalLink href="/workspace/create">
              + Add a new workspace
            </ExternalLink>
          </Box>
        </Box>
      </DropdownPopover>
    </DropdownBox>
  );
};

export default DashboardUserDropdown;

import _ from 'lodash';

// The following diversificationLabels are duplicate. We should move to merge these duplicates in our infrastructure,
// but for now we handle the duplicate problem in the frontend.
export const duplicateCommerceCategories = {
  'Shopping & Retail': ['Shopping/Retail'],
  'Travel & Tourism': ['Travel'],
  'Art & Crafts': ['Art'],
  'Clothing & Accessories': ['Fashion'],
  'Sports, Fitness & Outdoors': ['Sports/Fitness', 'Sports'],
  'Food & Beverage': ['Food/Beverage'],
  'Media & Entertainment': ['Media']
};

// Other / Others are generally not useful diversificationLabels that should also be blacklisted in our infrastructure.
// Duplicate commerce diversificationLabels are added into the search filters before sending to the API.
export const blacklistedCommerceCategories = new Set([
  'Other',
  'Others',
  ..._.flatten(_.values(duplicateCommerceCategories))
]);

export const blacklistedAggregateCategoryCodes = new Set(['o']);
export const blacklistedDiversificationLabelIndices = new Set([0, 2]);

type SearchOptionCount = Map<string, number>;

interface CreatorPageMetadata {
  languageCodes: SearchOptionCount;
  states: SearchOptionCount;
  countries: SearchOptionCount;
  commerceCategories: SearchOptionCount;
  audienceCountries: SearchOptionCount;
  audienceLanguages: SearchOptionCount;
  audienceGenders: SearchOptionCount;
  audienceAgeGenerations: SearchOptionCount;
}

interface SoundTagPageMetadata {
  aggregateLanguageCodes: SearchOptionCount;
  aggregateCountries: SearchOptionCount;
  aggregateStates: SearchOptionCount;
  aggregateCommerceCategories: SearchOptionCount;
  aggregateDiversificationLabels: SearchOptionCount;
}

interface SongAlbumArtistMetadata {
  aggregateLanguageCodes: SearchOptionCount;
  aggregateCountries: SearchOptionCount;
  aggregateStates: SearchOptionCount;
  aggregateCommerceCategories: SearchOptionCount;
  aggregateDiversificationLabels: SearchOptionCount;
}

export interface SearchMetadata {
  creators: CreatorPageMetadata;
  sounds: SoundTagPageMetadata;
  tags: SoundTagPageMetadata;
  songs: SongAlbumArtistMetadata;
  albums: SongAlbumArtistMetadata;
  artists: SongAlbumArtistMetadata;
  loading: boolean;
  error: boolean;
}

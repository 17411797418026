import axios, { CancelTokenSource } from 'axios';
import {
  abbaResponseHandler,
  axiosErrorHandler,
  structuredErrorHandler
} from './util';
import { exportCollectionItem } from '../models/collectionItem';
import { requester } from './index';
import { CollectionList, DBCollectionItem } from '../models/collection';
import { UpdateUserArgs } from './user';
import { APIRequestError } from 'models/http';
import { useQuery } from 'react-query';

const CancelToken = axios.CancelToken;

export const listCollections = () => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v2/user/collections`;
  const cancelToken = CancelToken.source();
  return [
    requester
      .get(url, {
        cancelToken: cancelToken.token
      })
      .then(abbaResponseHandler, structuredErrorHandler),
    cancelToken
  ];
};

export const listCollectionsForItem = (item: DBCollectionItem) => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v2/user/collections/for_item`;
  const cancelToken = CancelToken.source();
  return [
    requester
      .post(url, exportCollectionItem(item), {
        cancelToken: cancelToken.token
      })
      .then(abbaResponseHandler, structuredErrorHandler),
    cancelToken
  ];
};

export const getCollection = (collectionId: string) => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v2/user/collections/${collectionId}`;
  const cancelToken = CancelToken.source();
  return [
    requester
      .get(url, {
        cancelToken: cancelToken.token
      })
      .then(abbaResponseHandler, structuredErrorHandler),
    cancelToken
  ];
};

export const addCollectionItem = (
  item: DBCollectionItem & { collectionId: string }
): [Promise<undefined>, CancelTokenSource] => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v2/user/collections/${item.collectionId}/item`;
  const cancelToken = CancelToken.source();
  return [
    requester
      .post<undefined>(url, exportCollectionItem(item), {
        cancelToken: cancelToken.token
      })
      .then(abbaResponseHandler, axiosErrorHandler),
    cancelToken
  ];
};

export const removeCollectionItem = (
  item: DBCollectionItem & { collectionId: string }
): [Promise<undefined>, CancelTokenSource] => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v2/user/collections/${item.collectionId}/item`;
  const cancelToken = CancelToken.source();
  return [
    requester
      .delete(url, {
        data: exportCollectionItem(item),
        cancelToken: cancelToken.token
      })
      .then(abbaResponseHandler, axiosErrorHandler),
    cancelToken
  ];
};

export const updateCollection = (
  collectionId: string,
  updateColumns: UpdateUserArgs
): [Promise<any>, CancelTokenSource] => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v2/user/collections/${collectionId}`;
  const cancelToken = CancelToken.source();
  return [
    requester
      .patch(url, updateColumns, {
        cancelToken: cancelToken.token
      })
      .then(abbaResponseHandler, structuredErrorHandler),
    cancelToken
  ];
};

export const createCollection = () => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v2/user/collections`;
  const cancelToken = CancelToken.source();
  return [
    requester
      .post(
        url,
        {},
        {
          cancelToken: cancelToken.token
        }
      )
      .then(abbaResponseHandler, structuredErrorHandler),
    cancelToken
  ];
};

export const deleteCollection = (collectionId: string) => {
  const url = `${process.env.REACT_APP_ABBA_URL}/v2/user/collections/${collectionId}`;
  const cancelToken = CancelToken.source();
  return [
    requester
      .delete(url, {
        cancelToken: cancelToken.token
      })
      .then(abbaResponseHandler, structuredErrorHandler),
    cancelToken
  ];
};

export const useCollection = (collectionId: string) => {
  return useQuery<any, APIRequestError>(
    [`tp-collections-${collectionId}`],
    async () => {
      const url = `${process.env.REACT_APP_ABBA_URL}/v2/user/collections/${collectionId}`;
      return await requester
        .get<any>(url)
        .then(abbaResponseHandler, axiosErrorHandler);
    },
    {
      retry: 1,
      enabled: !!collectionId
    }
  );
};

// Retrieve list of collections for Notifications
export const useCollections = (enabled?: boolean) => {
  return useQuery<CollectionList[], APIRequestError>(
    [`tp-collections`],
    async () => {
      const url = `${process.env.REACT_APP_ABBA_URL}/v2/user/collections`;
      return await requester
        .get<CollectionList[]>(url)
        .then(abbaResponseHandler, axiosErrorHandler);
    },
    {
      retry: 1,
      enabled
    }
  );
};

import React from 'react';
import styled, { withTheme } from 'styled-components/macro';
import { Grid, AppBar as MuiAppBar, Toolbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { DRAWER_WIDTH } from '../../constants';
import {
  fmtTrendpopProfileDetailPath,
  fmtTrendpopSoundDetailPath,
  fmtTrendpopHashtagDetailPath,
  fmtTrendpopVideoDetailPath,
  fmtTrendpopArtistDetailPath,
  fmtTrendpopSongDetailPath,
  fmtTrendpopAlbumDetailPath,
  fmtTrendpopISRCDetailPath
} from '../../utils/legacyFormat';
import TrendpopSearch from '../../components/TrendpopSearch';
import UserDropdown from './UserDropdown';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.palette.background.default};
  color: ${(props) => props.theme.header.color};
  width: auto;
`;

const Header = (props) => {
  const { width, extraChildren = [] } = props;
  const history = useHistory();

  const onChange = (event, option) => {
    if (!option) {
      return;
    }

    if (option.entity_type === 'tiktok_sound') {
      history.push(fmtTrendpopSoundDetailPath(option.id));
    } else if (option.entity_type === 'tiktok_profile') {
      history.push(fmtTrendpopProfileDetailPath(option.primary));
    } else if (option.entity_type === 'tiktok_tag') {
      history.push(fmtTrendpopHashtagDetailPath(option.primary));
    } else if (option.entity_type === 'tiktok_item') {
      history.push(
        fmtTrendpopVideoDetailPath(option.profileUniqueId, option.videoId)
      );
    } else if (option.entity_type === 'spotify_artist') {
      history.push(fmtTrendpopArtistDetailPath(option.id));
    } else if (option.entity_type === 'spotify_track') {
      history.push(fmtTrendpopSongDetailPath(option.id));
    } else if (option.entity_type === 'spotify_album') {
      history.push(fmtTrendpopAlbumDetailPath(option.id));
    } else if (option.entity_type === 'isrc') {
      history.push(fmtTrendpopISRCDetailPath(option.id));
    }
  };

  return (
    <AppBar
      position="static"
      style={{
        left: isWidthUp('md', width) ? DRAWER_WIDTH : 0,
        zIndex: 99
      }}
      elevation={0}>
      <Toolbar style={{ paddingRight: 0, paddingLeft: 15 }}>
        <Grid container alignItems="center">
          <Grid item xs>
            <TrendpopSearch onChange={onChange} numResults={10} />
          </Grid>
          {extraChildren.filter(Boolean).map((x, idx) => {
            return (
              <Grid item key={idx}>
                {x}
              </Grid>
            );
          })}
          <Grid item>
            <UserDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withWidth()(withTheme(Header));

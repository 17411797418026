import React from 'react';
import { useQuery } from 'react-query';
import { getSearchMetadata } from '../api/misc';
import {
  blacklistedAggregateCategoryCodes,
  blacklistedCommerceCategories,
  blacklistedDiversificationLabelIndices,
  SearchMetadata
} from '../models/searchMetadata';
import { useUser } from './hooks/user';
import { useLoginLocalState } from 'pages/accounts/useLoginLocalState';

const initialCreatorMetadata = {
  commerceCategories: new Map<string, number>(),
  languageCodes: new Map<string, number>(),
  countries: new Map<string, number>(),
  states: new Map<string, number>(),
  audienceCountries: new Map<string, number>(),
  audienceGenders: new Map<string, number>(),
  audienceLanguages: new Map<string, number>(),
  audienceAgeGenerations: new Map<string, number>()
};

const initialSoundTagMetadata = {
  aggregateLanguageCodes: new Map<string, number>(),
  aggregateCountries: new Map<string, number>(),
  aggregateStates: new Map<string, number>(),
  aggregateCommerceCategories: new Map<string, number>(),
  aggregateDiversificationLabels: new Map<string, number>()
};

const initialSongAlbumArtistMetadata = {
  aggregateLanguageCodes: new Map<string, number>(),
  aggregateCountries: new Map<string, number>(),
  aggregateStates: new Map<string, number>(),
  aggregateCommerceCategories: new Map<string, number>(),
  aggregateDiversificationLabels: new Map<string, number>()
};

const emptySearchMetadata = {
  creators: initialCreatorMetadata,
  sounds: initialSoundTagMetadata,
  tags: initialSoundTagMetadata,
  songs: initialSongAlbumArtistMetadata,
  albums: initialSongAlbumArtistMetadata,
  artists: initialSongAlbumArtistMetadata,
  loading: true,
  error: false
};

export const SearchMetadataContext = React.createContext<SearchMetadata>(
  emptySearchMetadata
);

const docCountToMap = (
  docCounts: Array<{ key: string; doc_count: number }>
) => {
  return new Map(docCounts.map((dc) => [dc.key, dc.doc_count || 0]));
};

const creatorDocCountsToPageMetadata = (pageResponse: any) => {
  return {
    commerceCategories: docCountToMap(
      (pageResponse.commerce_category || []).filter(
        (c: any) => !blacklistedCommerceCategories.has(c.key)
      )
    ),
    languageCodes: docCountToMap(pageResponse.language_code || []),
    countries: docCountToMap(pageResponse.geo_country || []),
    states: docCountToMap(pageResponse.geo_state || []),
    audienceCountries: docCountToMap(
      pageResponse['audience_demographics.aggregate_geo_country'] || []
    ),
    audienceGenders: docCountToMap(
      pageResponse['audience_demographics.aggregate_gender'] || []
    ),
    audienceLanguages: docCountToMap(
      pageResponse['audience_demographics.aggregate_language'] || []
    ),
    audienceAgeGenerations: docCountToMap(
      pageResponse['audience_demographics.aggregate_age_generation'] || []
    )
  };
};

const songAlbumArtistDocCountsToPageMetadata = (pageResponse: any) => {
  return {
    aggregateDiversificationLabels: docCountToMap(
      (
        pageResponse['creator_demographics.aggregate_diversification_labels'] ||
        []
      ).filter((c: any) => !blacklistedDiversificationLabelIndices.has(c.key))
    ),
    aggregateLanguageCodes: docCountToMap(
      pageResponse['creator_demographics.aggregate_language'] || []
    ),
    aggregateCountries: docCountToMap(
      pageResponse['creator_demographics.aggregate_geo_country'] || []
    ),
    aggregateStates: docCountToMap(
      pageResponse['creator_demographics.aggregate_geo_state'] || []
    ),
    aggregateCommerceCategories: docCountToMap(
      (
        pageResponse['creator_demographics.aggregate_commerce_category'] || []
      ).filter((c: any) => !blacklistedAggregateCategoryCodes.has(c.key))
    )
  };
};

const soundTagDocCountsToPageMetadata = (pageResponse: any) => {
  return {
    aggregateDiversificationLabels: docCountToMap(
      (
        pageResponse['creator_demographics.aggregate_diversification_labels'] ||
        []
      ).filter((c: any) => !blacklistedDiversificationLabelIndices.has(c.key))
    ),
    aggregateLanguageCodes: docCountToMap(
      pageResponse['creator_demographics.aggregate_language'] || []
    ),
    aggregateCountries: docCountToMap(
      pageResponse['creator_demographics.aggregate_geo_country'] || []
    ),
    aggregateStates: docCountToMap(
      pageResponse['creator_demographics.aggregate_geo_state'] || []
    ),
    aggregateCommerceCategories: docCountToMap(
      (
        pageResponse['creator_demographics.aggregate_commerce_category'] || []
      ).filter((c: any) => !blacklistedAggregateCategoryCodes.has(c.key))
    )
  };
};

export const SearchMetadataProvider = ({
  children
}: {
  children: JSX.Element[];
}) => {
  const { workspaceId } = useLoginLocalState();
  const { isLoading: userLoading, error: userError } = useUser();

  const {
    data: searchMetadata,
    isLoading: searchMetadataLoading,
    isError: searchMetadataError
  } = useQuery(
    ['searchMetaData', userError, userLoading, workspaceId],
    () => {
      const t = getSearchMetadata();
      const promise = t[0] as Promise<any>;

      return promise.then((response) => {
        const creators = response.creators || {};
        const sounds = response.sounds || {};
        const tags = response.tags || {};
        const songs = response.songs || {};
        const albums = response.songs || {};
        const artists = response.artists || {};
        return {
          creators: creatorDocCountsToPageMetadata(creators),
          sounds: soundTagDocCountsToPageMetadata(sounds),
          tags: soundTagDocCountsToPageMetadata(tags),
          songs: songAlbumArtistDocCountsToPageMetadata(songs),
          albums: songAlbumArtistDocCountsToPageMetadata(albums),
          artists: songAlbumArtistDocCountsToPageMetadata(artists)
        };
      });
    },
    {
      enabled: !userLoading && !userError && !!workspaceId,
      retry: 1
    }
  );

  return (
    <SearchMetadataContext.Provider
      value={{
        ...emptySearchMetadata,
        ...searchMetadata,
        loading: searchMetadataLoading,
        error: searchMetadataError
      }}>
      {children}
    </SearchMetadataContext.Provider>
  );
};

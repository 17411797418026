import React from 'react';
import {
  Box,
  Button,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import { SUPPORT_EMAIL } from 'constants/links';
import { TRENDPOP_PURPLE_3 } from 'constants/colors';
import { useLoginLocalState } from '../useLoginLocalState';

const useStyles = makeStyles(() => ({
  backButton: {
    height: 50,
    width: 175,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  }
}));

const ErrorPage500 = () => {
  const classes = useStyles();

  const { error: errorMessage } = useLoginLocalState();

  return (
    <Grid container alignItems="center" justify="center" direction="column">
      <Typography variant="h3">Error</Typography>
      <Box my={2} />
      <Typography variant="h6">
        {errorMessage && errorMessage.length > 1
          ? errorMessage
          : 'Looks like something went wrong, and our team has been notified'}
      </Typography>
      <Typography variant="h6">
        If you have any questions, contact us at{' '}
        <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>.
      </Typography>
      <Box my={5} />
      <Button
        href="/auth/callback"
        variant="contained"
        color="primary"
        className={classes.backButton}>
        Back to Home
      </Button>
    </Grid>
  );
};

export default ErrorPage500;

// Theme
export const THEMES = {
  DEFAULT: 'DEFAULT'
};

// Layout
export const DRAWER_WIDTH = 258;
export const DRAWER_MIN_WIDTH = 84;

// API
export const API_REQUEST_CANCELED = 'API_REQUEST_CANCELED';

// Elasticsearch has a limit of 10,000 on pagination size.
// Reference: https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html
export const ELASTIC_SEARCH_PAGINATION_LIMIT = 10000;

// Minimum number of videos to display virality badges.
//
// If a sound or hashtag is underneath this amount of videos, do not
// display a badge for it!
//
// TODO: Remove from the frontend once this gets shipped in the backend!
export const MIN_VIDEOS_FOR_VIRALITY_BADGES = 1000;

// This threshold will determine how many times the backend must mark an entity
// as "deleted" before we consider it as deleted to users.
export const DELETED_THRESHOLD = 3;

// Copy to display to user when they try to export something and it's disabled due to a free trial.
export const EXPORT_FREE_TRIAL_TOOLTIP =
  'Exporting is disabled during the free trial, please upgrade to a paid subscription to unlock this functionality.';

export const FILTER_SETTING_FREE_TRIAL_TOOLTIP =
  'Changing this setting is disabled during the free trial, please upgrade to a paid subscription to unlock this functionality';

export const TABLE_LIMIT_FREE_TRIAL = 5;

// The following unique IDs are creators that have requested we disable their data in the dashboard for privacy reasons.
// It is used to suppress discovery and creator report requests.
export const CREATOR_TAKEDOWN_REQUESTS = new Set(['mistermainer']);

// default constant for most recent content highlights count
export const DEFAULT_MOST_RECENT_COUNT = 30;

// Campaign limits
export const STARTER_PLAN_CAMPAIGNS_LIMIT = 100;

// Default time(milliseconds) before live fetch stops
export const DEFAULT_LIVE_FETCH_TIMEOUT = 60000;

// Default top items for chart
export const DEFAULT_TOP_CHART = 5;

import React from 'react';
import { Paper as MuiPaper, withWidth } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import styled from 'styled-components/macro';

import Footer from '../components/Footer';
import Header from './components/Header';

import Base, { BaseLayoutProps } from './Base';

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Paper = styled(MuiPaper)(spacing);

const InnerAppContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

interface DashboardLayoutProps extends BaseLayoutProps {
  omitHeader: boolean;
}

const Dashboard = ({ children, omitHeader, ...rest }: DashboardLayoutProps) => {
  const appContent = (
    <AppContent>
      {!omitHeader && <Header />}
      <InnerAppContent>{children}</InnerAppContent>
      <Footer />
    </AppContent>
  );
  return <Base children={appContent} {...rest} />;
};

export default withWidth()(Dashboard);

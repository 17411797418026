import axios from 'axios';

export const requester = axios.create({
  withCredentials: true
});

requester.interceptors.request.use(function (config) {
  config.headers['x-tp-workspace'] = localStorage.getItem('workspace_id');
  return config;
});

import React from 'react';
import { Box, CssBaseline, Theme } from '@material-ui/core';
import styled, { createGlobalStyle } from 'styled-components/macro';

import Sidebar from './components/Sidebar';
import AudioPlayer from './components/AudioPlayer';
import { DRAWER_MIN_WIDTH, DRAWER_WIDTH } from '../constants';
import { LayoutContext } from '../context/layout';
import Alerts from './components/Alerts';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface BaseLayoutProps {
  children?: React.ReactNode;
}

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props: { theme: Theme }) =>
      props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const useSidebarStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: DRAWER_MIN_WIDTH
  }
}));

const Base: React.FC<BaseLayoutProps> = ({ children }) => {
  const [layoutState] = React.useContext(LayoutContext);
  const sidebarOpen = layoutState.sidebarOpen;

  const classes = useSidebarStyles();

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Box display="flex" style={{ flex: 1 }}>
        <Sidebar
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: sidebarOpen,
            [classes.drawerClose]: !sidebarOpen
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: sidebarOpen,
              [classes.drawerClose]: !sidebarOpen
            })
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          style={{ flex: 1 }}
          overflow="auto">
          <Alerts />
          {children}
        </Box>
      </Box>
      <AudioPlayer />
    </Root>
  );
};

export default Base;

import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { AppBar, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router';

import { TIKTOK_SOUND_AUDIO_ASSET_BASE_PATH } from '../../constants/links';
import { fmtTikTokSoundURL } from '../../utils/legacyFormat';

const trendpopAudioSrc = (soundId: string) =>
  `${TIKTOK_SOUND_AUDIO_ASSET_BASE_PATH}/${soundId}.mp3`;

const currentAudioSrc = (state: AudioPlayerState) => {
  if (state.soundId && !state.soundAssetError) {
    return trendpopAudioSrc(state.soundId);
  }
  if (state.backupAudioUrl && !state.backupAudioUrlError) {
    return state.backupAudioUrl;
  }
};

export interface AudioPlayerState {
  soundId: string | undefined;
  soundAssetError: boolean;
  backupAudioUrl: string | undefined;
  backupAudioUrlError: boolean;

  playing: boolean;
}

export const initialAudioPlayerState = {
  soundId: undefined,
  soundAssetError: false,
  backupAudioUrl: undefined,
  backupAudioUrlError: false,
  playing: false
};

export const AudioPlayerContext = React.createContext<
  [AudioPlayerState, Dispatch<SetStateAction<AudioPlayerState>>]
>([initialAudioPlayerState, () => {}]);

export const AudioPlayerProvider = ({
  children
}: {
  children?: JSX.Element | JSX.Element[];
}) => {
  const [state, setState] = React.useState<AudioPlayerState>(
    initialAudioPlayerState
  );

  return (
    <AudioPlayerContext.Provider value={[state, setState]}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

// This AudioPlayer tries to play the audio from the audio player state via a fallback mechanism.
// 1. If the sound ID is set and Trendpop has the asset in our S3 folder, we'll play it from there.
// 2. Next, try playing the "backupAudioUrl" which is typically the audio file hosted on TiKTok servers.
// 3. If neither (1) nor (2) work, then we'll show the user an error and recommend they listen to the sound on TikTok's website.
const AudioPlayer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [audioPlayerState, setAudioPlayerState] = React.useContext(
    AudioPlayerContext
  );

  // When the user navigates to a new page, reset the audio player.
  useEffect(() => {
    setAudioPlayerState(initialAudioPlayerState);
  }, [setAudioPlayerState, location.pathname]);

  const url = currentAudioSrc(audioPlayerState);

  return (
    <AppBar
      position="fixed"
      color="transparent"
      style={{
        top: 'auto',
        right: 0,
        bottom: 0,
        paddingBottom: '10px',
        paddingRight: '10px',
        height: '60px',
        width: '30%',
        borderTop: '1px solid rgba(255, 255, 255, 0.12)'
      }}>
      <ReactPlayer
        height="60px"
        width="100%"
        playing={audioPlayerState.playing}
        url={url}
        onPlay={() => {
          setAudioPlayerState((prev) => {
            return { ...prev, playing: true };
          });
        }}
        onPause={() => {
          setAudioPlayerState((prev) => {
            return { ...prev, playing: false };
          });
        }}
        onError={() => {
          const newState = { ...audioPlayerState };
          if (
            audioPlayerState.soundId &&
            url === trendpopAudioSrc(audioPlayerState.soundId)
          ) {
            newState.soundAssetError = true;
          } else if (
            audioPlayerState.backupAudioUrl &&
            url === audioPlayerState.backupAudioUrl
          ) {
            newState.backupAudioUrlError = true;
          }

          // If we've tried all available options, display an error state to the user to have them go to the TikTok URL.
          if (
            (!newState.soundId || newState.soundAssetError) &&
            (!newState.backupAudioUrl || newState.backupAudioUrlError)
          ) {
            const soundUrl = fmtTikTokSoundURL('s', newState.soundId);
            enqueueSnackbar(
              <Typography>
                Trendpop cannot play the sound at this time. Please listen at{' '}
                <a
                  target="_blank"
                  href={soundUrl}
                  rel="noreferrer"
                  style={{ color: 'white' }}>
                  {soundUrl}
                </a>
                .
              </Typography>,
              {
                variant: 'error'
              }
            );
          }

          setAudioPlayerState(newState);
        }}
        controls={true}
      />
    </AppBar>
  );
};

export default AudioPlayer;

import { useMemo } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  IconButton,
  Link,
  Typography
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { formatDistanceToNow, parseISO } from 'date-fns';
import {
  BillingStatus,
  billingStatusToLabel,
  Workspace
} from '../../models/workspaceSelect';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import { workspaceStatusColor } from '../../pages/workspace-select/util';
import { AUTH_CALLBACK_PATH, AUTH_WORKSPACE_SETTINGS } from 'constants/links';
import { useLoginLocalState } from '../../pages/accounts/useLoginLocalState';

const workspaceStatusFontSize = '11px';
const workspaceDetailsFontSize = '12px';
const workspaceNameFontSize = '14px';

const isValidDate = (d: unknown) => {
  return d instanceof Date && !isNaN(d.getTime());
};

interface WorkspaceCardProps {
  workspace: Workspace;
}

const contrastTextColor = (color: string) => {
  const bigint = parseInt(color.substring(1, 7), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);
  return brightness > 125 ? 'black' : 'white';
};

const SubscriptionStatus = ({
  workspace
}: {
  workspace: Workspace;
}): JSX.Element => {
  const bgColor = workspaceStatusColor(workspace);
  const fontColor = contrastTextColor(bgColor);

  return (
    <Box
      style={{
        height: '22px',
        fontSize: workspaceStatusFontSize,
        padding: '3px 5px',
        backgroundColor: workspaceStatusColor(workspace),
        color: fontColor
      }}>
      {billingStatusToLabel(workspace.billing_status)}
    </Box>
  );
};

const TrialEndingDate = ({
  workspace
}: {
  workspace: Workspace;
}): JSX.Element => {
  const parsedDate = useMemo(() => {
    return parseISO(workspace.billing_valid_until);
  }, [workspace.billing_valid_until]);

  if (workspace.billing_status !== BillingStatus.Trialing) {
    return <></>;
  }

  if (!isValidDate(parsedDate)) {
    return <></>;
  }

  return (
    <Typography
      style={{ fontStyle: 'italic', fontSize: workspaceDetailsFontSize }}>
      {formatDistanceToNow(parsedDate)} remaining
    </Typography>
  );
};

export const WorkspaceCard = (props: WorkspaceCardProps): JSX.Element => {
  const { workspace } = props;
  const { setWorkspaceId, setRedirectUrl } = useLoginLocalState();

  const handleClick = (workspaceId: string, settings = false) => {
    setWorkspaceId(workspaceId);
    if (settings) {
      setRedirectUrl(AUTH_WORKSPACE_SETTINGS);
    }
  };

  return (
    <Card
      style={{
        background: '#ECEFF1',
        width: '100%',
        minWidth: 234,
        maxWidth: 400,
        minHeight: 134,
        marginBottom: 20
      }}>
      <Link
        href={AUTH_CALLBACK_PATH}
        onClick={() => handleClick(workspace.internal_id)}
        underline="none">
        <CardActionArea>
          <Box
            style={{
              minHeight: 134,
              maxHeight: 134,
              padding: 13,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start'
            }}>
            <Box
              width="70%"
              style={{ flex: 1, paddingTop: '9px', marginRight: 10 }}>
              <Typography
                noWrap
                style={{
                  color: '#000',
                  fontSize: workspaceNameFontSize,
                  marginBottom: '8px'
                }}>
                {workspace.name}
              </Typography>
              <Box mb={2} style={{ display: 'flex', flexDirection: 'row' }}>
                <SubscriptionStatus workspace={workspace} />
              </Box>
              <Typography
                style={{
                  fontSize: workspaceDetailsFontSize,
                  marginBottom: '8px'
                }}>
                {workspace.internal_id.substring(0, 5)}
              </Typography>
              <TrialEndingDate workspace={workspace} />
            </Box>
            <IconButton
              onClick={() => handleClick(workspace.internal_id, true)}>
              <SettingsOutlinedIcon style={{ color: '#000' }} />
            </IconButton>
          </Box>
        </CardActionArea>
      </Link>
    </Card>
  );
};

export const LoadingCard = (): JSX.Element => {
  return (
    <Card
      style={{
        width: 220,
        height: 140,
        marginBottom: 20
      }}>
      <Skeleton
        style={{
          marginLeft: 20,
          marginTop: 30,
          height: 70,
          width: 100
        }}
      />
      <Box
        style={{
          display: 'flex',
          paddingLeft: 20,
          paddingRight: 20
        }}>
        <Skeleton
          style={{
            width: 40,
            height: 30
          }}
        />
        <Skeleton
          style={{
            marginLeft: 'auto',
            width: 40,
            height: 30
          }}
        />
      </Box>
    </Card>
  );
};

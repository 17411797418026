import React from 'react';
import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  Tooltip as MuiTooltip,
  withStyles
} from '@material-ui/core';

import {
  DefaultUpgradePlanColor,
  upgradePlanColor
} from '../../../models/plan';
import MuiCheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PlanIcon from './PlanIcon';
import { PlanName } from 'models/user';

export interface CompareSupportProps {
  setOpenSupport: (openSupport: boolean) => void;
  openSupport: boolean;
}

const CompareCollapsible = withStyles(() => ({
  root: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent'
    }
  }
}))(Box);

const CellHeaderBox = withStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))(Box);

const CellFeatureBox = withStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}))(Box);

const TableRow = withStyles(() => ({
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    borderTop: `1px solid #e4dbff`
  }
}))(MuiTableRow);

const TableCell = withStyles(() => ({
  root: {
    paddingTop: 15,
    paddingBottom: 15,
    borderLeft: `1px solid #e4dbff`,
    borderBottom: 'none'
  }
}))(MuiTableCell);

const Tooltip = withStyles(() => ({
  arrow: {
    color: '#333333'
  },
  tooltip: {
    fontSize: 14,
    backgroundColor: '#333333'
  }
}))(MuiTooltip);

const CheckIcon = withStyles({
  root: {
    color: DefaultUpgradePlanColor
  }
})(MuiCheckIcon);

const CompareSupport = (props: CompareSupportProps) => {
  const { setOpenSupport, openSupport } = props;

  return (
    <Paper>
      <Box padding={5}>
        <Box margin={5} border="1px solid #e4dbff" borderRadius={10}>
          <CompareCollapsible
            style={{
              color: DefaultUpgradePlanColor
            }}
            onClick={() => setOpenSupport(!openSupport)}>
            <Typography variant="h3">
              Compare Support {openSupport ? '-' : '+'}
            </Typography>
          </CompareCollapsible>
          <Collapse in={openSupport}>
            <TableContainer>
              <Table>
                <colgroup>
                  <col style={{ width: '40%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '15%' }} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}></TableCell>
                    <TableCell>
                      <CellHeaderBox>
                        <PlanIcon color={upgradePlanColor[PlanName.Starter]} />
                        <Typography style={{ paddingLeft: 5 }}>
                          Starter
                        </Typography>
                      </CellHeaderBox>
                    </TableCell>
                    <TableCell>
                      <CellHeaderBox>
                        <PlanIcon color={upgradePlanColor[PlanName.Team]} />
                        <Typography style={{ paddingLeft: 5 }}>Team</Typography>
                      </CellHeaderBox>
                    </TableCell>
                    <TableCell>
                      <CellHeaderBox>
                        <PlanIcon color={upgradePlanColor[PlanName.Business]} />
                        <Typography style={{ paddingLeft: 5 }}>
                          Business
                        </Typography>
                      </CellHeaderBox>
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#fff',
                        backgroundImage:
                          'linear-gradient(90deg, rgba(60,0,160,1) 0%, rgba(119,32,150,1) 50%, rgba(181,63,137,1) 100%)'
                      }}>
                      <CellHeaderBox>
                        <PlanIcon color="#fff" />
                        <Typography style={{ paddingLeft: 5 }}>
                          Enterprise
                        </Typography>
                      </CellHeaderBox>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Help Center
                        <Tooltip
                          arrow
                          title="Trendpop's help center is the best place to get your quick questions answered in no time."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Email Support
                        <Tooltip
                          arrow
                          title="Trendpop's support team is here to answer your questions. Email us at support@trendpop.com and we will get back to you as soon as possible."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">3 Days</TableCell>
                    <TableCell align="center">3 Days</TableCell>
                    <TableCell align="center">1 Day</TableCell>
                    <TableCell align="center">1 Day</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Senior Support Team
                        <Tooltip
                          arrow
                          title="Our senior support staff will help you answer the toughest of questions."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Onboarding and Integration
                        <Tooltip
                          arrow
                          title="Our account managers offer onboarding sessions with your team so you can hit the ground running."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Live Chat
                        <Tooltip
                          arrow
                          title="Our live chat support gives you the flexibility to get your support request done in real time."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Dedicated Account Manager
                        <Tooltip
                          arrow
                          title="Trendpop's dedicated account manager will be there every step of the way on your Trendpop journey to ensure you are getting the best use of Trendpop."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderLeft: 'none' }}>
                      <CellFeatureBox>
                        Custom Payment Options
                        <Tooltip
                          arrow
                          title="Do you need custom payment plans and options? We will integrate with your billing department for seamless billing."
                          placement="right">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 14,
                              paddingLeft: 2,
                              color: DefaultUpgradePlanColor
                            }}
                          />
                        </Tooltip>
                      </CellFeatureBox>
                    </TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                    <TableCell align="center">
                      <CheckIcon />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </Box>
      </Box>
    </Paper>
  );
};

export default CompareSupport;

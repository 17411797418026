import React, { PropsWithChildren } from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { CssBaseline } from '@material-ui/core';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: #F5F5F5;
  }
`;

const Root = styled.div`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const Public = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
};

export default Public;

import React from 'react';

const LockIcon = (props) => {
  const size = props.size || '75px';
  return (
    <img
      style={{ maxHeight: size, filter: 'opacity(75%)' }}
      src="/static/img/icons/padlock.png"
      alt="Feature Unavailable"
    />
  );
};

export default LockIcon;

// Remove announcement, called when logged out
export const removeAnnouncementsVersionKey = () => {
  for (const key in localStorage) {
    if (key.includes('Announcement')) {
      localStorage.removeItem(key);
    }
  }
};

// Remove first login, called when logged out
export const removeFirstTimeLoggedIn = () => {
  for (const key in localStorage) {
    if (key.includes('firstLogin')) {
      localStorage.removeItem(key);
    }
  }
};

// Remove chart tutorial, called when logged out
export const removeChartTutorial = () => {
  for (const key in localStorage) {
    if (key.includes('chartTutorial')) {
      localStorage.removeItem(key);
    }
  }
};

// Remove add item tutorial, called when logged out
export const removeAddItemTutorial = () => {
  for (const key in localStorage) {
    if (key.includes('addItemTutorial')) {
      localStorage.removeItem(key);
    }
  }
};

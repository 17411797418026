import axios from 'axios';

import { useQuery } from 'react-query';
import { requester } from '../../api';
import { abbaResponseHandler, axiosErrorHandler } from '../../api/util';
import { APIRequestError } from '../../models/http';
import {
  CreatorsToWatch,
  DashboardResponse,
  RecentProjectType,
  SearchHistory
} from '../../models/dashboard';

const CancelToken = axios.CancelToken;

// "list" is a required key in get creator watch details api
interface GetCreatorToWatchDetailsParams {
  list: CreatorsToWatch[];
}

export enum RecentProjectTypeField {
  catalog = 'catalog_id',
  campaign = 'campaign_id',
  collection = 'collection_id'
}

interface SaveRecentProjectHistoryParams {
  type: RecentProjectType;
  field: RecentProjectTypeField;
  id: string;
}

interface DetailResponse {
  profiles: CreatorToWatch[];
}

interface CreatorToWatch {
  unique_id: string;
  emails: string[];
  tiktok_id: string;
  verified: boolean;
  follower_count: number;
  followers_current: number;
  followers_offset7: number;
}

export const useDashboardResponse = () => {
  return useQuery<DashboardResponse, APIRequestError>(
    [`tp-dashboard`],
    async () => {
      const url = `${process.env.REACT_APP_MIA_URL}/v1/dashboard`;
      return await requester
        .get<DashboardResponse>(url)
        .then(abbaResponseHandler)
        .then((response) => {
          return response;
        })
        .catch(axiosErrorHandler)
        .catch((error) => {
          console.error(error);
          return Promise.reject({
            description:
              'Something went wrong when parsing response from dashboard.',
            status: 500
          });
        });
    },
    {
      retry: 1
    }
  );
};

export const useReportSearchHistory = (limit?: number) => {
  return useQuery<SearchHistory[], APIRequestError>(
    [`tp-search-history`, limit],
    async () => {
      const url = `${process.env.REACT_APP_MIA_URL}/v1/report/search-history?limit=${limit}`;
      return await requester
        .get<SearchHistory[]>(url)
        .then(abbaResponseHandler)
        .then((response) => {
          return response;
        })
        .catch(axiosErrorHandler)
        .catch((error) => {
          console.error(error);
          return Promise.reject({
            description:
              'Something went wrong when parsing response from search history.',
            status: 500
          });
        });
    },
    {
      retry: 1
    }
  );
};

export const useCreatorToWatchDetailsResponse = (
  body: GetCreatorToWatchDetailsParams
) => {
  return useQuery<DetailResponse, APIRequestError>(
    [`tp-dashboard-creators-to-watch`],
    async () => {
      const url = `${process.env.REACT_APP_ABBA_URL}/v2/user/collections/items/detail`;
      return await requester
        .post<DetailResponse>(url, { ...body })
        .then(abbaResponseHandler)
        .then((response) => {
          return response;
        })
        .catch(axiosErrorHandler)
        .catch((error) => {
          console.error(error);
          return Promise.reject({
            description:
              'Something went wrong when parsing response from creators to watch.',
            status: 500
          });
        });
    },
    {
      enabled: !!body,
      retry: 1
    }
  );
};

export const saveRecentProjectHistory = (
  body: SaveRecentProjectHistoryParams
): Promise<any> => {
  const url = `${process.env.REACT_APP_MIA_URL}/v1/dashboard/recent_projects`;
  const cancelToken = CancelToken.source();

  const params = {
    type: body.type,
    [body.field]: body.id
  };

  return requester
    .post(
      url,
      {
        ...params
      },
      {
        cancelToken: cancelToken.token
      }
    )
    .then(abbaResponseHandler, axiosErrorHandler);
};

export const deleteRecentHistoryById = (id: string): Promise<any> => {
  const url = `${process.env.REACT_APP_MIA_URL}/v1/dashboard/search_history/${id}`;
  const cancelToken = CancelToken.source();

  return requester
    .delete(url, {
      cancelToken: cancelToken.token
    })
    .then(abbaResponseHandler, axiosErrorHandler);
};

export const getTrackingData = (url: string): Promise<any> => {
  const { token: cancelToken } = CancelToken.source();

  return requester
    .get(url, { cancelToken })
    .then(abbaResponseHandler)
    .then((response) => response)
    .catch(axiosErrorHandler)
    .catch((error) => {
      console.error(error);
      return Promise.reject({
        description:
          'Something went wrong when parsing response from tracking URL.',
        status: 500
      });
    });
};

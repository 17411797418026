import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CampaignIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 300.0 300.0">
      <g
        transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        stroke="none">
        <path
          d="M2195 2355 c-104 -79 -191 -148 -193 -154 -4 -12 136 -201 149 -201
14 0 399 291 399 301 0 16 -142 199 -154 198 -6 0 -96 -65 -201 -144z"
        />
        <path
          d="M1307 2065 l-309 -185 -266 0 c-153 0 -283 -5 -304 -10 -59 -17 -120
-69 -150 -129 -28 -54 -28 -57 -28 -242 0 -201 5 -228 56 -288 14 -17 47 -44
72 -58 40 -24 60 -28 144 -31 l98 -4 0 -249 0 -249 130 0 130 0 0 250 0 250
59 0 c59 0 61 -1 359 -180 165 -99 307 -183 316 -186 15 -6 16 57 16 745 0
413 -3 751 -7 751 -5 0 -147 -83 -316 -185z"
        />
        <path
          d="M1751 1500 l0 -425 49 53 c114 122 165 319 129 494 -17 80 -73 191
-129 250 l-49 53 0 -425z"
        />
        <path d="M2250 1500 l0 -130 250 0 250 0 0 130 0 130 -250 0 -250 0 0 -130z" />
        <path
          d="M2077 909 c-38 -49 -70 -96 -73 -103 -5 -12 374 -306 395 -306 10 0
151 185 151 199 0 9 -385 301 -398 301 -4 0 -38 -41 -75 -91z"
        />
      </g>
    </SvgIcon>
  );
};

export default CampaignIcon;

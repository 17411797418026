export const AggregateCategoryCodes = new Map<string, string>([
  ['p', 'PersonalBlog'],
  ['m', 'MediaEntertainment'],
  ['g', 'Gaming'],
  ['a', 'ArtCrafts'],
  ['b', 'Beauty'],
  ['o', 'Other'],
  ['e', 'Entertainment'],
  ['c', 'ClothingAccessories'],
  ['ar', 'Art'],
  ['pf', 'PublicFigure'],
  ['et', 'EducationTraining'],
  ['s', 'SportsFitnessOutdoors'],
  ['pa', 'PublicAdministration'],
  ['sr', 'ShoppingRetail'],
  ['at', 'AutomotiveTransportation'],
  ['f', 'FoodBeverage'],
  ['h', 'HealthWellness'],
  ['md', 'MusicDance'],
  ['el', 'Electronics'],
  ['pe', 'Pets'],
  ['fa', 'Fashion'],
  ['pr', 'ProfessionalServices'],
  ['t', 'TravelTourism'],
  ['br', 'Brand'],
  ['fi', 'FinanceInvesting'],
  ['l', 'LifeStyle'],
  ['ed', 'Education'],
  ['hf', 'HomeFurnitureAppliances'],
  ['ba', 'Baby'],
  ['me', 'Media'],
  ['sf', 'SportsFitness'],
  ['sa', 'SoftwareApps'],
  ['fn', 'Finance'],
  ['i', 'Internet'],
  ['mh', 'MedicalHealth'],
  ['r', 'RealEstate'],
  ['tr', 'Travel'],
  ['rb', 'RestaurantsBars'],
  ['ma', 'MachineryEquipment'],
  ['n', 'NGO'],
  ['re', 'Restaurant'],
  ['gp', 'GovernmentPolitics']
]);

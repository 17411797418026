import React from 'react';

const TikTokIcon = (props) => {
  const size = props.size || 16;
  return (
    <img
      alt="Tik Tok"
      src="/static/img/brands/tiktok.png"
      height={size}
      width={size}
    />
  );
};

export default TikTokIcon;

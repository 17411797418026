import React, { useEffect } from 'react';
import { Box, Link, Typography } from '@material-ui/core';

import InternalErrorDisplay from './InternalErrorDisplay';
import { LockIcon, ServerFailureIcon } from './Icons';
import { SUPPORT_EMAIL } from '../constants/links';
import { useLoggedOutModalContext } from 'context/loggedOutModal';
import MinusCircleIcon from './Icons/MinusCircleIcon';
import { TRENDPOP_BODY_TEXT } from 'constants/colors';

const StructuredErrorDisplay = (props) => {
  const { response } = props;
  const { setShowModal } = useLoggedOutModalContext();

  useEffect(() => {
    if (response.status_code === 401) {
      setShowModal(true);
    }
  }, [response.status_code, setShowModal]);

  if (response && response.status_code >= 500) {
    return <InternalErrorDisplay response={response} />;
  }

  if (response && response.status_code === 402) {
    return (
      <Box align="center" style={{ maxWidth: '600px', margin: 'auto' }}>
        <Typography variant="h1" color="error" align="center">
          <LockIcon />
        </Typography>
        <Box my={2} />
        <Typography variant="h3" color="textSecondary">
          Feature Unavailable
        </Typography>
        <Box my={2} />
        <Typography color="textSecondary" variant="subtitle2">
          {JSON.stringify(response.response || response.error)}
        </Typography>
      </Box>
    );
  }

  if (response && response.status_code === 404) {
    return (
      <Box align="center" style={{ maxWidth: '600px', margin: 'auto' }}>
        <Box my={30} />
        <MinusCircleIcon color={TRENDPOP_BODY_TEXT} />
        <Typography
          variant="h1"
          align="center"
          style={{ color: TRENDPOP_BODY_TEXT }}>
          404: Not Found
        </Typography>
        <Typography variant="subtitle1" style={{ color: TRENDPOP_BODY_TEXT }}>
          We do not currently have any data about this in our database. This may
          be because our systems have never encountered it before, it was
          deleted, not available, or we are unable to display it. Please contact{' '}
          <Link
            href={`mailto:${SUPPORT_EMAIL}`}
            style={{ color: TRENDPOP_BODY_TEXT }}>
            {SUPPORT_EMAIL}
          </Link>{' '}
          for more help!
        </Typography>
        <Box my={5} />
        <Typography variant="subtitle1" style={{ color: TRENDPOP_BODY_TEXT }}>
          Unfortunately, we no longer support song, artist, and album reports.
        </Typography>
      </Box>
    );
  }

  if (response && response.status_code === 400) {
    return (
      <Box align="center" style={{ maxWidth: '600px', margin: 'auto' }}>
        <ServerFailureIcon />
        <Typography variant="h1" align="center" gutterBottom>
          400: Invalid Request Error
        </Typography>
        <Box my={2} />
        <Typography color="textSecondary" variant="subtitle2">
          Something went wrong. Please contact{' '}
          <Link href={`mailto:${SUPPORT_EMAIL}`}>${SUPPORT_EMAIL}</Link> for
          help.
        </Typography>
      </Box>
    );
  }

  return (
    <Box align="center" style={{ maxWidth: '600px', margin: 'auto' }}>
      <ServerFailureIcon />
      <Typography variant="h1" color="error" align="center" gutterBottom>
        {response.status_code || 500} Error
      </Typography>
      <Typography color="textSecondary" variant="subtitle2" gutterBottom>
        {JSON.stringify(response.response)}
      </Typography>
      {(typeof response.error === 'string' ||
        response.error instanceof String) && (
        <Typography color="error" variant="subtitle2">
          {response.error}
        </Typography>
      )}
      {response.status_code >= 500 && (
        <Typography color="textSecondary" variant="subtitle2">
          Please try again later.
        </Typography>
      )}
    </Box>
  );
};

export default StructuredErrorDisplay;

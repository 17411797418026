import { Box } from '@material-ui/core';
import { SVG_FILENAMES } from './CollectionIcon';
import MurmurHash3 from 'imurmurhash';

const CampaignInfoCardIcon = (props) => {
  const { campaign, size = 45, padding = 4, borderWidth = 2 } = props;

  const idx = MurmurHash3(campaign.id).result() % SVG_FILENAMES.length; // commented but i assume it will be hashed by this

  const path = campaign.icon
    ? campaign.icon
    : '/static/img/animal-pack/' + SVG_FILENAMES[idx];

  return (
    <Box
      style={{
        borderStyle: 'solid',
        borderWidth: borderWidth,
        borderColor: 'rgba(0, 0, 0, .75)',
        borderRadius: size / 10,
        padding: padding,
        width: size + padding * 2 + borderWidth * 2,
        height: size + padding * 2 + borderWidth * 2
      }}>
      <img alt={campaign.name} src={path} height={size} width={size} />
    </Box>
  );
};

export default CampaignInfoCardIcon;

import axios from 'axios';

import { useQuery } from 'react-query';
import { requester } from '../../api';
import { abbaResponseHandler, axiosErrorHandler } from '../../api/util';
import { APIRequestError } from '../../models/http';
import { SavedQueryListResponse } from '../../models/savedQuery';
import { ObjectModel } from 'objectmodel';

const CancelToken = axios.CancelToken;

export interface SavedQueryCreateParams {
  name: string;
  type: string;
  filters: ObjectModel[];
}

export const useSavedQueryListResponse = () => {
  return useQuery<SavedQueryListResponse[], APIRequestError>(
    [`tp-saved-query-list`],
    async () => {
      const url = `${process.env.REACT_APP_MIA_URL}/v1/saved_query`;
      return await requester
        .get<SavedQueryListResponse[]>(url)
        .then(abbaResponseHandler)
        .then((response) => {
          return response;
        })

        .catch(axiosErrorHandler)
        .catch((error) => {
          console.error(error);
          return Promise.reject({
            description:
              'Something went wrong when parsing response from saved queries list.',
            status: 500
          });
        });
    },
    {
      retry: 1
    }
  );
};

export const useSavedQueryListByTypeResponse = (type: string) => {
  return useQuery<SavedQueryListResponse[], APIRequestError>(
    [`tp-saved-query-list-${type}`],
    async () => {
      const url = `${process.env.REACT_APP_MIA_URL}/v1/saved_query?type=${type}`;
      return await requester
        .get<SavedQueryListResponse[]>(url)
        .then(abbaResponseHandler)
        .then((response) => {
          return response;
        })
        .catch(axiosErrorHandler)
        .catch((error) => {
          console.error(error);
          return Promise.reject({
            description:
              'Something went wrong when parsing response from saved queries list.',
            status: 500
          });
        });
    },
    {
      retry: 1
    }
  );
};

export const useSavedQueryTrendingSearchesResponse = () => {
  return useQuery<SavedQueryListResponse[], APIRequestError>(
    [`tp-saved-query-trending-searches`],
    async () => {
      const url = `${process.env.REACT_APP_MIA_URL}/v1/saved_query/recommend`;
      return await requester
        .get<SavedQueryListResponse[]>(url)
        .then(abbaResponseHandler)
        .then((response) => {
          return response;
        })
        .catch(axiosErrorHandler)
        .catch((error) => {
          console.error(error);
          return Promise.reject({
            description:
              'Something went wrong when parsing response from trending searches.',
            status: 500
          });
        });
    },
    {
      retry: 1
    }
  );
};

export const useSavedQueryDetail = (id: string | null) => {
  return useQuery<SavedQueryListResponse, APIRequestError>(
    [`tp-saved-query-detail-${id}`],
    async () => {
      const url = `${process.env.REACT_APP_MIA_URL}/v1/saved_query/${id}`;
      return await requester
        .get<SavedQueryListResponse>(url)
        .then(abbaResponseHandler)
        .then((response) => {
          return response;
        })

        .catch(axiosErrorHandler)
        .catch((error) => {
          console.error(error);
          return Promise.reject({
            description:
              'Something went wrong when parsing response from saved query detail.',
            status: 500
          });
        });
    },
    {
      enabled: !!id,
      retry: 1
    }
  );
};

export const createSavedQuery = (
  body: SavedQueryCreateParams
): Promise<any> => {
  const url = `${process.env.REACT_APP_MIA_URL}/v1/saved_query`;
  const cancelToken = CancelToken.source();

  return requester
    .post(
      url,
      {
        ...body
      },
      {
        cancelToken: cancelToken.token
      }
    )
    .then(abbaResponseHandler, axiosErrorHandler);
};

export const editSavedQuery = (
  savedQueryId: string,
  updateColumns: any
): Promise<any> => {
  const url = `${process.env.REACT_APP_MIA_URL}/v1/saved_query/${savedQueryId}`;
  const cancelToken = CancelToken.source();

  return requester
    .post(
      url,
      {
        ...updateColumns
      },
      {
        cancelToken: cancelToken.token
      }
    )
    .then(abbaResponseHandler, axiosErrorHandler);
};

export const deleteSavedQuery = (id: string): Promise<any> => {
  const url = `${process.env.REACT_APP_MIA_URL}/v1/saved_query/${id}`;
  const cancelToken = CancelToken.source();

  return requester
    .delete(url, {
      cancelToken: cancelToken.token
    })
    .then(abbaResponseHandler, axiosErrorHandler);
};

import React from 'react';
import { Typography } from '@material-ui/core';

const ToolTipMessage = (count: number, minSeats: number, maxSeats: number) => {
  return count === minSeats ? (
    <Typography>
      You have reached the minimum number of users allowed for this plan
    </Typography>
  ) : count === maxSeats ? (
    <Typography>
      {/* {LIMIT_MAX_SEATS_MESSAGE} */}
      You have reached the maximum number of users allowed for this plan
    </Typography>
  ) : (
    ''
  );
};

export default ToolTipMessage;

import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import DeleteIcon from '@material-ui/icons/Delete';
import { parseISO } from 'date-fns';
import _ from 'lodash';

import {
  addUser as addUserRequest,
  removeUser as removeUserRequest,
  updateWorkspaceUser as updateWorkspaceUserRequest
} from '../../api/user';
import { GetWorkspaceResponse, useWorkspace } from '../../api/workspace';
import {
  roleToLabel,
  WorkspaceUser,
  WorkspaceUserRole
} from '../../models/user';
import StructuredErrorDisplay from '../../components/StructuredErrorDisplay';
import TablePaginationActions from '../../components/TablePaginationActions';
import { useSnackbar } from 'notistack';
import RoundedButton from './components/Buttons/RoundedButton';
import SquareButton from './components/Buttons/SquareButton';
import { APIRequestError } from '../../models/http';
import { useUser } from 'context/hooks/user';
import { User } from 'context/user';

const useStyles = makeStyles({
  customButton: {
    padding: '8px 10px',

    '&:disabled': {
      color: '#fff',
      backgroundColor: '#c4c8cc'
    }
  }
});

class UsersState {
  users: Array<WorkspaceUser>;
  constructor(users: Array<WorkspaceUser>) {
    this.users = users || [];
  }

  getUsers() {
    return this.users;
  }

  getNumSeats() {
    return this.users.length;
  }

  addUser(userObj: WorkspaceUser) {
    this.users = [...this.users, userObj];
    return _.cloneDeep(this);
  }

  removeUser(email: string) {
    this.users = _.filter(this.users, (u) => u.email !== email);
    return _.cloneDeep(this);
  }

  updateUser(userId: string, userObj: { role: WorkspaceUserRole }) {
    const idx = this.users.findIndex((user) => user.user_id === userId);
    if (idx >= 0) {
      this.users[idx] = {
        ...this.users[idx],
        ...userObj
      };
    }
    return _.cloneDeep(this);
  }
}

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const RoleDropdown = (props: {
  user: WorkspaceUser;
  disabled: boolean;
  usersState: UsersState;
  setUsersState: (u: UsersState) => void;
}) => {
  const { user, disabled, usersState, setUsersState } = props;

  const { user: userData } = useUser();
  const workspaceId = userData.getWorkspaceId();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  const updateRole = (user: WorkspaceUser, new_role: WorkspaceUserRole) => {
    if (user.role === new_role) {
      return;
    }

    const oldRole = user.role;
    setUsersState(usersState.updateUser(user.user_id, { role: new_role }));
    const promise = updateWorkspaceUserRequest(workspaceId, user.user_id, {
      role: new_role
    });

    promise
      .then(() => {
        return;
      })
      .catch((error: APIRequestError) => {
        // If there is an error, rollback.
        setUsersState(usersState.updateUser(user.user_id, { role: oldRole }));
        console.error(error);
      });
  };
  return (
    <>
      <Button
        disabled={disabled}
        variant="outlined"
        color="primary"
        endIcon={anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        onClick={handleClick}
        className={classes.customButton}>
        {roleToLabel(user.role)}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {['admin' as WorkspaceUserRole, 'user' as WorkspaceUserRole].map(
          (r) => (
            <MenuItem
              onClick={() => {
                updateRole(user, r);
                handleClose();
              }}>
              {roleToLabel(r)}
            </MenuItem>
          )
        )}
      </Menu>
    </>
  );
};

const RemoveUserButton = (props: {
  user: WorkspaceUser;
  disabled: boolean;
  usersState: UsersState;
  setUsersState: (a: UsersState) => void;
}) => {
  const { user: userData } = useUser();

  const workspaceId = userData.getWorkspaceId();
  const { user, disabled, usersState, setUsersState } = props;
  const [open, setOpen] = useState(false);

  const removeUser = () => {
    setUsersState(usersState.removeUser(user.email));
    const promise = removeUserRequest(workspaceId, user.email);
    promise.catch((error: APIRequestError) => {
      setUsersState(usersState.addUser(user));
      console.error(error);
    });
  };

  return (
    <Box width="100%">
      <IconButton
        disabled={disabled}
        onClick={() => {
          setOpen(true);
        }}>
        <DeleteIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}>
        <Paper
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            padding: '16px',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 400
          }}>
          <Typography variant="h6" gutterBottom>
            Delete User
          </Typography>
          <Box display="flex" marginTop={5} marginBottom={10}>
            <Typography>
              Are you sure you would like to remove user <b>{user.email}</b>{' '}
              from workspace?
            </Typography>
          </Box>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center">
            <Box marginRight={5}>
              <SquareButton
                color="#fff"
                backgroundColor="#ed2842"
                onClick={() => {
                  removeUser();
                }}
                value="Remove"
              />
            </Box>
            <Box>
              <SquareButton
                color="#000"
                backgroundColor="#c4c8cc"
                onClick={() => {
                  setOpen(false);
                }}
                value="Cancel"
              />
            </Box>
          </Grid>
        </Paper>
      </Modal>
    </Box>
  );
};

const InviteUserButton = (props: {
  user: User;
  usersState: UsersState;
  setUsersState: (u: UsersState) => void;
  disabled: boolean;
}) => {
  const { usersState, setUsersState, disabled, user } = props;
  const workspaceId = user.getWorkspaceId();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [admin, setAdmin] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeModal = () => {
    setOpen(false);
    setEmailValue('');
    setLoading(false);
  };

  const addUser = (email: string) => {
    const promise = addUserRequest(workspaceId, email, admin);
    setLoading(true);
    promise
      .then((response: any) => {
        setLoading(false);
        setUsersState(usersState.addUser(response as WorkspaceUser));
        closeModal();
      })
      .catch((error: APIRequestError) => {
        setLoading(false);
        enqueueSnackbar(`Failed to add user: ${error.description}`, {
          variant: 'error'
        });
        console.error(error);
      });
  };

  const validEmail = emailValue.length > 0 && validateEmail(emailValue);

  return (
    <Box>
      <RoundedButton
        onClick={() => {
          setOpen(true);
        }}
        disabled={disabled}
        value="Invite User"
      />
      <Modal
        open={open}
        onClose={() => {
          closeModal();
        }}>
        <Paper
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            padding: '16px',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 400
          }}>
          <Typography variant="h6" gutterBottom>
            Invite User
          </Typography>
          <Box display="flex" marginTop={5} marginBottom={10}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                error={!validEmail}
                helperText={
                  !validEmail ? 'Please provide a valid email address.' : ''
                }
                value={emailValue}
                onChange={(event) => {
                  setEmailValue(event.target.value);
                }}
                placeholder={"Enter an email of the user you'd like to invite."}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4} container justify="flex-end">
              <Button
                style={{ maxHeight: 35 }}
                variant="outlined"
                color="primary"
                endIcon={anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                onClick={handleClick}>
                {admin ? 'Admin' : 'User'}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    setAdmin(true);
                    handleClose();
                  }}>
                  Admin
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAdmin(false);
                    handleClose();
                  }}>
                  User
                </MenuItem>
              </Menu>
            </Grid>
          </Box>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center">
            <Box marginRight={5}>
              {(() => {
                if (loading) {
                  return (
                    <SquareButton
                      disabled={true}
                      value={
                        <CircularProgress style={{ color: '#fff' }} size={20} />
                      }
                    />
                  );
                } else {
                  return (
                    <SquareButton
                      disabled={!validEmail}
                      onClick={() => {
                        addUser(emailValue);
                      }}
                      value="Invite"
                    />
                  );
                }
              })()}
            </Box>
            <Box>
              <SquareButton
                color="#000"
                backgroundColor="#c4c8cc"
                onClick={() => {
                  closeModal();
                }}
                value="Cancel"
              />
            </Box>
          </Grid>
        </Paper>
      </Modal>
    </Box>
  );
};

const isValidDate = (d: unknown) => {
  return d instanceof Date && !isNaN(d.getTime());
};

const LastLoggedInAt = (props: { workspaceUser: WorkspaceUser }) => {
  const { workspaceUser } = props;
  const lastAuthenticatedDate = parseISO(workspaceUser.authed_at);

  if (isValidDate(lastAuthenticatedDate)) {
    return (
      <Typography>{lastAuthenticatedDate.toLocaleString('en-US')}</Typography>
    );
  } else if (workspaceUser.invite_id) {
    return <Chip label="Invite Pending" />;
  } else {
    return <Typography>N/A</Typography>;
  }
};

const WorkspaceUsers = (props: {
  user: User;
  workspaceResponse: GetWorkspaceResponse | undefined;
}) => {
  const { user, workspaceResponse } = props;

  const isAdmin = user.isWorkspaceAdmin();
  const [usersState, setUsersState] = useState<UsersState>(new UsersState([]));

  useEffect(() => {
    if (workspaceResponse?.users) {
      setUsersState(new UsersState(workspaceResponse?.users));
    }
  }, [workspaceResponse]);

  const [filterText, setFilterText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const users = usersState.getUsers();
  const lowercaseFilterText = filterText.toLowerCase();
  const filteredUsers =
    lowercaseFilterText.length > 0
      ? users.filter((u: WorkspaceUser) => {
          return u && u.email.toLowerCase().includes(lowercaseFilterText);
        })
      : users;

  const workspaceSeatCount = workspaceResponse?.workspace.seat_count;
  const canInviteUser =
    isAdmin &&
    (workspaceSeatCount ? usersState.getNumSeats() < workspaceSeatCount : true);

  return (
    <Card>
      <CardContent
        style={{
          paddingBottom: 0,
          paddingLeft: 25,
          paddingRight: 25
        }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ marginBottom: 20 }}>
          <Box>
            <Typography variant="h6">Users</Typography>
            <Typography color="textSecondary">Your workspace users</Typography>
            <Typography color="textSecondary">
              {workspaceSeatCount
                ? `${workspaceSeatCount} seats`
                : 'Unlimited seats'}
            </Typography>
          </Box>
          <Box>
            <InviteUserButton
              user={user}
              usersState={usersState}
              setUsersState={setUsersState}
              disabled={!canInviteUser}
            />
          </Box>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ marginBottom: 20 }}>
          <Box>
            {workspaceResponse?.workspace.seat_count
              ? `${usersState?.getNumSeats()} out of ${
                  workspaceResponse.workspace.seat_count
                } seats used.`
              : ''}
          </Box>
          <Box>
            <Box mr={2}>
              <TextField
                value={filterText}
                onChange={(event) => {
                  setFilterText(event.target.value);
                }}
                inputProps={{ style: { textAlign: 'right' } }}
                placeholder={'Search for user by email'}
                variant="outlined"
                size="small"
                style={{ minWidth: '250px' }}
              />
            </Box>
          </Box>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size="small" width={8} />
                <TableCell>Email</TableCell>
                <TableCell>Last Logged In</TableCell>
                <TableCell align="right">Role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((workspaceUser) => {
                  return (
                    <TableRow key={workspaceUser.id}>
                      <TableCell size="small" width={8}>
                        <RemoveUserButton
                          user={workspaceUser}
                          disabled={!isAdmin}
                          usersState={usersState}
                          setUsersState={setUsersState}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>{workspaceUser.email}</Typography>
                      </TableCell>
                      <TableCell>
                        <LastLoggedInAt workspaceUser={workspaceUser} />
                      </TableCell>
                      <TableCell>
                        <Box style={{ textAlign: 'right' }}>
                          <RoleDropdown
                            user={workspaceUser}
                            disabled={
                              !isAdmin || workspaceUser.role === 'account_owner'
                            }
                            usersState={usersState}
                            setUsersState={setUsersState}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </CardContent>
    </Card>
  );
};

const WorkspaceSettingsUsers = () => {
  const { user, isLoading: userLoading } = useUser();

  const workspaceId = user && !userLoading ? user.getWorkspaceId() : null;
  const { data: workspaceResponse, isLoading, error } = useWorkspace(
    workspaceId
  );

  if (error && error.status) {
    return (
      <StructuredErrorDisplay
        response={{
          status_code: error.status,
          response: error.description
        }}
      />
    );
  } else if (userLoading || isLoading) {
    return (
      <Box p={2} display="flex" width="100%" justifyContent="center">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Box width="100%" paddingX={10} paddingBottom={10}>
      <Box paddingX={10} paddingBottom={10}>
        <WorkspaceUsers user={user} workspaceResponse={workspaceResponse} />
      </Box>
    </Box>
  );
};

export default WorkspaceSettingsUsers;

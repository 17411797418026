import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { useUser } from 'context/hooks/user';
import { Redirect, useHistory } from 'react-router-dom';
import { useLoginLocalState } from './useLoginLocalState';

const Callback = () => {
  const { user, isLoading, error } = useUser();
  const history = useHistory();

  const {
    workspaceId,
    productSlug,
    redirectUrl,
    period,
    sellerInviteId,
    trialPeriod
  } = useLoginLocalState();

  const isNewWorkspace = new URLSearchParams(history.location.search).has(
    'new'
  );

  if (isLoading) {
    return (
      <Grid container alignItems="center" justify="center">
        <CircularProgress size="40px" color="secondary" />
      </Grid>
    );
  } else if (error) {
    return <Redirect to={`/error?=${error.description}`} />;
  }

  // Not verified, will proceed to verify email page
  if (!user.getIsEmailVerified()) {
    return <Redirect to="/auth/verify" />;
  }

  if (user.getIsNameVerified()) {
    if (sellerInviteId) {
      // Create workspace through seller invite
      return (
        <Redirect
          to={`/workspace/create?product_slug=${productSlug}&seller_invite_id=${sellerInviteId}&trial_period=${trialPeriod}`}
        />
      );
    } else if (user.getTotalWorkspaces() < 1) {
      // If profile is verified but no workspaces found
      return <Redirect to="/workspace/create" />;
    }
  } else {
    if (sellerInviteId) {
      // Continue signup and invitation through seller invite
      return (
        <Redirect
          to={`/auth/signup-continue?product_slug=${productSlug}&seller_invite_id=${sellerInviteId}&trial_period=${trialPeriod}`}
        />
      );
    } else if (!workspaceId) {
      // Continue signup, no invitation
      return (
        <Redirect
          to={`/auth/signup-continue?product_slug=${productSlug}&period=${period}`}
        />
      );
    } else if (user.getTotalWorkspaces() > 0 && workspaceId) {
      // Continue signup and invitation through workspace given id
      return (
        <Redirect to={`/auth/signup-invite?workspace_id=${workspaceId}`} />
      );
    }
  }

  // Go to redirect URL if found
  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  // If selected workspace is inactive (not active or trialing)
  if (
    workspaceId &&
    user.getWorkspace() &&
    workspaceId === user.getWorkspaceId() &&
    !user.getIsWorkspaceActive()
  ) {
    if (user.isWorkspaceAdmin()) {
      return <Redirect to="/settings/workspace/billing" />;
    }

    return <Redirect to="/lockout" />;
  }

  // Go to homepage with ?new parameter to open invite user modal
  if (isNewWorkspace) {
    return <Redirect to={`/${user.getWorkspaceHomeRoute()}?new`} />;
  }

  // Go to homepage if workspace id found
  if (workspaceId) {
    return <Redirect to={`/${user.getWorkspaceHomeRoute()}`} />;
  }

  // Go to workspace select if no workspace id
  if (!workspaceId) {
    return <Redirect to="/workspace-select" />;
  }
};

export default Callback;

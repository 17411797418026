import React from 'react';
import styled from 'styled-components/macro';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import { rgba } from 'polished';
import DropdownPopover from '../../components/Filters/DropdownPopover';

import {
  Avatar,
  Badge,
  Box,
  MenuList,
  Link as MuiLink,
  MenuItem,
  CircularProgress,
  IconButton as MuiIconButton
} from '@material-ui/core';
import { useUser } from 'context/hooks/user';
import { MIA_LOGOUT } from 'constants/links';
import { useLoginLocalState } from 'pages/accounts/useLoginLocalState';
import {
  removeAnnouncementsVersionKey,
  removeChartTutorial,
  removeFirstTimeLoggedIn,
  removeAddItemTutorial
} from 'components/Announcements/utils';

const InternalLink = styled(ReactRouterLink)`
  color: inherit;
  text-decoration: inherit;
`;

const ExternalLink = styled(MuiLink)`
  color: inherit;
  text-decoration: inherit;

  &:hover {
    text-decoration: inherit;
  }
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 18px;
    height: 18px;
  }
`;

const DropdownBox = styled(Box)`
  padding: 0 ${(props) => props.theme.spacing(4)}px;
  margin-left: ${(props) => props.theme.spacing(4)}px;
  border-left: ${(props) =>
    props.showLeftBorder
      ? `1px solid ${rgba(props.theme.palette.primary.main, 0.3)}`
      : 'none'};
`;

const UserBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

// List of URL paths that removes the border left
const removeBorderLeft = [
  '/workspace-select',
  '/lockout',
  '/demo-booked',
  '/workspace/create',
  '/settings/workspace',
  '/settings/profile'
];

const RenderDropdownItems = (dropdownOptions) => {
  const { setWorkspaceId } = useLoginLocalState();

  return (
    <>
      {dropdownOptions.items.includes('settings') && (
        <InternalLink to="/settings/profile">
          <MenuItem>Settings</MenuItem>
        </InternalLink>
      )}
      {dropdownOptions.items.includes('select-workspace') && (
        <InternalLink to="/workspace-select">
          <MenuItem>Select Workspace</MenuItem>
        </InternalLink>
      )}
      {dropdownOptions.items.includes('logout') && (
        <ExternalLink
          href={MIA_LOGOUT}
          onClick={() => [
            setWorkspaceId(''),
            removeAnnouncementsVersionKey(),
            removeFirstTimeLoggedIn(),
            removeChartTutorial(),
            removeAddItemTutorial()
          ]}>
          <MenuItem>Sign out</MenuItem>
        </ExternalLink>
      )}
    </>
  );
};

const UserDropdown = ({ showLeftBorder = true }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user, isLoading: loading } = useUser();

  const handleOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Dynamic setting of dropdown paths depending on URL
  const location = useLocation();
  const pathname = location.pathname;
  const dropdownOptions = React.useMemo(() => {
    switch (pathname) {
      case '/lockout':
      case '/demo-booked':
      case '/workspace-select':
        return ['logout'];
      case '/workspace/create':
        return ['select-workspace', 'logout'];
      default:
        return ['settings', 'select-workspace', 'logout'];
    }
  }, [pathname]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <DropdownBox
      showLeftBorder={showLeftBorder}
      style={{
        borderLeft: removeBorderLeft.includes(pathname) ? 0 : {}
      }}>
      <UserBadge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        variant="dot">
        <IconButton onClick={handleOpen} color="inherit">
          <Avatar
            alt={user.getDisplayName() || user.getEmail()}
            src={user.getPhotoURL()}
          />
        </IconButton>
      </UserBadge>
      <DropdownPopover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuList>
          <RenderDropdownItems items={dropdownOptions} />
        </MenuList>
      </DropdownPopover>
    </DropdownBox>
  );
};

export default UserDropdown;

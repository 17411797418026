import { PlanName } from './user';

export const DefaultUpgradePlanColor = '#974cee';

export const upgradePlanColor = {
  starter: DefaultUpgradePlanColor,
  team: '#49d88a',
  business: '#f52544',
  music_pro: '7d2ddb',
  bulk_data: '#7d2ddb',
  enterprise: '#7d2ddb'
};

export enum PlanPeriod {
  Monthly = 'monthly',
  Yearly = 'yearly'
}

export interface PlanState {
  period: PlanPeriod;
  plan: PlanName;
  count: number;
  rate: number;
  isPlanSelected: boolean;
}

export const getPeriodString = (periodType: PlanPeriod): string => {
  switch (periodType) {
    case PlanPeriod.Monthly:
      return 'month';
    case PlanPeriod.Yearly:
      return 'year';
    default:
      throw new Error('Invalid period type');
  }
};

import React from 'react';

const VerifiedIcon = (props) => {
  const size = props.size || 16;
  return (
    <img
      style={{ verticalAlign: 'text-bottom' }}
      alt="verified icon"
      src="/static/img/verified.svg"
      height={size}
      width={size}
    />
  );
};

export default VerifiedIcon;

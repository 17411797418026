import { BillingStatus, Workspace } from '../../models/workspaceSelect';

const DefaultSubscriptionStatusColor = '#808080';
export const workspaceStatusColor = (w: Workspace) => {
  switch (w.billing_status) {
    case BillingStatus.Active:
      return '#00d88a';
    case BillingStatus.Canceled:
      return '#000000';
    case BillingStatus.PastDue:
      return '#ff2543';
    case BillingStatus.Trialing:
      return w.billing_self_serve_trial ? '#e4dbff' : '#7d2ddb';
    default:
      return DefaultSubscriptionStatusColor;
  }
};

import { ObjectModel } from 'objectmodel';

const OptionalString = [String, undefined, null];

export const CollectionItem = new ObjectModel({
  id: OptionalString,
  collectionId: OptionalString,
  itemType: [
    'profile',
    'profile_name',
    'tag',
    'sound',
    'artist',
    'song',
    'album',
    'item'
  ],

  // These columns should depend on the item_type above. Exactly one should be filled in.
  profileId: OptionalString,
  profileUniqueId: OptionalString,
  tagTitle: OptionalString,
  soundId: OptionalString,
  artistId: OptionalString,
  songId: OptionalString,
  albumId: OptionalString,
  videoId: OptionalString
});

export const fromDuaLipa = (item) => {
  return CollectionItem({
    id: item.id,
    collectionId: item.collection_id,
    itemType: item.item_type,

    profileId: item.profile_id,
    profileUniqueId: item.profile_unique_id,
    tagTitle: item.tag_title,
    soundId: item.sound_id,
    artistId: item.artist_id,
    songId: item.song_id,
    albumId: item.album_id,
    videoId: item.video_id
  });
};

export const exportCollectionItem = (collectionItem) => {
  return {
    collection_id: collectionItem.collectionId,
    item_type: collectionItem.itemType,

    profile_id: collectionItem.profileId,
    profile_unique_id: collectionItem.profileUniqueId,
    tag_title: collectionItem.tagTitle,
    sound_id: collectionItem.soundId,
    artist_id: collectionItem.artistId,
    song_id: collectionItem.songId,
    album_id: collectionItem.albumId,
    video_id: collectionItem.videoId
  };
};

export const exportBulkCollectionItem = (collectionItem) => {
  return {
    item_type: collectionItem.itemType,

    profile_id: collectionItem.profileId,
    profile_unique_id: collectionItem.profileUniqueId,
    tag_title: collectionItem.tagTitle,
    sound_id: collectionItem.soundId,
    artist_id: collectionItem.artistId,
    song_id: collectionItem.songId,
    album_id: collectionItem.albumId,
    video_id: collectionItem.videoId
  };
};

export const idForItem = (collectionItem) => {
  if (collectionItem.itemType === 'profile') {
    return collectionItem.profileId;
  } else if (collectionItem.itemType === 'profile_name') {
    return collectionItem.profileUniqueId;
  } else if (collectionItem.itemType === 'tag') {
    return collectionItem.tagTitle;
  } else if (collectionItem.itemType === 'sound') {
    return collectionItem.soundId;
  } else if (collectionItem.itemType === 'artist') {
    return collectionItem.artistId;
  } else if (collectionItem.itemType === 'song') {
    return collectionItem.songId;
  } else if (collectionItem.itemType === 'album') {
    return collectionItem.albumId;
  } else if (collectionItem.itemType === 'item') {
    return collectionItem.videoId;
  } else {
    throw new Error(collectionItem.itemType);
  }
};

// Convert the item to a "search result" so that it can be used in parts of the stack that rely on search (e.g. Discover >> Videos).
export const toSearchResult = (collectionItem) => {
  if (collectionItem.itemType === 'profile') {
    return {
      entity_type: 'tiktok_profile',
      id: collectionItem.profileId
    };
  } else if (collectionItem.itemType === 'profile_name') {
    return {
      entity_type: 'tiktok_profile',
      primary: collectionItem.profileUniqueId
    };
  } else if (collectionItem.itemType === 'tag') {
    return {
      entity_type: 'tiktok_tag',
      id: collectionItem.tagTitle
    };
  } else if (collectionItem.itemType === 'sound') {
    return {
      entity_type: 'tiktok_sound',
      id: collectionItem.soundId
    };
  } else if (collectionItem.itemType === 'artist') {
    return {
      entity_type: 'spotify_artist',
      id: collectionItem.artistId
    };
  } else if (collectionItem.itemType === 'song') {
    return {
      entity_type: 'spotify_track',
      id: collectionItem.songId
    };
  } else if (collectionItem.itemType === 'album') {
    return {
      entity_type: 'spotify_album',
      id: collectionItem.albumId
    };
  } else if (collectionItem.itemType === 'item') {
    return {
      entity_type: 'tiktok_item',
      id: collectionItem.videoId
    };
  } else {
    return null;
    // throw new Errorrror(collectionItem.itemType);
  }
};

import { CollectionItem } from './collection';

export enum PlanName {
  Starter = 'starter',
  Team = 'team',
  Business = 'business',
  Enterprise = 'enterprise',
  Music = 'music',
  MusicPro = 'music_pro',
  BulkData = 'bulk_data'
}

export const planNameToLabel = (planName: PlanName) => {
  switch (planName) {
    case PlanName.Starter:
      return 'Starter';
    case PlanName.Team:
      return 'Team';
    case PlanName.Business:
      return 'Business';
    case PlanName.Enterprise:
      return 'Enterprise';
    case PlanName.Music:
      return 'Music';
    case PlanName.MusicPro:
      return 'Music Pro';
    case PlanName.BulkData:
      return 'Bulk Data';
  }
};

export interface Limits {
  collections: number;
  campaigns: number;
  saved_queries: number;
}

export interface Workspace {
  stripe_id: string;
  internal_id: string;
  name: string;
  account_owner: string;
  subdomain: string | null;
  home_screen: 'default' | 'catalog';
}

export type WorkspaceUserRole =
  | 'user'
  | 'admin'
  | 'billing_admin'
  | 'account_owner';

export interface WorkspaceUser {
  id: string;
  user_id: string;
  invite_id: string;
  email: string;
  role: WorkspaceUserRole;
  authed_at: string;
}

export const roleToLabel = (role: WorkspaceUserRole) => {
  switch (role) {
    case 'user':
      return 'User';
    case 'admin':
      return 'Admin';
    case 'billing_admin':
      return 'Billing Admin';
    case 'account_owner':
      return 'Account Owner';
  }
};

export interface Subscription {
  id: string;
  created_at: string;
  customer_id: string;
  status:
    | 'active'
    | 'canceled'
    | 'incomplete'
    | 'incomplete_expired'
    | 'past_due'
    | 'trialing'
    | 'unpaid';
  self_serve_trial: boolean;
  current_period_start: string;
  current_period_end: string;
  cancel_at_period_end: boolean;
  collection_method: string;
  days_until_due?: number;
  billing_interval_count: bigint;
  billing_interval: 'month' | 'quarter' | 'year';
  product_id: string;
  plan_name: PlanName;
  seat_count?: bigint;
  upcoming_invoice_total?: bigint;
}

export interface Features {
  id: string;
  billing_override: boolean;
  alerting: boolean;
  catalog: boolean;
  hourly_tracking: boolean;
  workspace: boolean;
  api: boolean;
  data_dumps: boolean;
  music_discovery: boolean;
  music_links: boolean;
  sound_discovery: boolean;
  creator_discovery: boolean;
  tag_discovery: boolean;
  benchmarking: boolean;
  video_discovery: boolean;
  dashboard: boolean;
  granular_demographics: boolean;
  youtube_data_dumps: boolean;
  full_access_data_dumps: boolean;
  campaigns: boolean;
  video_discovery_contact_links: boolean;
  saved_queries: boolean;
  notifications: boolean;
}

export type EmailFrequency = 'daily' | 'weekly' | 'off';

export type HomeScreen = 'home' | 'catalogs';

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  picture: string;
  digest_frequency?: EmailFrequency;
  alert_frequency?: EmailFrequency;
  authed_at: string;
  email_verified: boolean;
  name_verified: boolean;
}

export interface UserResponse {
  user: User;
  workspace_user: {
    id: string;
    admin: boolean;
  };
  workspace: Workspace;
  subscription: Subscription;
  limits: Limits;
  features: Features;
  favorites: {
    id: string;
    items: CollectionItem[];
  };
}

import axios, { CancelTokenSource } from 'axios';
import { miaResponseHandler, structuredErrorHandler } from './util';
import { requester } from './index';
import {
  MIA_BILLING_OVERVIEW_LINK,
  MIA_BILLING_REACTIVATE_LINK,
  MIA_BILLING_CANCEL_LINK
} from '../constants/links';

const CancelToken = axios.CancelToken;

/**
 * Get Billing overview with current plan and past invoices
 */
export const getBillingOverview = (): [Promise<any>, CancelTokenSource] => {
  const cancelToken = CancelToken.source();
  return [
    requester
      .get(MIA_BILLING_OVERVIEW_LINK, {
        cancelToken: cancelToken.token
      })
      .then(miaResponseHandler, structuredErrorHandler),
    cancelToken
  ];
};

/**
 * Cancels plan and requires a string message reason
 */
export const cancelPlanRequest = (
  reason: string
): [Promise<any>, CancelTokenSource] => {
  const cancelToken = CancelToken.source();
  return [
    requester
      .post(
        MIA_BILLING_CANCEL_LINK,
        {
          reason
        },
        {
          cancelToken: cancelToken.token
        }
      )
      .then(miaResponseHandler, structuredErrorHandler),
    cancelToken
  ];
};

/**
 * Reactivates plan
 */
export const reactivatePlanRequest = (): [Promise<any>, CancelTokenSource] => {
  const cancelToken = CancelToken.source();
  return [
    requester
      .post(MIA_BILLING_REACTIVATE_LINK, {
        cancelToken: cancelToken.token
      })
      .then(miaResponseHandler, structuredErrorHandler),
    cancelToken
  ];
};

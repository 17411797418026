import React from 'react';

import async from '../components/Async';

import {
  Search as SearchIcon,
  Home as HomeIcon,
  BarChart2 as BarChartIcon,
  Eye as EyeIcon,
  Settings as SettingsIcon,
  Bookmark as BookmarkIcon,
  Star as StarIcon,
  List as ListIcon
} from 'react-feather';
import { preAuthRoutes } from './auth';

import MusicNoteIcon from '@material-ui/icons/MusicNote';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { NotificationsActive } from '@material-ui/icons';
import CampaignIcon from '../components/Icons/CampaignIcon';
import DataUsageIcon from '@material-ui/icons/DataUsage';

// Guards
const AuthGuard = async(() => import('../layouts/components/AuthGuard'));

// Trendpop routes
const Home = async(() => import('../pages/home'));
const Favorites = async(() => import('../pages/collections/favorites'));
const DiscoverCreators = async(() => import('../pages/discover/Creators'));
const DiscoverSounds = async(() => import('../pages/discover/Sounds'));
const DiscoverTags = async(() => import('../pages/discover/Tags'));
const DiscoverVideos = async(() => import('../pages/discover/Videos'));
const DiscoverSongs = async(() => import('../pages/discover/Songs'));
const DiscoverArtists = async(() => import('../pages/discover/Artists'));
const DiscoverAlbums = async(() => import('../pages/discover/Albums'));
const Detail = async(() => import('../pages/detail'));
const Mentions = async(() => import('../pages/mentions'));
const Catalog = async(() => import('../pages/catalog'));
const Collections = async(() => import('../pages/collections'));
const Campaigns = async(() => import('../pages/campaigns'));
const ProfileSettings = async(() =>
  import('../pages/settings/ProfileSettings')
);
const WorkspaceSettings = async(() =>
  import('../pages/settings/WorkspaceSettings')
);
const WorkspaceSettingsApiKeys = async(() =>
  import('../pages/settings/WorkspaceSettingsApiKeys')
);
const WorkspaceSettingsUsers = async(() =>
  import('../pages/settings/WorkspaceSettingsUsers')
);
const BulkDataSnapshots = async(() => import('../pages/bulk-data/Snapshots'));
const WorkspaceSettingsBilling = async(() =>
  import('../pages/settings/WorkspaceSettingsBilling')
);
const SavedSearches = async(() => import('../pages/saved-searches'));
const Integrations = async(() => import('../pages/notifications/Integrations'));
const NotificationCollections = async(() =>
  import('../pages/notifications/Collections')
);
const NotificationsCatalogs = async(() =>
  import('../pages/notifications/Catalogs')
);

export const homeRoute = {
  id: 'Home',
  path: '/home',
  icon: <HomeIcon />,
  containsHome: true,
  component: Home,
  guard: AuthGuard
};

export const favorites = {
  id: 'Favorites',
  path: '/favorites',
  icon: <StarIcon />,
  omitHeader: true,
  component: Favorites,
  guard: AuthGuard
};

const catalogsRoute = {
  id: 'Catalogs',
  path: '/catalogs/*',
  sidebarPath: '/catalogs',
  icon: <MusicNoteIcon />,
  containsHome: false,
  omitHeader: true,
  component: Catalog,
  guard: AuthGuard,
  enabledForUser: (user) => {
    return user && user.featureCatalogEnabled();
  }
};

export const collections = {
  id: 'Collections',
  path: '/collections/*',
  sidebarPath: '/collections',
  icon: <ListIcon />,
  omitHeader: true,
  component: Collections,
  guard: AuthGuard,
  enabledForUser: (user) => {
    return user && user.featureWorkspaceEnabled();
  }
};

const campaignsRoute = {
  id: 'Campaigns',
  path: '/campaigns/*',
  sidebarPath: '/campaigns',
  icon: <CampaignIcon />,
  containsHome: false,
  component: Campaigns,
  guard: AuthGuard,
  omitHeader: true,
  enabledForUser: (user) => {
    return user && user.featureCampaignsEnabled();
  }
};

const discoverRoute = {
  id: 'Discover',
  path: '/discover',
  icon: <SearchIcon />,
  containsHome: false,
  enabledForUser: (user) => {
    return (
      discoverRoute.children.filter(
        (child) => !child.enabledForUser || child.enabledForUser(user)
      ).length > 0
    );
  },
  children: [
    {
      path: '/discover/creators',
      name: 'Creators',
      component: DiscoverCreators,
      guard: AuthGuard,
      enabledForUser: (user) => {
        return user && user.featureCreatorDiscoveryEnabled();
      }
    },
    {
      path: '/discover/sounds',
      name: 'Sounds',
      component: DiscoverSounds,
      guard: AuthGuard,
      enabledForUser: (user) => {
        return user && user.featureSoundDiscoveryEnabled();
      }
    },
    {
      path: '/discover/tags',
      name: 'Hashtags',
      component: DiscoverTags,
      guard: AuthGuard,
      enabledForUser: (user) => {
        return user && user.featureTagDiscoveryEnabled();
      }
    },
    {
      path: '/discover/videos',
      name: 'Videos',
      component: DiscoverVideos,
      guard: AuthGuard,
      enabledForUser: (user) => {
        return user && user.featureVideoDiscoveryEnabled();
      }
    },
    {
      path: '/discover/songs',
      name: 'Songs',
      component: DiscoverSongs,
      guard: AuthGuard,
      enabledForUser: (user) => {
        return user && user.featureMusicDiscoveryEnabled();
      }
    },
    {
      path: '/discover/artists',
      name: 'Artists',
      component: DiscoverArtists,
      guard: AuthGuard,
      enabledForUser: (user) => {
        return user && user.featureMusicDiscoveryEnabled();
      }
    },
    {
      path: '/discover/albums',
      name: 'Albums',
      component: DiscoverAlbums,
      guard: AuthGuard,
      enabledForUser: (user) => {
        return user && user.featureMusicDiscoveryEnabled();
      }
    }
  ],
  component: null,
  guard: AuthGuard
};

const savedSearches = {
  id: 'Saved Searches',
  path: '/saved-searches/*',
  sidebarPath: '/saved-searches',
  icon: <BookmarkIcon />,
  component: SavedSearches,
  guard: AuthGuard,
  enabledForUser: (user) => {
    return user && user.featureSavedQueriesEnabled();
  }
};

const detailRoute = {
  id: 'Report',
  path: '/detail/*',
  sidebarPath: '/detail',
  icon: <BarChartIcon />,
  containsHome: false,
  component: Detail,
  omitHeader: true,
  guard: AuthGuard
};

const mentionsRoute = {
  id: 'Benchmarking',
  path: '/mentions',
  icon: <EyeIcon />,
  containsHome: false,
  component: Mentions,
  guard: AuthGuard,
  enabledForUser: (user) => {
    return user && user.featureBenchmarkingEnabled();
  }
};

const notifications = {
  id: 'Notifications',
  path: '/notifications/',
  icon: <NotificationsActive />,
  enabledForUser: (user) => {
    return (
      notifications.children.filter(
        (child) => !child.enabledForUser || child.enabledForUser(user)
      ).length > 0
    );
  },
  children: [
    {
      path: '/notifications/integrations',
      name: 'Integrations',
      component: Integrations,
      guard: AuthGuard,
      enabledForUser: (user) => {
        return (
          user &&
          user.featureNotificationsEnabled() &&
          user.isWorkspaceAdmin() &&
          user.featureCatalogEnabled()
        );
      }
    },
    {
      path: '/notifications/collections',
      name: 'Collections',
      component: NotificationCollections,
      guard: AuthGuard,
      enabledForUser: (user) => {
        return (
          user &&
          user.featureWorkspaceEnabled() &&
          user.featureNotificationsEnabled()
        );
      }
    },
    {
      path: '/notifications/catalogs',
      name: 'Catalogs',
      component: NotificationsCatalogs,
      guard: AuthGuard,
      enabledForUser: (user) => {
        return (
          user &&
          user.featureCatalogEnabled() &&
          user.featureNotificationsEnabled()
        );
      }
    }
  ]
};

const bulkDataRoute = {
  id: 'Bulk Data',
  path: '/bulk-data/*',
  icon: <DataUsageIcon />,
  containsHome: false,
  guard: AuthGuard,
  enabledForUser: (user) => {
    return user && user.featureDataDumpsEnabled();
  },
  children: [
    {
      id: 'Snapshots',
      path: '/bulk-data/snapshots',
      name: 'Snapshots',
      component: BulkDataSnapshots,
      guard: AuthGuard
    }
  ]
};

export const profileSettingsRoute = {
  id: 'Profile Settings',
  path: '/settings/profile',
  name: 'Profile Settings',
  icon: <SettingsIcon />,
  component: ProfileSettings,
  guard: AuthGuard
};

const workspaceSettingsRoute = {
  id: 'Workspace Settings',
  path: '/settings/workspace',
  name: 'Workspace Settings',
  icon: <SupervisorAccountIcon />,
  component: WorkspaceSettings,
  children: [
    {
      id: 'Workspace',
      path: '/settings/workspace',
      name: 'Workspace',
      component: WorkspaceSettings,
      guard: AuthGuard
    },
    {
      id: 'Users',
      path: '/settings/workspace/users',
      name: 'Users',
      component: WorkspaceSettingsUsers,
      guard: AuthGuard
    },
    {
      id: 'API Keys',
      path: '/settings/workspace/api-keys',
      name: 'API Keys',
      component: WorkspaceSettingsApiKeys,
      guard: AuthGuard,
      // Only enable developers route if the dashboard is enabled. Otherwise, "home" will be developers.
      enabledForUser: (user) => {
        return user && user.featureDeveloperEnabled();
      }
    },
    {
      id: 'Billing',
      path: '/settings/workspace/billing',
      name: 'Billing',
      component: WorkspaceSettingsBilling,
      guard: AuthGuard,
      isAdminOnly: true
    }
  ]
};

export const separator = {
  isSeparator: true
};

export const notificationSeparator = Object.assign({}, separator, {
  enabledForUser: notifications.enabledForUser
});

export const bulkDataSeparator = Object.assign({}, separator, {
  enabledForUser: bulkDataRoute.enabledForUser
});

export const appRoutes = () => [
  homeRoute,
  favorites,
  catalogsRoute,
  collections,
  campaignsRoute,
  separator,
  discoverRoute,
  savedSearches,
  detailRoute,
  mentionsRoute,
  notificationSeparator,
  notifications,
  bulkDataSeparator,
  bulkDataRoute
];

// TODO(yoavz): Do these require dependence on the user object?
export const dashboardSettingsRoutes = () => [
  profileSettingsRoute,
  workspaceSettingsRoute
];

export const publicFacingRoutes = () => [preAuthRoutes];

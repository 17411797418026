import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ServerFailureIcon } from './Icons';

const InternalErrorDisplay = (props) => {
  const { response } = props;
  const errMsg =
    response.status_code === 503
      ? 'Our servers are currently under heavy load'
      : 'Unknown internal error';
  return (
    <Box align="center">
      <ServerFailureIcon />
      <Box my={2} />
      <Typography variant="h5" color="textSecondary">
        {errMsg}
      </Typography>
      <Box my={2} />
      <Typography color="textSecondary">
        Our team has been notified and are working on a fix! Please try again
        soon.
      </Typography>
    </Box>
  );
};

export default InternalErrorDisplay;

export enum RefreshEntityType {
  Creator = 'creator',
  Sound = 'sound',
  Tag = 'hashtag',
  Video = 'video'
}

export enum RefreshStageStatus {
  Pending = 0,
  Success = 1,
  NotFound = 2,
  Error = 3
}

export interface RefreshResponse {
  fetched: string; // date string fetched
  key: string;
  status: RefreshStageStatus;
}

export function backendResourceNameOf(type: RefreshEntityType): string {
  switch (type) {
    case RefreshEntityType.Creator:
      return 'profiles';
    case RefreshEntityType.Sound:
      return 'sounds';
    case RefreshEntityType.Tag:
      return 'hashtags';
    case RefreshEntityType.Video:
      return 'profiles'; // Profiles but will be appended by /videos from function call
  }
}

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Button,
  Grid,
  TextField,
  Link,
  CircularProgress,
  FormHelperText
} from '@material-ui/core';
import {
  TRENDPOP_BLACK_2,
  TRENDPOP_GREY_2,
  TRENDPOP_PURPLE_3,
  TRENDPOP_WHITE
} from '../../../constants/colors';
import { fetchPostRedirect } from '../util';
import {
  AUTH_CALLBACK_PATH,
  AUTH_WORKSPACE_SELECT,
  CALENDLY_UPSELL_LINK,
  MIA_WORKSPACES_LIST,
  MIA_WORKSPACE_SELF_SERVE_TRIAL,
  MIA_WORKSPACE_SELLER_INVITE,
  SUPPORT_EMAIL
} from 'constants/links';
import {
  MIN_BUSINESS_SEATS,
  MIN_STARTER_SEATS,
  MIN_TEAM_SEATS,
  MONTHLY_BUSINESS_PRICE,
  MONTHLY_DEFAULT_STARTER_PRICE,
  MONTHLY_STARTER_PRICE,
  MONTHLY_TEAM_PRICE,
  YEARLY_BUSINESS_PRICE,
  YEARLY_DEFAULT_BUSINESS_PRICE,
  YEARLY_DEFAULT_STARTER_PRICE,
  YEARLY_DEFAULT_TEAM_PRICE,
  YEARLY_STARTER_PRICE,
  YEARLY_TEAM_PRICE
} from 'constants/plan';
import { PlanName, planNameToLabel } from 'models/user';
import SelectPlanModalButton from '../sign-up/components/SelectPlanModalButton';
import PlanIcon from 'components/Icons/PlanIcon';
import { PlanPeriod, upgradePlanColor } from 'models/plan';
import { useLoginLocalState } from '../useLoginLocalState';
import { PopupModal } from 'react-calendly';
import { useUser } from 'context/hooks/user';
import DashboardUserDropdown from 'layouts/components/DashboardUserDropdown';
import {
  BusinessFeatureCardDescription,
  EnterpriseFeatureCardDescription,
  StarterFeatureCardDescription,
  TeamFeatureCardDescription
} from 'components/Plans/FeatureCardDescription';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh'
  },
  link: {
    fontWeight: 600,
    color: TRENDPOP_PURPLE_3
  },
  linkButton: {
    fontWeight: 600,
    textDecoration: 'underline'
  },
  submitButton: {
    height: 50,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  },
  bookDemoButton: {
    height: 50,
    borderRadius: 30,
    color: TRENDPOP_BLACK_2,
    backgroundColor: TRENDPOP_WHITE,
    border: `1px solid ${TRENDPOP_PURPLE_3}`,
    '&:hover': {
      backgroundColor: TRENDPOP_WHITE
    }
  },
  sideBox: {
    maxWidth: '100%',
    padding: theme.spacing(10)
  },
  logo: {
    position: 'relative',
    bottom: 15
  },
  imageContainer: {
    backgroundColor: TRENDPOP_GREY_2,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 20%',
    backgroundSize: '100%',
    objectFit: 'cover'
  },
  formContainer: {
    padding: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    maxWidth: '500px',
    width: '100%'
  }
}));

const WorkspaceCreate = () => {
  const classes = useStyles();
  const { user, isLoading } = useUser();

  const {
    period: paramPeriod,
    productSlug: paramProductSlug,
    seat: paramSeat,
    workspaceName: paramWorkspaceName,
    sellerInviteId: paramSellerInviteId,
    trialPeriod: paramTrialPeriod,
    setSellerInviteId: setParamSellerInviteId
  } = useLoginLocalState();

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const [planState, setPlanState] = useState({
    period: PlanPeriod.Monthly, // monthly, yearly
    plan: PlanName.Team, // default Team plan
    count: MIN_TEAM_SEATS,
    rate: MONTHLY_TEAM_PRICE * MIN_TEAM_SEATS,
    isPlanSelected: false
  });

  useEffect(() => {
    if (paramProductSlug && !paramSellerInviteId) {
      const selectedPlan = paramProductSlug as PlanName;

      const seatCount = paramSeat
        ? parseInt(paramSeat)
        : selectedPlan === PlanName.Starter
        ? MIN_STARTER_SEATS
        : PlanName.Team
        ? MIN_TEAM_SEATS
        : PlanName.Business
        ? MIN_BUSINESS_SEATS
        : 0;

      const selectedPrice =
        selectedPlan === PlanName.Starter
          ? paramPeriod && paramPeriod === PlanPeriod.Monthly
            ? MONTHLY_DEFAULT_STARTER_PRICE
            : YEARLY_DEFAULT_STARTER_PRICE
          : selectedPlan === PlanName.Team
          ? paramPeriod && paramPeriod === PlanPeriod.Monthly
            ? MONTHLY_TEAM_PRICE
            : YEARLY_DEFAULT_TEAM_PRICE
          : selectedPlan === PlanName.Business
          ? paramPeriod && paramPeriod === PlanPeriod.Monthly
            ? MONTHLY_BUSINESS_PRICE
            : YEARLY_DEFAULT_BUSINESS_PRICE
          : 0;

      const rate =
        paramPeriod === PlanPeriod.Monthly
          ? selectedPlan === PlanName.Starter && seatCount > MIN_STARTER_SEATS
            ? selectedPrice +
              (seatCount - MIN_STARTER_SEATS) * MONTHLY_STARTER_PRICE
            : selectedPrice * seatCount
          : selectedPlan === PlanName.Starter && seatCount > MIN_STARTER_SEATS
          ? selectedPrice +
            (seatCount - MIN_STARTER_SEATS) * YEARLY_STARTER_PRICE
          : selectedPlan === PlanName.Team && seatCount > MIN_TEAM_SEATS
          ? selectedPrice + (seatCount - MIN_TEAM_SEATS) * YEARLY_TEAM_PRICE
          : selectedPlan === PlanName.Business && seatCount > MIN_BUSINESS_SEATS
          ? selectedPrice +
            (seatCount - MIN_BUSINESS_SEATS) * YEARLY_BUSINESS_PRICE
          : selectedPrice;

      setPlanState({
        period: (paramPeriod as PlanPeriod) || PlanPeriod.Monthly,
        plan: selectedPlan, // starter, team, business, enterprise
        count: seatCount, // number
        rate: rate, // rate
        isPlanSelected: true
      });
    }

    if (paramWorkspaceName) {
      setWorkspaceName(paramWorkspaceName);
    }
  }, [
    setPlanState,
    paramProductSlug,
    paramWorkspaceName,
    paramPeriod,
    paramSeat,
    paramSellerInviteId
  ]);

  // If seller invite id is detected
  useEffect(() => {
    if (paramSellerInviteId) {
      setPlanState((prevPlanState: any) => ({
        ...prevPlanState,
        period: (paramPeriod as PlanPeriod) || PlanPeriod.Monthly,
        plan: paramProductSlug as PlanName, // starter, team, business, enterprise
        count: 0, // manual override to be 0 on free trial
        rate: 0, // manual override to be 0 on free trial
        isPlanSelected: true
      }));

      // TODO(Jon) Seller invite flow: Find a better solution for clearing id after accepting invite
      // Clears seller invite id when unmounting to prevent loop when accepting invite
      return () => {
        setParamSellerInviteId('');
      };
    }
  }, [
    setPlanState,
    setParamSellerInviteId,
    paramProductSlug,
    paramSellerInviteId,
    paramPeriod
  ]);

  const handleCreateWorkspace = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const promise = fetchPostRedirect(
      MIA_WORKSPACES_LIST,
      {
        name: workspaceName,
        plan: planState.plan,
        period: planState.period,
        seat: planState.count,
        redirect: `${window.location.origin}${AUTH_CALLBACK_PATH}`,
        cancel_redirect: `${window.location.origin}${AUTH_WORKSPACE_SELECT}`
      },
      () => {
        setError('Error in creating workspace, please try again.');
      },
      `${window.location.origin}${AUTH_CALLBACK_PATH}`,
      true
    );

    promise.then(
      () => {
        setLoading(false);
      },
      (error: any) => {
        console.log(error);
        setLoading(false);
        setError(error.message);
      }
    );
  };

  const handleCreateWorkspaceSellerInvite = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const promise = fetchPostRedirect(
      MIA_WORKSPACE_SELLER_INVITE,
      {
        name: workspaceName,
        invite_id: paramSellerInviteId
      },
      () => {
        setError('Error in completing signing up, please try again.');
      },
      `${window.location.origin}${AUTH_CALLBACK_PATH}`,
      false,
      true
    );

    promise.then(
      () => {
        // resets the seller invite state
        setParamSellerInviteId('');
        setLoading(false);
      },
      (error: any) => {
        console.log(error);
        setLoading(false);
        setError(error.message);
      }
    );
  };

  const handleCreateWorkspaceSelfServeFreeTrial = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const promise = fetchPostRedirect(
      MIA_WORKSPACE_SELF_SERVE_TRIAL,
      {
        name: workspaceName,
        plan: planState.plan,
        period: planState.period,
        seat: planState.count,
        redirect: `${window.location.origin}${AUTH_CALLBACK_PATH}`,
        cancel_redirect: `${window.location.origin}${AUTH_WORKSPACE_SELECT}`
      },
      () => {
        setError('Error in creating workspace, please try again.');
      },
      `${window.location.origin}${AUTH_CALLBACK_PATH}`,
      false,
      true
    );

    promise.then(
      () => {
        setLoading(false);
      },
      (error: any) => {
        console.log(error);
        setLoading(false);
        setError(error.message);
      }
    );
  };

  if (isLoading) {
    return (
      <Grid container alignItems="center" justify="center">
        <CircularProgress size="40px" color="secondary" />
      </Grid>
    );
  }

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        xs={12}
        md={3}
        direction="column"
        justify="space-between"
        className={classes.imageContainer}>
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          alignItems="center"
          className={classes.sideBox}>
          <Box my={10} />
          <Box width="100%">
            {planState.isPlanSelected ? (
              <Box>
                {!paramSellerInviteId ? (
                  <Typography variant="h3" style={{ fontWeight: 600 }}>
                    Plan Summary
                  </Typography>
                ) : (
                  <Typography
                    variant="h3"
                    style={{ fontWeight: 600, color: TRENDPOP_PURPLE_3 }}>
                    You are
                    <br />
                    invited to
                  </Typography>
                )}
              </Box>
            ) : (
              <Box>
                <Typography
                  variant="h3"
                  style={{ fontWeight: 600, color: TRENDPOP_PURPLE_3 }}>
                  You haven't
                  <br />
                  selected a
                  <br />
                  plan yet.
                </Typography>
              </Box>
            )}
            {!paramSellerInviteId && (
              <SelectPlanModalButton
                planState={planState}
                setPlanState={setPlanState}
              />
            )}
            <Box my={10} />
            <Box marginBottom={2} display="flex" alignItems="center">
              <PlanIcon
                color={
                  upgradePlanColor[
                    planState.plan as keyof typeof upgradePlanColor
                  ]
                }
              />
              <Typography variant="h5" style={{ paddingLeft: 5 }}>
                {planNameToLabel(planState.plan)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="h2">
                {planState.rate === 0
                  ? 'Custom'
                  : planState.period === PlanPeriod.Monthly
                  ? `$${planState.rate}`
                  : `$${Math.round((planState.rate / 12) * 100) / 100}`}
              </Typography>
              <Typography variant="h4" display="inline" color="textSecondary">
                / month
              </Typography>
            </Box>
            <Typography variant="body1" color="textSecondary">
              {planState.rate === 0
                ? `${paramTrialPeriod ? paramTrialPeriod : '7'} days free trial`
                : planState.period === PlanPeriod.Monthly
                ? ' Billed Monthly.'
                : `Billed at $${planState.rate} annually.`}
            </Typography>
            <Box my={10} />
            <Box>
              {planState.plan === PlanName.Starter ? (
                <StarterFeatureCardDescription />
              ) : planState.plan === PlanName.Team ? (
                <TeamFeatureCardDescription />
              ) : planState.plan === PlanName.Business ? (
                <BusinessFeatureCardDescription />
              ) : (
                <EnterpriseFeatureCardDescription />
              )}
            </Box>
            <Box my={10} />
            <Box display="flex" alignItems="center">
              <Typography variant="body1">
                Got questions? Email us at{' '}
                <Link
                  className={classes.linkButton}
                  href={`mailto:${SUPPORT_EMAIL}`}>
                  {SUPPORT_EMAIL}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img
            height="40px"
            width="200px"
            className={classes.logo}
            alt="logo-dark"
            src="/static/img/logo-dark.png"
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={9} className={classes.formContainer}>
        <Box position="absolute" top={30} right={{ xs: 0, sm: 50 }}>
          <DashboardUserDropdown />
        </Box>
        <Box display="flex" flexDirection="column" className={classes.form}>
          <Box my={3} />
          <Typography variant="h3" style={{ fontWeight: 600 }}>
            Tell us about your Workspace
          </Typography>
          <Box my={2} />
          <TextField
            fullWidth
            variant="outlined"
            value={workspaceName}
            onChange={(e) => setWorkspaceName(e.target.value)}
            placeholder="Workspace Name*"
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
          <Box my={5} />
          <PopupModal
            onModalClose={() => setShowModal(false)}
            open={showModal}
            rootElement={document.getElementById('root')!}
            url={`${CALENDLY_UPSELL_LINK!}?first_name=${user.getFirstName()}&last_name=${user.getLastName()}&full_name=${user.getDisplayName()}&email=${encodeURIComponent(
              user.getEmail()
            )}`}
          />
          {paramSellerInviteId ? (
            <Button
              fullWidth
              disabled={loading || !workspaceName}
              onClick={handleCreateWorkspaceSellerInvite}
              variant="contained"
              color="primary"
              className={classes.submitButton}>
              Get Started
            </Button>
          ) : (
            <Box display="flex" justifyContent="space-between">
              {/* ENG-1138: Self checkout is hidden for enterprise tier and above. */}
              {planState.plan === PlanName.Enterprise ? (
                <Button
                  fullWidth
                  disabled={loading || !workspaceName}
                  onClick={() => setShowModal(true)}
                  variant="contained"
                  color="primary"
                  className={classes.bookDemoButton}>
                  Request A Free Trial
                </Button>
              ) : user.getUserHasTrialWorkspace() ? (
                <Button
                  fullWidth
                  disabled={loading || !workspaceName}
                  onClick={handleCreateWorkspace}
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}>
                  {loading ? <CircularProgress size="20px" /> : 'Checkout'}
                </Button>
              ) : (
                <Button
                  fullWidth
                  disabled={loading || !workspaceName}
                  onClick={handleCreateWorkspaceSelfServeFreeTrial}
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}>
                  {loading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Start My Trial'
                  )}
                </Button>
              )}
            </Box>
          )}
          <Box my={3} />
          <Box display="flex" justifyContent="center">
            <Typography variant="body1">
              Go back to{' '}
              <Link href="/workspace-select" className={classes.link}>
                Select a workspace
              </Link>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WorkspaceCreate;

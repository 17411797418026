import { Icon } from '@iconify/react';
import spotifyIcon from '@iconify-icons/mdi/spotify';

const SpotifyIcon = (props) => {
  const size = props.size || '1em';
  return (
    <Icon icon={spotifyIcon} color={props.color} width={size} height={size} />
  );
};

export default SpotifyIcon;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Button,
  Grid,
  Hidden,
  TextField,
  Link,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  CircularProgress,
  FormHelperText
} from '@material-ui/core';
import {
  TRENDPOP_PURPLE_3,
  TRENDPOP_PURPLE_4
} from '../../../constants/colors';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Banner from '../components/Banner';
import { resetPassword } from './queries';
import { Redirect } from 'react-router-dom';
import { useLoginLocalState } from '../useLoginLocalState';

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: 600,
    color: TRENDPOP_PURPLE_3
  },
  submitButton: {
    height: 50,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  },
  imageContainer: {
    backgroundColor: TRENDPOP_PURPLE_4,
    background: `url('/static/img/line-vector.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 20%',
    backgroundSize: '100%',
    objectFit: 'cover'
  },
  formContainer: {
    padding: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    maxWidth: '500px',
    width: '100%'
  }
}));

// At least 8 characters, 1 number, 1 upper case letter
const validatePassword = (password: string): boolean => {
  const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{8,}$/;
  return passwordPattern.test(password);
};

const ResetPassword = () => {
  const classes = useStyles();

  const { resetId: paramResetId } = useLoginLocalState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [passwordState, setPasswordState] = useState({
    password: '',
    confirmPassword: ''
  });
  const [showPasswordState, setShowPasswordState] = useState({
    password: false,
    confirmPassword: false
  });
  const [passwordStateError, setPasswordStateError] = useState({
    password: '',
    confirmPassword: ''
  });
  const [resetIdLoaded, setResetIdLoaded] = useState(false);

  useEffect(() => {
    setResetIdLoaded(true);
  }, [paramResetId, setResetIdLoaded]);

  if (!resetIdLoaded) {
    return (
      <Box mt={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  if (resetIdLoaded && !paramResetId) {
    return <Redirect to="/auth/login" />;
  }

  const handleResetPassword = () => {
    setError('');
    setLoading(true);

    const promise = resetPassword({
      reset_id: paramResetId,
      password: passwordState.password,
      repeat: passwordState.confirmPassword
    });

    promise.then(
      () => {
        setLoading(false);
        setIsSubmitted(true);
      },
      (error: any) => {
        console.log(error);
        setLoading(false);
        setError('Error in resetting password. Please try again.');
      }
    );
  };

  return (
    <Grid container justify="center">
      <Hidden smDown>
        <Grid item sm={3} className={classes.imageContainer}>
          <Banner />
        </Grid>
      </Hidden>
      <Grid item xs={10} sm={9} className={classes.formContainer}>
        <Box display="flex" flexDirection="column" className={classes.form}>
          {!isSubmitted ? (
            <>
              <Typography variant="h3" style={{ fontWeight: 600 }}>
                Reset Password
              </Typography>
              <Box my={2} />
              <Typography variant="h6" style={{ fontWeight: 600 }}>
                Your new password must be different from your previous used
                passwords.
              </Typography>
              <Box my={5} />
              <Typography>Password must contain:</Typography>
              <List
                style={{
                  listStyleType: 'disc',
                  listStylePosition: 'inside'
                }}>
                <ListItem
                  style={{
                    display: 'list-item',
                    padding: 0
                  }}>
                  at least 8 characters
                </ListItem>
                <ListItem
                  style={{
                    display: 'list-item',
                    padding: 0
                  }}>
                  at least 1 number
                </ListItem>
                <ListItem
                  style={{
                    display: 'list-item',
                    padding: 0
                  }}>
                  at least 1 uppercase letter
                </ListItem>
                <ListItem
                  style={{
                    display: 'list-item',
                    padding: 0
                  }}>
                  at least 1 lowercase letter
                </ListItem>
              </List>
              <Box my={3} />
              <Typography variant="h6" style={{ fontWeight: 600 }}>
                New Password*
              </Typography>
              <Box my={2} />
              <TextField
                required
                fullWidth
                variant="outlined"
                type={showPasswordState.password ? 'text' : 'password'}
                value={passwordState.password}
                onChange={(e) => [
                  setPasswordState((prevState) => ({
                    ...prevState,
                    password: e.target.value
                  })),
                  setPasswordStateError((prevState) => ({
                    ...prevState,
                    password:
                      validatePassword(e.target.value) || e.target.value === ''
                        ? ''
                        : 'Invalid password format'
                  })),
                  setError('')
                ]}
                error={Boolean(passwordStateError.password)}
                helperText={
                  passwordStateError.password ? passwordStateError.password : ''
                }
                placeholder="New Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPasswordState((prevState) => ({
                            ...prevState,
                            password: !prevState.password
                          }))
                        }
                        color="primary">
                        {showPasswordState.password ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Box my={3} />
              <Typography variant="h6" style={{ fontWeight: 600 }}>
                Confirm Password*
              </Typography>
              <Box my={2} />
              <TextField
                required
                fullWidth
                variant="outlined"
                type={showPasswordState.confirmPassword ? 'text' : 'password'}
                value={passwordState.confirmPassword}
                onChange={(e) => [
                  setPasswordState((prevState) => ({
                    ...prevState,
                    confirmPassword: e.target.value
                  })),
                  setPasswordStateError((prevState) => ({
                    ...prevState,
                    confirmPassword:
                      e.target.value === passwordState.password
                        ? ''
                        : 'Passwords do not match'
                  })),
                  setError('')
                ]}
                error={Boolean(passwordStateError.confirmPassword)}
                helperText={
                  passwordStateError.confirmPassword
                    ? passwordStateError.confirmPassword
                    : ''
                }
                placeholder="Confirm Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPasswordState((prevState) => ({
                            ...prevState,
                            confirmPassword: !prevState.confirmPassword
                          }))
                        }
                        color="primary">
                        {showPasswordState.confirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {error && <FormHelperText error>{error}</FormHelperText>}
              <Box my={5} />
              <Button
                disabled={
                  loading ||
                  passwordState.password === '' ||
                  passwordState.confirmPassword === '' ||
                  passwordStateError.confirmPassword !== '' ||
                  passwordStateError.password !== ''
                }
                variant="contained"
                color="primary"
                className={classes.submitButton}
                onClick={handleResetPassword}>
                {loading ? <CircularProgress size="20px" /> : 'Reset Password'}
              </Button>
              <Box my={5} />
              <Box display="flex">
                <Typography variant="body1">
                  Go back to{' '}
                  <Link href="/auth/login" className={classes.link}>
                    Login
                  </Link>
                </Typography>
              </Box>
            </>
          ) : (
            <Box>
              <Typography variant="h3" style={{ fontWeight: 600 }}>
                Password has been reset!
              </Typography>
              <Box my={2} />
              <Typography variant="h6" style={{ fontWeight: 600 }}>
                Your account's password has been resetted with a new password.
              </Typography>
              <Box my={5} />
              <Box display="flex">
                <Typography variant="body1">
                  Go back to{' '}
                  <Link href="/auth/login" className={classes.link}>
                    Login
                  </Link>
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;

import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  makeStyles,
  Button,
  Grid,
  Link,
  IconButton,
  CircularProgress,
  FormHelperText,
  Avatar
} from '@material-ui/core';
import {
  TRENDPOP_GREY_2,
  TRENDPOP_PURPLE_3,
  TRENDPOP_PURPLE_4,
  TRENDPOP_WHITE
} from '../../../constants/colors';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import { AUTH_CALLBACK_PATH, MIA_LOGOUT, MIA_USER } from 'constants/links';
import { useUser } from 'context/hooks/user';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { useWorkspace } from 'api/workspace';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    height: 50,
    borderRadius: 30,
    color: 'white',
    backgroundColor: TRENDPOP_PURPLE_3
  },
  linkButton: {
    fontWeight: 600,
    textDecoration: 'underline'
  },
  imageContainer: {
    backgroundColor: TRENDPOP_GREY_2,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 20%',
    backgroundSize: '100%',
    objectFit: 'cover'
  },
  sideBox: {
    maxWidth: '100%',
    height: 'calc(100vh - 40px)',
    padding: theme.spacing(10)
  },
  logo: {
    position: 'relative',
    bottom: 15
  },
  formContainer: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    maxWidth: '450px',
    width: '100%'
  },
  avatar: {
    width: 120,
    height: 120
  }
}));

interface ImageDisplayProps {
  imageUrl: string;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({ imageUrl }) => {
  const classes = useStyles();
  return (
    <Avatar className={classes.avatar} src={imageUrl} alt="profile-picture" />
  );
};

const SignUpInvitation = () => {
  const classes = useStyles();

  const { user, isLoading: userLoading, error: userDataError } = useUser();

  const workspaceId = user && !userLoading ? user.getWorkspaceId() : null;
  const {
    data: workspaceResponse,
    isLoading,
    error: workspaceError
  } = useWorkspace(workspaceId);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  if (userLoading || isLoading) {
    return (
      <Grid container alignItems="center" justify="center">
        <CircularProgress size="40px" color="secondary" />
      </Grid>
    );
  }

  if (
    user.getUserId() === undefined ||
    userDataError ||
    workspaceError ||
    !workspaceResponse
  ) {
    return <Redirect to="/auth/login" />;
  }

  if (user.getIsNameVerified()) {
    return <Redirect to="/auth/callback" />;
  }

  const handleFileChange = (e: any) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      if (
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg'
      ) {
        setSelectedFile(file);
        const url = URL.createObjectURL(file);
        setImageUrl(url);
      } else {
        setError('Only image files are allowed');
      }
    }
  };

  const handlePatchUser = async () => {
    setLoading(true);
    try {
      const formData = new FormData();

      if (selectedFile) {
        formData.append('picture', selectedFile);
      }

      formData.append('first_name', firstName);
      formData.append('last_name', lastName);

      await axios
        .patch(MIA_USER, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true
        })
        .then(() => {
          window.location.href = `${window.location.origin}${AUTH_CALLBACK_PATH}`;
        });
    } catch (error: any) {
      console.log('error: ', error);
      setError(error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container justify="center">
      <Grid
        container
        item
        xs={12}
        md={3}
        direction="column"
        justify="space-between"
        className={classes.imageContainer}>
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          className={classes.sideBox}>
          <Box my={20} />
          <Typography
            align="left"
            variant="h3"
            style={{ color: TRENDPOP_PURPLE_3 }}>
            You're invited to join
          </Typography>
          <Typography variant="h3">
            {workspaceResponse?.workspace.name}
          </Typography>
          <Typography variant="h3" style={{ color: TRENDPOP_PURPLE_3 }}>
            Workspace
          </Typography>
          <Box my={5} />
          <Box width="100%">
            <Typography variant="body1" align="left">
              {user.getEmail()}{' '}
              <Link className={classes.linkButton} href={MIA_LOGOUT}>
                Logout
              </Link>
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img
            height="40px"
            width="200px"
            className={classes.logo}
            alt="logo-dark"
            src="/static/img/logo-dark.png"
          />
        </Box>
      </Grid>
      <Grid item xs={10} md={9} className={classes.formContainer}>
        <Box display="flex" flexDirection="column" className={classes.form}>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box my={1} />
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <input
                    type="file"
                    accept=".png,.jpeg,.jpg"
                    style={{ display: 'none' }}
                    id="image-upload"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="image-upload">
                    <IconButton
                      color="primary"
                      component="span"
                      style={{
                        width: 120,
                        height: 120,
                        backgroundColor: imageUrl
                          ? TRENDPOP_WHITE
                          : TRENDPOP_PURPLE_4
                      }}>
                      {imageUrl ? (
                        <ImageDisplay imageUrl={imageUrl} />
                      ) : (
                        <AddPhotoAlternateOutlinedIcon
                          color="primary"
                          style={{
                            fontSize: 50
                          }}
                        />
                      )}
                    </IconButton>
                  </label>
                </Grid>
              </Grid>
            </Box>
            <Box my={1} />
            <input
              type="file"
              accept=".png,.jpeg,.jpg"
              style={{ display: 'none' }}
              id="image-upload"
              onChange={handleFileChange}
            />
            <label htmlFor="image-upload">
              <Typography
                variant="body1"
                style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                Add Photo
              </Typography>
            </label>
          </Box>
          <Box my={5} />
          <Box>
            <Typography variant="h3" style={{ fontWeight: 600 }}>
              Tell us your name
            </Typography>
            <Box my={5} />
            <Box display="flex">
              <TextField
                fullWidth
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First name"
              />
              <Box mx={1} />
              <TextField
                fullWidth
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last name"
              />
            </Box>
          </Box>
          {error && <FormHelperText error>{error}</FormHelperText>}
          <Box my={5} />
          <Button
            disabled={loading || !firstName || !lastName}
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={handlePatchUser}>
            {loading ? <CircularProgress size="20px" /> : 'Join Workspace'}
          </Button>
          <Box my={5} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignUpInvitation;

import React from 'react';

const ServerFailureIcon = (props) => {
  const size = props.size || '75px';
  return (
    <img
      style={{ maxHeight: size, filter: 'opacity(75%)' }}
      src="/static/img/icons/server-failure.png"
      alt="server failure icon"
    />
  );
};

export default ServerFailureIcon;

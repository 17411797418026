import { Icon } from '@iconify/react';
import linkedInFill from '@iconify-icons/mdi/linkedin';
import { TRENDPOP_GREY } from 'constants/colors';

interface LinkedInIconProps {
  color?: string;
  size?: string;
}

const LinkedInIcon = (props: LinkedInIconProps) => {
  const color = props.color || TRENDPOP_GREY;
  const size = props.size || '1em';

  return <Icon icon={linkedInFill} color={color} width={size} height={size} />;
};

export default LinkedInIcon;

import React from 'react';
import { Box, Typography, Tooltip } from '@material-ui/core';

const lightbulbDescription = `This feature is powered by Trendpop audience intelligence.`;

const LightbulbIcon = (props) => {
  const size = props.size || 16;
  const inverted = props.inverted;

  return (
    <Tooltip
      title={
        <Box p={1}>
          <Typography>{lightbulbDescription}</Typography>
        </Box>
      }>
      <img
        style={{
          filter: inverted ? 'invert(1)' : 'invert(0)',
          cursor: 'pointer'
        }}
        alt="AI Prediction"
        src="/static/img/icons/lightbulb.png"
        height={size}
        width={size}
      />
    </Tooltip>
  );
};

export default LightbulbIcon;

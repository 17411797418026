import React, { useState } from 'react';
import { Box, Button, Modal, Paper, Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { MIA_LOGOUT } from 'constants/links';
import { useLoginLocalState } from 'pages/accounts/useLoginLocalState';
import {
  removeAddItemTutorial,
  removeAnnouncementsVersionKey,
  removeChartTutorial,
  removeFirstTimeLoggedIn
} from 'components/Announcements/utils';

interface LoggedOutModalProps {
  open: boolean;
  onClose?: () => void;
}

const LoggedOutModal = (props: LoggedOutModalProps) => {
  const { open } = props;

  const { setWorkspaceId } = useLoginLocalState();
  const [redirectToLogin, setRedirectToLogin] = useState(false); // State to manage redirection

  const handleLoginAgain = () => {
    setWorkspaceId('');
    setRedirectToLogin(true);
    removeAnnouncementsVersionKey();
    removeFirstTimeLoggedIn();
    removeChartTutorial();
    removeAddItemTutorial();
  };

  if (redirectToLogin) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <Modal
      open={open}
      // prevents from clicking outside
      disableBackdropClick>
      <Paper
        onClick={(e) => e.stopPropagation()}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          padding: '20px',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 400
        }}>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column">
          <Typography variant="h5">You've been logged out</Typography>
          <Box my={2} />
          <Button
            variant="contained"
            color="primary"
            href={MIA_LOGOUT}
            onClick={handleLoginAgain}
            style={{
              marginTop: 10
            }}>
            Back To Login
          </Button>
          <Box my={2} />
        </Box>
      </Paper>
    </Modal>
  );
};

export default LoggedOutModal;

import { useQuery } from 'react-query';
import axios from 'axios';
import { MiaWorkspacesQuery } from '../../models/workspaceSelect';
import { MIA_WORKSPACES_LIST } from '../../constants/links';

export const useWorkspacesQuery = () => {
  return useQuery<MiaWorkspacesQuery>(['organizations'], async () => {
    return await axios
      .get<MiaWorkspacesQuery>(MIA_WORKSPACES_LIST, {
        withCredentials: true
      })
      .then((r) => r.data);
  });
};

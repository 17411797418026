import { useContext } from 'react';
import { TrendpopUserContext } from '../user';

export const useUser = () => {
  const [user, isLoading, error] = useContext(TrendpopUserContext);
  if (!user) {
    throw new Error(
      'Cannot use useUser hook (User context) outside of TrendpopUserProvider!'
    );
  }
  return { user, isLoading, error };
};

export const useFreeTrialCheck = () => {
  const { user } = useUser();
  return user.billingSelfServeTrial();
};

export const useGranularDemographics = () => {
  const { user } = useUser();
  return user.featureGranularDemographics();
};

export const useVideoDiscoveryContactLinks = () => {
  const { user } = useUser();
  return user.featureVideoDiscoveryContactLinks();
};

export const useSongAlbumArtistCreatorLocator = () => {
  const { user } = useUser();
  return user.featureVideoDiscoveryContactLinks();
};

export const useCollectionsLimit = (): number | undefined => {
  const { user } = useUser();
  return user.limitCollections();
};

export const useCampaignsLimit = (): number | undefined => {
  const { user } = useUser();
  return user.limitCampaigns();
};

export const useSavedQueriesLimit = (): number | undefined => {
  const { user } = useUser();
  return user.limitSavedQueries();
};
